// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, RouteComponentProps } from 'react-router'

// ------------------------- COMPONENTS --------------------------------------
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import Button, { ButtonDefinition } from '@mv-submodules/inplant-components-fe/ui/components/Button/Button'
import Modal, { FormModal } from '@mv-submodules/inplant-components-fe/ui/components/Modal/Modal'

// ------------------------- MODULE --------------------------------------

import { Cargo, CargoDirections } from '../../../../types/Cargo'
import Filters from '../../../../types/Filters'
import { CommonStore, ConfigManagerStore } from '../../../../types/Store'
import {
  cargoOpsFetchCargo,
  cargoOpsCheckoutCargo,
  cargoOpsRequeueCargo,
  cargoOpsPrintCheckout,
} from '../../../../redux/actions/cargoOps'
import { FieldReadOnly, TextAreaReadOnly } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/Field'
import { imageUrl, listImages } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { handleDownloadImages } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'
import RemoteImage from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/RemoteImage'
import { Input, TextArea } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/Inputs'

library.add(faCircleNotch)

interface StateProps {
  filters: Filters
  common: CommonStore
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchCargo: (cargoId: string, direction: CargoDirections) => Promise<Cargo>
  checkoutCargo: (cargo: Cargo, direction: CargoDirections, handledWeightKg: number) => Promise<void>
  requeueCargo: (cargo: Cargo, direction: CargoDirections) => Promise<void>
  printCargo: (cargoId: string, direction: CargoDirections) => Promise<Blob>
  listImages: (direction: CargoDirections, truck: Cargo) => Promise<string[]>
  imageUrl: (direction: CargoDirections, imageId: string, truck: Cargo) => Promise<string>
}

interface OwnState {
  isPrinting: boolean
  isFetching: boolean
  cargo: Cargo | null
  direction: CargoDirections
  handledWeightKg: number
  isLoadingImages: boolean
  images: Array<{ imageId: string; imageURL: string | undefined }>

  showModal: { type: 'Checkout' } | { type: 'FullScreenImage'; imageURL: string }

  imagesToDownload: Array<{ imageId: string; imageURL: string }>
}

interface OwnProps extends RouteComponentProps<any> {
  cargoId: string
  onClose: (refreshData?: boolean) => void
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchCargo: (cargoId, direction) => dispatch(cargoOpsFetchCargo(cargoId, direction)),
    checkoutCargo: (cargo, direction, handledWeightKg) =>
      dispatch(cargoOpsCheckoutCargo(cargo, direction, handledWeightKg)),
    requeueCargo: (cargo, direction) => dispatch(cargoOpsRequeueCargo(cargo, direction)),
    printCargo: (cargoId, direction) => dispatch(cargoOpsPrintCheckout(cargoId, direction)),
    listImages: (direction, truck) => dispatch(listImages(direction, truck)),
    imageUrl: (direction: CargoDirections, imageId: string, truck: Cargo) =>
      dispatch(imageUrl(direction, imageId, truck)),
  }
}

class CheckoutCargoModal extends React.Component<Props, OwnState> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      isPrinting: false,
      isFetching: false,
      isLoadingImages: false,
      cargo: null,
      direction: props.filters.direction,
      handledWeightKg: 0,
      images: [],
      showModal: { type: 'Checkout' },
      imagesToDownload: [],
    }
    this.handleNoteChange = this.handleNoteChange.bind(this)
    this.handleCheckoutCargo = this.handleCheckoutCargo.bind(this)
    this.handleRequeueCargo = this.handleRequeueCargo.bind(this)
    this.handlePrintCargoCheckout = this.handlePrintCargoCheckout.bind(this)
    this.checkForkliftDriverNote = this.checkForkliftDriverNote.bind(this)
  }

  public async componentDidMount() {
    this.props
      .fetchCargo(this.props.cargoId, this.props.filters.direction)
      .then(cargo => {
        this.setState({ cargo })
      })
      .catch(e => {
        console.warn(e) //tslint:disable-line
      })
  }

  private handleNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    e.persist()
    this.setState(prevState => {
      const cargo = prevState.cargo as Cargo
      cargo.note = e.target.value
      return { ...prevState, cargo }
    })
  }

  private async handleCheckoutCargo(e: React.FormEvent<HTMLFormElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget.checkValidity()) {
      try {
        this.setState({ isFetching: true })
        await this.props.checkoutCargo(this.state.cargo!, this.state.direction, this.state.handledWeightKg)
        this.setState({ isFetching: false })
        this.props.onClose(true)
      } catch (error) {
        console.error(error) // tslint:disable-line
        this.setState({ isFetching: false })
      }
    }
  }

  private async handleRequeueCargo(e: React.FormEvent<HTMLButtonElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    try {
      this.setState({ isFetching: true })
      await this.props.requeueCargo(this.state.cargo!, this.state.direction)
      this.setState({ isFetching: false })
      this.props.onClose(true)
    } catch (error) {
      console.error(error) // tslint:disable-line
      this.setState({ isFetching: false })
    }
  }

  private async handlePrintCargoCheckout() {
    try {
      this.setState({ isPrinting: true })
      const cargo = this.state.cargo as Cargo
      const blob = await this.props.printCargo(cargo.id!, this.state.direction)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const title = `${this.props.filters.date.format('YYYYMMDD')} - ${this.props.t(
        `mcs.cargo.direction.${this.props.filters.direction}`
      )} - ${cargo.plate}`
      link.href = objectURL
      link.download = `${title}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error(error) // tslint:disable-line
    }
    this.setState({ isPrinting: false })
  }

  /***
   * Use this function to choose if show in red the forklift notes, by checking if the notes has
   * one of the phases inside specialNotes
   * @param note
   */
  private checkForkliftDriverNote(note: string) {
    const specialNotes = [
      'Mezzo con materiale a bordo',
      'Autista senza AV',
      'Autista senza scarpe antinfortunistiche',
      'Autista fuma',
      "Rifiuta l'uso di transenna",
      'Odore forte',
    ]
    const normalize = (x: string) => x.toLowerCase().replace(' ', '')
    return specialNotes.some(x => normalize(note).includes(normalize(x)))
  }

  public render() {
    const { cargo } = this.state

    let additionalFooterButtons: ButtonDefinition[] = [
      {
        disabled: this.state.isFetching,
        onClick: this.handleRequeueCargo,
        variant: 'danger',
        isLoading: this.state.isFetching,
        label: this.props.t('mcs.cargo.actions.requeue'),
      },
      {
        disabled: this.state.isPrinting || this.state.isFetching,
        onClick: this.handlePrintCargoCheckout,
        variant: 'success',
        isLoading: this.state.isPrinting,
        label: this.props.t('mcs.cargo.actions.print'),
      },
    ]

    if (this.state.imagesToDownload.length > 0) {
      additionalFooterButtons = [
        {
          onClick: () => handleDownloadImages(this.state.imagesToDownload),
          variant: 'success',
          label: this.props.t('mcs.cargo.actions.downloadImages'),
        },
        ...additionalFooterButtons,
      ]
    }

    return (
      <>
        <FormModal
          width={75}
          visible={this.state.showModal.type === 'Checkout'}
          onClose={this.props.onClose}
          onSubmit={this.handleCheckoutCargo}
          submitButton={{
            label:
              this.state.direction === 'transfer' &&
              this.state.cargo?.status === 'ready_to_transfer' /*// TODO sistemare*/
                ? this.props.t('mcs.cargo.actions.checkout_transfer')
                : this.props.t('mcs.cargo.actions.checkout'),
            disabled: this.state.isFetching,
            isLoading: this.state.isFetching,
          }}
          title={this.props.t('mcs.modals.checkoutCargo.title')}
          closeLabel={this.props.t('mcs.modals.close')}
          additionalFooterButtons={additionalFooterButtons}
        >
          {this.state.isFetching || cargo === null ? (
            <div className="text-center">
              <FontAwesomeIcon icon={faCircleNotch} spin={true} size="3x" className="text-secondary" />
            </div>
          ) : (
            <Row spacing={{ vertical: false }}>
              <Column xs={6}>
                {this.props.configManager.data.progressiveTruckNumberEnabled && (
                  <FieldReadOnly
                    label={this.props.t('mcs.cargo.progressiveNumber.placeholder')}
                    value={
                      cargo.progressiveNumber
                        ? cargo.progressiveNumber
                        : (this.props.t('mcs.cargo.progressiveNumber.undefined') as string)!
                    }
                  />
                )}

                <FieldReadOnly
                  label={this.props.t('mcs.cargo.arrivalDate')}
                  value={moment(this.state.cargo!.arrivalDate).format('DD/MM/YYYY')}
                />
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.direction.label')}
                  value={this.props.t(`mcs.cargo.direction.${this.props.filters.direction}`) as string}
                />
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.materialType.label')}
                  value={cargo.materialType!.description}
                />
                <FieldReadOnly label={this.props.t('mcs.cargo.ticketNumber.label')} value={cargo.ticketNumber || ''} />

                {this.state.direction === 'incoming' ? (
                  <FieldReadOnly label={this.props.t('mcs.cargo.producer.label')} value={cargo.producer || ''} />
                ) : (
                  <FieldReadOnly label={this.props.t('mcs.cargo.recipient.label')} value={cargo.recipient || ''} />
                )}

                <FieldReadOnly label={this.props.t('mcs.cargo.carrier.label')} value={cargo.carrier || ''} />
                <div
                  className="form-group"
                  id={
                    this.state.cargo &&
                    ((this.state.cargo.forkliftDriverNote &&
                      this.checkForkliftDriverNote(this.state.cargo.forkliftDriverNote)) ||
                      this.state.cargo.truckAnomalies)
                      ? 'forklift-driver-or-truck-anomalies-note'
                      : 'forklift-driver-or-truck-anomalies-nonote'
                  }
                >
                  <Row verticalAlignment={'center'} spacing={{ vertical: false }}>
                    <Column sm={4}>
                      <label className="col-form-label">
                        {this.props.t('mcs.cargo.forkliftDriverNote.label')}
                        <br />
                        {cargo.forkliftDriverDisplayName ? <small>({cargo.forkliftDriverDisplayName})</small> : null}
                      </label>
                    </Column>
                    <Column sm={8}>
                      <textarea
                        className="form-control-plaintext"
                        rows={2}
                        readOnly={true}
                        value={
                          (cargo.forkliftDriverNote || '') + (cargo.truckAnomalies ? '\n' + cargo.truckAnomalies : '')
                        }
                      />
                    </Column>
                  </Row>
                </div>
                <TextAreaReadOnly
                  label={this.props.t('mcs.cargo.materialAnomalies.label')}
                  value={
                    this.state.cargo &&
                    this.state.cargo.materialAnomalies &&
                    this.props.configManager.data.materialComplianceEnabled
                      ? this.state.cargo.materialAnomalies.trim()
                      : ''
                  }
                  rows={2}
                />
              </Column>
              <Column xs={6}>
                <FieldReadOnly label={this.props.t('mcs.cargo.plate.label')} value={this.state.cargo!.plate || ''} />
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.parking.label')}
                  value={this.state.cargo!.parking || ''}
                />

                {this.props.filters.direction === 'incoming' ? (
                  <>
                    <FieldReadOnly
                      label={this.props.t('mcs.cargo.samplingRequired.label')}
                      value={
                        this.props.t(
                          this.state.cargo!.samplingRequired
                            ? 'mcs.cargo.samplingRequired.yes'
                            : 'mcs.cargo.samplingRequired.no'
                        ) as string
                      }
                      valueVariant={
                        this.state.cargo!.samplingConfirmed !== this.state.cargo!.samplingRequired
                          ? 'danger'
                          : undefined
                      }
                    />

                    <FieldReadOnly
                      label={this.props.t('mcs.cargo.samplingConfirmed.label')}
                      valueVariant={
                        this.state.cargo!.samplingConfirmed !== this.state.cargo!.samplingRequired
                          ? 'danger'
                          : undefined
                      }
                      value={
                        this.props.t(
                          this.state.cargo!.samplingConfirmed
                            ? 'mcs.cargo.samplingConfirmed.yes'
                            : 'mcs.cargo.samplingConfirmed.no'
                        ) as string
                      }
                    />
                    {
                      <FieldReadOnly
                        label={this.props.t('mcs.cargo.basin.label')}
                        value={this.state.cargo!.basin || ''}
                      />
                    }
                  </>
                ) : null}
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.packages.label')}
                  value={this.state.cargo!.packages || ''}
                />
                <TextArea slug={'note'} value={cargo.note || ''} onChange={this.handleNoteChange} />
                {this.props.configManager.data.handleWeightKg ? (
                  <Input
                    slug={'handledWeightKg'}
                    onChange={e => {
                      this.setState({ handledWeightKg: Number(e.currentTarget.value) })
                    }}
                    type={'number'}
                    value={this.state.handledWeightKg.toString() || ''}
                    onKeyDown={e => {
                      if (e.key === '.' || e.key === ',') {
                        e.preventDefault()
                      }
                    }}
                  />
                ) : null}
              </Column>
              {(cargo.images.length > 0 && (
                <Column xs={12}>
                  {this.state.images.length === 0 && (
                    <Row horizontalAlignment={'start'} verticalAlignment={'center'}>
                      <Column xs={12}>
                        <Button
                          spacing={{ horizontal: false }}
                          isLoading={this.state.isLoadingImages}
                          label={this.props.t('mcs.modals.checkoutCargo.showImages')}
                          variant={'primary'}
                          onClick={() => {
                            this.setState({ isLoadingImages: true })
                            this.props
                              .listImages(this.props.filters.direction, cargo)
                              .then(imagesId =>
                                this.setState({
                                  images: imagesId.map(id => ({ imageId: id, imageURL: undefined })),
                                  isLoadingImages: false,
                                })
                              )
                              .catch(_e => {
                                this.setState({ isLoadingImages: false })
                              })
                          }}
                        />
                      </Column>
                    </Row>
                  )}
                  <Row>
                    {this.state.images.map((objImage, index) => {
                      return (
                        <Column xs={3} key={objImage.imageId}>
                          <RemoteImage
                            imageURL={objImage.imageURL}
                            onImageLoaded={(imageURL: string) => {
                              const newImages = [...this.state.images]
                              newImages[index] = { imageId: newImages[index].imageId, imageURL }
                              this.setState({ images: newImages })
                            }}
                            onSelectImage={imageURL => {
                              this.setState({ showModal: { type: 'FullScreenImage', imageURL }, imagesToDownload: [] })
                            }}
                            onCheckedImage={(imageURL, isSelected) => {
                              const indexFound = this.state.imagesToDownload.findIndex(
                                img => img.imageId === objImage.imageId
                              )
                              let newImageURLS = [...this.state.imagesToDownload]
                              if (isSelected && indexFound >= 0) {
                                newImageURLS[indexFound] = { imageId: objImage.imageId, imageURL }
                              } else if (!isSelected && indexFound >= 0) {
                                newImageURLS = newImageURLS.filter(el => el.imageId !== objImage.imageId)
                              } else if (isSelected && indexFound < 0) {
                                newImageURLS.push({ imageId: objImage.imageId, imageURL })
                              }
                              this.setState({ imagesToDownload: newImageURLS })
                            }}
                            direction={this.props.filters.direction}
                            truck={cargo}
                            imageId={objImage.imageId}
                          />
                        </Column>
                      )
                    })}
                  </Row>
                </Column>
              )) ||
                null}
            </Row>
          )}
        </FormModal>
        {(this.state.showModal.type === 'FullScreenImage' && (
          <Modal
            width={75}
            visible={true}
            title={this.props.t('mcs.modals.checkoutCargo.modal.title')}
            onClose={() => {
              this.setState({ showModal: { type: 'Checkout' } })
            }}
            closeLabel={this.props.t('mcs.modals.checkoutCargo.modal.closeLabel')}
          >
            <img style={{ minWidth: '100%' }} src={this.state.showModal.imageURL} />
          </Modal>
        )) ||
          null}
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CheckoutCargoModal)))
