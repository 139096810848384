import { RouteComponentProps } from 'react-router'
import { ChecklistAssetStateProps } from './assets'
import { StateProps as IndexStateProps } from '../ui/components/views/ChecklistIndex/ChecklistIndexPageView'
import { StateProps as EditStateProps } from '../ui/components/views/ChecklistEdit/ChecklistEdit'
import { StateProps as ArchiveStateProps } from '../ui/components/views/ChecklistArchive/ChecklistArchivePageView'
import {
  NumCalcComponentDefaultValue,
  NumCalcComponentField,
  NumCalcComponentLastValue,
} from '../ui/components/widgets/NumberCalculatorComponent/NumberCalculatorComponent'

export interface ChecklistStateProps {
  index: IndexStateProps
  edit: EditStateProps
  asset: ChecklistAssetStateProps
  archive: ArchiveStateProps
}

export interface LastCompilationData {
  concludedDate: string
  isBlocking: boolean
  user: {
    displayName: string
    id: string
  }
}

export interface ChecklistModelFilter {
  checklist?: string
  asset?: string
}

export interface ChecklistSetupFilter {
  checklist?: string
}

export interface ChecklistArchive {
  title?: string
}

export interface ChecklistModelParams {
  id: string
  title: string
  checklistInstanceId: string | null
  enabled: boolean
  hasAssets: boolean
  lastCompilationData?: LastCompilationData
  differentUserOpenChecklist: {
    createdDate: string
    id: string
    user: {
      id: string
      displayName: string
    }
  } | null
}

export class ChecklistModel {
  public id: string
  public title: string
  public checklistInstanceId: string | null
  public enabled: boolean
  public hasAssets: boolean
  public differentUserOpenChecklist: {
    createdDate: string
    id: string
    user: {
      id: string
      displayName: string
    }
  } | null
  public lastCompilationData?: LastCompilationData

  constructor(params: ChecklistModelParams) {
    this.id = params.id
    this.title = params.title
    this.checklistInstanceId = params.checklistInstanceId
    this.enabled = params.enabled
    this.hasAssets = params.hasAssets
    this.differentUserOpenChecklist = params.differentUserOpenChecklist
    this.lastCompilationData = params.lastCompilationData
  }
}

export interface ChecklistAsset {
  id: string
  code: string
  description: string
  positionCode: string
}

export interface ChecklistComponent {
  id?: string
  optional: boolean
  type: {
    type: string
    value?: number
    values?: string[]
    fields?: NumCalcComponentField[]
    options?: string[]
    beaconId?: string
  }
  defaultValue: string | number | NumCalcComponentDefaultValue
  lastValue: string | number | NumCalcComponentLastValue
  title?: string | null
}

export interface ChecklistSectionCheck {
  id: string
  description: string
  components: ChecklistComponent[]
  fillingTime: string | undefined
  restorationTime: {
    default: string | null
    editable: string | null
  }
  visibilityStep: string
  canBeFilled: boolean
  lastFilledAtTime: string | null
  lastFilledByUser: { id: string; displayName: string } | null
  visible: boolean
  isCustom: boolean
  isValid: boolean
  isBlocking: boolean
  generateEmailAlert: boolean
}

export interface ChecklistSection {
  id: string
  title: string
  checks: ChecklistSectionCheck[]
  customCheckPrototype: object | null
  UILayoutParams: {
    bigText: boolean
    reducedVerticalSpace: boolean
    increasedComponentWidths: boolean
    hideComponentTitles: boolean
  } | null
  attributes: Array<{ name: string; value: string }> | null
}

export interface ChecklistUser {
  id: string
  displayName: string
}

export interface ChecklistInstanceParams {
  id: string | null
  title: string | null
  checklistModelId?: string
  asset?: ChecklistAsset
  compilerRoles?: string[]
  validationRoles?: string[]
  attributes?: string[]
  sections?: ChecklistSection[]
  compilationTime?: string
  user?: ChecklistUser
  createdDate?: string | null
  concludedDate?: string | null
  possibleStates?: Array<{ name: string; action: string }>
  state?: string
  invalidChecksCount?: number
  lastValidation?: any
  validationFields?: ChecklistComponent[]
  canBeValidated?: boolean
  proximityEnabled?: boolean
  header?: string | null
  generateAlertEmail?: boolean 
}

export interface ChecklistSummaryParams {
  title: string
  asset?: ChecklistAsset
  anomalies?: ChecklistAnomaly[]
  status: string
  user?: ChecklistUser
  date?: string
  checklistId?: string
}

export interface ChecklistAnomaly {
  description: string
  note?: string
}

export class ChecklistSummaryInstance {
  public title: string
  public asset: ChecklistAsset | undefined
  public anomalies: ChecklistAnomaly[] | undefined
  public status: string
  public user: ChecklistUser | undefined
  public date: string | undefined
  public checklistId: string | undefined
  public color?: string

  constructor(params: ChecklistSummaryParams) {
    this.title = params.title
    this.asset = params.asset
    this.anomalies = params.anomalies
    this.status = params.status
    this.user = params.user
    this.date = params.date
    this.checklistId = params.checklistId
    if (params.status === 'not_concluded') {
      this.color = '#fef6dd'
    }
    if (params.status === 'open_with_anomalies' || params.status === 'concluded_with_anomalies') {
      this.color = '#f97f8d'
    }
  }
}

export class ChecklistInstance {
  public id: string | null
  public title: string | null
  public checklistModelId: string | undefined
  public asset: ChecklistAsset | undefined
  public compilerRoles: string[] | undefined
  public validationRoles: string[] | undefined
  public attributes: string[] | undefined
  public sections: ChecklistSection[] | undefined
  public compilationTime: string | undefined
  public user: ChecklistUser | undefined
  public createdDate: string | null | undefined
  public concludedDate: string | null | undefined
  public possibleStates: Array<{ name: string; action: string }> | undefined
  public state: string | undefined
  public invalidChecksCount: number | undefined
  public lastValidation?: any
  public validationFields?: ChecklistComponent[]
  public canBeValidated?: boolean
  public proximityEnabled?: boolean
  public header: string | null | undefined
  public generateAlertEmail: boolean

  constructor(params: ChecklistInstanceParams) {
    this.id = params.id
    this.title = params.title
    this.checklistModelId = params.checklistModelId
    this.asset = params.asset
    this.compilerRoles = params.compilerRoles
    this.validationRoles = params.validationRoles
    this.attributes = params.attributes
    this.sections = params.sections
    this.compilationTime = params.compilationTime
    this.user = params.user
    this.createdDate = params.createdDate
    this.concludedDate = params.concludedDate
    this.possibleStates = params.possibleStates
    this.state = params.state
    this.invalidChecksCount = params.invalidChecksCount
    this.lastValidation = params.lastValidation
    this.validationFields = params.validationFields
    this.canBeValidated = params.canBeValidated
    this.proximityEnabled = params.proximityEnabled
    this.header = params.header
    this.generateAlertEmail = params.generateAlertEmail || false
  }

  public currentState() {
    return this.possibleStates!.find(item => item.name === this.state) as {
      name: string
      action: string
    }
  }

  public hasNextState() {
    const currentIndex = this.possibleStates!.findIndex(item => item.name === this.state)
    return this.possibleStates!.length > currentIndex + 1
  }

  public isConcluded() {
    return typeof this.concludedDate === 'string'
  }
}

export class PartialChecklistInstance {
  public id: string

  constructor(params: { id: string }) {
    this.id = params.id
  }
}

export interface ChecklistAssetItemParams {
  model: ChecklistModel
  asset: ChecklistAsset | null
  enableQuickCompilation: boolean
}

export class ChecklistAssetItem {
  public model: ChecklistModel
  public asset: ChecklistAsset | null
  public enableQuickCompilation: boolean

  constructor(params: ChecklistAssetItemParams) {
    this.model = params.model
    this.asset = params.asset
    this.enableQuickCompilation = params.enableQuickCompilation
  }
}

export interface ChecklistSectionCheckComponentItemStateProps {
  componentData: ChecklistComponent
  checklistInstanceId: string
  checkId: string
}

export interface ChecklistSectionCheckComponentOwnProps extends RouteComponentProps<any> {
  disabled: boolean
}

export interface ChecklistSectionCheckComponentItemOwnState {
  currentValue: string
  loading: boolean
}

export interface ChecklistSetupItemChecklistParams {
  id: string
  title: string
}

export interface ChecklistSetupItemParams {
  id: string | null
  code: string | null
  positionCode: string | null
  description: string | null
  model: string | null
  brand: string | null
  serialNumber: string | null
  status: string | null
  checklistModels: ChecklistSetupItemChecklist[] | null
}

export class ChecklistSetupItemChecklist {
  public id: string
  public title: string

  constructor(params: ChecklistSetupItemChecklistParams) {
    this.id = params.id
    this.title = params.title
  }
}

export class ChecklistSetupItem {
  public id: string | null
  public code: string | null
  public positionCode: string | null
  public description: string | null
  public model: string | null
  public brand: string | null
  public serialNumber: string | null
  public status: string | null
  public checklistModels: ChecklistSetupItemChecklist[] | null

  constructor(params: ChecklistSetupItemParams) {
    this.id = params.id || null
    this.code = params.code || null
    this.positionCode = params.positionCode || null
    this.description = params.description || null
    this.model = params.model || null
    this.brand = params.brand || null
    this.serialNumber = params.serialNumber || null
    this.status = params.status || null
    this.checklistModels = params.checklistModels
      ? params.checklistModels.map(checklistModel => new ChecklistSetupItemChecklist(checklistModel))
      : []
  }
}

export interface ChecklistSetupModelItemParams {
  id: string
  title: string
}

export class ChecklistSetupModelItem {
  public id: string
  public title: string

  constructor(params: ChecklistSetupModelItemParams) {
    this.id = params.id
    this.title = params.title
  }
}
