// * ------------------- COMPONENTS -----------------------------
import { mvDate } from '../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import Halt, { IHalt } from './halt'
import Process, { IProcess } from './process'
import { ISlot } from './slot'
import {EmptySlot} from "./emptySlot";
import ScheduleOverride, { IScheduleOverride } from './scheduleOverride';

export interface IShiftList {
  results: IShift[]
}

export interface IShift {
  name: string
  from: string
  to: string
  remainingWorks: number
  remainingTimes: string
  day: string
  process: IProcess[]
  processHalts: IHalt[]
  confirmed: boolean
  remainingSlots: ISlot[]
  scheduledOverrides: IScheduleOverride[]
  maximumAllowedOverrideTime: string
}

class Shift {
  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get from(): Date {
    return this._from
  }

  get to(): Date {
    return this._to
  }

  get remainingWorks(): number {
    return this._remainingWorks
  }

  get remainingTimes(): string {
    return this._remainingTimes
  }

  get day(): Date {
    return this._day
  }

  get process(): Process[] {
    return this._process.map(proc => proc.copyWith())
  }

  get processHalts(): Halt[] {
    return this._processHalts.map(proc => proc.copyWith())
  }

  get confirmed(): boolean {
    return this._confirmed
  }

  get remainingSlots(): EmptySlot[] {
    return this._remainingSlots.map(slot => slot.copyWith())
  }

  get scheduledOverrides(): ScheduleOverride[]{
    return this._scheduledOverrides.map(schedule => schedule.copyWith())
  }

  get maximumAllowedOverrideTime(): Date {
    return this._maximumAllowedOverrideTime
  }

  private readonly _id: string
  private readonly _name: string
  private readonly _from: Date
  private readonly _to: Date
  private readonly _remainingWorks: number
  private readonly _remainingTimes: string
  private readonly _day: Date
  private readonly _process: Process[]
  private readonly _processHalts: Halt[]
  private readonly _confirmed: boolean
  private readonly _remainingSlots: EmptySlot[]
  private readonly _scheduledOverrides: ScheduleOverride[]
  private readonly _maximumAllowedOverrideTime: Date

  constructor(iShift: IShift) {
    this._id = iShift.day + iShift.from
    this._maximumAllowedOverrideTime = mvDate.getDateFromString(iShift.maximumAllowedOverrideTime)
    this._name = iShift.name
    this._from = mvDate.getDateFromString(iShift.from)
    this._to = mvDate.getDateFromString(iShift.to)
    this._remainingWorks = iShift.remainingWorks
    this._remainingTimes = iShift.remainingTimes
    this._day = mvDate.getDateFromString(iShift.day)
    this._process = iShift.process.map(process => new Process(process))
    this._confirmed = iShift.confirmed
    this._remainingSlots = iShift.remainingSlots.map(slot => new EmptySlot(slot))
    this._processHalts = iShift.processHalts.map(processHalt => new Halt(processHalt))
    this._scheduledOverrides = iShift.scheduledOverrides.map(scheduleOverride => new ScheduleOverride(scheduleOverride))
  }
}

export default Shift
