// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import AlertErrorData from '../../../../../mvlabs-components-fe/ui/components/Alert/AlertErrorData'
import CenterLoader from '../../../../../mvlabs-components-fe/ui/components/Loaders/CenterLoader'
import useGenericFetchHook from '../../../../../mvlabs-components-fe/functions/hooks/useGenericFetchHook'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import { OwnPlannerWidgetProps } from '../Planner/PlannerWidget'
import { getPlannerConfig } from '../../../../redux/action/getPlannerConfig'

/**
 * Attached on Planner to request the configuration
 */
const WithConfigManager = (Component: React.ComponentType<OwnPlannerWidgetProps & WithTranslation>) => (
  props: Omit<OwnPlannerWidgetProps & WithTranslation, 'plannerConfig'> & { readonly?: boolean }
) => {
  const configurationHook = useGenericFetchHook<Configuration>(() => getPlannerConfig(props.installationSlug))

  const renderSuccess = (configuration: Configuration) => {
    return <Component {...props} plannerConfig={configuration} />
  }

  const renderError = () => {
    return <AlertErrorData />
  }

  const renderLoading = () => {
    return <CenterLoader />
  }

  const renderPossibleCase = (): JSX.Element => {
    switch (configurationHook.state.kind) {
      case 'success':
        return renderSuccess(configurationHook.state.data)
      case 'fetching':
        return renderLoading()
      case 'error':
        return renderError()
      default:
        return renderLoading()
    }
  }

  return <div>{renderPossibleCase()}</div>
}

export default WithConfigManager
