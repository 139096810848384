export interface IAlertConfig {
  emptySlot: boolean
  unconfirmedDay: boolean
}

export class AlertConfig {
  private readonly _emptySlot: boolean
  private readonly _unconfirmedDay: boolean

  get emptySlot() {
    return this._emptySlot
  }

  get unconfirmedDay() {
    return this._unconfirmedDay
  }

  get interface(): IAlertConfig {
    return {
      emptySlot: this._emptySlot,
      unconfirmedDay: this._unconfirmedDay
    }
  }

  static get default(): IAlertConfig {
    return (new AlertConfig({})).interface
  }

  constructor(iAlertConfig: Partial<IAlertConfig>) {
    this._emptySlot = iAlertConfig.emptySlot ?? true
    this._unconfirmedDay = iAlertConfig.unconfirmedDay ?? true
  }

}