// ------------------------- NPM --------------------------------------
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'

// ------------------------- COMPONENTS --------------------------------------
import {
  Input,
  MnemonicInputWrapper,
  Select,
  TextArea,
} from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/Inputs'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { FormModal } from '@mv-submodules/inplant-components-fe/ui/components/Modal/Modal'

// ------------------------- MODULE --------------------------------------
import { Cargo, cargoBeToCargo, CargoDirections, CargoSlugs, possibleDirections } from '../../../../types/Cargo'
import Filters from '../../../../types/Filters'
import { incomingProducersFetchData } from '../../../../redux/actions/incoming'
import { outgoingRecipientsFetchData } from '../../../../redux/actions/outgoing'
import { cargoOpsFetchCargo, cargoOpsAddCargo, cargoOpsEditCargo } from '../../../../redux/actions/cargoOps'
import {
  fetchData,
  FetchDataProperty,
  materialsFetchData,
} from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import Material from '@mv-submodules/inplant-mcs-fe-iblu/types/Material'
import { ConfigManagerStore } from '@mv-submodules/inplant-mcs-fe-iblu/types/Store'
import { changeDirection, changeDate } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/filters'
import { FieldReadOnly } from '../Utils/Field'

library.add(faCircleNotch)

interface StateProps {
  filters: Filters
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchCargo: (cargoId: string, direction: CargoDirections) => Promise<Cargo>
  fetchMaterials: (direction: CargoDirections) => Promise<Material[]>
  cargoOpsAddCargo: (cargo: Cargo) => Promise<void>
  editCargo: (cargo: Cargo) => Promise<void>
  fetchOutgoingRecipients: (q?: string) => Promise<string[]>
  fetchIncomingProducers: (q?: string) => Promise<string[]>
  fetchData: (cargoDirections: CargoDirections, property: FetchDataProperty, q?: string) => Promise<string[]>
  changeDirection: (CargoDirections: CargoDirections) => Promise<void>
  changeDate: (date: moment.Moment) => void
}

interface OwnState {
  isFetchingMaterials: boolean
  isAddingCargo: boolean
  cargo: Cargo
  materials: Material[]
  direction: CargoDirections
}

interface OwnProps extends RouteComponentProps<any> {
  canRescheduleCargo: boolean
  cargoId: string | null
  onClose: (refreshData?: boolean) => void
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchCargo: (cargoId, direction) => dispatch(cargoOpsFetchCargo(cargoId, direction)),
    fetchMaterials: direction => dispatch(materialsFetchData(direction)),
    cargoOpsAddCargo: cargo => dispatch(cargoOpsAddCargo(cargo)),
    editCargo: cargo => dispatch(cargoOpsEditCargo(cargo)),
    fetchOutgoingRecipients: q => dispatch(outgoingRecipientsFetchData(q)),
    fetchIncomingProducers: q => dispatch(incomingProducersFetchData(q)),
    fetchData: (direction, property, q) => dispatch(fetchData(direction, property, q)),
    changeDirection: direction => dispatch(changeDirection(direction)),
    changeDate: date => dispatch(changeDate(date)),
  }
}

class AddCargoModal extends React.Component<Props, OwnState> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      isAddingCargo: false,
      isFetchingMaterials: false,
      materials: [],
      cargo: cargoBeToCargo({ arrivalDate: this.props.filters.date }, props.filters.direction),
      direction: props.filters.direction,
    }
    this.handleArrivalDateChange = this.handleArrivalDateChange.bind(this)
    this.handleArrivalDateKeyDown = this.handleArrivalDateKeyDown.bind(this)
    this.handleDirectionChange = this.handleDirectionChange.bind(this)
    this.handleMaterialChange = this.handleMaterialChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addCargo = this.addCargo.bind(this)
    this.updateCargo = this.updateCargo.bind(this)
  }

  public async componentDidMount() {
    if (this.props.cargoId) {
      this.props
        .fetchCargo(this.props.cargoId, this.props.filters.direction)
        .then(cargo => {
          this.setState({ cargo })
        })
        .catch(e => {
          console.warn(e) //tslint:disable-line
        })
    }
    let allMaterials: Material[] = []
    const materialsPromises: Array<Promise<Material[]>> = possibleDirections(this.props.configManager.data).map(
      direction => {
        return new Promise((resolve, _reject) => resolve(this.props.fetchMaterials(direction)))
      }
    )
    this.setState({ isFetchingMaterials: true })
    await Promise.all(materialsPromises)
      .then(materials => {
        allMaterials = materials.reduce((a, b) => a.concat(b), [])
        this.setState({ materials: allMaterials, isFetchingMaterials: false })
      })
      .catch(e => {
        console.warn(e) //tslint:disable-line
        this.setState({ isFetchingMaterials: false })
      })
  }

  private handleArrivalDateChange(date: Date) {
    if (moment(date).isValid()) {
      const cargo = this.state.cargo as Cargo
      cargo.arrivalDate = moment(date)
      this.setState(prevState => ({ ...prevState, cargo }))
    }
  }

  private handleArrivalDateKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  private handleDirectionChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.persist()
    this.setState(prevState => {
      const direction = e.target.value as CargoDirections
      const cargo: Cargo = { ...prevState.cargo, direction, materialType: undefined, recipient: '', producer: '' }
      return { ...prevState, cargo, direction }
    })
  }

  private handleMaterialChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.persist()
    this.setState(prevState => {
      const material = this.state.materials
        .filter(m => m.direction === this.state.direction)
        .find((m: Material) => m.name === e.target.value)
      const cargo: Cargo = { ...prevState.cargo, materialType: material, ticketNumber: null }
      return { ...prevState, cargo }
    })
  }

  private handleChange = (
    slug: CargoSlugs,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    this.handleChangeValue(slug, e.target.value)
  }

  private handleChangeValue = (slug: CargoSlugs, value: string) => {
    this.setState(prevState => {
      const cargo: Cargo = { ...prevState.cargo, [slug]: value }
      return { ...prevState, cargo }
    })
  }

  private async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    const apiCall = this.state.cargo.id ? this.updateCargo : this.addCargo
    if (e.currentTarget.checkValidity()) {
      this.setState({ isAddingCargo: true })
      apiCall(this.state.cargo)
        .then(() => {
          this.setState({ isAddingCargo: false })
          this.props.onClose(true)
          this.props.changeDirection(this.state.direction)
          this.props.changeDate(this.state.cargo.arrivalDate)
        })
        .catch((error: any) => {
          this.setState({ isAddingCargo: false })
          console.error(error) // tslint:disable-line
        })
    }
  }

  private addCargo(cargo: Cargo) {
    return this.props.cargoOpsAddCargo(cargo)
  }

  private updateCargo(cargo: Cargo) {
    return this.props.editCargo(cargo)
  }

  private renderTransportInfo = () => {
    switch (this.state.direction) {
      case 'incoming':
        return (
          <MnemonicInputWrapper
            suggestionFunction={this.props.fetchIncomingProducers}
            required={true}
            slug={'producer'}
            onChange={e => this.handleChangeValue('producer', e)}
            value={this.state.cargo.producer || ''}
          />
        )
      case 'outgoing':
        return (
          <MnemonicInputWrapper
            suggestionFunction={this.props.fetchOutgoingRecipients}
            required={true}
            slug={'recipient'}
            onChange={e => this.handleChangeValue('recipient', e)}
            value={this.state.cargo.recipient || ''}
          />
        )
      default:
        return null
    }
  }

  public render() {
    return ReactDOM.createPortal(
      <FormModal
        visible={true}
        onClose={this.props.onClose}
        onSubmit={this.handleSubmit}
        width={75}
        submitButton={{
          label: this.props.t(this.state.cargo.id ? 'mcs.modals.save' : 'mcs.modals.add'),
          isLoading: this.state.isAddingCargo,
          disabled: this.state.isAddingCargo || this.state.isFetchingMaterials,
        }}
        closeLabel={this.props.t('mcs.modals.close')}
        title={this.props.t('mcs.cargo.info')}
      >
        {(this.props.canRescheduleCargo && (
          <div className="form-group">
            <Row verticalAlignment={'center'}>
              <Column sm={4}>
                <label className="col-form-label">{this.props.t('mcs.cargo.arrivalDate')}</label>
              </Column>
              <Column sm={8}>
                <DatePicker
                  selected={this.state.cargo.arrivalDate.toDate()}
                  onChange={this.handleArrivalDateChange}
                  onKeyDown={this.handleArrivalDateKeyDown}
                  dateFormat="dd/MM/yyyy"
                  className="form-control datepicker"
                  required={true}
                  minDate={moment().toDate()}
                />
              </Column>
            </Row>
          </div>
        )) || (
          <FieldReadOnly
            label={this.props.t('mcs.cargo.arrivalDate')}
            value={this.state.cargo.arrivalDate.format('dd/MM/yyyy')}
          />
        )}

        <Select
          slug={'direction'}
          onChange={this.handleDirectionChange}
          required={true}
          defaultValue={this.state.cargo.direction}
          values={possibleDirections(this.props.configManager.data).map(d => ({ value: d, label: d }))}
          disabled={!!this.state.cargo.id}
        />
        {(this.props.configManager.data.units.enable && this.state.direction !== 'transfer' && (
          <Select
            slug={'units'}
            onChange={e => this.handleChange('unit', e)}
            required={true}
            dontTranslateValues={true}
            defaultValue={''}
            values={[
              { label: this.props.t('mcs.cargo.units.placeholder'), value: '', disabled: true },
              ...this.props.configManager.data.units.values.map(d => ({ value: d.id, label: d.name })),
            ]}
            disabled={!!this.state.cargo.id}
          />
        )) ||
          null}
        <div className="form-group">
          <Row verticalAlignment={'center'}>
            <Column sm={4}>
              <label className="col-form-label">{this.props.t('mcs.cargo.materialType.label')}</label>
            </Column>
            <Column sm={8}>
              <div>
                {this.state.isFetchingMaterials ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                ) : (
                  <React.Fragment>
                    <select
                      className="custom-select"
                      onChange={this.handleMaterialChange}
                      required={this.state.direction === 'incoming'}
                      value={this.state.cargo.materialType ? this.state.cargo.materialType.name : ''}
                    >
                      <option key="null" value="" disabled={true}>
                        {this.props.t('mcs.cargo.materialType.undefined')}
                      </option>
                      {this.state.materials
                        .filter(m => m.direction === this.state.direction)
                        .sort((a: Material, b: Material) =>
                          a.description.toLowerCase().localeCompare(b.description.toLowerCase())
                        )
                        .map((material: Material) => (
                          <option key={material.name} value={material.name}>
                            {material.description}
                          </option>
                        ))}
                    </select>
                    <div className="invalid-feedback">{this.props.t('mcs.cargo.materialType.invalidFeedback')}</div>
                  </React.Fragment>
                )}
              </div>
            </Column>
          </Row>
        </div>
        <MnemonicInputWrapper
          slug={'ticketNumber'}
          value={this.state.cargo.ticketNumber || ''}
          disabled={
            this.state.direction === 'incoming' &&
            (!this.state.cargo.materialType ||
              (this.state.cargo.materialType && !this.state.cargo.materialType.requiresTicket))
          }
          required={
            this.props.filters.direction === 'incoming' &&
            this.state.cargo.materialType &&
            this.state.cargo.materialType.requiresTicket
          }
          readOnly={!this.state.cargo.materialType?.requiresTicket}
          onChange={e => this.handleChangeValue('ticketNumber', e)}
          suggestionFunction={value => this.props.fetchData(this.state.direction, 'ticket-numbers', value)}
        />
        {this.renderTransportInfo()}
        {this.state.direction === 'incoming' ? (
          <Input
            noFormGroup={true}
            slug={'basin'}
            onChange={e => this.handleChange('basin', e)}
            value={this.state.cargo.basin || ''}
          />
        ) : null}
        <MnemonicInputWrapper
          suggestionFunction={value => this.props.fetchData(this.state.direction, 'carriers', value)}
          slug={'carrier'}
          onChange={e => this.handleChangeValue('carrier', e)}
          value={this.state.cargo.carrier}
          required={true}
        />
        <TextArea slug={'note'} onChange={e => this.handleChange('note', e)} value={this.state.cargo.note || ''} />
      </FormModal>,
      document.getElementById('modal-container') as Element
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCargoModal)))
