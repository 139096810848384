const it = {
  checklist: {
    common: {
      assetNotAvailable: "Attenzione, mezzo non utilizzabile",
      koBlocksCompilations: "In caso di anomalia non risolta per questo controllo, non sarà possibile l'utilizzo del mezzo"
    },
    navigation: {
      checklists: 'Checklist',
      checklistModels: 'Tutte le Checklist',
      checklistArchive: 'Archivio',
      checklistTodaySummary: 'Riepilogo',
      checklistYesterdaySummary: 'Riepilogo giornata',
      checklistPersonalArchive: 'Archivio personale',
      checklistValidations: 'Validazioni',
      checklistModelsAssets: 'Checklist abituali',
      checklistPhotos: 'Foto checklist',
      resetAssetHours: 'Reset ore macchina',
      gia: 'GIA',
      setup: 'Setup',
    },
    index: {
      title: 'Tutte le Checklist',
      table: {
        noData: 'Nessun dato disponibile',
        title: 'Nome',
        edit: 'Compila',
        actions: '',
        compile: 'Compila',
        infoCompile: "Compilata l'ultima volta da {{user}} il {{date}}",
      },
    },
    general: {
      unit: 'Unità',
      U1: 'Unità 1',
      U2: 'Unità 2',
    },
    edit: {
      title: 'Compilazione Checklist',
      back: 'Indietro',
      save_and_back: 'Salva e torna indietro',
      conclude: 'Concludi',
      nextStep: 'Conferma',
      csv: 'CSV',
      print: 'Stampa',
      user: 'Compilata da',
      createdDate: 'Aperta il',
      concludedDate: 'Chiusa il',
      addCheck: 'Aggiungi prodotto',
      concludeTooltipText: 'Prima di concludere verifica che tutti i controlli siano OK',
      deleteChecklistModalTitle: 'Elimina Checklist',
      deleteChecklistInstructions: 'Stai per eliminare questa checklist, sei sicuro?',
      deleteChecklist: 'Elimina',
      errorMessage:
        'Ci sono degli errori che impediscono la prosecuzione della compilazione. Ricontrolla la checklist prima di procedere.',
      total: 'Totale:',
      validation: {
        title: 'Validazione Checklist',
        valid: 'Esito',
        comment: 'Commento',
        photo: 'Foto',
        noPhoto: '(nessuna foto)',
        validate: 'Valida la Checklist',
        validatedBy: 'Validato da',
        notYetValidated: 'Non ancora validata.',
      },
      checklistEmailInfo: 'I KO di questa checklist compariranno nel riepilogo quotidiano'
    },
    checks: {
      compiledBy: 'Compilato da {{ name }}',
      compiledTime: 'il {{ time }}',
      addCheckModalTitle: 'Aggiungi prodotto',
      deleteCheckModalTitle: 'Elimina Controllo',
      description: 'Descrizione',
      addCheck: 'Aggiungi prodotto',
      addCheckInstructions: 'Di quale prodotto si tratta?',
      deleteCheck: 'Elimina controllo',
      deleteCheckInstructions: 'Sei sicuro di voler cancellare il controllo?',
      back: 'Annulla',
      optionalChecks: 'Campi opzionali',
    },
    components: {
      browse: 'Sfoglia',
      previewImageError: 'Anteprima non supportata',
      back: 'Annulla',
      numberCalculator: {
        total: 'Totale',
        producedBales: 'Prod.',
        pressedAgainBales: 'Ripress.',
        toReselectBales: 'Riselez.',
        partial: 'Parz.',
      },
      number: {
        value: 'Valore',
      },
      assetHours: {
        value: 'Valore',
      },
      multiFile: {
        viewer: 'Visualizza file',
        deleteFile: 'Elimina file',
        deleteFileInstructions: 'Sei sicuro di voler eliminare il file?',
        shotAt: 'Scattata il',
        missingShotAt: 'data non disponibile',
      },
    },
    asset: {
      title: 'Selezione Asset',
      back: 'Indietro',
      table: {
        noData: 'Nessun dato disponibile',
        description: 'Descrizione',
        select: 'Seleziona',
        actions: '',
        compile: 'Compila',
      },
    },
    archive: {
      title: 'Archivio Checklist',
      table: {
        title: 'Nome',
        showAll: '-- Tutte --',
        noData: 'Nessun dato disponibile',
        previous: 'Precedente',
        next: 'Successiva',
        loading: 'Caricamento dati...',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        user: {
          displayName: 'Utente',
        },
        asset: {
          description: 'Asset',
          showAll: '-- Tutti --',
        },
        createdDate: 'Iniziata',
        createdDatePlaceholder: 'Dal...',
        concludedDate: 'Chiusa',
        concludedDatePlaceholder: 'Al...',
        invalidChecksCount: 'N° KO',
        actions: '',
        edit: 'Modifica',
        details: 'Dettagli',
        lastValidation: {
          label: 'Ultima validazione',
          none: '--',
        },
      },
    },
    todaySummary: {
      title: 'Riepilogo',
      table: {
        title: 'Nome',
        status: 'Stato',
        user: 'Utente',
        date: 'Data',
        userss: 'utenti',
        users: 'utente',
        asset: ' ({{assetNumber}} asset)'
      },
      status: {
        open: 'Aperta',
        not_concluded: 'Non compilata',
        open_with_anomalies: 'Aperta',
        concluded: 'Chiusa',
        concluded_with_anomalies: 'Chiusa',
      },
    },
    yesterdaySummary: {
      title: 'Riepilogo della giornata',
      table: {
        title: 'Nome',
        status: 'Stato',
        user: 'Utente',
        date: 'Data',
      },
      status: {
        open: 'Aperta',
        not_concluded: 'Non compilata',
        open_with_anomalies: 'Aperta',
        concluded: 'Compilata',
        concluded_with_anomalies: 'Compilata',
      },
    },
    validation: {
      title: 'Checklist da validare',
      table: {
        title: 'Nome',
        showAll: '-- Tutte --',
        noData: 'Nessun dato disponibile',
        previous: 'Precedente',
        next: 'Successiva',
        loading: 'Caricamento dati...',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        user: {
          displayName: 'Utente',
        },
        asset: {
          description: 'Asset',
          showAll: '-- Tutti --',
        },
        createdDate: 'Iniziata',
        createdDatePlaceholder: 'Dal...',
        concludedDate: 'Chiusa',
        concludedDatePlaceholder: 'Al...',
        invalidChecksCount: 'N° KO',
        actions: '',
        edit: 'Modifica',
        details: 'Dettagli',
      },
    },
    modelsAssets: {
      title: 'Checklist abituali',
      goToModels: 'Seleziona altra checklist',
      table: {
        modelTitle: 'Checklist',
        assetDescription: 'Asset',
        showAll: '-- Tutti --',
        noData: 'Nessun dato disponibile',
        previous: 'Precedente',
        next: 'Successiva',
        loading: 'Caricamento dati...',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        actions: '',
        compile: 'Compila',
        select: '',
        quickConclude: 'Completa selezionati',
      },
    },
    resetHours: {
      title: 'Imposta Ore Asset',
      table: {
        code: 'Codice',
        asset: 'Asset',
        noData: 'Nessun dato disponibile',
        previous: 'Precedente',
        next: 'Successiva',
        loading: 'Caricamento dati...',
        page: 'Pagina',
        of: 'di',
        rows: 'righe',
        reset: 'Imposta',
      },
      modal: {
        resetAssetHours: 'Imposta ore asset',
        code: 'Codice',
        asset: 'Asset',
        assetHours: 'Ore attuali',
        changeAssetHours: 'Imposta ore',
        back: 'Annulla',
        reset: 'Imposta',
      },
    },
    photosView: {
      title: 'Visualizzazione foto',
      selectModelPlaceholder: 'Seleziona un modello',
      selectDatePlaceholder: 'Seleziona una data',
      modelLabel: 'Modello',
      dateLabel: 'Data',
      photosOfDate: 'Foto del ',
      noPhoto: 'Non ci sono foto disponibili nel periodo selezionato',
      close: 'Chiudi',
      dateTimeOriginal: 'Scattata il: ',
      noDateAvailable: 'Data non disponibile'
    },
    setup: {
      table: {
        title: 'Setup Checklist',
        noData: 'Nessun dato disponibile',
        code: 'Cod. Macchina',
        positionCode: 'Cod. Impianto',
        description: 'Descrizione',
        model: 'Modello',
        brand: 'Marchio',
        name: 'Nome',
        serialNumber: 'Seriale',
        status: 'Stato',
        checklist: 'Checklist',
        searchPlaceholder: 'Cerca per codice o modello',
        searchAssetPlaceholder: 'Modello',
        searchStatusPlaceholder: 'Stato',
        actions: '',
      },
      modal: {
        title: 'Configurazione Asset',
        cancel: 'Annulla',
        save: 'Salva',
      },
      statusLabels: {
        tobeconfigured: 'DA CONFIGURARE',
        deactivated: 'DISATTIVATO',
        active: 'ATTIVO E CONFIGURATO',
        tobevalidated: 'ATTESA VALIDAZIONE',
        undefined: 'UNDEFINED',
      },
      configure: 'CONFIGURA',
      filterAllStatus: "Tutti gli stati dell'asset",
      notifications: {
        saved: 'Configurazione salvata',
        notSaved: "C'è stato un errore salvando la configurazione",
      },
    },
    proximity: {
      errorNoBeacon: 'Checklist non configurata'
    },
  },
  gia: {
    table: {
      date: 'Data',
      noData: 'Nessun dato disponibile',
      user: 'Utente',
      createdDate: 'Iniziata',
      createdDatePlaceholder: 'Dal...',
      concludedDate: 'Chiusa',
      concludedDatePlaceholder: 'Al...',
      details: 'Dettagli',
    },
    details: {
      createdDate: 'Iniziata il ',
      concludedDate: 'Chiusa il ',
      notes: 'Note: ',
    },
    section: {
      material: {
        description: 'Descrizione',
        cer: 'CER',
        total: 'Totale',
        images: 'IMM.',
      },
    },
    modalImages: {
      title: 'Immagini',
      closeButton: 'Chiudi',
    },
    modalImage: {
      title: 'Immagine',
      closeButton: 'Chiudi',
    },
  },

}

export default it
