// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
// ------------------------- COMPONENTS --------------------------------------
import Button from '@mv-submodules/inplant-components-fe/ui/components/Button/Button'

// ------------------------- MODULE --------------------------------------
import Filters from '../../../../types/Filters'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { canViewCloseDayPageView } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'

interface Props {
  cargos: Cargo[]
}

const CloseDayButton = (props: Props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const filters: Filters = useSelector((state: any) => state.mcs.filters)

  const { cargos } = props

  const handleClick = () => {
    history.push(`/mcs/close-day/${filters.date.format('YYYYMMDD')}`)
  }

  return (
    <Button
      onClick={handleClick}
      variant={'success'}
      spacing={{ vertical: false }}
      disabled={!canViewCloseDayPageView(cargos)}
      label={t('mcs.today.closeDay')}
    />
  )
}

export default CloseDayButton
