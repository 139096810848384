// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import SimpleTable, { SimpleTableColumn } from '../../../../../mvlabs-components-fe/ui/components/Table/SimpleTable'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'

// * -------------------------------- MODULE --------------------------------------
import Process from '../../../../types/process'
import Recipe from '../../../../types/recipe'
import { Asset } from '../../../../types/asset'
import { TABLE_CELL_WARNING, TABLE_CELL_ERROR, TABLE_CELL_OVERRIDE } from './TableConnection'
import { isConnectionProblem, isMismatch, mismatchString } from '../../../../functions/warning'
import { EmptySlot } from '../../../../types/emptySlot'
import Halt from '../../../../types/halt'
import { getModule } from '../../../../functions'
import { Modules } from '../../../routes'
import Shift from '../../../../types/shift'
import { IconComponent } from '../../../../../mvlabs-components-fe/ui/components'
import { Icon } from '../../../../../mvlabs-components-fe/services/icon'
import { generateSpace, SpaceType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'

interface Props extends WithTranslation {
  process: Process | Halt | EmptySlot
  mappingAssetRecipe: Array<[Asset, Recipe]>
  shift: Shift
}

interface TableData {
  asset?: Asset
  recipe?: Recipe
  warningType: 'connection' | 'recipe-mismatch' | 'ok' | 'scheduleOverride' | 'scheduleOverrideSame'
  errorStartTime: string
  errorEndTime: string
  mismatchPercentage?: number
  isHaltError?: boolean
  isScheduledOverride?: boolean
  scheduleOverrideNote?: string
  userInfo?: string
  userEditInfo?: string
}

const TableProcessDetail = (props: Props) => {
  const { mappingAssetRecipe, process, shift } = props
  const { t, base } = props.translation

  const isHalt = process instanceof Halt
  const isEmptySlot = process instanceof EmptySlot
  const haltsTranslationString = t(`${base}.halt`)
  const emptySlotTranslationString = t(`${base}.emptySlot`)
  const dataFromMapping: TableData[] = []


  process.allEvents.forEach(w => {
    if (w.type === 'recipe-mismatch') {
      const resM = mappingAssetRecipe.find(a => a[0].id === w.assetId)
      const asset = resM ? resM[0] : new Asset({ id: w.assetId, displayName: w.assetDisplayName })
      const recipe = resM
        ? resM[1]
        : w.actualRecipe && new Recipe({ id: w.actualRecipe?.id, displayName: w.actualRecipe?.displayName })

      const override = w.relatedManualOverrideId
        ? shift.scheduledOverrides.find(o => o.id === w.relatedManualOverrideId)
        : undefined
      const userInfo = override ? override.createdByUser.displayName : undefined
      const userEditInfo = override
        ? override.lastUpdatedByUser
          ? override.lastUpdatedByUser.displayName
          : undefined
        : undefined
      dataFromMapping.push({
        asset,
        recipe,
        warningType: w.isMismatch
          ? 'recipe-mismatch'
          : w.relatedManualOverrideId && !w.isMismatch
          ? 'scheduleOverrideSame'
          : w.relatedManualOverrideId
          ? 'scheduleOverride'
          : 'ok',
        errorEndTime: w.endedAt,
        errorStartTime: w.startedAt,
        mismatchPercentage: w.isMismatch ? w.mismatchPercentage : undefined,
        isHaltError: w.isHalt,
        isScheduledOverride: w.relatedManualOverrideId ? true : false,
        scheduleOverrideNote:
          (w.relatedManualOverrideId && shift.scheduledOverrides.find(o => o.id === w.relatedManualOverrideId)?.note) ||
          undefined,
        userInfo,
        userEditInfo,
      })
    } else {
      const resC = mappingAssetRecipe.find(a => a[0].id === w.assetId)
      const asset = resC ? resC[0] : new Asset({ id: w.assetId, displayName: w.assetDisplayName })
      const recipe = resC && resC[1]
      dataFromMapping.push({
        asset,
        recipe,
        warningType: w.isConnected ? 'ok' : w.type,
        errorEndTime: w.endedAt,
        errorStartTime: w.startedAt,
      })
    }
  })

  shift.scheduledOverrides
    .filter(
      o =>
        mvDate.isSameOrAfterSecond(o.from, process.from) &&
        mvDate.isSameOrBeforeSecond(o.from, process.to) &&
        !process.allMismatchesWarning.find(w => w.relatedManualOverrideId === o.id)
    )
    .forEach(o =>
      dataFromMapping.push({
        errorStartTime: o.from.toString(),
        warningType: 'scheduleOverride',
        isScheduledOverride: true,
        userInfo: o.createdByUser.displayName,
        userEditInfo: o.lastUpdatedByUser ? o.lastUpdatedByUser.displayName : undefined,
        isHaltError: o.isHalt,
        scheduleOverrideNote: o.note || undefined,
        recipe: o.recipeGroup
          ? new Recipe({ id: o.recipeGroup.id, displayName: o.recipeGroup.displayName })
          : undefined,
        errorEndTime: mvDate.isSameOrBeforeSecond(o.to, process.to) ? o.to.toString() : process.to.toString(),
      })
    )

  function getBackgroundColor(mAR: TableData): string {
    if (
      mAR.asset
        ? isConnectionProblem(mAR.asset, process.allConnectionWarning) && mAR.warningType === 'connection'
        : mAR.warningType === 'connection'
    ) {
      return TABLE_CELL_ERROR
    }
    if (mAR.warningType === 'scheduleOverride') {
      return TABLE_CELL_OVERRIDE
    }
    if ((isHalt || isEmptySlot) && mAR.warningType === 'recipe-mismatch') {
      if (mAR.mismatchPercentage) {
        if (mAR.mismatchPercentage >= 75) {
          return `${TABLE_CELL_WARNING}-lg`
        }
        if (mAR.mismatchPercentage > 25) {
          return `${TABLE_CELL_WARNING}`
        }
        return `${TABLE_CELL_WARNING}-sm`
      }
      return TABLE_CELL_WARNING
    }
    if (
      mAR.recipe && typeof mAR.recipe !== 'string'
        ? isMismatch(mAR.recipe, process.allMismatchesWarning) && mAR.warningType === 'recipe-mismatch'
        : mAR.warningType === 'recipe-mismatch'
    ) {
      if (mAR.mismatchPercentage) {
        if (mAR.mismatchPercentage >= 75) {
          return `${TABLE_CELL_WARNING}-lg`
        }
        if (mAR.mismatchPercentage > 25) {
          return `${TABLE_CELL_WARNING}`
        }
        return `${TABLE_CELL_WARNING}-sm`
      }
      return TABLE_CELL_WARNING
    }
    return ''
  }

  function getActualRecipe(mAR: TableData): string {
    switch (mAR.warningType) {
      case 'ok':
        return mAR.recipe
          ? typeof mAR.recipe !== 'string'
            ? mAR.recipe.displayName
            : mAR.recipe
          : mAR.isHaltError
          ? haltsTranslationString
          : ''
      case 'recipe-mismatch':
        return mAR.recipe
          ? mismatchString(mAR.recipe, t(`${base}.unknownActualRecipe`), process.allMismatchesWarning)
          : mAR.isHaltError
          ? haltsTranslationString
          : ''
      case 'scheduleOverride':
      case 'scheduleOverrideSame':
        return mAR.recipe ? mAR.recipe.displayName : mAR.isHaltError ? haltsTranslationString : ''
      case 'connection':
      default:
        return t(`${base}.noConnectionRecipe`)
    }
  }

  function getTimeValue(mAR: TableData, type: 'startTime' | 'endTime'): string {
    let timeValue = ''
    let userInfo = ''
    switch (type) {
      case 'startTime':
        timeValue = mAR.errorStartTime ? mvDate.getDateFromStringWithFormatting(mAR.errorStartTime, TIME) : '--'
        userInfo = mAR.userInfo ? mAR.userInfo : ''
        break
      case 'endTime':
        timeValue = mAR.errorEndTime ? mvDate.getDateFromStringWithFormatting(mAR.errorEndTime, TIME) : '--'
        userInfo = mAR.userEditInfo ? mAR.userEditInfo : ''
        break
      default:
        break
    }
    return `${timeValue} ${userInfo}`
  }

  function getMachineName(mAR: TableData) {
    return mAR.asset && mAR.asset.displayName
  }

  function getExpectedRecipe(mAR: TableData) {
    if (isHalt) {
      return haltsTranslationString
    }
    if (isEmptySlot) {
      return emptySlotTranslationString
    }
    return mAR.recipe && typeof mAR.recipe !== 'string' ? mAR.recipe.displayName : '--'
  }

  function getIcon(mAR: TableData): Icon | undefined {
    if (mAR.warningType === 'scheduleOverride' || mAR.isScheduledOverride) {
      return 'pencil-alt'
    }
    if (mAR.warningType === 'connection') {
      return 'wifi'
    }
    if (mAR.warningType === 'recipe-mismatch') {
      return 'not-equal'
    }
    return
  }

  function generateColumns(): Array<SimpleTableColumn<TableData>> {
    const iconTypeColumn: Array<SimpleTableColumn<TableData>> = [
      {
        title: '',
        cell: mAR => {
          const icon = getIcon(mAR)
          return icon && <IconComponent icon={icon} className={generateSpace(SpaceType.margin, { x: 3 })} />
        },
        className: getBackgroundColor,
      },
    ]
    const baseColumn: Array<SimpleTableColumn<TableData>> = [
      {
        title: t(`${base}.expectedRecipe`),
        cell: getExpectedRecipe,
        className: getBackgroundColor,
      },
      {
        title: t(`${base}.actualRecipe`),
        cell: getActualRecipe,
        className: getBackgroundColor,
      },
      {
        title: t(`${base}.errorStartTime`),
        cell: mAR => getTimeValue(mAR, 'startTime'),
        className: getBackgroundColor,
      },
      {
        title: t(`${base}.errorEndTime`),
        cell: mAR => getTimeValue(mAR, 'endTime'),
        className: getBackgroundColor,
      },
      {
        title: getModule() === Modules.titech ? t(`${base}.isConnected`) : t(`${base}.connection`),
        cell: mAR =>
          mAR.warningType === 'recipe-mismatch' || mAR.warningType === 'scheduleOverrideSame'
            ? t(`${base}.yes`)
            : mAR.warningType === 'connection'
            ? t(`${base}.no`)
            : mAR.warningType === 'ok'
            ? t(`${base}.yes`)
            : mAR.warningType === 'scheduleOverride'
            ? '--'
            : '--',
        className: getBackgroundColor,
      },
      {
        title: t(`${base}.comment`),
        cell: mAR => (mAR.scheduleOverrideNote ? mAR.scheduleOverrideNote : '--'),
        className: getBackgroundColor,
      },
    ]

    if (getModule() === Modules.titech) {
      return [
        ...iconTypeColumn,
        {
          title: t(`${base}.machineName`),
          cell: getMachineName,
          className: getBackgroundColor,
        },
        ...baseColumn,
      ]
    }
    return [...iconTypeColumn, ...baseColumn]
  }

  return (
    <SimpleTable<TableData>
      data={dataFromMapping.sort((a, b) =>
        mvDate.isBeforeSecond(mvDate.getDateFromString(a.errorStartTime), mvDate.getDateFromString(b.errorStartTime))
          ? -1
          : mvDate.isBeforeSecond(
              mvDate.getDateFromString(b.errorStartTime),
              mvDate.getDateFromString(a.errorStartTime)
            )
          ? 1
          : 0
      )}
      columns={generateColumns()}
    />
  )
}

export default TableProcessDetail
