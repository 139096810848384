import { Cargo, CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { imageUrl } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { Loader } from '@mv-submodules/inplant-components-fe'
import Button from '../../../../../inplant-components-fe/ui/components/Button/Button'
import * as React from 'react'

type ImageStates = { type: 'loading' } | { type: 'success'; src: string }

interface ImageProps {
  direction: CargoDirections
  imageId: string
  truck: Cargo

  // callback to open modal to show fullImage
  onSelectImage: (imageURL: string) => void
  onImageLoaded: (imageURL: string) => void

  // callback to set images to download
  onCheckedImage: (imageURL: string, isSelected: boolean) => void

  // the component Modal is always destroyed when it change the prop visible so is necessary to maintain the imageURL to prevent a new imageLoad
  imageURL?: string
}

// the component Modal is always destroyed when it change the prop visible so is necessary to maintain the imageURL to prevent a new imageLoad
const RemoteImage = (props: ImageProps) => {
  const [imageState, setImageState] = useState<ImageStates>({ type: 'loading' })
  const [checkedForDownload, setCheckedForDownload] = useState<boolean>(false)

  const { direction, imageId, truck } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.imageURL === undefined) {
      imageUrl(
        direction,
        imageId,
        truck,
      )(dispatch).then(src => {
        setImageState({ type: 'success', src })
        props.onImageLoaded(src)
      })
    } else {
      setImageState({ type: 'success', src: props.imageURL })
    }
  }, [])

  switch (imageState.type) {
    case 'loading':
      return <Loader />
    case 'success':
      const imageURL = URL.createObjectURL(imageState.src)
      return (
        <div className={`img-container`}>
          <img
            alt={'img-checkout'}
            onClick={() => {
              props.onSelectImage(imageURL)
            }}
            className={'mt-4 mw-100 w-100'}
            src={imageURL}
          />
          <Button onClick={() => {
            props.onCheckedImage(imageURL, !checkedForDownload)
            setCheckedForDownload(prev => !prev)
          }
          } size={'sm'} icon={checkedForDownload ? "check" : ["regular","circle"]} variant={'primary'}
                  spacing={{ vertical: false, horizontal: false }} />
        </div>

      )
  }
}

export default RemoteImage