// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'

// ------------------------- MODULE --------------------------------------
import UpperPageActions from '../../widgets/UpperPageActions/UpperPageActions'
import AddCargoModal from '../../widgets/Modals/AddCargoModal'
import { CargoSelected } from '../../../../types/Cargo'
import { CommonStore } from '../../../../types/Store'
import Filters from '../../../../types/Filters'
import DeleteCargoModal from '../../widgets/Modals/DeleteCargoModal'
import ActionModal from '../../widgets/Modals/ActionModal'
import HeaderPage from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/ComponentsPastTodayFuture/HeaderPage'
import CargoTable from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/CargoTable'
import { Actions } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/ColumnsRender'
import { ModalState } from '@mv-submodules/inplant-mcs-fe-iblu/types/Modals'
import { PeriodProps } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/views/MCSRoutes/MCSRouterPageView'

library.add(faCircleNotch, faInfoCircle, faEyeDropper)

interface StateProps {
  filters: Filters
  common: CommonStore
}

interface OwnProps extends RouteComponentProps<any> {}

interface OwnState {
  showModal: ModalState
  cargosSelected: CargoSelected
  showCheckbox: boolean
}

type Props = StateProps & OwnProps & WithTranslation & PeriodProps

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
})

class FuturePageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: { visible: false },
      cargosSelected: {},
      showCheckbox: false,
    }
    this.handleOpenAddCargoModal = this.handleOpenAddCargoModal.bind(this)
    this.handleOpenDeleteCargoModal = this.handleOpenDeleteCargoModal.bind(this)
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.filters.direction !== this.props.filters.direction) {
      this.setState({ cargosSelected: {} })
    }
  }

  private handleOpenAddCargoModal(cargoId: string) {
    this.setState({ showModal: { visible: true, type: 'add', cargoId } })
  }

  private handleOpenDeleteCargoModal(cargoId: string) {
    this.setState({ showModal: { visible: true, type: 'delete', cargoId } })
  }

  private handleCloseModal = (refreshData?: boolean) => {
    this.setState({
      showModal: { visible: false },
    })
    if (refreshData) {
      this.props.fetchData(true)
    }
  }

  private handleCloseCargosModal = () => {
    this.setState(
      {
        showModal: { visible: false },
        cargosSelected: {},
      },
      () => {
        this.toggleColumnsSelection()
      }
    )
  }

  private toggleColumnsSelection = () => {
    this.setState({
      showCheckbox: !this.state.showCheckbox,
    })
  }

  private cargoOpsBatchDeleteCargo = async () => {
    const cargosToDelete = Object.keys(this.state.cargosSelected).filter(cargo => this.state.cargosSelected[cargo])
    this.setState({ showModal: { visible: true, cargoIds: cargosToDelete, type: 'multipleDelete' } })
  }

  private cargoOpsBatchReplicaCargo = async () => {
    const cargosToReplicate = Object.keys(this.state.cargosSelected).filter(cargo => this.state.cargosSelected[cargo])
    this.setState({ showModal: { visible: true, cargoIds: cargosToReplicate, type: 'replica' } })
  }

  private handleOnAction = (action: Actions) => {
    const cargoId = action.cargo.id || ''
    switch (action.type) {
      case 'delete':
        return this.setState({ showModal: { visible: true, cargoId, type: 'delete' } })
      case 'add':
      case 'default':
        return this.setState({ showModal: { visible: true, cargoId, type: 'add' } })
      case 'toggle_row':
        return this.setState({
          cargosSelected: {
            ...this.state.cargosSelected,
            [cargoId]: !this.state.cargosSelected[cargoId],
          },
        })
    }
  }

  private renderModals = () => {
    if (this.state.showModal.visible) {
      switch (this.state.showModal.type) {
        case 'delete':
          return (
            <DeleteCargoModal type={'delete'} cargoId={this.state.showModal.cargoId} onClose={this.handleCloseModal} />
          )
        case 'add':
          return (
            <AddCargoModal
              canRescheduleCargo={true}
              cargoId={this.state.showModal.cargoId}
              onClose={this.handleCloseModal}
            />
          )
        case 'multipleDelete':
          return this.state.showModal.cargoIds.length !== 0 ? (
            <DeleteCargoModal
              cargoIds={this.state.showModal.cargoIds}
              type={'multipleDelete'}
              onClose={this.handleCloseCargosModal}
            />
          ) : (
            <></>
          )
        case 'replica':
          return this.state.showModal.cargoIds.length !== 0 ? (
            <ActionModal
              type={'replica'}
              onClose={this.handleCloseCargosModal}
              cargoIds={this.state.showModal.cargoIds}
            />
          ) : (
            <></>
          )
      }
    }
    return <></>
  }

  public render() {
    const cargos = this.props.cargos.filter(c => c.direction === this.props.filters.direction)
    return (
      <div className="mv4iot-fe-mcs">
        <HeaderPage date={this.props.filters.date} title={'program'} />
        <div className="content">
          {this.renderModals()}
          <UpperPageActions
            toggleColumnsSelection={this.toggleColumnsSelection}
            cargosSelected={this.state.cargosSelected}
            cargoOpsBatchDeleteCargo={this.cargoOpsBatchDeleteCargo}
            cargoOpsBatchReplicaCargo={this.cargoOpsBatchReplicaCargo}
            allCargos={this.props.cargos}
            refreshData={() => this.props.fetchData(true)}
          />
          <React.Fragment>
            <CargoTable
              data={cargos}
              isLoading={this.props.isFetchingCargos}
              type={{ type: 'future' }}
              direction={this.props.filters.direction}
              onAction={this.handleOnAction}
              cargosSelected={this.state.cargosSelected}
              showCheckbox={this.state.showCheckbox}
            />
          </React.Fragment>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, {})(withTranslation()(FuturePageView)))
