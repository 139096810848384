import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mv-submodules/inplant-components-fe'
import { SortingRule } from 'react-table'

export type Pages = 'today' | 'future' | 'past' | 'closeDay' | 'weeklyReport'

interface BaseProps {
  handleFullTextFilterChange: (value: string) => void
  fullTextFilter: string
}

// interface TodayProps extends BaseProps {
//   type: 'today'
//   onResetOrder: () => void
//   manageableCargosSorted: SortingRule[]
// }

// interface FutureProps extends BaseProps {
//   type: 'future'
//   onResetOrder: () => void
//   manageableCargosSorted: SortingRule[]
// }

// interface PastProps extends BaseProps {
//   type: 'past'
// }

export interface ExportCSVProps {
  action: () => void
  isLoading: boolean
}

interface Props extends BaseProps {
  onResetOrder: () => void
  cargoSorted: SortingRule[]
  exportCSVButton?: ExportCSVProps
}

// type ComplexProps = TodayProps | FutureProps

const CargoTableHeader = (props: Props) => {
  const { t } = useTranslation()
  const { fullTextFilter } = props

  const resetComponents = () => {
    return (
      <Column xs={6}>
        <div className={'text-right'}>
          {props.cargoSorted.length > 0 && (
            <Button
              variant={'warning'}
              spacing={{ vertical: false, horizontal: false }}
              label={t('mcs.today.resetOrder')}
              onClick={() => props.onResetOrder()}
            />
          )}
          {renderExportCSVButton()}
        </div>
      </Column>
    )
  }

  function renderExportCSVButton() {
    if (props.exportCSVButton !== undefined) {
    return (
      <Button
        variant={'secondary-alternate'}
        spacing={{ vertical: false, horizontal: false }}
        label={t('mcs.common.exportCSV')}
        isLoading={props.exportCSVButton?.isLoading}
        onClick={() => props.exportCSVButton?.action()}
      />
    )
    }
  }

  return (
    <Row horizontalAlignment={'start'} spacing={{ horizontal: false, vertical: false }}>
      <Column xs={6}>
        <div className="form-group">
          <Row spacing={{ horizontal: false, vertical: false }} verticalAlignment={'center'}>
            <Column sm={2}>
              <label className="col-form-label">{t('mcs.today.filter')}</label>
            </Column>
            <Column sm={8}>
              <input
                className="form-control"
                type="text"
                onChange={e => {
                  e.persist()
                  const value = e.target.value
                  return props.handleFullTextFilterChange(value)
                }}
                value={fullTextFilter}
              />
            </Column>
          </Row>
        </div>
      </Column>
      {resetComponents()}
    </Row>
  )
}

export default CargoTableHeader
