import Period, { Action } from './Period'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export type PastType = 'canceled' | 'concluded' | 'unprocessed' | 'rescheduled'

export class Past extends Period {
  private type: PastType

  constructor(type: PastType) {
    super()
    this.type = type
  }

  protected getConfigColumns = (configTables: Tables) => {
    switch (this.type) {
      case 'canceled':
        return configTables.pastCanceled /* [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.basin,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.forkliftDriverDisplayName,
          TableColumnType.packages,
          TableColumnType.samplingRequired,
          TableColumnType.note,
          TableColumnType.timeAbbrev,
        ] */
      case 'concluded':
        return configTables.pastConcluded /* [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.basin,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.forkliftDriverDisplayName,
          TableColumnType.packages,
          TableColumnType.samplingRequired,
          TableColumnType.note,
          TableColumnType.images,
          TableColumnType.timeAbbrev,
          TableColumnType.actions,
        ] */
      case 'unprocessed':
        return configTables.pastUnprocessed /* [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.basin,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.forkliftDriverDisplayName,
          TableColumnType.packages,
          TableColumnType.samplingRequired,
          TableColumnType.note,
          TableColumnType.timeAbbrev,
          TableColumnType.actions,
        ] */
      case 'rescheduled':
        return configTables.pastRescheduled /*  [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.basin,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.forkliftDriverDisplayName,
          TableColumnType.packages,
          TableColumnType.samplingRequired,
          TableColumnType.note,
          TableColumnType.timeAbbrev,
        ] */
    }
  }
  public noActionShowText: (cargo: Cargo) => boolean = _cargo => false
  public actionButton: (cargo: Cargo) => Action | undefined = cargo => {
    switch (cargo.status) {
      case 'concluded':
        return { label: 'show', onClickType: 'summary' }
    }
  }
  public getDropdownAction: (cargo: Cargo) => Action[] = _cargo => []
}
