// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex, {
  AlignContent,
  Direction,
  JustifyContent,
} from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'

// * -------------------------------- MODULE --------------------------------------
import PlannerSlotComponent from './PlannerSlot'
import Process from '../../../../types/process'
import { buildContentLevelSlot } from '../../../../functions/buildContentLevelSlot'
import Halt from '../../../../types/halt'

export class PlannerSlotProcessCompare extends PlannerSlotComponent<Process | Halt> {
  private size: 'lg' | 'md' | 'sm' = 'sm'
  private t = this.props.translations.t
  private base = 'recipeControl.weekCompare'
  private manualRecipeTraduction = `${this.base}.manualRecipe`
  private haltsTranslationString = this.t(`${this.base}.halt`)

  private renderWarningText(direction: 'vertical' | 'horizontal'): React.ReactNode {
    const { slot } = this.props
    const warning = slot.allWarnings

    const isProcess = slot instanceof Process

    const warningRender = () => {
      if (slot.allMismatchesWarning.length > 0) {
        if (direction === 'horizontal' && !this.props.showFullData) {
          const expectedRecipe = isProcess ? slot.recipeGroup.displayName : this.haltsTranslationString
          const actualRecipe = this.t(this.manualRecipeTraduction)
          return (
            <Flex
              justifyContent={JustifyContent.center}
              alignSelf={AlignSelf.center}
              alignContent={AlignContent.center}
              className={'planner__slot--compare__title-container planner__slot--compare__title'}
            >
              <div className={'planner__slot--compare__title-content'}>
                <Text text={`|**~${expectedRecipe}~**|`} alignment={'center'} />
              </div>
              <div className={'planner__slot--compare__title-content'}>
                <Text text={`|**${actualRecipe}**|`} alignment={'center'} />
              </div>
            </Flex>
          )
        } else {
          const expectedRecipe = isProcess ? slot.recipeGroup.displayName : this.haltsTranslationString
          const actualRecipe = this.t(this.manualRecipeTraduction)
          return (
            /*               <Flex
              key={index}
              alignItems={AlignItems.center}
              alignSelf={AlignSelf.center}
              justifyContent={JustifyContent.center}
              // className={'planner__slot--compare__title-container'}
            > */
            <Flex
              direction={Direction.column}
              justifyContent={JustifyContent.center}
              alignSelf={AlignSelf.center}
              alignContent={AlignContent.center}
              className={'planner__slot--compare__title-container planner__slot--compare__title'}
            >
              <div className={'planner__slot--compare__title-content-lg'}>
                <Text text={`|**~${expectedRecipe}~**|`} alignment={'center'} />
              </div>
              <div className={'planner__slot--compare__title-content-lg'}>
                <Text text={`|**${actualRecipe}**|`} alignment={'center'} />
              </div>
            </Flex>
            /* </Flex> */
          )
        }
      }
      if (slot.allConnectionWarning.length > 0) {
        const recipe = isProcess ? slot.recipeGroup.displayName : this.haltsTranslationString
        if (!warning.find(e => e.type === 'recipe-mismatch')) {
          return (
            /* <Flex
                key={index}
                alignItems={AlignItems.center}
                alignSelf={AlignSelf.center}
                justifyContent={JustifyContent.center}
              > */
            <Flex
              justifyContent={JustifyContent.center}
              alignSelf={AlignSelf.center}
              alignContent={AlignContent.center}
              className={'planner__slot--compare__title-container planner__slot--compare__title '}
            >
              <Text text={`|**${recipe}**|`} alignment={'center'} />
            </Flex>
            /* </Flex> */
          )
        }
      }
      return <></>
    }

    return <>{warningRender()}</>
  }

  private isWarning(): boolean {
    return this.props.slot.allWarnings.length > 0
  }

  protected processToSlotTypes(): string {
    if (this.isWarning()) {
      if (this.props.slot.mismatchPercentage > 25 && this.props.slot.mismatchPercentage < 75) {
        this.size = 'md'
      }
      if (this.props.slot.mismatchPercentage >= 75) {
        this.size = 'lg'
      }
      return super.processToSlotTypes() + ` planner__slot--compare-warning-${this.size}`
    }
    return super.processToSlotTypes() + ''
  }

  protected showTooltip(): boolean {
    const process = this.props.slot
    let textLength = 0
    process.allWarnings
      .filter(w => w.type === 'recipe-mismatch')
      .forEach(w => {
        if (w.type === 'recipe-mismatch') {
          const processDisplayNameLength =
            process instanceof Process ? process.recipeGroup.displayName.length : this.haltsTranslationString.length
          textLength += processDisplayNameLength + this.t(this.manualRecipeTraduction).length
        }
      })
    return (
      (textLength >= this.CHAR_TRIM * 0.5 &&
        !buildContentLevelSlot(this.props.showFullData || this.props.isOneShiftPerDay).isToShowAllContent(this.props.percentageDimension) &&
        !this.props.showFullData) ||
      buildContentLevelSlot(this.props.showFullData || this.props.isOneShiftPerDay).isToShowShortContent(this.props.percentageDimension)
    )
  }

  protected renderTooltip(): string {
    const process = this.props.slot

    const processDisplayNameLength =
      process instanceof Process ? process.recipeGroup.displayName : this.haltsTranslationString
    const tooltipContent = processDisplayNameLength + ' - ' + this.t(this.manualRecipeTraduction)
    
    if (tooltipContent.length) {
      return `${this.dateString}\n${tooltipContent}`
    }
    return this.dateString
  }

  protected renderShortContent(): React.ReactNode {
    const process = this.props.slot
    if (this.isWarning()) {
      return this.renderWarningText('horizontal')
    }
    let textToShow =
      process instanceof Process ? `|**${process.recipeGroup.displayName}**|` : `|**${this.haltsTranslationString}**|`
    if (process instanceof Process && this.showLongText(process.materialBlend).length > 0) {
      textToShow += ` |\n|  |**${this.showLongText(process.materialBlend)}**|`
    }
    return this.renderText(textToShow)/*  <Text text={`${textToShow}`} /> */
  }

  protected renderHalfContent(): React.ReactNode {
    const process = this.props.slot
    if (this.isWarning()) {
      return this.renderWarningText('horizontal')
    }
    let textToShow =
      process instanceof Process ? `|**${process.recipeGroup.displayName}**|` : `|**${this.haltsTranslationString}**|`
    if (process instanceof Process && this.showLongText(process.materialBlend).length > 0) {
      textToShow += ` |\n|  |**${this.showLongText(process.materialBlend)}**|`
    }
    return this.renderTextWithDate(textToShow) /* (
      <>
        {this.renderShortContent()}
        <Text text={`${this.dateString}`} />
      </>
    ) */
  }

  protected renderAllContent(): React.ReactNode {
    const process = this.props.slot

    if (this.isWarning()) {
      return (
        <>
          {this.renderWarningText('vertical')}
          <Text text={`${this.dateString}`} />
        </>
      )
    }
    const longText = process instanceof Process && this.showLongText(process.materialBlend)
    return (
      <Text
        text={`|**${
          process instanceof Process ? process.recipeGroup.displayName : this.haltsTranslationString
        }**|  ${(longText && `|\n| |**${this.showLongText(process.materialBlend)}**`) || ''}  |\n| ${this.dateString} `}
      />
    )
  }
}

export default PlannerSlotProcessCompare
