import { Button } from '@mv-submodules/inplant-components-fe'
import DatePicker from 'react-datepicker'
import * as React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface Props {
  date: moment.Moment
  onDateChange: (date: moment.Moment) => void
}

const DateSwitcher = (props: Props) => {
  const { t } = useTranslation()
  const { date, onDateChange } = props

  const handleDateDecrement = () => {
    const newDate = moment(date).subtract(1, 'day')
    onDateChange(newDate)
  }

  const handleTodayDate = () => {
    const newDate = moment()
    onDateChange(newDate)
  }

  const handleDateIncrement = () => {
    const newDate = moment(date).add(1, 'day')
    onDateChange(newDate)
  }

  const handleDateChange = (newDate: Date) => {
    onDateChange(moment(newDate))
  }

  return (
    <>
      <Button
        label={t('mcs.dateSwitcher.today')}
        variant={'secondary-alternate'}
        spacing={{ vertical: false }}
        disabled={date.date() === moment().date()}
        onClick={handleTodayDate}
      />
      <div className="input-group">
        <div className="input-group-prepend">
          <Button
            variant={'secondary-alternate'}
            spacing={{ horizontal: false, vertical: false }}
            type="button"
            onClick={handleDateDecrement}
            icon={"chevron-left"}
          />
        </div>
        <DatePicker
          selected={date.toDate()}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          className="form-control text-center"
        />
        <div className="input-group-append">
          <Button
            variant={'secondary-alternate'}
            spacing={{ horizontal: false, vertical: false }}
            icon={"chevron-right"}
            type="button"
            onClick={handleDateIncrement}
          />
        </div>
      </div>
    </>
  )
}

export default DateSwitcher
