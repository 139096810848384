import { API } from '../actions'
import { Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'

export const outgoingRecipientsFetchData = (q?: string): ((dispatch: Dispatch) => Promise<string[]>) => {
  return async dispatch => {
    try {
      return await API().request(`/outgoing/recipients${q ? `?q=${q}` : ''}`)
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}
