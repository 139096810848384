import { lazy } from 'react'
import { RouteProps } from '../../inplant-core-fe/types'

const BeaconListPageView = lazy(() => import('./components/views/BeaconList/BeaconListPageView'))
const ActivityPageView = lazy(() => import('./components/views/Activity/ActivityPageView'))
const DetailActivityPageView = lazy(() => import('./components/views/DetailActivity/DetailActivityPageView'))
const EditBeaconPageView = lazy(() => import('./components/views/EditBeacon/EditBeaconPageView'))
const GroupListPageView = lazy(() => import('./components/views/GroupList/GroupListPageView'))
const ManageGroupPageView = lazy(() => import('./components/views/ManageGroup/ManageGroupPageView'))
const CreateGroupPageView = lazy(() => import('./components/views/CreateGroup/CreateGroupPageView'))

export const module = 'proximity'

export const Pages = {
  activity: 'activity',
  beaconList: 'beacon-list',
  groups: 'groups',
  createGroup: 'create-groups'
}

export const ChildPages = {
  edit: `${Pages.beaconList}/:id`,
  detailActivity: `${Pages.activity}/:id`,
}

type KeysPages = keyof typeof Pages
export const routesEntries: Array<[KeysPages, string]> = Object.entries(Pages) as Array<[KeysPages, string]>

function generateRoutes(pageSlugs: typeof routesEntries): RouteProps {
  return {
    path: `/${module}/${Pages.beaconList}`,
    i18nkey: `${module}.module`,
    icon: 'wave-square',
    visible: true,
    breadcrumbPath: module,
    children: pageSlugs.reduce((acc: RouteProps[], [key, path]) => {
      return acc.concat(
        {
          path: `/${module}/${path}`,
          exact: true,
          i18nkey: `${module}.navigation.${key}`,
          component: selectPageToGenerate(key),
          visible: getIsVisibleFromPage(key),
          breadcrumbPath: path
        },
        ...createDerivatePages(key)
      )
    }, []),
  }
}

const getIsVisibleFromPage = (pageSlug: KeysPages) => {
  switch (pageSlug) {
    case 'createGroup':
      return false
    // case 'activity':
    case 'beaconList':
    case 'groups':
    default:
      return true
  }
}

const selectPageToGenerate = (pageSlug: KeysPages) => {
  switch (pageSlug) {
    case 'createGroup': 
      return CreateGroupPageView
    case 'groups':
      return GroupListPageView
    case 'activity':
      return ActivityPageView
    case 'beaconList':
      return BeaconListPageView
  }
}

const createDerivatePages = (pageSlug: KeysPages): RouteProps[] => {
  switch (pageSlug) {
    case 'createGroup': 
      return []
    case 'groups': 
      return [
        {
          path: `/${module}/${Pages[pageSlug]}/:id`,
          exact: true,
          visible: false,
          component: ManageGroupPageView,
          i18nkey: 'manage-group',
        }
      ]
    case 'activity':
      return [
        {
          path: `/${module}/${Pages[pageSlug]}/:id`,
          exact: true,
          visible: false,
          component: DetailActivityPageView,
          i18nkey: 'detailActivity',
        },
      ]
    case 'beaconList':
      return [
        {
          path: `/${module}/${Pages[pageSlug]}/:id`,
          exact: true,
          visible: false,
          component: EditBeaconPageView,
          i18nkey: 'editBeacon',
        },
      ]
  }
}

export default generateRoutes
