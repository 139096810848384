// * -------------------------------- NPM --------------------------------------
import React from 'react'
// * -------------------------------- COMPONENTS --------------------------------------
import Flex, { JustifyContent, StretchColumn } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import IconComponent from '../../../../../mvlabs-components-fe/ui/components/Icon/Icon'
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { DAY_MONTH_YEAR, mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { generateSpace, SpaceType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import Process from '../../../../types/process'
import Recipe from '../../../../types/recipe'
import Shift from '../../../../types/shift'
import TableProcessDetail from '../Table/TableProcessDetail'
import { Asset } from '../../../../types/asset'
import Halt from '../../../../types/halt'
import { EmptySlot } from '../../../../types/emptySlot'
import { getModule } from '../../../../functions'
import { Modules } from '../../../routes'

interface Props {
  slot: Process | Halt | EmptySlot
  shift: Shift
  configuration: Configuration
  onClose: () => void
}

const ModalCompare: React.FC<Props & WithTranslation> = props => {
  const { onClose, slot, shift } = props
  const { t, base } = props.translation
  const { assets, recipes } = props.configuration
  const currentBase = base + '.modalCompare'
  const classBase = 'recipecontrol__modal-compare'

  // const mismatchWarning: IRecipeMismatchWarning = slot.warnings.filter(
  //   w => w.type === 'recipe-mismatch'
  // )[0] as IRecipeMismatchWarning
  // const mismatchWarning: RecipeMismatch = slot.warning?.mismatches[0] as RecipeMismatch

  // const connectionError: IConnectionWarning = slot.warnings.filter(
  //   w => w.type === 'connection'
  // )[0] as IConnectionWarning

  // const _connectionError: AssetConnection = slot.warning?.connections[0] as AssetConnection

  const mappingAssetRecipe: Array<[Asset, Recipe]> = slot instanceof Process ? slot.recipeGroup.mappings.reduce(
    (acc: Array<[Asset, Recipe]>, mapping) => {
      const asset = mapping.getAsset(assets)
      const recipe = mapping.getRecipe(recipes)
      if (asset && recipe) {
        return [...acc, [asset, recipe]]
      }
      return acc
    },
    []
  ) : []

  const isPlant = getModule() === Modules.plant

  return (
    <Modal
      visible={true}
      onClose={onClose}
      closeOnFocusOut={true}
      disableCloseButton={true}
      title={t(`${currentBase}.title`, { date: mvDate.format(slot.day, DAY_MONTH_YEAR), time: shift.name })}
    >
      <StretchColumn spaceSize={'lg'}>
        <Flex className={classBase} justifyContent={JustifyContent.evenly}>
          <Flex
            alignSelf={AlignSelf.start}
            spaceSize={'md'}
            borderRadius={'md'}
            className={`${classBase}--planned-recipe ${generateSpace(SpaceType.padding, { val: 3 })}`}
          >
            <StretchColumn spaceSize={'none'}>
              <Text text={`|**${t(`${currentBase}.recipePlanned`)}**|`} semantic={'light'} />
              <Text
                size={'lg'}
                text={`|**${
                  slot.getDisplayName(
                    props.translation
                  ) /*  mismatchWarning ? mismatchWarning.recipeGroupDisplayName : connectionError.recipeGroupDisplayName */
                }**|`}
              />
              {slot.warning && (
                <Text
                  text={`|**${mvDate.getDateFromStringWithFormatting(
                    slot.warning.from,
                    TIME
                  )} - ${mvDate.getDateFromStringWithFormatting(slot.warning.to, TIME)}**|`}
                />
              )}
            </StretchColumn>
          </Flex>
          {
            /* mismatchWarning &&  */ <>
              <IconComponent icon={'not-equal'} />
              <Flex
                alignSelf={AlignSelf.end}
                spaceSize={'md'}
                borderRadius={'md'}
                className={`${classBase}--actual-recipe ${generateSpace(SpaceType.padding, { val: 3 })}`}
              >
                <StretchColumn spaceSize={'none'}>
                  <Text text={`|**${t(`${currentBase}.actualRecipe`)}**|`} />
                  <Text size={'lg'} text={`|**${t(`${currentBase}.manualActualRecipe`)}**|`} />
                  {slot.warning && (
                    <Text
                      text={`|**${mvDate.getDateFromStringWithFormatting(
                        slot.warning.from,
                        TIME
                      )} - ${mvDate.getDateFromStringWithFormatting(slot.warning.to, TIME)}**|`}
                    />
                  )}
                </StretchColumn>
              </Flex>
            </>
          }
        </Flex>
        {/* // TODO Sistemare i commenti */}
        {/* <StretchColumn>
          <Text size={'lg'} semantic={'light'} text={`|**${t(`${currentBase}.motivationTitle`)}**|`} />
          <Text text={`|**${mismatchWarning?.motivation || '---'}**|`} />
        </StretchColumn> */}
        <StretchColumn>
          <Text size={'lg'} semantic={'light'} text={`|**${isPlant ? t(`${currentBase}.listActivity`) : t(`${currentBase}.listMachine`)}**|`} />
            <TableProcessDetail
              shift={shift}
              process={slot}
              mappingAssetRecipe={mappingAssetRecipe}
              translation={{ t, base: base + '.planner.modalProcessDetail' }}
            />
        </StretchColumn>
      </StretchColumn>
    </Modal>
  )
}
export default ModalCompare
