export interface IConfirmationConfig {
  confirmationEnabled: boolean
  minDateConfirmShifts: number
  minutesTimingAdvanceBeforeManualUpload: number
}

export class ConfirmationConfig {

  private readonly _confirmationEnabled: boolean
  private readonly _minDateConfirmShifts: number
  private readonly _minutesTimingAdvanceBeforeManualUpload: number

  get confirmationEnabled() {
    return this._confirmationEnabled
  }

  get minDateConfirmShifts() {
    return this._minDateConfirmShifts
  }

  get minutesTimingAdvanceBeforeManualUpload(): number {
    return this._minutesTimingAdvanceBeforeManualUpload;
  }

  get interface(): IConfirmationConfig {
    return {
      confirmationEnabled: this._confirmationEnabled,
      minDateConfirmShifts: this._minDateConfirmShifts,
      minutesTimingAdvanceBeforeManualUpload: this._minutesTimingAdvanceBeforeManualUpload
    }
  }

  constructor(iConfirmationConfig: Partial<ConfirmationConfig>) {
    this._confirmationEnabled = iConfirmationConfig.confirmationEnabled ?? true
    this._minDateConfirmShifts = iConfirmationConfig.minDateConfirmShifts || 3
    this._minutesTimingAdvanceBeforeManualUpload = iConfirmationConfig.minutesTimingAdvanceBeforeManualUpload || 20
  }

  static get default(): IConfirmationConfig {
    return new ConfirmationConfig({})
  }

  public copyWith = (props?: Partial<ConfirmationConfig>): ConfirmationConfig => {
    return new ConfirmationConfig({
      confirmationEnabled: props && props.confirmationEnabled !== undefined ? props.confirmationEnabled : this._confirmationEnabled,
      minDateConfirmShifts: props && props.minDateConfirmShifts !== undefined ? props.minDateConfirmShifts : this._minDateConfirmShifts,
    })
  }
}