import * as React from 'react'
import { ChecklistSectionCheck, ChecklistComponent } from '../../../../types'
import { withTranslation, WithTranslation } from 'react-i18next'
import ComponentGroup from './ComponentGroup'
import CollapsableComponentGroup from './CollapsableComponentGroup'
import CheckFillingInfo from './CheckFillingInfo'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { Button } from '@mv-submodules/inplant-components-fe'
import { IconComponent } from '../../../../../mvlabs-components-fe/ui/components'
import { Tooltip } from 'react-tippy'

export interface OwnProps {
  check: ChecklistSectionCheck
  checklistId: string
  checklistIsConcluded: boolean
  deleteCheckHandler: (event: any, checkId: string) => void
}

export type Props = OwnProps & WithTranslation

function componentsHaveData(components: ChecklistComponent[]) {
  return components.some(component => component.lastValue !== null)
}

export function ChecklistCheck(props: Props) {
  const mandatoryComponents = props.check.components.filter(component => !component.optional)
  const optionalComponents = props.check.components.filter(component => component.optional)
  return (
    <Column xs={12} key={props.check.id}>
      <div className={'cl-item'}>

        <div className='d-flex flex-column flex-md-row align-items-start justify-content-between'>
          <div className='cl-item-description'>
            <p>
              {props.check.isCustom && !props.checklistIsConcluded ? (
                <Button variant={'link'}
                        onClick={(e) => props.deleteCheckHandler(e, props.check.id)}
                        icon={"times-circle"}
                        size={"sm"}
                        spacing={{horizontal:false}}
                />
              ) : null}
              <span className={!props.check.isValid ? 'text-danger' : ''}>
                {props.check.isBlocking ? (
                          <span
                            className="text-warning"
                            aria-label={props.t('checklist.common.koBlocksCompilations')}
                            data-balloon-pos="right"
                          >
                            <IconComponent icon={'exclamation'} className='mr-1 text-info' /> 
                          </span>
                        ) : null}
                {!props.check.isValid ? <IconComponent icon={'exclamation-triangle'} className='mr-1' /> : null}
                <span className='mx-1'>
                  {props.check.description}
                </span>
                {props.check.generateEmailAlert && 
                    <Tooltip  title={props.t('checklist.edit.checklistEmailInfo')} trigger='mouseenter click'>
                      <IconComponent icon={'envelope'} />
                    </Tooltip>
                }
            </span>
            </p>
          </div>
          <div className='cl-item-controls'>
            {props.check.canBeFilled || props.checklistIsConcluded ? (
              <ComponentGroup
                className={`mandatory-components ${optionalComponents.length === 0 ? 'has-not-optional-components' : ''}`}
                components={mandatoryComponents}
                checklistInstanceId={props.checklistId}
                checkId={props.check.id}
                disabled={props.checklistIsConcluded}
              />
            ) : null}
            {(!props.checklistIsConcluded && props.check.canBeFilled) ||
            (props.checklistIsConcluded && componentsHaveData(optionalComponents)) ? (
              <CollapsableComponentGroup
                title={props.t('checklist.checks.optionalChecks')}
                className='optional-components'
                components={optionalComponents}
                checklistInstanceId={props.checklistId}
                checkId={props.check.id}
                disabled={props.checklistIsConcluded}
              />
            ) : null}
            {!props.check.canBeFilled && !props.checklistIsConcluded ? <CheckFillingInfo check={props.check} /> : null}
          </div>
        </div>
      </div>

    </Column>
  )
}

export default withTranslation()(ChecklistCheck)
