// * ------------------- NPM -----------------------------
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// * ------------------- COMPONENTS -----------------------------
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'

// * ------------------- MODULE -----------------------------
import { RecipeGroup } from '../../../../types/recipeGroup'
import { deleteConfiguration } from '../../../../redux/action/configurationActions'

interface Props extends WithTranslation {
  recipeGroup: RecipeGroup
  onClose: () => void
  onActionComplete: () => void
}

const ModalDeleteRecipeGroup = (props: Props) => {
  const { recipeGroup, onClose, translation, onActionComplete } = props
  const { t } = translation
  const base = translation.base + '.delete'
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = () => {
    setIsLoading(true)
    deleteConfiguration(recipeGroup)(dispatch)
      .then(() => {
        setIsLoading(false)
        onActionComplete()
      })
      .catch(_e => {
        setIsLoading(false)
      })
  }

  return (
    <Modal
      visible={true}
      title={t(`${base}.title`)}
      rightFooterContents={[
        {
          isDisable: isLoading,
          isLoading,
          label: t(`${base}.deleteButton`),
          onClick: handleDelete,
          semantic: 'danger',
          kind: 'button',
        },
      ]}
      onClose={onClose}
    >
      <Text text={t(`${base}.content`)} />
    </Modal>
  )
}

export default ModalDeleteRecipeGroup
