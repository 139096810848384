// * ------------------- MODULE -----------------------------
import { IMapping, Mapping } from './mapping'

export interface IRecipeGroup {
  id: string
  displayName: string
  mappings: IMapping[]
}

export class RecipeGroup {
  get id(): string {
    return this._id
  }

  get displayName(): string {
    return this._displayName
  }

  get mappings(): Mapping[] {
    return this._mappings
  }

  get body() {
    return JSON.stringify({
      recipeIds: this._mappings.map(m => m.recipeId),
      displayName: this._displayName,
    })
  }

  get interface(): IRecipeGroup {
    return {
      id: this.id,
      displayName: this.displayName,
      mappings: this.mappings.map(m => m.interface),
    }
  }

  /**
   * key is assetId
   * value is recipeId
   */
  public getMappingAsKeyValue(): { [key: string]: Mapping } {
    return this.mappings.reduce((acc, curr) => ({ ...acc, [curr.assetId]: curr }), {})
  }

  private readonly _id: string
  private readonly _displayName: string
  private readonly _mappings: Mapping[]

  constructor(iRecipeGroup: Partial<IRecipeGroup>) {
    if (!iRecipeGroup.id) {
      throw new Error()
    }
    this._id = iRecipeGroup.id
    this._displayName = iRecipeGroup.displayName || ''
    this._mappings = iRecipeGroup.mappings?.map(m => new Mapping(m)) || []
  }

  public copyWith(props?: Partial<{ id: string; displayName: string; mappings: Mapping[] }>): RecipeGroup {
    return new RecipeGroup({
      id: (props && props.id) || this._id,
      displayName: (props && props.displayName) || this._displayName,
      mappings: (props && props.mappings) || this._mappings,
    })
  }
}
