// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex, { AlignItems, Direction, Fit } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { InputDiscreteTime } from '../../../../../mvlabs-components-fe/ui/components/Input/Input'
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------

export interface HoursIntervalProps {
  initialTime: { from: Date; to: Date }
  startTime: Date
  endTime: Date
  onTimeChange: (type: 'from' | 'to', value: Date) => void
  minWorkingDuration: number
}

const HoursInterval = (props: HoursIntervalProps) => {
  const { t } = useComponentsTranslation()
  const tStart = 'recipeControl.form'

  const { onTimeChange, initialTime, minWorkingDuration, startTime, endTime } = props

  return (
    <Flex fit={Fit.oneLine} direction={Direction.row} alignItems={AlignItems.end} spaceSize={'lg'}>
      <InputDiscreteTime
        required={true}
        onTimeChange={value => {
          onTimeChange('from', value)
        }}
        initialTime={initialTime.from}
        label={t(`${tStart}.from`)}
        startTime={mvDate.toDate(startTime)}
        endTime={mvDate.toDate(endTime)}
        minuteDiscreteInterval={minWorkingDuration}
      />
      <InputDiscreteTime
        required={true}
        onTimeChange={value => {
          onTimeChange('to', value)
        }}
        initialTime={initialTime.to}
        label={t(`${tStart}.to`)}
        startTime={mvDate.toDate(startTime)}
        endTime={mvDate.toDate(endTime)}
        minuteDiscreteInterval={minWorkingDuration}
      />
    </Flex>
  )
}

export default HoursInterval
