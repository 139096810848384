// * ------------------- NPM -----------------------------
import React, { useState } from 'react'
import { useComponentsTranslation } from '@mv-submodules/mvlabs-components-fe/services/translation'
import { useDispatch } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import Flex, { AlignContent, Direction, Fit } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import Title from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { ButtonVariants } from '../../../../../mvlabs-components-fe/ui/components/Buttons/types'
import { DAY_MONTH_LONG, DAY_MONTH_SHORT, mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import PlannerBadge from './PlannerBadge'
import Shift from '../../../../types/shift'
import { PlannerViewTypes } from '../../../../types/viewType'
import { confirmDay } from '../../../../redux/action/plannerActions'
import { shiftEditable } from '../../../../functions'

interface OwnProps {
  shiftsGroupedForDays: { [key: string]: Shift[] }
  viewType: PlannerViewTypes
  configuration: Configuration
  onConfirmDay: (shifts: Shift[]) => void
}

type Props = OwnProps
const PlannerDates = (props: Props) => {
  const { t } = useComponentsTranslation()
  const dispatch = useDispatch()
  const { confirmationEnabled } = props.configuration.confirmationConfig
  /*
   * -----------------------------------------------------------------------
   * ---------------------------- INIT ------------------------------------
   * -----------------------------------------------------------------------
   * */
  const { shiftsGroupedForDays: shiftsGroupedForDays } = props
  const tName = 'recipeControl.planner'

  const plannerDateComponent: React.ReactNode[] = []

  const [areButtonSubmitting, setAreButtonsSubmitting] = useState<boolean[]>(
    new Array(Object.values(shiftsGroupedForDays).length).fill(false)
  )

  /*
   * -----------------------------------------------------------------------
   * ---------------------------- DEFINITIONS ------------------------------
   * -----------------------------------------------------------------------
   * */
  const renderButton = (dayConfirmed: boolean, day: Date, index: number) => {
    if (!confirmationEnabled) {
      return
    }
    const isReadonly = props.viewType === 'read-only'
    const isCompare = props.viewType === 'compare'
    if (isCompare) {
      return null
    }
    if (dayConfirmed || isReadonly) {
      return (
        <PlannerBadge
          icon={dayConfirmed ? 'check' : isReadonly ? 'hourglass-start' : undefined}
          description={dayConfirmed ? t(`${tName}.dates.confirmed`) : t(`${tName}.dates.toConfirm`)}
          readonly={
            isReadonly
              ? {
                  confirmed: dayConfirmed,
                  today: mvDate.getDate(mvDate.now()) === mvDate.getDate(day),
                }
              : false
          }
        />
      )
    }
    return (
      <Button
        isLoading={areButtonSubmitting[index]}
        isDisable={areButtonSubmitting[index]}
        icon={isReadonly ? 'hourglass-start' : undefined}
        semantic={'primary'}
        label={t(`${tName}.dates.toConfirm`)}
        alignSelf={AlignSelf.stretch}
        size={'sm'}
        onClick={() => {
          const submitting = [...areButtonSubmitting]
          submitting[index] = true
          setAreButtonsSubmitting(submitting)
          confirmDay(day)(dispatch)
            .then(shifts => props.onConfirmDay(shifts))
            .catch(e => {
              console.error(e) //tslint:disable-line
            })
            .finally(() => {
              const sub = [...areButtonSubmitting]
              sub[index] = false
              setAreButtonsSubmitting(sub)
            })
        }}
        variant={ButtonVariants.outline}
      />
    )
  }

  const renderPlannerDate = (shifts: Shift[], index: number) => {
    if (shifts.length !== 0) {
      const day = shifts[0].day

      const dayConfirmed = shifts
        .filter(shift => shiftEditable(shift, props.configuration.minTimeEditProcess))
        .every(shift => shift.confirmed)

      return (
        <Flex
          className={`${mvDate.isSameDay(day, mvDate.now()) ? 'date__today' : ''}`}
          direction={Direction.column}
          grow={1}
          shrink={1}
          alignContent={AlignContent.stretch}
          key={index}
        >
          <Title level={3} title={mvDate.format(day, 'EEE').toUpperCase()} />
          <Title level={2} title={mvDate.format(day, DAY_MONTH_SHORT)} />
          {renderButton(dayConfirmed, day, index)}
        </Flex>
      )
    }
    return null
  }

  /*
   * -----------------------------------------------------------------------
   * ---------------------------- RENDERS ----------------------------------
   * -----------------------------------------------------------------------
   * */

  const arrayShiftsGroupedForDays = Object.entries(shiftsGroupedForDays)
  if (arrayShiftsGroupedForDays.length === 1 && props.viewType === 'compare') {
    return null
  }
  if (arrayShiftsGroupedForDays.length === 1) {
    const day = arrayShiftsGroupedForDays[0][0]
    return (
      <Flex className={'planner__date'}>
        {<Title level={5} title={`${mvDate.format(mvDate.getDateFromString(day), DAY_MONTH_LONG)}`} />}
      </Flex>
    )
  }

  arrayShiftsGroupedForDays.forEach(([_day, shiftsGrouped], index) => {
    plannerDateComponent.push(renderPlannerDate(shiftsGrouped, index))
  })

  return (
    <Flex fit={Fit.oneLine} className={'planner__dates'}>
      {plannerDateComponent}
    </Flex>
  )
}

export default PlannerDates
