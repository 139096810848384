import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { Button } from '@mv-submodules/inplant-components-fe'
import DateSwitcher from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/ComponentsPastTodayFuture/DateSwitcher'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { API } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions'
import { useState } from 'react'

export type Titles = 'today' | 'archive' | 'program'

interface Props {
  date: moment.Moment
  title: Titles
}

const HeaderPage = (props: Props) => {

  const { date } = props
  const { t } = useTranslation()

  const [isLoading,setIsLoading] = useState(false)

  const handlePrintCargos = async () => {
    try {
      setIsLoading(true)
      const title = t('mcs.today.title')
      const formatDate = date.format('YYYY-MM-DD')
      const blob = await API().request(`/reports/daily/${formatDate}`)
      const objectURL = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = objectURL
      link.download = `${title} - ${date}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      setIsLoading(false)
    } catch (error) {
      console.error('Download error', error) // tslint:disable-line
    }
  }

  return (
    <header>
      <Row flex={true} horizontalAlignment={'between'} spacing={{ vertical: true, horizontal: false }}>
        <div>
          <h1 className='title'>{t('mcs.today.title')}</h1>
          <h2 className='subtitle'>
            {t(`mcs.${props.title}.subtitle`, { date: date.format('DD/MM/YYYY') })}
          </h2>
        </div>
        <div className='d-flex align-items-center'>
          <Button
            spacing={{ vertical: false }}
            type='button'
            isLoading={isLoading}
            variant={'secondary-alternate'}
            onClick={handlePrintCargos}
            icon={"print"}
          />

          <DateSwitcher />
        </div>
      </Row>
    </header>
  )
}

export default HeaderPage