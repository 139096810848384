import { AnyAction } from 'redux'
import { CommonStore } from '../../types/Store'
import * as moment from 'moment'

const commonInitialState: CommonStore = {
  status: {
    data: {},
    fetching: false,
    error: undefined,
  },
  parkings: {
    data: [],
    fetching: false,
    error: undefined,
  },
  lastClosedDays: {
    data: {
      incoming: null,
      outgoing: null,
    },
    fetching: false,
    error: undefined,
  },
  manageableTable: {
    resized: [],
  },
  concludedTable: {
    resized: [],
  },
}

export const common = (state = commonInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'MCS_COMMON_STATUS_IS_FETCHING':
      return {
        ...state,
        status: {
          data: state.status.data,
          fetching: true,
          error: undefined,
        },
      }
    case 'MCS_COMMON_STATUS_FETCH_SUCCESS':
      return {
        ...state,
        status: {
          data: action.payload,
          fetching: false,
          error: undefined,
        },
      }
    case 'MCS_COMMON_STATUS_FETCH_ERROR':
      return {
        ...state,
        status: {
          data: state.status.data,
          fetching: false,
          error: action.payload,
        },
      }
    case 'MCS_COMMON_PARKINGS_IS_FETCHING':
      return {
        ...state,
        parkings: {
          data: state.parkings.data,
          fetching: true,
          error: undefined,
        },
      }
    case 'MCS_COMMON_PARKINGS_FETCH_SUCCESS':
      return {
        ...state,
        parkings: {
          data: action.payload,
          fetching: false,
          error: undefined,
        },
      }
    case 'MCS_COMMON_PARKINGS_FETCH_ERROR':
      return {
        ...state,
        parkings: {
          data: state.parkings.data,
          fetching: false,
          error: action.payload,
        },
      }
    case 'MCS_COMMON_LAST_CLOSED_DAYS_IS_FETCHING':
      return {
        ...state,
        lastClosedDays: {
          data: state.lastClosedDays.data,
          fetching: true,
          error: undefined,
        },
      }
    case 'MCS_COMMON_LAST_CLOSED_DAYS_FETCH_SUCCESS':
      return {
        ...state,
        lastClosedDays: {
          data: {
            incoming: action.payload.incoming ? moment(action.payload.incoming) : null,
            outgoing: action.payload.outgoing ? moment(action.payload.outgoing) : null,
          },
          fetching: false,
          error: undefined,
        },
      }
    case 'MCS_COMMON_LAST_CLOSED_DAYS_FETCH_ERROR':
      return {
        ...state,
        lastClosedDays: {
          data: state.lastClosedDays.data,
          fetching: false,
          error: action.payload,
        },
      }
    case 'MCS_MANAGEABLE_SAVE_RESIZED_COLUMNS':
      return {
        ...state,
        manageableTable: {
          ...state.manageableTable,
          resized: action.resized,
        },
      }
    case 'MCS_CONCLUDED_SAVE_RESIZED_COLUMNS':
      return {
        ...state,
        concludedTable: {
          ...state.concludedTable,
          resized: action.resized,
        },
      }
    default:
      return state
  }
}

export default common
