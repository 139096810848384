// * ------------------- NPM -----------------------------
import { Dispatch } from 'redux'

// * ------------------- CORE -----------------------------
import { logoutUser } from '../../../inplant-core-fe/redux/actions'

// * ------------------- MODULE -----------------------------
import { API, CONFIGURATION_URL, RECIPE_URL } from '../action/index'
import { DataConfiguration } from '../../types/others'
import { RecipeGroup } from "../../types/recipeGroup";

export const createConfiguration = (data: DataConfiguration
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${CONFIGURATION_URL}/create`, {
        method: 'POST',
        body: JSON.stringify({ displayName: data.displayName,
          recipeIds: data.mappings.map(m => m.recipeId)}),
      })
  } catch (error: any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}


export const editConfiguration = (recipeGroup: RecipeGroup) => async (dispatch: Dispatch): Promise<void> => {
  try {

    return API()
      .request(`/${RECIPE_URL}/${CONFIGURATION_URL}/edit/${recipeGroup.id}`, {
        method: 'POST',
        body: recipeGroup.body,
      })
  } catch (error: any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}


export const deleteConfiguration = (recipeGroup: RecipeGroup) => async (dispatch: Dispatch): Promise<void> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${CONFIGURATION_URL}/${recipeGroup.id}`, {
        method: 'DELETE',
      })
  } catch (error: any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

