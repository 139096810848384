// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { Kind } from '../../../../../mvlabs-components-fe/types/kind'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import Halt from '../../../../types/halt'
import ModalEditCurrentProcess from './ModalEditCurrentProcess'
import ModalManageSlot from './ModalManageSlot'
import Process from '../../../../types/process'
import Shift from '../../../../types/shift'
import Slot from '../../../../types/slot'
import { ActionType } from '../../../../redux/action/plannerActions'
import { DataToCreateProcess } from '../../../../types/others'

export interface ModalEditFactoryProps {
  configuration: Configuration
  visible: boolean
  onClose: () => void
  updateShifts: (shifts: Shift[]) => void

  process:
    | {
        kind: 'create'
        data: Slot
      }
    | {
        kind: 'edit'
        type: ActionType.process
        data: Process
      }
    | {
        kind: 'edit'
        type: ActionType.halt
        data: Halt
      }
  shift: Shift
}

interface EditProcess extends Kind<'edit'> {
  type: ActionType.process
  data: Process
}

interface EditHalt extends Kind<'edit'> {
  type: ActionType.halt
  data: Halt
}

type Edit = EditProcess | EditHalt

interface Create extends Kind<'create'> {
  type: ActionType
  data: DataToCreateProcess
}

export type Actions = Edit | Create

const ModalManageFactory = (props: ModalEditFactoryProps) => {
  const { process, shift, configuration, visible, onClose, updateShifts } = props
  if (process.kind === 'edit' && process.data instanceof Process && process.data.isCurrentSlot()) {
    return (
      <ModalEditCurrentProcess
        configuration={configuration}
        visible={visible}
        onClose={onClose}
        updateShifts={updateShifts}
        process={process.data}
        shift={shift}
      />
    )
  }

  return (
    <ModalManageSlot
      updateShifts={updateShifts}
      shift={shift}
      onClose={onClose}
      visible={visible}
      configuration={configuration}
      process={process}
    />
  )
}

export default ModalManageFactory
