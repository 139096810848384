import { userReducer as user } from '@mv-submodules/inplant-user-fe'
import { authReducer } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { reducers as mcsReducer } from '@mv-submodules/inplant-mcs-fe-iblu'
import { checklistReducer } from '@mv-submodules/inplant-checklist-fe-iblu'
import { reducers as chronoReducer } from '@mv-submodules/inplant-chronoframe-fe'
import { reducers as plantHaltsReducer } from '@mv-submodules/inplant-fermiimpianto-fe-iblu'
import { reducers as titech } from '@mv-submodules/inplant-titech-fe-iblu'
import { reducers as recipeControlReducer } from '@mv-submodules/vidim-recipecontrol-fe'
import coreControlReducers from '../../inplant-coreadapter-fe/redux/reducers'
import componentsReducers from '../../mvlabs-components-fe/redux/reducers'
import { reducers as assetManagereReducer } from '@mv-submodules/inplant-asset-manager-fe'
import { combineReducers } from 'redux'

export const config = {
  generic: {
    loginSuccessPath: '/checklist/models-assets',
    plantType: 'inplant',
  },
  plantHalts: {
    enabledActions: (window as any).HALTS_ENABLED_ACTIONS || process.env.REACT_APP_HALTS_ENABLED_ACTIONS || false,
    typeOfInplant: (window as any).TYPE_OF_INPLANT || process.env.REACT_APP_TYPE_OF_INPLANT || 'v2',
    frequentsNotes: (window as any).HALTS_FREQUENTS_NOTES || process.env.REACT_APP_HALTS_FREQUENTS_NOTES || '',
  },
  plantSelector: {
    isMultiPlant: (window as any).MULTI_PLANT || process.env.REACT_APP_MULTI_PLANT || false,
  },
}

export default {
  recipeControl: recipeControlReducer,
  auth: authReducer,
  checklist: checklistReducer,
  chronoframe: chronoReducer,
  mcs: mcsReducer,
  plantHalts: plantHaltsReducer,
  user,
  titech,
  core: coreControlReducers,
  components: combineReducers(componentsReducers),
  config: () => config,
  assetManager: assetManagereReducer,
}
