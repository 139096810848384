import { TableColumnType, ActionTypes } from './ColumnsRender'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export interface Action {
  label: string
  onClickType: ActionTypes
}

abstract class Period {
  public getColumns: (configTables: Tables) => TableColumnType[] = configTables => {
    return this.getConfigColumns(configTables)
      .sort((a, b) => a.sort - b.sort)
      .map(el => el.type)
  }
  protected abstract getConfigColumns: (configTables: Tables) => Array<{ type: TableColumnType; sort: number }>
  public abstract actionButton: (cargo: Cargo) => Action | undefined
  public abstract getDropdownAction: (cargo: Cargo) => Action[]
  public abstract noActionShowText: (cargo: Cargo) => boolean
}

export default Period
