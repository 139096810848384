// * ------------------- MODULE -----------------------------
import Recipe, { IRecipe } from './recipe'
import { AlertConfig, IAlertConfig } from './alertConfig'
import { Asset, IAsset } from './asset'
import { ConfirmationConfig, IConfirmationConfig } from './confirmationConfig'
import { FormData, IFormData } from './formData'
import { IEditConfig } from './editConfig'
import { IRecipeGroup, RecipeGroup } from './recipeGroup'

interface IOverrideAdditionalFormData {
  process: IFormData[]
  halt: IFormData[]
}

interface OverrideAdditionalFormData {
  process: FormData[]
  halt: FormData[]
}

export interface IConfiguration {
  minWorkingDuration: number
  alertConfig: IAlertConfig
  processAdditionalFormData: IFormData[]
  haltAdditionalFormData: IFormData[]
  recipes: IRecipe[]
  confirmationConfig: IConfirmationConfig
  assets: IAsset[]
  recipeGroups: IRecipeGroup[]
  haltsEnabled: boolean
  editConfig: IEditConfig
  overrideAdditionalFormData: IOverrideAdditionalFormData
  scheduleOverrideEnable: boolean
}

class Configuration {
  private readonly _minWorkingDuration: number
  private readonly _requiresManualShiftUpload: boolean
  private readonly _recipes: Recipe[]
  private readonly _assets: Asset[]
  private readonly _recipeGroups: RecipeGroup[]
  private readonly _alertConfig: AlertConfig
  private readonly _processAdditionalFormData: FormData[]
  private readonly _haltAdditionalFormData: FormData[]
  private readonly _confirmationConfig: ConfirmationConfig
  private readonly _haltsEnabled: boolean
  private readonly _editConfig: IEditConfig
  private readonly _scheduleOverrideEnable: boolean
  private readonly _overrideAdditionalFormData: OverrideAdditionalFormData

  get scheduleOverrideEnable(): boolean {
    return this._scheduleOverrideEnable
  }

  get haltsEnabled(): boolean {
    return this._haltsEnabled
  }

  get requiresManualShiftUpload(): boolean {
    return this._requiresManualShiftUpload
  }

  get alertConfig(): AlertConfig {
    return this._alertConfig
  }

  get processAdditionalFormData(): FormData[] {
    return this._processAdditionalFormData
  }

  get haltAdditionalFormData(): FormData[] {
    return this._haltAdditionalFormData
  }

  get confirmationConfig(): ConfirmationConfig {
    return this._confirmationConfig
  }

  get assets(): Asset[] {
    return this._assets.map(a => a.copyWith())
  }

  get recipeGroups(): RecipeGroup[] {
    return this._recipeGroups.map(r => r.copyWith())
  }

  get minWorkingDuration(): number {
    return this._minWorkingDuration
  }

  get minTimeEditProcess(): number {
    return this.editConfig.currentShiftEditEnabled ? this.editConfig.minTimeEditShift : 0
  }

  get recipes(): Recipe[] {
    return this._recipes.map(r => r.copyWith())
  }

  get editConfig(): IEditConfig {
    return this._editConfig
  }

  get overrideAdditionalFormData(): OverrideAdditionalFormData {
    return this._overrideAdditionalFormData
  }

  get interface(): IConfiguration {
    return {
      alertConfig: this._alertConfig.interface,
      processAdditionalFormData: this._processAdditionalFormData,
      confirmationConfig: this._confirmationConfig.interface,
      recipes: this._recipes.map(r => r.interface),
      minWorkingDuration: this._minWorkingDuration,
      haltAdditionalFormData: this._haltAdditionalFormData,
      assets: this._assets.map(a => a.interface),
      recipeGroups: this._recipeGroups.map(rg => rg.interface),
      haltsEnabled: this.haltsEnabled,
      editConfig: this.editConfig,
      scheduleOverrideEnable: this.scheduleOverrideEnable,
      overrideAdditionalFormData: this.overrideAdditionalFormData
    }
  }

  constructor(props: Partial<IConfiguration>) {
    this._minWorkingDuration = props.minWorkingDuration || 15
    this._recipes = (props.recipes && props.recipes.map(r => new Recipe(r))) || []
    this._requiresManualShiftUpload = !props.confirmationConfig?.confirmationEnabled || false
    this._alertConfig = new AlertConfig(props.alertConfig || {})
    this._processAdditionalFormData = props.processAdditionalFormData?.map(fd => new FormData(fd)) || []
    this._confirmationConfig = new ConfirmationConfig(props.confirmationConfig || {})
    this._haltAdditionalFormData = props.haltAdditionalFormData?.map(fd => new FormData(fd)) || []
    this._recipeGroups = props.recipeGroups?.map(rg => new RecipeGroup(rg)) || []
    this._assets = props.assets?.map(a => new Asset(a)) || []
    this._haltsEnabled = props.haltsEnabled ?? false
    this._editConfig = props.editConfig || {
      currentShiftEditEnabled: false,
      minTimeEditShift: 0
    }
    this._scheduleOverrideEnable = props.scheduleOverrideEnable || false
    this._overrideAdditionalFormData = {
      process: props.overrideAdditionalFormData?.process.map(fd => new FormData(fd)) || [],
      halt: props.overrideAdditionalFormData?.halt.map(fd => new FormData(fd)) || []
    } || {
      process: [],
      halt: []
    }
  }

  static get default(): IConfiguration {
    return new Configuration({}).interface
  }
}

export default Configuration
