// ------------------------- NPM --------------------------------------
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { useState } from 'react'

// ------------------------- COMPONENTS --------------------------------------
import Modal from '@mv-submodules/inplant-components-fe/ui/components/Modal/Modal'

// ------------------------- MODULE --------------------------------------
import { cargoOpsDeleteCargo, cargoOpsBatchDeleteCargo } from '../../../../redux/actions/cargoOps'

library.add(faCircleNotch)

interface BaseOwnProps {
  onClose: (refreshData?:boolean) => void
}

interface DeleteProps extends BaseOwnProps {
  type: 'delete'
  cargoId: string
}

interface BatchDeleteProps extends BaseOwnProps {
  type: 'multipleDelete'
  cargoIds: string[]
}

type Props = DeleteProps | BatchDeleteProps

const DeleteCargoModal = (props: Props) => {
  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- HOOKs ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filters = useSelector((state: any) => state.mcs.filters)

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- INIT ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  const baseString = `mcs.modals.${props.type === 'multipleDelete' ? 'deleteCargos' : 'deleteCargo'}`
  const [isRemovingCargo, setIsRemovingCargo] = useState(false)

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- BLoS ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    try {
      setIsRemovingCargo(true)
      if (props.type === 'multipleDelete') {
        await cargoOpsBatchDeleteCargo(props.cargoIds, filters.direction)(dispatch)
      } else {
        await cargoOpsDeleteCargo(props.cargoId, filters.direction)(dispatch)
      }
      setIsRemovingCargo(false)
      props.onClose(true)
    } catch (error) {
      console.error(error) // tslint:disable-line
      setIsRemovingCargo(false)
    }
  }

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- RENDERS ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  return ReactDOM.createPortal(
    <Modal
      title={t(`${baseString}.title`)}
      visible={true}
      width={50}
      onClose={props.onClose}
      closeLabel={t('mcs.modals.deleteCargo.cancel')}
      additionalFooterButtons={[
        {
          label: t('mcs.modals.deleteCargo.delete'),
          isLoading: isRemovingCargo,
          disabled: isRemovingCargo,
          onClick: handleSubmit,
          variant: 'danger',
        },
      ]}
    >
      {t(`${baseString}.text`)}
    </Modal>,
    document.getElementById('modal-container') as Element
  )
}

export default DeleteCargoModal
