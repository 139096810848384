import { TableColumns } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/CargoTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Cargo, CargoDirections, CargoSelected } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'
import { Button } from '@mv-submodules/inplant-components-fe'
import { Tooltip } from 'react-tippy'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'
import { Column } from 'react-table'
import { faImages } from '@fortawesome/free-regular-svg-icons'
import { getCargoQueueWaitingTime } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'
import Period from './Period'
import { Future } from './Future'
import { Today } from './Today'
import { Past } from './Past'
import { CloseDay } from './CloseDay'
import { useSelector } from 'react-redux'
import { ConfigManager } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'
import { WeeklyReport } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/WeeklyReport'
import * as moment from 'moment'

export type ActionTypes =
  | 'delete'
  | 'detail'
  | 'checkout'
  | 'sendingToPendingArrival'
  | 'summary'
  | 'default'
  | 'toggle_row'
  | 'edit'
  | 'add'

export interface Actions {
  type: ActionTypes
  cargo: Cargo
}

interface Props {
  type: TableColumns
  direction: CargoDirections
  onAction?: (action: Actions) => void
  showCheckbox: boolean
  cargosSelected: CargoSelected
}

export enum TableColumnType {
  ticketNumber = 'ticketNumber',
  materialType = 'materialType',
  basin = 'basin',
  directionInfo = 'directionInfo',
  carrier = 'carrier',
  plate = 'plate',
  forkliftDriverDisplayName = 'forkliftDriverDisplayName',
  packages = 'packages',
  samplingRequired = 'samplingRequired',
  note = 'note',
  timeAbbrev = 'timeAbbrev',
  actions = 'actions',
  progressiveNumber = 'progressiveNumber',
  images = 'images',
  units = 'units',
  rescheduled = 'rescheduled',
  handledWeightKg = 'handledWeightKg',
}

const ColumnsRender = (props: Props) => {
  const translation = useTranslation()
  const configManager: ConfigManager = useSelector((state: any) => state.mcs.configManager.data)

  const { t } = translation
  const { type, direction } = props

  const createPeriodLogic = (): Period => {
    switch (type.type) {
      case 'future':
        return new Future()
      case 'today':
        return new Today(type.subType, type.subType === 'manageable' ? type.progressiveNumber : false)
      case 'past':
        return new Past(type.subType)
      case 'closeDay':
        return new CloseDay()
      case 'weeklyReport':
        return new WeeklyReport()
    }
  }

  const [periodLogic] = React.useState<Period>(createPeriodLogic())

  const handleOnAction = (action: Actions): void => {
    if (props.onAction) {
      props.onAction(action)
    }
  }

  // * --------------------------------------------------------------------
  // * --------------------------- DEFAULT METHODS ------------------------
  // * --------------------------------------------------------------------
  const renderSamplingIcon = (data: Cargo) => {
    if (data.samplingRequired) {
      if (data.samplingConfirmed === null) {
        return <FontAwesomeIcon icon={faEyeDropper} className="text-secondary" />
      }
      if (data.samplingConfirmed) {
        return <FontAwesomeIcon icon={faEyeDropper} className="text-secondary" />
      }
      if (!data.samplingConfirmed) {
        return <FontAwesomeIcon icon={faEyeDropper} className="text-danger" />
      }
    }
    if (!data.samplingRequired) {
      if (data.samplingConfirmed == null) {
        return null
      }
      if (data.samplingConfirmed) {
        return <FontAwesomeIcon icon={faEyeDropper} className="text-warning" />
      }
      if (!data.samplingConfirmed) {
        return null
      }
    }
    return null
  }

  // * --------------------------------------------------------------------
  // * ------------------------------ ACTIONS -----------------------------
  // * --------------------------------------------------------------------
  const getActionButton = (cargo: Cargo): JSX.Element => {
    const { status } = cargo
    const dropdownActions = periodLogic.getDropdownAction(cargo)
    const action = periodLogic.actionButton(cargo)

    if (periodLogic.noActionShowText(cargo)) {
      return <p className="font-italic text-secondary text-center mb-1">{t(`mcs.cargo.status.${status}`)}</p>
    }

    return (
      <div className={dropdownActions.length > 0 ? 'btn-group btn-block' : ''}>
        {(action && (
          <Button
            variant={'secondary-alternate'}
            blockLevel={true}
            size={'sm'}
            onClick={() => handleOnAction({ type: action.onClickType, cargo })}
            label={t(`mcs.cargo.actions.${action.label}`)}
            spacing={{ vertical: false, horizontal: false }}
          />
        )) ||
          null}
        {dropdownActions.length > 0 ? (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">{t('mcs.actions.toggleDropdown')}</span>
            </button>
            <div className="dropdown-menu">
              {dropdownActions.map((value, index) => {
                return (
                  <button
                    key={index}
                    className={`dropdown-item`}
                    onClick={() => handleOnAction({ type: value.onClickType, cargo })}
                  >
                    {t(`mcs.cargo.actions.${value.label}`)}
                  </button>
                )
              })}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    )
  }

  // * --------------------------------------------------------------------
  // * ------------------------------ COLUMNS -----------------------------
  // * --------------------------------------------------------------------
  const cargosCheckboxColumn = {
    accessor: 'cargosCheckbox',
    Cell: (data: any) => {
      return (
        <input
          type="checkbox"
          className="checkbox"
          checked={!!props.cargosSelected[data.original.id]} // dont remove !!
          onChange={() => handleOnAction({ type: 'toggle_row', cargo: data.original })}
        />
      )
    },
    sortable: false,
    width: 30,
  }

  const getColumn = (tableType: TableColumnType): Column | null => {
    const baseSlug = `mcs.cargo.${tableType}`
    const header = t(`${baseSlug}.label`)

    switch (tableType) {
      case TableColumnType.ticketNumber:
        return {
          Header: header,
          accessor: 'ticketNumber',
          maxWidth: 75,
        }
      case TableColumnType.materialType:
        return {
          Header: header,
          accessor: 'materialType.name',
          Cell: (data: any) => {
            if (data.original.materialType) {
              return data.original.materialType.description
            } else {
              return ''
            }
          },
          width: 100,
        }
      case TableColumnType.basin:
        return direction === 'incoming'
          ? {
              Header: header,
              accessor: 'basin',
              maxWidth: 100,
              Cell: (data: any) => {
                return data.original.basin
              },
            }
          : null
      case TableColumnType.directionInfo:
        switch (direction) {
          case 'incoming':
            return {
              Header: t('mcs.cargo.producer.label'),
              accessor: 'producer',
              width: 140,
            }
          case 'outgoing':
            return {
              Header: t('mcs.cargo.recipient.label'),
              accessor: 'recipient',
              width: 140,
            }
          case 'transfer':
            return null
        }
      case TableColumnType.carrier:
        return {
          Header: header,
          accessor: 'carrier',
          width: 120,
        }
      case TableColumnType.plate:
        return {
          Header: header,
          accessor: 'plate',
          className: 'text-uppercase',
          width: 80,
        }
      case TableColumnType.forkliftDriverDisplayName:
        return {
          Header: header,
          accessor: 'forkliftDriverDisplayName',
          width: 140,
        }
      case TableColumnType.packages:
        return {
          Header: header,
          accessor: 'packages',
          width: 50,
        }
      case TableColumnType.samplingRequired:
        return direction === 'incoming'
          ? {
              Header: '', // t('mcs.cargo.samplingRequired.label'),
              accessor: 'samplingRequired',
              maxWidth: 50,
              Cell: data => renderSamplingIcon(data.original),
            }
          : null
      case TableColumnType.units:
        return configManager.units.enable
          ? {
              Header: header,
              accessor: 'unit',
              width: 80,
              Cell: data => {
                return data.original.unit
              },
            }
          : null
      case TableColumnType.note:
        return {
          Header: header,
          accessor: 'note',
          style: { overflow: 'visible' },
          maxWidth: 55,
          Cell: (data: any) => {
            let notes
            if (
              !data.original.note &&
              !data.original.forkliftDriverNote &&
              !data.original.truckAnomalies &&
              !data.original.materialAnomalies
            ) {
              if (!data.original.note && !data.original.forkliftDriverNot) {
                return null
              }
              notes = `${data.original.note || ''} ${data.original.forkliftDriverNote || ''}`
              return (
                <div aria-label={notes} data-balloon-pos="left">
                  <FontAwesomeIcon icon={faInfoCircle} className="text-secondary" />
                </div>
              )
            }
            notes = (
              <>
                {data.original.note && (
                  <div>
                    {t('mcs.cargo.note.label')}: {data.original.note || ''}
                  </div>
                )}
                {(data.original.forkliftDriverNote || data.original.truckAnomalies) && (
                  <>
                    <hr />
                    <div>
                      {t('mcs.cargo.forkliftDriverNote.label')}:{' '}
                      {(data.original.forkliftDriverNote.replace(/\r\n|\r|\n/g, ', ') || '') +
                        (data.original.truckAnomalies.replace(/\r\n|\r|\n/g, ', ') || '')}
                    </div>
                  </>
                )}
                {data.original.materialAnomalies && (
                  <>
                    <hr />
                    <div>
                      {t('mcs.cargo.materialAnomalies.label')}:{' '}
                      {data.original.materialAnomalies.replace(/\r\n|\r|\n/g, ', ') || ''}
                    </div>
                  </>
                )}
              </>
            )
            return (
              <Tooltip arrow={true} html={notes}>
                <FontAwesomeIcon icon={faInfoCircle} className="text-secondary" />
              </Tooltip>
            )
          },
        }
      case TableColumnType.rescheduled:
        return {
          Header: t('mcs.cargo.rescheduled.label'),
          accessor: 'rescheduled',
          maxWidth: 120,
          Cell: (data: any) => {
            if (Array.isArray(data.value) && data.value.length === 0) {
              return null
            }
            const notes = (
              <>
                {data.value.action === 'preponed' && (
                  <>
                    <div>
                      {t('mcs.cargo.rescheduled.preponed')} {moment(data.value.date).format('DD/MM')}
                    </div>
                  </>
                )}
                {data.value.action === 'postponed' && (
                  <>
                    <div>
                      {t('mcs.cargo.rescheduled.postponed')} {moment(data.value.date).format('DD/MM')}
                    </div>
                  </>
                )}
              </>
            )
            return <div>{notes}</div>
          },
        }
      case TableColumnType.handledWeightKg:
        return {
          Header: header,
          accessor: 'handledWeightKg',
          Cell: (data: any) => data.original?.handledWeightKg || 0,
          width: 85,
        }
      case TableColumnType.timeAbbrev:
        return {
          Header: (
            <>
              <FontAwesomeIcon icon={faHourglassHalf} title={t('mcs.cargo.timeAbbrev')} />
            </>
          ),
          maxWidth: 75,
          Cell: (data: any) => {
            return getCargoQueueWaitingTime(data.original)
          },
        }
      case TableColumnType.actions:
        return {
          Header: header,
          style: { overflow: 'visible' },
          minWidth: 130,
          Cell: (data: any) => getActionButton(data.original),
        }
      case TableColumnType.progressiveNumber:
        return {
          Header: header,
          accessor: 'progressiveNumber',
          maxWidth: 75,
          width: 45,
          Cell: (data: any) => {
            return data.original.progressiveNumber ? (
              <label className={`progressive-number-cell`}>{data.original.progressiveNumber}</label>
            ) : null
          },
        }
      case TableColumnType.images:
        return {
          Header: header,
          maxWidth: 55,
          Cell: (data: any) => {
            if (data.original.images && data.original.images.length > 0) {
              return (
                <div data-balloon-pos="left">
                  <FontAwesomeIcon icon={faImages} className="text-secondary" />
                </div>
              )
            }
            return null
          },
        }
    }
  }

  const baseColumns = periodLogic
    .getColumns(configManager.tables)
    .map(c => getColumn(c))
    .filter(c => c !== null) as Column[]

  if (props.showCheckbox) {
    return baseColumns.find(column => column.accessor && column.accessor === 'cargosCheckbox')
      ? baseColumns.slice(1)
      : [cargosCheckboxColumn, ...baseColumns]
  }

  return baseColumns
}

export default ColumnsRender
