// * ------------------- NPM -----------------------------
import { combineReducers } from 'redux'

// * ------------------- MODULE -----------------------------
import { plannerConfig, PlannerConfigState } from './plannerConfig'

const recipeControlReducersMap = {
  plannerConfig,
}
const recipeControlReducers = combineReducers(recipeControlReducersMap)

export default recipeControlReducers

export interface IRecipeControlReducers {
  plannerConfig: PlannerConfigState
}