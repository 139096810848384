import { API } from '.'
import { AnyAction, Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { StatusBE, Status, statusBEToStatus } from '@mv-submodules/inplant-mcs-fe-iblu/types/Status'

export const commonFetchStatus = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    dispatch(commonStatusIsFetching())
    try {
      const data = await API().request('/schedule-status/workflow-actions')
      dispatch(commonStatusFetchSuccess(data))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(commonStatusFetchError(error))
      throw error
    }
  }
}

export const commonFetchLastClosedDays = (): ((dispatch: Dispatch) => Promise<Status>) => {
  return async dispatch => {
    dispatch(commonLastClosedDaysIsFetching())
    try {
      const data: StatusBE = await API().request('/daily-closures/status')
      dispatch(commonLastClosedDaysFetchSuccess(data))
      return statusBEToStatus(data)
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(commonLastClosedDaysFetchError(error))
      throw error
    }
  }
}

export const commonFetchParkings = (): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    dispatch(commonParkingsIsFetching())
    try {
      const data = await API().request('/parkings')
      dispatch(commonParkingsFetchSuccess(data))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(commonParkingsFetchError(error))
      throw error
    }
  }
}


export const commonParkingsIsFetching = (): AnyAction => {
  return {
    type: 'MCS_COMMON_PARKINGS_IS_FETCHING',
  }
}

export const commonParkingsFetchSuccess = (statusData?: { [key: string]: string[] }): AnyAction => {
  return {
    type: 'MCS_COMMON_PARKINGS_FETCH_SUCCESS',
    payload: statusData,
  }
}

export const commonParkingsFetchError = (error: Error): AnyAction => {
  return {
    type: 'MCS_COMMON_PARKINGS_FETCH_ERROR',
    payload: error,
  }
}


export const commonLastClosedDaysIsFetching = (): AnyAction => {
  return {
    type: 'MCS_COMMON_LAST_CLOSED_DAYS_IS_FETCHING',
  }
}

export const commonLastClosedDaysFetchSuccess = (statusData?: { [key: string]: string | null }): AnyAction => {
  return {
    type: 'MCS_COMMON_LAST_CLOSED_DAYS_FETCH_SUCCESS',
    payload: statusData,
  }
}

export const commonLastClosedDaysFetchError = (error: Error): AnyAction => {
  return {
    type: 'MCS_COMMON_LAST_CLOSED_DAYS_FETCH_ERROR',
    payload: error,
  }
}

export const commonStatusIsFetching = (): AnyAction => {
  return {
    type: 'MCS_COMMON_STATUS_IS_FETCHING',
  }
}

export const commonStatusFetchSuccess = (statusData?: { [key: string]: string[] }): AnyAction => {
  return {
    type: 'MCS_COMMON_STATUS_FETCH_SUCCESS',
    payload: statusData,
  }
}

export const commonStatusFetchError = (error: Error): AnyAction => {
  return {
    type: 'MCS_COMMON_STATUS_FETCH_ERROR',
    payload: error,
  }
}

