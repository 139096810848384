const it = {
  mcs: {
    navigation: {
      mcs: 'MCS',
      today: 'Programma di oggi',
    },
    dateSwitcher: {
      today: 'Oggi',
    },
    today: {
      title: 'Programma Carico Scarico',
      subtitle: 'Programma di oggi: {{ date }}',
      show: 'Mostra',
      incoming: 'Ingressi',
      outgoing: 'Uscite',
      transfer: 'Trasferimenti',
      closeDay: 'Chiudi Giornata',
      addCargo: 'Aggiungi Carico',
      usersInQueue: 'In coda',
      usersUnderProcessing: 'In gestione',
      usersTotalInPlant: 'In impianto',
      handledWeightKg: {
        titleIncoming: 'Kg movimentati in ingresso',
        titleOutgoing: 'Kg movimentati in uscita',
        info: {
          measure: 'Kg: ',
          material: 'Materiale: ',
        },
      },
      dailyMeanWaitTime: 'T attesa medio giornata',
      currentMeanWaitTime: 'T attesa medio al momento',
      awaited: 'Attesi',
      blocked: 'Bloccati',
      enqueued: 'Accodati',
      underProcessing: 'In gestione',
      exiting: 'In uscita',
      rescheduled: 'Rimandati',
      concludedCargos: 'Gestiti oggi',
      loading: 'Aggiornamento dati...',
      filter: 'Ricerca carico:',
      replicaDay: 'Replica Giornata',
      replicaMulti: 'Replica Multi',
      multiActions: 'Azioni multiple',
      resetOrder: 'RESET ORD.',
    },
    weeklyReport: {
      incoming: {
        title: 'Riepilogo ingressi',
        subtitle: 'Programma INGRESSI CSS settimana',
      },
      outgoing: {
        title: 'Riepilogo uscite',
        subtitle: 'Programma USCITE CSS settimana',
      },
      transfer: {
        title: 'Riepilogo trasferimenti',
        subtitle: 'Programma TRASFERIMENTI CSS settimana',
      },
      subtitleFrom: ' da ',
      subtitleTo: ' a ',
      incomingDayColumns: {
        type: 'Tipo',
        producer: 'Produttore',
        notes: 'Note',
        preponed: 'Ant. dal',
        postponed: 'Post. dal',
        sampling: 'Camp.',
        basin: 'Bacino',
      },
      outgoingDayColumns: {
        type: 'Tipo',
        destination: 'Destino',
        notes: 'Note',
        preponed: 'Ant. dal',
        postponed: 'Post. dal',
      },
    },
    archive: {
      subtitle: 'Programma del {{ date }}',
      canceledCargos: 'Annullati',
      rescheduledCargos: 'Posticipati',
      concludedCargos: 'Gestiti in giornata',
      unprocessedCargos: 'Non gestiti',
      noCargosMessage: 'Non ci sono dati da visualizzare.',
    },
    program: {
      subtitle: 'Programma del {{ date }}',
    },
    closeDay: {
      subtitle: 'Chiusura giornata del {{ date }}',
      toRescheduleMandatory: 'Da riprogrammare necessariamente (CIT)',
      toRescheduleOptional: 'Eventualmente da riprogrammare',
      rescheduled: 'Riprogrammati',
      proceed: 'Procedi',
      returnToDay: 'Ritorna alla giornata',
      noCargoToClose: 'Non ci sono carichi da riprogrammare.',
    },
    cargo: {
      progressiveNumber: {
        label: 'N',
        placeholder: 'Num. Progressivo',
        undefined: '-- non definito --',
      },
      invalidDocuments: {
        label: 'Documenti mancanti o incompleti',
        no: 'No',
        yes: 'Sì',
        invalidFeedback: "Seleziona un'opzione",
      },
      ticketNumber: {
        label: 'Num. Buono',
        placeholder: '-- Inserisci num. buono --',
        invalidFeedback: 'Per favore, inserisci il numero del buono.',
      },
      rescheduled: {
        label: 'Note',
        preponed: 'Ant. dal',
        postponed: 'Post. dal',
      },
      units: {
        label: 'Unità',
        placeholder: "-- Inserisci l'unità --",
        invalidFeedback: "Per favore, inserisci l'unità",
      },
      materialType: {
        label: 'Tip. Materiale',
        undefined: '-- Seleziona un materiale --',
        invalidFeedback: 'Per favore, seleziona un materiale.',
      },
      producer: {
        label: 'Produttore',
        placeholder: '-- Inserisci nome produttore --',
        invalidFeedback: 'Per favore, inserisci il nome del produttore.',
      },
      recipient: {
        label: 'Destinatario',
        placeholder: '-- Inserisci nome destinatario --',
        invalidFeedback: 'Per favore, inserisci il nome del destinatario.',
      },
      carrier: {
        label: 'Trasportatore',
        placeholder: '-- Inserisci nome trasportatore --',
        invalidFeedback: 'Per favore, inserisci il nome del trasportatore.',
      },
      plate: {
        label: 'Targa',
        placeholder: '-- Inserisci la targa --',
        invalidFeedback: 'Inserisci una targa valida.',
      },
      samplingRequired: {
        label: 'Campionamento richiesto',
        no: 'No',
        yes: 'Sì',
        invalidFeedback: "Seleziona un'opzione",
      },
      samplingConfirmed: {
        label: 'Campionamento effettuato',
        no: 'No',
        yes: 'Sì',
      },
      basin: {
        label: 'Bacino',
        placeholder: '',
        invalidFeedback: 'Inserisci bacino.',
      },
      packages: {
        label: 'Colli',
        placeholder: '',
        invalidFeedback: 'Inserisci num. colli.',
      },
      handledWeightKg: {
        label: 'Kg movimentati',
        placeholder: '',
        invalidFeedback: 'Inserisci i Kg movimentati.',
      },
      note: {
        label: 'Note ufficio',
        placeholder: '',
        invalidFeedback: 'Inserisci qualche nota.',
      },
      images: {
        label: 'Imm.',
      },
      info: 'Informazioni Carico',
      arrivalDate: 'Data arrivo',
      queueTiming: 'Tempo in coda',
      parking: {
        label: 'Spiazzo',
        undefined: '-- Seleziona uno spiazzo --',
        invalidFeedback: 'Inserisci lo spiazzo.',
      },
      direction: {
        label: 'Camion in',
        incoming: 'Ingresso',
        outgoing: 'Uscita',
        transfer: 'Trasferimento',
      },
      status: {
        pending_arrival: 'In attesa',
        in_queue: 'In coda',
        requeued_with_priority: 'In coda',
        requeued_with_priority_load: 'In coda - carico',
        requeued_with_priority_unload: 'In coda - scarico',
        under_processing: 'In gestione',
        under_load_processing: 'In gestione - carico',
        under_unload_processing: 'In gestione - scarico',
        concluded: 'Concluso',
      },
      forkliftDriverDisplayName: {
        label: 'Magazziniere',
      },
      forkliftDriverId: {
        label: 'ID Magazziniere',
      },
      forkliftDriverNote: {
        label: 'Note magazziniere',
      },
      materialAnomalies: {
        label: 'Anomalie materiali',
      },
      timeAbbrev: 'tempo',
      actions: {
        label: '',
        modify: 'Modifica',
        welcome: 'Accogli',
        edit: 'Salva',
        block: 'Blocca',
        unblock: 'Sblocca',
        reschedule: 'Rimanda',
        delete: 'Elimina',
        replicaSelected: 'Replica',
        postponed: 'Posticipa',
        checkout: 'Checkout',
        checkout_transfer: 'Trasferisci',
        requeue: 'Riaccoda',
        print: 'Stampa',
        show: 'Dettagli',
        downloadImages: 'Scarica immagini',
        putBackToPendingArrival: 'Rimetti in arrivo',
        putBackToReadyToTransfer: 'Rimetti in trasferimento',
      },
    },
    modals: {
      close: 'Chiudi',
      add: 'Aggiungi',
      save: 'Salva',
      deleteCargo: {
        title: 'Elimina carico',
        delete: 'Elimina',
        cancel: 'Annulla',
        text: 'Sei sicuro di voler eliminare il carico?',
      },
      deleteCargos: {
        title: 'Elimina carichi selezionati',
        delete: 'Elimina',
        cancel: 'Annulla',
        text: 'Sei sicuro di voler eliminare i carichi selezionati?',
      },
      checkoutCargo: {
        title: 'Checkout',
        showImages: 'Mostra le immagini',
        modal: {
          title: 'Immagine',
          closeLabel: 'Chiudi',
        },
      },
      summaryCargo: {
        title: 'Riepilogo carico',
      },
      actions: {
        replica: {
          title: 'Replica',
          text: 'Seleziona la giornata in cui vuoi replicare i carichi.',
          replicate: 'Replica',
          cargosFound: 'Verranno replicati {{value}} carichi nella giornata {{date}}.',
          times: 'Quante volte vuoi replicare il carico?',
        },
        postponed: {
          title: 'Posticipa',
          text: 'Seleziona la giornata in cui vuoi posticipare i carichi.',
          replicate: 'Posticipa',
          cargosFound: 'Verranno posticipati {{value}} carichi nella giornata {{date}}.',
        },
      },
    },
    actions: {
      toggleDropdown: 'Mostra menu',
    },
    common: {
      closeDayMessage1: 'La giornata del {{ day }} non è stata chiusa.',
      closeDayMessage2: 'per procedere alla sua chiusura adesso.',
      clickHere: 'Clicca qui',
      noData: 'Nessun dato disponibile',
      exportCSV: 'Esporta CSV'
    },
  },
}

export default it
