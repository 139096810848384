// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import Alert from '../../../../../mvlabs-components-fe/ui/components/Alert/Alert'
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { DAY_MONTH_YEAR, mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { Select } from '../../../../../mvlabs-components-fe/ui/components/Input/Input'
import { StretchColumn } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import CountDownSlot from '../ManualShiftController/CountDownSlot'
import Process from '../../../../types/process'
import Shift from '../../../../types/shift'
import { editProcess } from '../../../../redux/action/plannerActions'

interface Props {
  configuration: Configuration
  visible: boolean
  onClose: () => void
  updateShifts: (shifts: Shift[]) => void
  process: Process
  shift: Shift
}

const ModalEditCurrentProcess = (props: Props) => {
  const { visible, onClose, process, shift, configuration } = props
  const { recipeGroups } = configuration
  const { t } = useComponentsTranslation()
  const dispatch = useDispatch()

  const tStart = 'recipeControl.planner.modalEditCurrentSlot'

  const [recipeGroupId, setRecipeGroupId] = useState<string>(process.recipeGroup.id)

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    editProcess(process.copyWith({ recipe: recipeGroups.filter(r => r.id === recipeGroupId)[0] }))(dispatch)
      .then(result => {
        setIsLoading(false)
        props.updateShifts(result)
        onClose()
      })
      .catch(e => {
        setIsLoading(false)
        console.warn(e) //tslint:disable-line
      })
  }

  return (
    <Modal
      visible={visible}
      title={`${t(`${tStart}.title`)} ${mvDate.format(shift.day, DAY_MONTH_YEAR)}, ${shift.name}`}
      onClose={onClose}
      rightFooterContents={[
        {
          kind: 'button',
          semantic: 'primary',
          onClick: handleSubmit,
          isLoading,
          label: `${t(`${tStart}.save`)}`,
        },
      ]}
    >
      <StretchColumn spaceSize={'md'}>
        <CountDownSlot processType={'current'} process={process} />
        <SectionTitle title={t(`${tStart}.question`)} />
        <Text
          text={`${t(`${tStart}.newHours`)} |\n| |**${mvDate.format(mvDate.now(), 'HH-mm')} - ${mvDate.format(
            process.to,
            'HH-mm'
          )}**|`}
        />
        <Select
          onChange={value => {
            setRecipeGroupId(value)
          }}
          alignSelf={AlignSelf.start}
          required={true}
          label={t(`${tStart}.activity`)}
          options={{
            defaultOption: { disable: false, value: process.recipeGroup.id },
            items: recipeGroups.map(r => {
              return { value: r.id, label: r.displayName }
            }),
          }}
        />
        <Text
          text={`${t(`${tStart}.processAvailable`, {
            value: `|**${shift.remainingWorks.toString()}**|`,
          })}|\n|${t(`${tStart}.timeAvailable`, { value: `|**${shift.remainingTimes}**|` })}`}
        />
        <Alert variant={'info'} text={t(`${tStart}.banner`)} alignSelf={AlignSelf.stretch} />
      </StretchColumn>
    </Modal>
  )
}

export default ModalEditCurrentProcess
