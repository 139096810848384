import { TableColumnType } from '../ui/components/widgets/CargoTable/ColumnsRender'

export type ConfigurableFields = 'basin' | 'ticket_number' | 'invalid_documents'

export interface ConfigurableField {
  slug: ConfigurableFields
  readOnly: boolean
}

export const defaultTables: Tables = {
  future: [],
  closeDay: [],
  pastCanceled: [],
  pastConcluded: [],
  pastRescheduled: [],
  pastUnprocessed: [],
  todayConcluded: [],
  todayManageable: [],
  weeklyReport: [],
}

export type PossibleTables =
  | 'todayManageable'
  | 'todayConcluded'
  | 'future'
  | 'pastRescheduled'
  | 'pastCanceled'
  | 'pastConcluded'
  | 'pastUnprocessed'
  | 'closeDay'
  | 'weeklyReport'

export type Tables = {
  [key in PossibleTables]: Array<{ type: TableColumnType; sort: number }>
}

export interface ConfigManagerBE {
  materialComplianceEnabled: boolean
  progressiveTruckNumberEnabled: boolean
  handleWeightKg: boolean
  enableTruckImages?: boolean
  isBasinMandatoryIfSamplingIsRequired: boolean
  inFields: ConfigurableField[]
  outFields: ConfigurableField[]
  tranFields: ConfigurableField[]
  transfer: TransferBE
  units: UnitsBE
  tables: Tables
  canEditTodayConcludedTrucks: boolean
}

export interface UnitsBE {
  enable: boolean
  values: UnitBE[]
}

export interface UnitBE {
  id: string
  name: string
}

export interface TransferBE {
  enable: boolean
  from: string
  to: string
}

export const configManagerBeToConfigManager = (cf: ConfigManagerBE): ConfigManager => {
  return {
    materialComplianceEnabled: cf.materialComplianceEnabled,
    progressiveTruckNumberEnabled: cf.progressiveTruckNumberEnabled,
    handleWeightKg: cf.handleWeightKg,
    enableTruckImages: cf.enableTruckImages,
    isBasinMandatoryIfSamplingIsRequired: cf.isBasinMandatoryIfSamplingIsRequired,
    inFields: cf.inFields || [],
    outFields: cf.outFields || [],
    tranFields: cf.tranFields || [],
    transfer: cf.transfer,
    units: cf.units || [],
    tables: cf.tables || {},
    canEditTodayConcludedTrucks: cf.canEditTodayConcludedTrucks || false,
  }
}

export interface Unit {
  id: string
  name: string
}

export interface Units {
  enable: boolean
  values: Unit[]
}

export interface Transfer {
  enable: boolean
  from: string
  to: string
}

export interface ConfigManager extends LightConfigManager {
  tables: Tables
}

export interface LightConfigManager {
  materialComplianceEnabled: boolean
  progressiveTruckNumberEnabled: boolean
  handleWeightKg: boolean
  enableTruckImages?: boolean
  isBasinMandatoryIfSamplingIsRequired: boolean
  inFields: ConfigurableField[]
  outFields: ConfigurableField[]
  tranFields: ConfigurableField[]
  transfer: Transfer
  units: Units
  canEditTodayConcludedTrucks: boolean
}
