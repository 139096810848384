// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import Flex, { JustifyContent, StretchColumn } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import Process from '../../../../types/process'
import Recipe from '../../../../types/recipe'
import Shift from '../../../../types/shift'
import TableProcessDetail from '../Table/TableProcessDetail'
import { Asset } from '../../../../types/asset'
import { Info } from './ModalSlotDetail'
import { PlannerViewTypes } from '../../../../types/viewType'
import { getSlot } from '../../../../redux/action/plannerActions'
import { isSlotEditable } from '../../../../functions/slot'
import TableMachines from '../Table/TableMachines'
import { Modules } from '../../../routes'
import { getModule } from '../../../../functions'

interface Props {
  process: Process
  assets: Asset[]
  recipes: Recipe[]
  onClickEditProcess: (process: Process) => void
  viewType: PlannerViewTypes
  configuration: Configuration
  shift: Shift
}

const ProcessDetail = (props: Props & WithTranslation) => {
  // * -----------------------------------------------------------------------
  // * ---------------------------- HOOKs --------------------------
  // * -----------------------------------------------------------------------
  const dispatch = useDispatch()

  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT --------------------------
  // * -----------------------------------------------------------------------
  const { recipes, assets, onClickEditProcess, shift } = props
  const { t, base } = props.translation
  const FIVE_SECONDS = 1000 * 5

  const [process, setProcess] = useState(props.process)
  // * -----------------------------------------------------------------------
  // * ---------------------------- STATE MANAGEMENT --------------------------
  // * -----------------------------------------------------------------------
  useEffect(() => {
    setProcess(process)
    let interval: ReturnType<typeof setInterval> | null

    // refreshing data if is current slot
    if (process.isCurrentSlot()) {
      interval = setInterval(() => {
        handleRefreshProcessData(process)
      }, FIVE_SECONDS)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [process])

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------
  const mappingAssetRecipe: Array<[Asset, Recipe]> = process.recipeGroup.mappings.reduce(
    (acc: Array<[Asset, Recipe]>, mapping) => {
      const asset = mapping.getAsset(assets)
      const recipe = mapping.getRecipe(recipes)
      if (asset && recipe) {
        return [...acc, [asset, recipe]]
      }
      return acc
    },
    []
  )

  function handleRefreshProcessData(proc: Process) {
    getSlot(proc.id)(dispatch)
      .then(result => setProcess(result))
      .catch(e => {
        console.warn(e) //tslint:disable-line
      })
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs --------------------------
  // * -----------------------------------------------------------------------
  return (
    <StretchColumn>
      <Flex justifyContent={JustifyContent.start}>
        <Info
          title={t(`${base}.recipeName`)}
          text={process.recipeGroup.displayName}
          date={`${mvDate.format(process.from, TIME)} - ${mvDate.format(process.to, TIME)}`}
          translation={{ t, base }}
        />
        {(props.viewType === 'attuative' && isSlotEditable(props.configuration, props.shift, process) && (
          <Button semantic={'primary'} label={t(`${base}.editProcess`)} onClick={() => onClickEditProcess(process)} />
        )) ||
          null}
      </Flex>

      {/*more info only if is current slot*/}
      {/* process.isCurrentSlot() && */ process.allWarnings.length > 0 ? (
        <div>
          <SectionTitle title={t(`${base}.listMachine`)} />
          <TableProcessDetail
            shift={shift}
            process={process}
            mappingAssetRecipe={mappingAssetRecipe}
            translation={{ t, base }}
          />
        </div>
      ) : getModule() === Modules.titech ? (
        <TableMachines list={mappingAssetRecipe.map(([asset, recipe]) => ({ asset, recipe }))} />
      ) : null}
    </StretchColumn>
  )
}

export default ProcessDetail
