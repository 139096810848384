import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ChecklistModel } from '../../../../types'
import { checklistEditFetchChecklist, checklistStartChecklist } from '../../../../redux/actions'
import { withRouter, RouteComponentProps } from 'react-router'
import { SortingRule, Filter } from 'react-table'
import SmallScreenView from './SmallScreenView'
import { checklistIndexPersistTableState, fetchChecklistIndexNoRedux } from '../../../../redux/actions/checklistIndex'
import { PageHeader, Table, TableRowButtonGroup } from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import UnitSelector from '../../widgets/UnitSelector/UnitSelector'
import UnitSelectorManager, { WithUnitSelector } from '../../widgets/UnitSelector/UnitSelectorManager'
import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import TitleWithCompilationData from '../../widgets/TitleWithCompilationData/TitleWithCompilationData'

export interface StateProps {
  error: Error | null
  page: number
  pageSize: number
  sorted: SortingRule[]
  filtered: Filter[]
}

export interface DispatchProps {
  fetchIndex: (unitSelected?: Value) => Promise<ChecklistModel[]>
  startChecklist: (checklistModelId: string) => Promise<any>
  fetchChecklist: (checklistInstanceId: string) => Promise<any>
  persistTableState: (params: { page?: number; pageSize?: number; sorted?: SortingRule[]; filtered?: Filter[] }) => void
}

export interface OwnProps extends RouteComponentProps<any> {}

export interface OwnState {
  viewportWidth: number
  checklistModels: ChecklistModel[]
  isFetching: boolean
  fetchErrors: boolean
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation & WithUnitSelector

const mapStateToProps = (state: any): StateProps => ({
  error: state.checklist.index.error,
  page: state.checklist.index.page,
  pageSize: state.checklist.index.pageSize,
  sorted: state.checklist.index.sorted,
  filtered: state.checklist.index.filtered,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchIndex: unitSelected => dispatch(fetchChecklistIndexNoRedux(unitSelected)),
  startChecklist: (checklistModelId: string) => dispatch(checklistStartChecklist(checklistModelId)),
  fetchChecklist: (checklistInstanceId: string) => dispatch(checklistEditFetchChecklist(checklistInstanceId)),
  persistTableState: ({ page, pageSize, sorted, filtered }) =>
    dispatch(checklistIndexPersistTableState({ page, pageSize, sorted, filtered })),
})

export class ChecklistIndex extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      viewportWidth: window.innerWidth,
      checklistModels: [],
      isFetching: false,
      fetchErrors: false,
    }
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleOnTablePageChange = this.handleOnTablePageChange.bind(this)
    this.handleOnTablePageSizeChange = this.handleOnTablePageSizeChange.bind(this)
    this.handleOnTableSortedChange = this.handleOnTableSortedChange.bind(this)
    this.handleOnTableFilteredChange = this.handleOnTableFilteredChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  public componentDidUpdate(prevProps: Props, prevState: OwnState) {
    if (prevProps.unitSelected?.id !== this.props.unitSelected?.id) {
      this.fetchData()
    }
  }

  public componentDidMount() {
    this.fetchData()
    window.addEventListener('resize', this.handleResize)
  }

  public fetchData() {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true, fetchErrors: false })
      this.props
        .fetchIndex(this.props.unitSelected)
        .then(checklistModels => this.setState({ checklistModels }))
        .catch(error => {
          this.setState({ fetchErrors: true })
        })
        .finally(() =>
          this.setState({
            isFetching: false,
          })
        )
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  public async handleChecklistClick(checklist: ChecklistModel) {
    if (checklist.hasAssets) {
      return this.props.history.push(`/checklist/${checklist.id}/asset`)
    }
    if (checklist.checklistInstanceId) {
      return this.props.history.push(`/checklist/edit/${checklist.checklistInstanceId}`)
    }
    try {
      const checklistInstanceId = await this.props.startChecklist(checklist.id)
      this.props.history.push(`/checklist/edit/${checklistInstanceId}`)
    } catch (error) {
      this.props.fetchIndex()
    }
  }

  private handleResize() {
    this.setState(prevState => ({
      ...prevState,
      viewportWidth: window.innerWidth,
    }))
  }

  private handleOnTablePageChange(page: number) {
    this.props.persistTableState({ page })
  }

  private handleOnTablePageSizeChange(pageSize: number) {
    this.props.persistTableState({ pageSize })
  }

  private handleOnTableSortedChange(sorted: SortingRule[]) {
    this.props.persistTableState({ sorted })
  }

  private handleOnTableFilteredChange(filtered: Filter[]) {
    this.props.persistTableState({ filtered })
  }

  public render() {
    const { t } = this.props
    const { isFetching, checklistModels } = this.state
    return (
      <React.Fragment>
        <PageHeader title={t('checklist.index.title')} />
        <MainPageContent>
          <UnitSelector />
          {this.state.viewportWidth > 768 ? (
            <Table
              isFetching={isFetching}
              onPageChange={this.handleOnTablePageChange}
              // pageSize={this.props.pageSize}
              page={0}
              pageSize={checklistModels.length}
              rows={checklistModels.length}
              onPageSizeChange={this.handleOnTablePageSizeChange}
              //  sorted={this.props.sorted}
              //  onSortedChange={this.handleOnTableSortedChange}
              //  filtered={this.props.filtered}
              //  onFilteredChange={this.handleOnTableFilteredChange}
              className="checklist-index-table -highlight"
              showPagination={false}
              manualIsFetching={true}
              manual={true}
              // pages={this.props.pages}
              //  defaultPageSize={20}
              data={checklistModels}
              //  minRows={this.props.checklistModels.length > 0 ? 1 : 4}
              noDataText={this.props.t('checklist.index.table.noData')}
              pageText={this.props.t('checklist.modelsAssets.table.page')}
              ofText={this.props.t('checklist.modelsAssets.table.of')}
              rowsText={this.props.t('checklist.modelsAssets.table.rows')}
              getTrProps={(state: any, row: any) => {
                if (!row) {
                  return {}
                }
                const classNames = []
                const openedBySelf = row.original.checklistInstanceId
                const openedByOtherUser = row.original.differentUserOpenChecklist
                if (openedBySelf) {
                  classNames.push('opened-by-self')
                }
                if (openedByOtherUser) {
                  classNames.push('opened-by-other-user')
                }
                return { className: classNames.join(' ') }
              }}
              columns={[
                {
                  Header: this.props.t('checklist.index.table.title'),
                  accessor: 'title',
                  Cell: data => {
                    const checklistModel: ChecklistModel = data.original
                    return (
                      <TitleWithCompilationData
                        title={
                          <>
                            {checklistModel.title}
                            {checklistModel.hasAssets ? ' ' : null}
                            {checklistModel.hasAssets ? <small className="text-muted">[asset]</small> : null}
                          </>
                        }
                        lastCompilationData={checklistModel.lastCompilationData}
                      />
                    )
                  },
                },
                {
                  Header: this.props.t('checklist.index.table.actions'),
                  sortable: false,
                  style: { overflow: 'visible' },
                  minWidth: 150,
                  Cell: data => {
                    return (
                      <div className="text-right">
                        {data.original.differentUserOpenChecklist ? (
                          <span
                            className="text-info"
                            aria-label={`
                                  ${mvDate.getDateFromStringWithFormatting(
                                    data.original.differentUserOpenChecklist.createdDate,
                                    'dd/MM/yyyy HH:mm'
                                  )}
                                  ${data.original.differentUserOpenChecklist.user.displayName}
                                `}
                            data-balloon-pos="left"
                          >
                            <IconComponent icon={'info-circle'} className="mr-3" size="lg" />
                          </span>
                        ) : null}
                        {data.original?.lastCompilationData?.isBlocking ? (
                          <span
                            className="text-warning"
                            aria-label={this.props.t('checklist.common.assetNotAvailable')}
                            data-balloon-pos="left"
                          >
                            <IconComponent icon={'exclamation-triangle'} className="mr-3" size="lg" />
                          </span>
                        ) : null}
                        <TableRowButtonGroup
                          buttons={[
                            {
                              onClick: () => this.handleChecklistClick(data.original),
                              variant: 'primary',
                              disabled: !data.original.enabled || data.original?.lastCompilationData?.isBlocking,
                              label: this.props.t('checklist.index.table.compile'),
                            },
                          ]}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          ) : (
            <SmallScreenView list={checklistModels} clickHandler={this.handleChecklistClick} />
          )}
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UnitSelectorManager(ChecklistIndex)))
)
