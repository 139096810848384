import * as moment from 'moment'
import { Cargo, cargoBlockingStatus } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Status } from '../types/Status'

export const getLastClosedDay = (
  lastClosedIncomingDay: moment.Moment | null,
  lastClosedOutgoingDay: moment.Moment | null
): moment.Moment | null => {
  if (lastClosedIncomingDay) {
    if (!lastClosedOutgoingDay) {
      return lastClosedIncomingDay
    }
    if (lastClosedOutgoingDay.isBefore(lastClosedIncomingDay)) {
      return lastClosedOutgoingDay
    }
    if (lastClosedOutgoingDay.isAfter(lastClosedIncomingDay)) {
      return lastClosedIncomingDay
    }
    return lastClosedIncomingDay
  }
  if (lastClosedOutgoingDay) {
    /* if (!lastClosedIncomingDay) {
      return lastClosedOutgoingDay
    } */
    /*  if (lastClosedIncomingDay!.isBefore(lastClosedOutgoingDay)) {
      return lastClosedIncomingDay
    } */
    /* if (lastClosedIncomingDay!.isAfter(lastClosedOutgoingDay)) {
      return lastClosedOutgoingDay
    } */
    return lastClosedOutgoingDay
  }
  return null
}

export const getOldestDate = (status: Status): moment.Moment | undefined => {
  let oldestDate: moment.Moment | undefined
  Object.values(status).forEach(date => {
    if (oldestDate === undefined && date !== undefined) {
      oldestDate = date
    } else if (oldestDate !== undefined && date !== undefined && date.isBefore(oldestDate)) {
      oldestDate = date
    }
  })
  return oldestDate
}

export const filterCargoDataByText = (data: Cargo[], searchString: string) => {
  const search = searchString.toLowerCase()
  if (!search) {
    return data
  }
  return data.filter(
    cargo =>
      (cargo.ticketNumber && cargo.ticketNumber.toLowerCase().includes(search)) ||
      (cargo.materialType && cargo.materialType.description.toLowerCase().includes(search)) ||
      (cargo.producer && cargo.producer.toLowerCase().includes(search)) ||
      (cargo.recipient && cargo.recipient.toLowerCase().includes(search)) ||
      (cargo.carrier && cargo.carrier.toLowerCase().includes(search)) ||
      (cargo.plate && cargo.plate.toLowerCase().includes(search)) ||
      (cargo.forkliftDriverDisplayName && cargo.forkliftDriverDisplayName.toLowerCase().includes(search)) ||
      (cargo.packages && cargo.packages.toString().includes(search)) ||
      (cargo.note && cargo.note.toLowerCase().includes(search))
  )
}

export const getCargoQueueWaitingTime = (cargo: Cargo) => {
  const enter = cargo.queueEnteringAt
  const exit = cargo.queueExitingAt || moment()
  if (!enter) {
    return '--'
  }
  if (cargo.status === 'concluded' && !cargo.queueExitingAt) {
    return '--'
  }
  const duration = Math.floor(moment.duration(moment(exit).diff(moment(enter))).asMinutes())
  if (duration > 60) {
    const minutes = duration % 60
    const hours = (duration - minutes) / 60
    return (hours ? `${hours}h ` : '') + `${minutes}m`
  } else {
    return `${duration.toString()}m`
  }
}

export const handleDownloadImages = (images: Array<{ imageId: string; imageURL: string }>) => {
  images.forEach((img, index) => {
    const a = document.createElement('a')
    a.href = img.imageURL
    a.download = `img-${index}`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  })
}

export const objectFrom = <T>(object: { [key: string]: any }, properties: string[]): T => {
  let newObj = {}
  properties.forEach(p => {
    if (object.p) {
      newObj = { ...newObj, [p]: object.p }
    }
  })
  return newObj as T
}

export const createCargoToSend = (
  cargo: Cargo,
  type: 'add' | 'edit' | 'welcome' | 'block' | 'saveToday' | 'editConcludedToday'
): { [key: string]: any } => {
  let obj: { [key: string]: boolean | string | number | null | undefined } = {
    material: cargo.materialType ? cargo.materialType.name : null,
    carrier: cargo.carrier,
    basin: cargo.basin,
    note: cargo.note,
  }
  if (cargo.ticketNumber) {
    obj = { ...obj, ticketNumber: cargo.ticketNumber }
  }
  if (cargo.unit) {
    obj = { ...obj, unit: cargo.unit }
  }
  if (cargo.direction === 'incoming') {
    obj = { ...obj, producer: cargo.producer }
  }
  if (cargo.direction === 'outgoing') {
    obj = { ...obj, recipient: cargo.recipient }
  }
  if (type === 'add' || type === 'edit') {
    obj = {
      ...obj,
      arrivalDate: cargo.arrivalDate.format('YYYY-MM-DD'),
    }
  }
  if (type === 'editConcludedToday') {
    obj = {
      ...obj,
      handledWeightKg: cargo.handledWeightKg,
    }
  }

  if (type === 'welcome' || type === 'block' || type === 'saveToday' || type === 'editConcludedToday') {
    obj = {
      ...obj,
      samplingRequired: cargo.samplingRequired,
      parking: cargo.parking,
      packages: cargo.packages,
      plate: cargo.plate,
    }
  }
  if (
    (type === 'welcome' || type === 'saveToday' || type === 'editConcludedToday') &&
    cargo.invalidDocuments !== undefined
  ) {
    obj = {
      ...obj,
      invalidDocuments: cargo.invalidDocuments,
    }
  }
  return obj
}

export const canViewCloseDayPageView = (cargos: Cargo[]) => {
  const blockingCargos = cargos.filter(cargo => cargoBlockingStatus.includes(cargo.status))
  const pendingArrivalCargos = cargos.filter(cargo => cargo.status === 'pending_arrival')
  if (blockingCargos.length > 0) {
    return false
  }
  if (pendingArrivalCargos.length === 0) {
    return false
  }
  return true
}
