import { Cargo, CargoDirections, possibleDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { cargoFetchData, CargoType } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { ConfigManagerStore } from '@mv-submodules/inplant-mcs-fe-iblu/types/Store'

// INFO: when i created this hook I could not add the dependency to the components version that has the genericFetchHook
const useHookCargoFetch = (cargoDirections: CargoDirections[], type: CargoType, date: moment.Moment) => {
  const dispatch = useDispatch()
  const configManger: ConfigManagerStore = useSelector((reduxState: any) => reduxState.mcs.configManager)

  const [state, setState] = useState<StateType>({
    kind: 'fetching',
  })

  //  if retryCount is odd then promise doesn't fire IsFetching state
  const [retryCount, setRetryCount] = useState<number>(2)

  // deferred a promise that will abort the param promise if the hook is unmount
  const [deferred, setDeferred] = useState<any>()

  useEffect(() => {
    if (retryCount % 2 === 1) {
      setState(prev => {
        return {
          kind: 'fetching',
          prevData: prev.kind === 'success' ? prev.data : prev.kind === 'fetching' ? prev.prevData : undefined,
        }
      })
    } else {
      setState(_prev => ({ kind: 'fetching', prevData: undefined }))
    }

    const p = new Promise((resolve, reject) => {
      setDeferred({ resolve, reject })
    })

    Promise.race([cargoFetchData(possibleDirections(configManger.data), date, type)(dispatch), p])
      .then(value => {
        // deferred promise doesn't return an object
        if (typeof value === 'object') {
          const result: Cargo[] = (value as any).reduce((a: Cargo[], b: Cargo[]) => a.concat(b), [])
          setState(_prev => ({ kind: 'success', data: result }))
        }
      })
      .catch(error => {
        setState(_prev => ({ kind: 'error', error }))
      })
  }, [dispatch, retryCount])

  // unmount logic
  useEffect(() => {
    return () => {
      if (deferred) {
        deferred.resolve('abort')
      }
    }
  }, [deferred])

  return {
    state,
    retryCall: (silentRetry: boolean = false) =>
      setRetryCount(prev => {
        let value = silentRetry ? prev * 2 + 1 : prev * 2
        if (value > 20) {
          value -= 20
        }
        return value
      }),
  }
}

export default useHookCargoFetch

export interface IsFetching {
  kind: 'fetching'
  prevData?: Cargo[]
}

export interface IsSuccess {
  kind: 'success'
  data: Cargo[]
}

export interface IsError {
  kind: 'error'
  error: Error
}

export type StateType = IsFetching | IsSuccess | IsError

export function useDidUpdate(callback: () => void, deps?: any) {
  const hasMount = useRef(false)

  useEffect(() => {
    if (hasMount.current) {
      callback()
    } else {
      hasMount.current = true
    }
  }, deps)
}
