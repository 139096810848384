// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'

// * -------------------------------- MODULE --------------------------------------
import Flex, { AlignItems, Direction, FlexSpace } from '../Flex/Flex'
import IconComponent from '../Icon/Icon'
import Text from '../Text/Text'
import { IFlexItem, renderFlexItem } from '../Flex/FlexItem'
import { getUniqueId } from '../../../functions/helpers/stringHelper'
import { Changeable } from '../../../types/onFunction'
import { IsDisable } from '../../../types/base'

interface RadioProps extends IFlexItem, Changeable<[id:string]> {
  initialValueSelected?: string
  label?: string
  required?: boolean
  items: RadioItemProps[]
  itemsDirection?: Direction
  itemSpace?: FlexSpace
  overrideValue?: boolean
}

const Radio = (props: RadioProps) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const randName = Math.random().toString()
  const handleRadioSelection = () => {
    return props.items.filter(i => i.value === props.initialValueSelected)[0] !== undefined
      ? props.items.filter(i => i.value === props.initialValueSelected)[0]
      : props.items[0]
  }
  const [radioSelected, setRadioSelected] = useState<RadioItemProps>(handleRadioSelection())

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- STATE MANAGEMENT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (props.initialValueSelected !== null && props.initialValueSelected !== undefined && props.overrideValue) {
      setRadioSelected(handleRadioSelection())
    }
  }, [props.initialValueSelected])
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  return (
    <div className={`mv-radio-container ${renderFlexItem({ ...(props as IFlexItem) })}`}>
      {(props.label ||
        (props.required) && (
          <label htmlFor={props.label}>
            {props.label}
            {props.required && <abbr className="required"> *</abbr>}
          </label>
        ))}
      <Flex
        alignItems={AlignItems.stretch}
        direction={props.itemsDirection || Direction.row}
        className={`mv-radio-group`}
        spaceSize={props.itemSpace || 'lg'}
      >
        {props.items.map((i, index) => (
          <RadioItem
            key={index}
            {...i}
            name={randName}
            selected={i.value === radioSelected.value}
            onChange={() => {
              if (i.value !== radioSelected.value) {
                setRadioSelected(i)
                props.onChange?.(i.value)
              }
            }}
          />
        ))}
      </Flex>
    </div>
  )
}

interface RadioItemProps extends IsDisable {
  value: string
  label?: string
  bold?: boolean
  children?: React.ReactNode
  /**
   * @deprecated instead of disabled use isDisable!
   */
  disabled?: boolean
}

interface HiddenRadioItemProps extends Changeable<[e: React.ChangeEvent<HTMLInputElement>]> {
  name: string
  selected?: boolean
}

export const RadioItem: React.FunctionComponent<RadioItemProps & HiddenRadioItemProps> = props => {
  const [uniqueId] = useState(getUniqueId(props.value))
  const { bold, label } = props
  const disable = props.disabled || props.isDisable
  const name = 'mv-radio'
  return (
    <div className={`${name}`}>
      <input
        disabled={disable}
        type="radio"
        name={props.name}
        id={uniqueId}
        checked={props.selected || false}
        onChange={props.onChange}
      />
      <label htmlFor={uniqueId}>
        <IconComponent className={'icon icon--unselected'} icon={["regular","circle"]} />
        <IconComponent className={'icon icon--selected'} icon={"dot-circle"} />
        {label && <Text semantic={disable ? 'light' : ''} text={bold ? `|**${label}**|` : label} />}
      </label>
      <div className={`${name}__children`}>{props.children}</div>
    </div>
  )
}

export default Radio
