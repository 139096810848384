import Recipe, { IRecipe } from './recipe'

export interface IAsset {
  id: string
  displayName: string
  recipes: IRecipe[]
}

export class Asset {
  private readonly _id: string
  private readonly _displayName: string
  private readonly _recipes: Recipe[]

  get id(): string {
    return this._id
  }

  get recipes(): Recipe[] {
    return this._recipes.map(r => r.copyWith())
  }

  get displayName(): string {
    return this._displayName
  }

  get interface(): IAsset {
    return {
      id: this._id,
      displayName: this._displayName,
      recipes: this._recipes,
    }
  }

  constructor(iAsset: Partial<IAsset>) {
    if (!iAsset.id) {
      throw new Error()
    }
    this._id = iAsset.id
    this._displayName = iAsset.displayName || ''
    this._recipes = iAsset.recipes?.map(r => new Recipe(r)) || []
  }

  public copyWith = (props?: Partial<Asset>): Asset => {
    return new Asset({
      id: props && props.id !== undefined ? props.id : this._id,
      displayName: props && props.displayName !== undefined ? props.displayName : this._displayName,
      recipes: (props && props.recipes?.map(r => r.interface)) || this.recipes.map(r => r.interface),
    })
  }
}
