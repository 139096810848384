// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex, {
  AlignContent,
  AlignItems,
  Direction,
  JustifyContent,
} from '../../../../..//mvlabs-components-fe/ui/components/Flex/Flex'
import IconComponent from '../../../../../mvlabs-components-fe/ui/components/Icon/Icon'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { generateSpace, SpaceType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Recipe from '../../../../types/recipe'
import { WarningsType } from '../../../../types/warning'
import {
  getIconFromWarning,
  getSemanticFromWarning,
  warningContent,
  warningLength,
} from '../../../../functions/warning'
import ScheduleOverride from '../../../../types/scheduleOverride'
import Shift from '../../../../types/shift'
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import Slot from '../../../../types/slot'
import { PlannerViewTypes } from '../../../../types/viewType'
import Process from '../../../../types/process'
import Halt from '../../../../types/halt'
import { mvDuration } from '../../../../../mvlabs-components-fe/functions/helpers/durationHelper'

interface Props {
  showFullData: boolean
  percentageDimension: number
  warnings?: WarningsType[]
  recipes: Recipe[]
  processOverride?: ScheduleOverride[]
  shift: Shift
  slot: Slot
  viewType: PlannerViewTypes
}

/**
 * Controller attached to PlannerSlot component to manage the warning props
 */
const useErrorController = (props: Props) => {
  const { percentageDimension, warnings, shift, slot, viewType } = props
  /* const LIMIT_WARNING_TO_SHOW = 5 */
  const { t } = useComponentsTranslation()

  let direction = Direction.column
  let layout: 'row' | 'column' = 'row'
  let children: React.ReactNode | null

  if (percentageDimension <= 15) {
    direction = Direction.row
  }

  const showLongMessage = () => {
    return false /* props.percentageDimension >= 70 || (props.showFullData && props.percentageDimension >= 35) */
  }

  const errorTooltip = (): string => {
    if (showLongMessage()) {
      return ''
    }

    if (warnings && warnings.length === 0) {
      return ''
    }
    
    const totalMismatchDuration = mvDuration.getDuration({
      milliseconds: slot.allMismatchesWarning.reduce(
        (acc, curr) =>
          acc +
          (mvDate.getTime(mvDate.getDateFromString(curr.endedAt)) -
            mvDate.getTime(mvDate.getDateFromString(curr.startedAt))),
        0
      ),
    })

    const totalConnectionDuration = mvDuration.getDuration({
      milliseconds: slot.allConnectionWarning.reduce((acc, curr) => {
        return (
          acc +
          (mvDate.getTime(mvDate.getDateFromString(curr.endedAt)) -
            mvDate.getTime(mvDate.getDateFromString(curr.startedAt)))
        )
      }, 0),
    })

    const totalScheduleOverrideDuration = mvDuration.getDuration({
      milliseconds: shift.scheduledOverrides.reduce((acc, curr) => {
        return acc + (mvDate.getTime(curr.to) - mvDate.getTime(curr.from))
      }, 0),
    })

    const mismatchString =
      slot.allMismatchesWarning.length > 0
        ? t(`recipeControl.planner.slot.warningsRecap.mismatchSummary`, {
            count: slot.allMismatchesWarning.length,
            totalHours: mvDuration.normalize(totalMismatchDuration)?.hours,
            totalMinutes: mvDuration.normalize(totalMismatchDuration)?.minutes,
          })
        : ''

    const connectionString =
      slot.allConnectionWarning.length > 0
        ? t(`recipeControl.planner.slot.warningsRecap.connectionSummary`, {
            count: slot.allConnectionWarning.length,
            totalHours: mvDuration.normalize(totalConnectionDuration).hours,
            totalMinutes: mvDuration.normalize(totalConnectionDuration).minutes,
          })
        : ''

    const scheduledOverrideString =
      shift.scheduledOverrides.length > 0
        ? t(`recipeControl.planner.slot.warningsRecap.scheduledOverrideSummary`, {
            count: shift.scheduledOverrides.length,
            totalHours: mvDuration.normalize(totalScheduleOverrideDuration).hours,
            totalMinutes: mvDuration.normalize(totalScheduleOverrideDuration).minutes,
          })
        : ''

    const resultString = [mismatchString, connectionString, scheduledOverrideString]
      .filter(s => s.length > 0)
      .join('\n')

    return `${resultString}`
  }

  const scheduledOverride = () => {
    // const mismatches = warnings && warnings.filter(m => m.type === 'recipe-mismatch')
    if (shift.scheduledOverrides.length === 0 /* || (mismatches && mismatches.length > 0) */) {
      return null
    }
    const orderedScheduledOverrides = shift.scheduledOverrides.filter(o => {
      return mvDate.isSameOrAfterSecond(o.from, slot.from) && mvDate.isSameOrBeforeSecond(o.from, slot.to)
    })
    if (orderedScheduledOverrides.length === 0) {
      return null
    }
    return (
      <Flex
        justifyContent={JustifyContent.center}
        className={`recipecontrol__warning-icon recipecontrol__warning-icon--override`}
      >
        <IconComponent size={'xs'} icon={'pencil-alt'} />
        <Text text={orderedScheduledOverrides.length.toString()} />
      </Flex>
    )
  }

  const showConnectionWarning = () => {
    if (!warnings || (warnings && warningLength(warnings, 'connection') === 0)) {
      return null
    }

    // if (viewType === 'compare' && (slot instanceof Process || slot instanceof Halt)) {
    //   return null
    // }

    return (
      <Flex
        justifyContent={JustifyContent.center}
        className={`recipecontrol__warning-icon ${getSemanticFromWarning(
          warnings.filter(w => w.type === 'connection')[0],
          'recipecontrol__warning-icon'
        )}`}
      >
        <IconComponent size={'xs'} icon={getIconFromWarning(warnings.filter(w => w.type === 'connection')[0])} />
        <Text text={warningLength(warnings, 'connection').toString()} />
      </Flex>
    )
  }

  const showMismatchWarning = () => {
    if (!warnings || (warnings && warningLength(warnings, 'recipe-mismatch') === 0)) {
      return null
    }
    if (viewType === 'compare' && (slot instanceof Process || slot instanceof Halt)) {
      return null
    }

    return (
      <Flex
        justifyContent={JustifyContent.center}
        className={`recipecontrol__warning-icon ${getSemanticFromWarning(
          warnings.filter(w => w.type === 'recipe-mismatch')[0],
          'recipecontrol__warning-icon'
        )}`}
      >
        <IconComponent size={'xs'} icon={getIconFromWarning(warnings.filter(w => w.type === 'recipe-mismatch')[0])} />
        <Text text={warningLength(warnings, 'recipe-mismatch').toString()} />
      </Flex>
    )
  }

  children =
    (warnings && (
      <Flex direction={direction} alignContent={AlignContent.center}>
        {showConnectionWarning()}
        {showMismatchWarning()}
        {scheduledOverride()}
      </Flex>
    )) ||
    scheduledOverride()

  if (showLongMessage()) {
    children =
      (warnings && warnings.map((w, index) => <LongErrorMessage key={index} warning={w} recipes={props.recipes} />)) ||
      null
    layout = 'column'
  }

  return { layout, errorChildren: children, errorTooltip: errorTooltip() }
}

const LongErrorMessage = (props: { warning: WarningsType; recipes: Recipe[] }) => {
  const { warning } = props
  const { t } = useComponentsTranslation()
  return (
    <Flex alignItems={AlignItems.start}>
      <div className={generateSpace(SpaceType.padding, { top: 1 })}>
        <IconComponent
          icon={getIconFromWarning(warning)}
          className={getSemanticFromWarning(warning, 'recipecontrol__icon-long-warning-message')}
        />
      </div>
      <Text
        text={`${t(`recipeControl.planner.slot.warnings.${warning.type}`, {
          value: warning.assetDisplayName,
        })}|\n| ${warningContent(warning)}`}
        alignment={'start'}
      />
    </Flex>
  )
}

export interface UseErrorController {
  layout: 'row' | 'column'
  children: React.ReactNode | null
  errorTooltip?: string
}

export default useErrorController
