import { AnyAction } from 'redux'
import * as moment from 'moment'
import { CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'

export const changeDate = (date: moment.Moment): AnyAction => {
  return {
    type: 'MCS_FILTERS_CHANGE_DATE',
    payload: date,
  }
}

export const changeDirection = (direction: CargoDirections): AnyAction => {
  return {
    type: 'MCS_FILTERS_CHANGE_DIRECTION',
    payload: direction,
  }
}
