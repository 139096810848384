// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENT --------------------------------
import { CollapseProps } from './Collapse'
import { FlexSpace, StretchColumn } from '../Flex/Flex'
import { useArray } from '../../../functions/helpers/arrayHelper'

interface Props {
  children: Array<React.ReactElement<CollapseProps>>
  initialOpened?: number
  maxChildrenOpen?: 1
  spaceSize?: FlexSpace
}

const Accordion = (props: Props) => {
  const { children } = props

  const { array, set, update } = useArray<boolean>(children.map((_c, index) => index === props.initialOpened))

  function handleClickCollapse(value: 'open' | 'closed', index: number) {
    if (props.maxChildrenOpen) {
      set(children.map(_c => false))
    }
    update(index, value === 'open')
  }

  return (
    <StretchColumn spaceSize={props.spaceSize}>
      {children.map((c, index) =>
        React.cloneElement(c, {
          onClick: (state: 'open' | 'closed') => handleClickCollapse(state, index),
          state: array[index] ? 'open' : 'closed',
        })
      )}
    </StretchColumn>
  )
}

export default Accordion
