export type FormDataSlugs = 'materialBlend' | 'notes'
export type DataTypes = 'string'
export type FormTypes = 'input' | 'text-area'

export interface IFormData {
  slug: FormDataSlugs
  dataType: DataTypes
  formType: FormTypes
  isRequired: boolean
}

export class FormData {
  get slug(): FormDataSlugs {
    return this._slug;
  }

  get dataType(): DataTypes {
    return this._dataType;
  }

  get formType(): FormTypes {
    return this._formType;
  }

  get isRequired(): boolean {
    return this._isRequired
  }


  get interface(): IFormData {
    return {
      dataType: this._dataType,
      formType: this._formType,
      slug: this._slug,
      isRequired: this._isRequired
    }
  }

  private readonly _slug: FormDataSlugs
  private readonly _dataType: DataTypes
  private readonly _formType: FormTypes
  private readonly _isRequired: boolean

  constructor(iFormData: Partial<IFormData>) {
    if (!iFormData.slug) {
      throw new Error()
    }
    this._slug = iFormData.slug
    this._dataType = iFormData.dataType || 'string'
    this._formType = iFormData.formType || 'input'
    this._isRequired = iFormData.isRequired || false
  }
}