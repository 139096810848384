import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as moment from 'moment'
import Future from '../Future/FuturePageView'
import Past from '../Past/PastPageView'
import Today from '../Today/TodayPageView'
import { Cargo, possibleDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import useHookCargoFetch, { useDidUpdate } from '@mv-submodules/inplant-mcs-fe-iblu/functions/hookCargoFetcht'
import { CargoType } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { useHistory } from 'react-router-dom'
import { ConfigManagerStore } from '@mv-submodules/inplant-mcs-fe-iblu/types/Store'
import { commonFetchLastClosedDays } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/common'
import { getOldestDate } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'

const MCSRouterPageView = () => {
  // ----------------------------------------------------------------------
  // -------------------------- HOOKs -------------------------------------
  // ----------------------------------------------------------------------
  const dispatch = useDispatch()
  const history = useHistory()
  const configManger: ConfigManagerStore = useSelector((state: any) => state.mcs.configManager)

  const date: moment.Moment = useSelector((state: any) => state.mcs.filters.date)
  const day = date.get('date')
  const today = moment()

  let type: CargoType = CargoType.default
  if (date.isBefore(today, 'day')) {
    type = CargoType.archive
  }

  const cargosHook = useHookCargoFetch(possibleDirections(configManger.data), type, date)

  // ----------------------------------------------------------------------
  // ------------------- STATE MANAGEMENT ---------------------------------
  // ----------------------------------------------------------------------
  React.useEffect(() => {
    fetchLastClosedDays()
  }, [])

  useDidUpdate(() => {
    cargosHook.retryCall()
    if (date.isSame(moment(), 'day')) {
      history.push('/mcs/today')
    } else {
      history.push(`/mcs/view/${date.format('YYYYMMDD')}`)
    }
    fetchLastClosedDays()
  }, [day])

  const fetchLastClosedDays = () => {
    commonFetchLastClosedDays()(dispatch)
      .then(data => {
        const oldestDate = getOldestDate(data)
        if (oldestDate) {
          history.push(`/mcs/close-day/${oldestDate.format('YYYYMMDD')}`)
        }
      })
      .catch(error => {
        console.warn(error) //tslint:disable-line
      })
  }

  // ----------------------------------------------------------------------
  // ----------------------------- INIT ---------------------------------
  // ----------------------------------------------------------------------
  const cargos =
    cargosHook.state.kind === 'success'
      ? cargosHook.state.data
      : cargosHook.state.kind === 'fetching'
      ? cargosHook.state.prevData || []
      : []
  const isFetchingCargos = cargosHook.state.kind === 'fetching'

  const handleRetryFetch = (silent?: boolean) => {
    cargosHook.retryCall(silent)
  }

  // ----------------------------------------------------------------------
  // ----------------------------- RENDERs ---------------------------------
  // ----------------------------------------------------------------------
  if (date.isSame(today, 'day') || window.location.pathname.includes('mcs/today')) {
    return <Today cargos={cargos} isFetchingCargos={isFetchingCargos} fetchData={handleRetryFetch} date={date} />
  }
  if (date.isBefore(today, 'day')) {
    return <Past cargos={cargos} isFetchingCargos={isFetchingCargos} fetchData={handleRetryFetch} date={date} />
  }
  if (date.isAfter(today, 'day')) {
    return <Future cargos={cargos} isFetchingCargos={isFetchingCargos} fetchData={handleRetryFetch} date={date} />
  }
  return <Today cargos={cargos} isFetchingCargos={isFetchingCargos} fetchData={handleRetryFetch} date={date} />
}

export interface PeriodProps {
  cargos: Cargo[]
  fetchData: (silent?: boolean) => void
  isFetchingCargos: boolean
  date: moment.Moment
}

export default MCSRouterPageView
