// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Halt from '../../../../types/halt'
import { Info } from './ModalSlotDetail'
import Shift from '../../../../types/shift'
import Flex, { JustifyContent, StretchColumn } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import TableProcessDetail from '../Table/TableProcessDetail'

const HaltDetail = (props: { halt: Halt; shift: Shift } & WithTranslation) => {
  const { halt, shift } = props
  const translation = useComponentsTranslation()
  const { t, base } = props.translation

  return (
    <StretchColumn>
      <Flex justifyContent={JustifyContent.start}>
        <Info
          title={t(`${base}.name`)}
          text={halt.getDisplayName(translation)}
          date={`${mvDate.format(halt.from, TIME)} - ${mvDate.format(halt.to, TIME)}`}
          translation={{ t, base }}
        />
      </Flex>
      {(/* process.isCurrentSlot() && */ halt.allWarnings.length > 0 && (
        <div>
          <SectionTitle title={t(`${base}.listMachine`)} />
          <TableProcessDetail shift={shift} process={halt} mappingAssetRecipe={[]} translation={{ t, base }} />
        </div>
      )) ||
        null}
    </StretchColumn>
  )
}

export default HaltDetail
