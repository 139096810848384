// * -------------------------------- NPM --------------------------------------
import React, { useState } from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex, { AlignContent, AlignItems, Direction } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import IconComponent from '../../../../../mvlabs-components-fe/ui/components/Icon/Icon'
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Slot from '../../../../types/slot'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'

// * -------------------------------- MODULE --------------------------------------
import CountDownTimer from './CountDownTimer'
import SlotProcessCountdown from './CountDownSlot'

interface Props {
  onClose: (showBanner?: boolean) => void
  nextProcess: Slot
  currentProcess: Slot
  onConfirmSlot: () => Promise<boolean>
}

const ModalManualShift = (props: Props & WithTranslation) => {
  const { onClose, nextProcess, currentProcess, onConfirmSlot } = props
  const { t } = props.translation
  const base = props.translation.base + '.manualShiftModal'

  const [isLoading, setIsLoading] = useState(false)

  return (
    <Modal
      visible={true}
      title={t(`${base}.title`)}
      onClose={onClose}
      rightFooterContents={[
        {
          kind: 'button',
          semantic: 'primary',
          isLoading,
          label: t(`${base}.confirmProcess`),
          onClick: () => {
            setIsLoading(true)
            onConfirmSlot().then(isSuccess => {
              setIsLoading(false)
              onClose(!isSuccess)
            })
          },
        },
      ]}
    >
      <Flex
        direction={Direction.column}
        alignItems={AlignItems.center}
        alignContent={AlignContent.stretch}
        className={'recipe-control__manual-process-shift'}
      >
        <SectionTitle title={t(`${base}.subtitle`)} />
        <CountDownTimer date={nextProcess.from} />
        <Flex alignSelf={AlignSelf.stretch} alignContent={AlignContent.stretch} spaceSize={'lg'}>
          <SlotProcessCountdown processType={'current'} process={currentProcess} />
          <IconComponent icon={'arrow-right'} />
          <SlotProcessCountdown processType={'next'} process={nextProcess} />
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ModalManualShift
