import { useTranslation } from 'react-i18next'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import * as React from 'react'
import MnemonicInput from '@mv-submodules/inplant-components-fe/ui/components/Input/MnemonicInput'

export interface InputProps {
  slug: 'basin' | 'packages' | 'producer' | 'ticketNumber' | 'carrier' | 'recipient' | 'handledWeightKg'
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  type?: string
  min?: number
  step?: number
  readOnly?: boolean
  required?: boolean
  noFormGroup?: boolean
}

export const Input = (props: InputProps) => {
  const { t } = useTranslation()

  const { slug, onChange, value, onKeyDown, type, min, step, readOnly, required, noFormGroup } = props

  const input = (
    <Row verticalAlignment={'center'}>
      <Column sm={4}>
        <label className="col-form-label">{t(`mcs.cargo.${slug}.label`)}</label>
      </Column>
      <Column sm={8}>
        <input
          required={required}
          readOnly={readOnly}
          min={min}
          step={step}
          onKeyDown={onKeyDown}
          type={type || 'text'}
          className="form-control"
          placeholder={t(`mcs.cargo.${slug}.placeholder`)}
          onChange={onChange}
          value={value}
        />
        <div className="invalid-feedback">{t(`mcs.cargo.${slug}.invalidFeedback`)}</div>
      </Column>
    </Row>
  )

  if (noFormGroup) {
    return input
  }
  return <div className={'form-group'}>{input}</div>
}

export interface MnemonicProps extends Omit<InputProps, 'onChange'> {
  suggestionFunction: (value: string) => Promise<string[]>
  disabled?: boolean
  onChange: (value: string) => void
}

export const MnemonicInputWrapper = (props: MnemonicProps) => {
  const { t } = useTranslation()

  const { slug, onChange, value, suggestionFunction, required, disabled, onKeyDown, readOnly } = props

  return (
    <div className="form-group">
      <Row verticalAlignment={'center'}>
        <Column sm={4}>
          <label className="col-form-label">{t(`mcs.cargo.${slug}.label`)}</label>
        </Column>
        <Column sm={8}>
          <MnemonicInput
            readOnly={readOnly}
            onKeyDown={onKeyDown}
            className={'form-control'}
            onChange={onChange}
            suggestionFunction={suggestionFunction}
            placeholder={t(`mcs.cargo.${slug}.placeholder`)}
            required={required}
            value={value}
            disabled={disabled}
          />
          <div className="invalid-feedback">{t(`mcs.cargo.${slug}.invalidFeedback`)}</div>
        </Column>
      </Row>
    </div>
  )
}

export interface TextAreaProps {
  slug: 'note'
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  id?: string
}

export const TextArea = (props: TextAreaProps) => {
  const { t } = useTranslation()

  const { slug, onChange, value, id } = props

  return (
    <div className="form-group" id={id}>
      <Row verticalAlignment={'center'}>
        <Column sm={4}>
          <label className="col-form-label">{t(`mcs.cargo.${slug}.label`)}</label>
        </Column>
        <Column sm={8}>
          <textarea className="form-control" onChange={onChange} value={value} rows={3} />
          <div className="invalid-feedback">{t(`mcs.cargo.${slug}.invalidFeedback`)}</div>
        </Column>
      </Row>
    </div>
  )
}

export interface SelectProps {
  slug: 'units' | 'direction'
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  required: boolean
  defaultValue: string | number | string[] | undefined
  values: Array<{ value: string; label: string; disabled?: boolean }>
  disabled: boolean
  dontTranslateValues?: boolean
}

export const Select = (props: SelectProps) => {
  const { t } = useTranslation()

  const { slug, onChange, defaultValue, values, required, disabled } = props

  return (
    <div className="form-group">
      <Row verticalAlignment={'center'}>
        <Column sm={4}>
          <label className="col-form-label">{t(`mcs.cargo.${slug}.label`)}</label>
        </Column>
        <Column sm={8}>
          <div>
            <select
              className="custom-select"
              defaultValue={defaultValue}
              onChange={onChange}
              required={required}
              disabled={disabled}
            >
              {values.map(v => (
                <option key={v.value} value={v.value} disabled={v.disabled}>
                  {props.dontTranslateValues ? v.label : t(`mcs.cargo.${slug}.${v.label}`)}
                </option>
              ))}
            </select>
          </div>
        </Column>
      </Row>
    </div>
  )
}
