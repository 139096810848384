// * ------------------- MODULE -----------------------------
import Slot, { ISlot } from './slot'
import { Warning } from './warning'

export interface IEmptySlot extends ISlot {} //tslint:disable-line

export class EmptySlot extends Slot {
  constructor(iEmptySlot: Partial<IEmptySlot>) {
    super(iEmptySlot)
  }

  public copyWith = (
    props?: Partial<{
      day: Date
      to: Date
      from: Date
      completed: boolean
      confirmed: boolean
      events: Warning
    }>
  ): EmptySlot => {
    return new EmptySlot({
      day: props && props.day ? props.day.toString() : this.day.toString(),
      to: props && props.to ? props.to.toString() : this.to.toString(),
      from: props && props.from ? props.from.toString() : this.from.toString(),
      completed: props && props.completed !== undefined ? props.completed : this.completed,
      confirmed: props && props.confirmed !== undefined ? props.confirmed : this.confirmed,
      events: (props && props.events) || this.warning,
    })
  }
}
