import { API } from './index'
import { Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { WeeklyReport } from '@mv-submodules/inplant-mcs-fe-iblu/types/WeeklyReport'

export const fetchWeeklyReportData = (date: string): ((dispatch: Dispatch) => Promise<WeeklyReport>) => {
  return async dispatch => {
    try {
      return API().request(`/weekly-report/${date}`).then((data:WeeklyReport) => {
        return data
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}
