import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { ChecklistAssetItem, ChecklistModelFilter } from '../../../../types'
import {
  startChecklist,
  quickConclude,
  persistTableState,
  fetchListNoRedux,
} from '../../../../redux/actions/checklistAssetPage'
import { SortingRule } from 'react-table'
import SmallScreenView from './SmallScreenView'
import 'balloon-css/balloon.min.css'
import { Button, Filters, PageHeader, Table } from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import UnitSelector from '../../widgets/UnitSelector/UnitSelector'
import { Value } from '@mv-submodules/inplant-checklist-fe-iblu/types/configuration'
import UnitSelectorManager, { WithUnitSelector } from '../../widgets/UnitSelector/UnitSelectorManager'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import TitleWithCompilationData from '../../widgets/TitleWithCompilationData/TitleWithCompilationData'

export interface StateProps {
  error: Error | undefined
  page: number
  pageSize: number
  sorted: SortingRule[]
  filtered: ChecklistModelFilter
}

export interface DispatchProps {
  fetchList: (unitSelected?: Value) => Promise<ChecklistAssetItem[]>
  startChecklist: (modelId: string, assetId?: string) => Function
  quickConclude: (items: Set<ChecklistAssetItem>) => Function
  persistTableState: (params: {
    page?: number
    pageSize?: number
    sorted?: SortingRule[]
    filtered?: ChecklistModelFilter
  }) => void
}

export interface OwnProps extends RouteComponentProps<any> {}

export interface OwnState {
  selectedRows: Set<ChecklistAssetItem>
  viewportWidth: number
  isFetching: boolean
  fetchErrors: boolean
  list: ChecklistAssetItem[]
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation & WithUnitSelector

const mapStateToProps = (state: any): StateProps => ({
  error: state.checklist.modelsAssets.error,
  page: state.checklist.modelsAssets.page,
  pageSize: state.checklist.modelsAssets.pageSize,
  sorted: state.checklist.modelsAssets.sorted,
  filtered: state.checklist.modelsAssets.filtered,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchList: unitSelected => dispatch(fetchListNoRedux(unitSelected)),
  startChecklist: (model, asset) => dispatch(startChecklist(model, asset)),
  quickConclude: items => dispatch(quickConclude(items)),
  persistTableState: ({ page, pageSize, sorted, filtered }) =>
    dispatch(persistTableState({ page, pageSize, sorted, filtered })),
})

export class ChecklistModelsAssets extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedRows: new Set(),
      viewportWidth: window.innerWidth,
      fetchErrors: false,
      isFetching: false,
      list: [],
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleChecklistClick = this.handleChecklistClick.bind(this)
    this.handleChangeFilters = this.handleChangeFilters.bind(this)
    this.handleRowSelection = this.handleRowSelection.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleQuickConclude = this.handleQuickConclude.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleOnTablePageChange = this.handleOnTablePageChange.bind(this)
    this.handleOnTablePageSizeChange = this.handleOnTablePageSizeChange.bind(this)
    this.handleOnTableSortedChange = this.handleOnTableSortedChange.bind(this)
    this.handleOnTableFilteredChange = this.handleOnTableFilteredChange.bind(this)
    this.renderFilteredList = this.renderFilteredList.bind(this)
  }

  public componentDidUpdate(prevProps: Props, prevState: OwnState) {
    if (prevProps.unitSelected?.id !== this.props.unitSelected?.id) {
      this.fetchData()
    }
  }

  public componentDidMount() {
    this.fetchData()
    window.addEventListener('resize', this.handleResize)
  }

  private fetchData() {
    if (!this.state.isFetching) {
      this.setState({ isFetching: true })
      this.props
        .fetchList(this.props.unitSelected)
        .then((list: ChecklistAssetItem[]) => this.setState({ list }))
        .catch((error: FetchError) => {
          this.setState({ fetchErrors: true })
        })
        .finally(() => {
          this.setState({ isFetching: false })
        })
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  public async handleChecklistClick(item: ChecklistAssetItem) {
    if (item.model.checklistInstanceId) {
      return this.props.history.push(`/checklist/edit/${item.model.checklistInstanceId}`)
    }
    try {
      const checklistInstanceId = await this.props.startChecklist(item.model.id, item.asset ? item.asset.id : undefined)
      this.props.history.push(`/checklist/edit/${checklistInstanceId}`)
    } catch (error) {
      this.props.fetchList()
    }
  }

  public handleRowSelection(item: ChecklistAssetItem) {
    const clone = new Set(this.state.selectedRows)
    if (this.state.selectedRows.has(item)) {
      clone.delete(item)
    } else {
      clone.add(item)
    }
    this.setState(prevState => ({
      ...prevState,
      selectedRows: clone,
    }))
  }

  public handleSelectAll() {
    const quickConcludeEnabledItems = this.state.list.filter(item => item.enableQuickCompilation)
    if (this.state.selectedRows.size === quickConcludeEnabledItems.length) {
      this.setState(prevState => ({
        ...prevState,
        selectedRows: new Set(),
      }))
    } else {
      const selectedRows = new Set()
      this.state.list.forEach(item => {
        if (item.enableQuickCompilation) {
          selectedRows.add(item)
        }
      })
      this.setState((prevState: any) => ({
        ...prevState,
        selectedRows,
      }))
    }
  }

  public async handleQuickConclude() {
    try {
      await this.props.quickConclude(this.state.selectedRows)
      this.setState(prevState => ({
        ...prevState,
        selectedRows: new Set(),
      }))
      this.props.fetchList()
    } catch (error) {
      this.props.fetchList()
    }
  }

  private handleResize() {
    this.setState(prevState => ({
      ...prevState,
      viewportWidth: window.innerWidth,
    }))
  }

  private handleOnTablePageChange(page: number) {
    this.props.persistTableState({ page })
  }

  private handleOnTablePageSizeChange(pageSize: number) {
    this.props.persistTableState({ pageSize })
  }

  private handleOnTableSortedChange(sorted: SortingRule[]) {
    this.props.persistTableState({ sorted })
  }

  private handleOnTableFilteredChange(filtered: ChecklistModelFilter) {
    this.props.persistTableState({ filtered })
  }

  private handleChangeFilters(name: string, value: string | number | null) {
    this.handleOnTableFilteredChange({
      ...(this.props.filtered ? this.props.filtered : {}),
      [name]: value,
    })
  }

  private renderFilteredList() {
    return this.state.list.filter(
      item =>
        (!this.props.filtered.checklist || this.props.filtered.checklist === item.model.title) &&
        (!this.props.filtered.asset ||
          (item.asset && `${item.asset.description} [${item.asset.code}]` === this.props.filtered.asset))
    )
  }

  public render() {
    const { t } = this.props
    const quickConcludeEnabledItems = this.state.list.filter(item => item.enableQuickCompilation)
    const filters: FilterComponent[] = [
      {
        disabled: this.state.isFetching || this.state.list.length === 0,
        name: 'checklist',
        label: this.props.t('checklist.modelsAssets.table.modelTitle'),
        type: 'Select',
        id: 'filterSelectChecklist',
        onChange: this.handleChangeFilters,
        value: this.props.filtered.checklist || '',
        options: {
          defaultOptionLabel: this.props.t('checklist.modelsAssets.table.showAll'),
          items: this.state.list
            .map(item => item.model.title)
            .filter((title, index, self) => self.indexOf(title) === index)
            .sort((a, b) => a.localeCompare(b))
            .map(title => ({
              label: title,
              value: title,
            })),
        },
      },
      {
        disabled: this.state.isFetching || this.state.list.length === 0,
        name: 'asset',
        label: this.props.t('checklist.modelsAssets.table.assetDescription'),
        type: 'Select',
        id: 'filterSelectAsset',
        onChange: this.handleChangeFilters,
        value: this.props.filtered.asset || '',
        options: {
          defaultOptionLabel: this.props.t('checklist.modelsAssets.table.showAll'),
          items: this.state.list
            .filter(item => item.asset !== null)
            .map(item => `${item.asset!.description} [${item.asset!.code}]`)
            .filter((description, index, self) => self.indexOf(description) === index)
            .sort((a, b) => a.localeCompare(b))
            .map(description => ({
              label: description,
              value: description,
            })),
        },
      },
    ]
    const list = this.renderFilteredList()
    return (
      <React.Fragment>
        <PageHeader title={t('checklist.modelsAssets.title')} />
        <MainPageContent>
          <UnitSelector />
          {this.state.viewportWidth > 768 ? (
            <>
              <Filters fields={filters} />
              <Table
                page={0}
                onPageChange={this.handleOnTablePageChange}
                // pageSize={this.props.pageSize}
                pageSize={list.length}
                showPagination={false}
                onPageSizeChange={this.handleOnTablePageSizeChange}
                isFetching={this.state.isFetching}
                //     className="checklist-models-assets-table -striped -highlight"
                noDataText={this.props.t('checklist.modelsAssets.table.noData')}
                pageText={this.props.t('checklist.modelsAssets.table.page')}
                ofText={this.props.t('checklist.modelsAssets.table.of')}
                rowsText={this.props.t('checklist.modelsAssets.table.rows')}
                data={list}
                minRows={list.length && list.length - 1}
                manualIsFetching={true}
                getTrProps={(state: any, row: any) => {
                  if (!row) {
                    return {}
                  }
                  const classNames = []
                  const openedBySelf = row.original.model.checklistInstanceId
                  const openedByOtherUser = row.original.model.differentUserOpenChecklist
                  if (openedBySelf) {
                    classNames.push('opened-by-self')
                  }
                  if (openedByOtherUser) {
                    classNames.push('opened-by-other-user')
                  }
                  return { className: classNames.join(' ') }
                }}
                columns={[
                  {
                    Header: (
                      <input
                        type="checkbox"
                        value=""
                        disabled={quickConcludeEnabledItems.length === 0}
                        checked={
                          this.state.selectedRows.size === quickConcludeEnabledItems.length &&
                          this.state.selectedRows.size > 0
                        }
                        onChange={() => this.handleSelectAll()}
                      />
                    ),
                    sortable: false,
                    filterable: false,
                    width: 40,
                    Cell: data => {
                      if (!data.original.enableQuickCompilation) {
                        return null
                      }
                      const original = data.original
                      return (
                        <input
                          key={`${original.model.id}-${original.asset ? original.asset.id : 'undefined'}`}
                          type="checkbox"
                          value=""
                          checked={this.state.selectedRows.has(data.original)}
                          onChange={() => this.handleRowSelection(data.original)}
                        />
                      )
                    },
                  },
                  {
                    Header: this.props.t('checklist.modelsAssets.table.modelTitle'),
                    accessor: 'model.title',
                    minWidth: 250,
                    Cell: data => {
                      const assetItem: ChecklistAssetItem = data.original
                      return (
                        <TitleWithCompilationData
                          title={assetItem.model.title}
                          lastCompilationData={assetItem.model.lastCompilationData}
                        />
                      )
                    },
                  },
                  {
                    Header: this.props.t('checklist.modelsAssets.table.assetDescription'),
                    accessor: 'asset.description',
                    Cell: data => {
                      if (data.original.asset) {
                        return `${data.original.asset.description} [${data.original.asset.code}]`
                      }
                      return null
                    },
                    minWidth: 250,
                  },
                  {
                    Header: this.props.t('checklist.modelsAssets.table.actions'),
                    sortable: false,
                    style: { overflow: 'visible' },
                    minWidth: 150,
                    Cell: data => {
                      if (this.state.selectedRows.size > 0) {
                        return null
                      }
                      return (
                        <div className="text-right">
                          {data.original.model.differentUserOpenChecklist ? (
                            <span
                              className="text-info"
                              aria-label={`
                                  ${mvDate.getDateFromStringWithFormatting(
                                    data.original.model.differentUserOpenChecklist.createdDate,
                                    'dd/MM/yyyy HH:mm'
                                  )}
                                  ${data.original.model.differentUserOpenChecklist.user.displayName}
                                `}
                              data-balloon-pos="left"
                            >
                              <IconComponent icon={'info-circle'} className="mr-3" size="lg" />
                            </span>
                          ) : null}
                          {data.original?.model.lastCompilationData?.isBlocking ? (
                            <span
                              className="text-warning"
                              aria-label={this.props.t('checklist.common.assetNotAvailable')}
                              data-balloon-pos="left"
                            >
                              <IconComponent icon={'exclamation-triangle'} className="mr-3" size="lg" />
                            </span>
                          ) : null}
                          <Button
                            onClick={() => this.handleChecklistClick(data.original)}
                            variant={'primary'}
                            disabled={data.original?.model.lastCompilationData?.isBlocking}
                            label={this.props.t('checklist.index.table.compile')}
                          />
                        </div>
                      )
                    },
                  },
                ]}
              />
            </>
          ) : (
            <SmallScreenView list={this.state.list} clickHandler={this.handleChecklistClick} />
          )}
          {(!this.state.isFetching && (
            <div className="d-flex justify-content-center mt-5">
              <Button
                variant="primary-alternate"
                type="button"
                onClick={() => this.props.history.push('/checklist/models')}
                label={this.props.t('checklist.modelsAssets.goToModels')}
              />
            </div>
          )) ||
            null}
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UnitSelectorManager(ChecklistModelsAssets)))
)
