import {
  AllPropsTable,
  DataPropsNoPagination,
  DataPropsPagination,
  LocalData,
  PaginationTypes,
} from '../types/table'
import Table from './Table'
import React from 'react'

/**
 *
 * @tutorial Dont use sticky column if contain a dropdown button
 *
 * @tutorial Use this table when data are load from a fetch request and data are paginated from BE
 *
 * @param request: base url to request data
 *
 * @param column[] specify columns to display and how render the value
 *
 * @param parseResult manipulate the result of the data retrieved from the fetch request to conform the Table interface data
 */
export const BETable = <T, K = any>(
  props: Omit<AllPropsTable<T, K> & DataPropsPagination<T, K>, 'kind' | 'localTable'>
) => {
  return <Table applyFiltersOn={'BE'} {...props} kind={PaginationTypes.withPagination} />
}

/**
 *
 * @tutorial Dont use sticky column if contain a dropdown button
 *
 * @tutorial Use this table when data are load from a fetch request, data are not paginated and FE must manipulate filters and pagination
 *
 * @param request: base url to request data
 *
 * @param column[] specify columns to display and how render the value
 *
 * @param parseResult manipulate the result of the data retrieved from the fetch request to conform the Table interface data
 */
export const FETable = <T, K = any>(
  props: Omit<AllPropsTable<T, K> & DataPropsNoPagination<T, K>, 'kind' | 'localTable'>
) => {
  return <Table applyFiltersOn={'FE'} {...props} kind={PaginationTypes.noPagination} />
}

/**
 *
 * @tutorial Dont use sticky column if contain a dropdown button
 *
 * @tutorial Use this table when data not require a fetch request
 *
 * @param column[] specify columns to display and how render the value
 */
export const LocalTable = <T, K = any>(
  props: Omit<AllPropsTable<T, K>, 'kind' | 'parseResult' | 'request' | 'fetchWrapperInstance' | 'onCatchError'> &
    Required<LocalData<T>>
) => {
  return (
    <Table
      {...props}
      kind={PaginationTypes.noPagination}
      applyFiltersOn={'FE'}
      parseResult={(result: any) => ({ result })}
      request={''}
      fetchWrapperInstance={{} as any}
      onCatchError={() => null}
    />
  )
}
