import { TableColumnType } from './ColumnsRender'
import Period, { Action } from './Period'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export type TodayType = 'manageable' | 'concluded'

export class Today extends Period {
  private type: TodayType
  private progressiveNumber: boolean

  constructor(type: TodayType, progressiveNumber: boolean) {
    super()
    this.type = type
    this.progressiveNumber = progressiveNumber
  }

  protected getConfigColumns = (configTables: Tables) => {
    switch (this.type) {
      case 'concluded':
        return configTables.todayConcluded /* [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.basin,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.forkliftDriverDisplayName,
          TableColumnType.packages,
          TableColumnType.samplingRequired,
          TableColumnType.note,
          TableColumnType.images,
          TableColumnType.timeAbbrev,
          TableColumnType.actions,
        ] */
      case 'manageable':
        return [
          ...(this.progressiveNumber ? [{ type: TableColumnType.progressiveNumber, sort: -1 }] : []),
          ...configTables.todayManageable,
        ] /*  [
          ...(this.progressiveNumber ? [TableColumnType.progressiveNumber] : []),
          TableColumnType.units,
          TableColumnType.ticketNumber,
          TableColumnType.materialType,
          TableColumnType.basin,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.forkliftDriverDisplayName,
          TableColumnType.packages,
          TableColumnType.samplingRequired,
          TableColumnType.note,
          TableColumnType.timeAbbrev,
          TableColumnType.actions,
        ] */
    }
  }

  public noActionShowText: (cargo: Cargo) => boolean = cargo => {
    return (
      cargo.status === 'under_processing' ||
      cargo.status === 'under_load_processing' ||
      cargo.status === 'under_unload_processing' ||
      cargo.status === 'requeued_with_priority' ||
      cargo.status === 'requeued_with_priority_load' ||
      cargo.status === 'requeued_with_priority_unload'
    )
  }

  public actionButton: (cargo: Cargo) => Action | undefined = cargo => {
    // TODO le label posso andare a riferire sui cargo status ???
    switch (cargo.status) {
      case 'concluded':
        return { label: 'show', onClickType: 'summary' }
      case 'pending_arrival':
        return { onClickType: 'detail', label: 'welcome' }
      case 'blocked_for_irregularities':
        return { onClickType: 'detail', label: 'unblock' }
      case 'ready_to_leave':
        return { onClickType: 'checkout', label: 'checkout' }
      case 'ready_to_transfer':
        return { onClickType: 'checkout', label: 'checkout_transfer' }
      case 'in_queue':
      case 'in_load_queue':
        return { onClickType: 'sendingToPendingArrival', label: 'putBackToPendingArrival' }
      case 'in_unload_queue':
        return { onClickType: 'sendingToPendingArrival', label: 'putBackToReadyToTransfer' }
      default:
        return undefined
    }
  }
  public getDropdownAction: (cargo: Cargo) => Action[] = cargo => {
    switch (cargo.status) {
      case 'pending_arrival':
        return [
          { label: 'modify', onClickType: 'edit' },
          { onClickType: 'delete', label: 'delete' },
        ]
      case 'in_queue':
      case 'in_load_queue':
      case 'in_unload_queue':
        return [{ label: 'modify', onClickType: 'edit' }]

      default:
        return []
    }
  }
}
