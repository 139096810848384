// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import SimpleTable from '../../../../../mvlabs-components-fe/ui/components/Table/SimpleTable'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'

// * -------------------------------- MODULE --------------------------------------
import { AssetConnection } from '../../../../types/warning'

interface TableConnectionProps extends WithTranslation {
  warning: AssetConnection[]
}

export const TABLE_CELL_WARNING = 'recipecontrol__table-cell-warning'
export const TABLE_CELL_ERROR = 'recipecontrol__table-cell-error'
export const TABLE_CELL_OVERRIDE = 'recipecontrol__table-cell-override'

const TableConnection = (props: TableConnectionProps) => {
  const { t, base } = props.translation
  const warningClassName = (asset: AssetConnection) => (!asset.isConnected ? TABLE_CELL_WARNING : '')

  return (
    <SimpleTable<AssetConnection>
      data={props.warning}
      columns={[
        {
          title: t(`${base}.machineName`),
          cell: asset => asset.assetDisplayName,
          className: warningClassName,
        },
        {
          title: t(`${base}.isConnected`),
          cell: asset => t(`${base}.${asset.isConnected ? 'yes' : 'no'}`),
          className: warningClassName,
        },
      ]}
    />
  )
}

export default TableConnection
