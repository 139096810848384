// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Halt from '../../../../types/halt'
import PlannerSlotEmptySlot from './PlannerSlotEmptySlot'
import PlannerSlotHalt from './PlannerSlotHalt'
import PlannerSlotProcess from './PlannerSlotProcess'
import PlannerSlotProcessCompare from './PlannerSlotProcessCompare'
import Process from '../../../../types/process'
import useErrorController from '../ErrorController/useErrorController'
import { PlannerSlotProps } from './PlannerSlot'
import { buildContentLevelSlot } from '../../../../functions/buildContentLevelSlot'
import { WarningsType } from '../../../../types/warning'

const PlannerSlotFactory = (props: Omit<PlannerSlotProps, 'contentLevel' | 'translations'>) => {
  const { slot, viewType, shift } = props

  const allWarnings: WarningsType[] = props.slot.allWarnings
  const { layout, errorChildren, errorTooltip } = useErrorController({ ...props, warnings: allWarnings, shift })

  const contentLevel = buildContentLevelSlot(props.showFullData || props.isOneShiftPerDay)
  const translations = useComponentsTranslation()

  // Needs to stay on top of if (slot instanceof Process)
  if (viewType === 'compare' && (slot instanceof Process || slot instanceof Halt)) {
    return (
      <PlannerSlotProcessCompare
        translations={translations}
        {...props}
        slot={slot}
        layout={layout}
        contentLevel={contentLevel}
        // children={[]}
      >
        {errorChildren}
      </PlannerSlotProcessCompare>
    )
  }

  if (slot instanceof Halt) {
    return (
      <PlannerSlotHalt
        {...props}
        slot={slot}
        layout={layout}
        errorTooltip={errorTooltip}
        translations={translations}
        contentLevel={contentLevel}
      >
        {errorChildren}
      </PlannerSlotHalt>
    )
  }

  if (slot instanceof Process) {
    return (
      <PlannerSlotProcess
        translations={translations}
        {...props}
        slot={slot}
        layout={layout}
        errorTooltip={errorTooltip}
        contentLevel={contentLevel}
      >
        {errorChildren}
      </PlannerSlotProcess>
    )
  }

  return (
    <PlannerSlotEmptySlot
      {...props}
      layout={layout}
      errorTooltip={errorTooltip}
      contentLevel={contentLevel}
      translations={translations}
    >
      {errorChildren}
    </PlannerSlotEmptySlot>
  )
}

export default PlannerSlotFactory
