import { Reducer } from 'redux'
import { ConfigManagerStore } from '../../types/Store'
import {
  ConfigManagerTypes,
  CONFIG_MANAGER_ERROR,
  CONFIG_MANAGER_IS_FETCHING,
  CONFIG_MANAGER_SUCCESS,
} from '../actions/configManager'
import { defaultTables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export const configManagerInitialState: ConfigManagerStore = {
  fetching: false,
  error: undefined,
  data: {
    progressiveTruckNumberEnabled: false,
    materialComplianceEnabled: false,
    handleWeightKg: false,
    inFields: [],
    isBasinMandatoryIfSamplingIsRequired: true,
    outFields: [],
    tranFields: [],
    tables: defaultTables,
    transfer: { enable: false, from: '', to: '' },
    units: { enable: false, values: [] },
    canEditTodayConcludedTrucks: false,
  },
}

export const configManager: Reducer<ConfigManagerStore, ConfigManagerTypes> = (
  state = configManagerInitialState,
  action: ConfigManagerTypes
) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (action.payload && action.payload.mcs && action.payload.mcs.configManager) {
        return {
          ...state,
          fetching: action.payload.mcs.configManager.fetching || state.fetching,
          error: action.payload.mcs.configManager.error || state.error,
          data: {
            handleWeightKg: action.payload.mcs.configManager.data.handleWeightKg || state.data.handleWeightKg,
            progressiveTruckNumberEnabled:
              action.payload.mcs.configManager.data.progressiveTruckNumberEnabled ||
              state.data.progressiveTruckNumberEnabled,
            materialComplianceEnabled:
              action.payload.mcs.configManager.data.materialComplianceEnabled || state.data.materialComplianceEnabled,
            inFields: action.payload.mcs.configManager.data.inFields || state.data.inFields,
            isBasinMandatoryIfSamplingIsRequired:
              action.payload.mcs.configManager.data.isBasinMandatoryIfSamplingIsRequired ||
              state.data.isBasinMandatoryIfSamplingIsRequired,
            outFields: action.payload.mcs.configManager.data.outFields || state.data.outFields,
            tranFields: action.payload.mcs.configManager.data.tranFields || state.data.tranFields,
            tables: action.payload.mcs.configManager.data.tables || state.data.tables,
            transfer: action.payload.mcs.configManager.data.transfer || state.data.transfer,
            units: action.payload.mcs.configManager.data.units || state.data.units,
            canEditTodayConcludedTrucks:
              action.payload.mcs.configManager.data.canEditConcludedTrucks || state.data.canEditTodayConcludedTrucks,
          },
        }
      } else {
        return state
      }
    case CONFIG_MANAGER_IS_FETCHING:
      return {
        ...state,
        fetching: true,
        error: undefined,
      }
    case CONFIG_MANAGER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        error: undefined,
      }
    case CONFIG_MANAGER_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default configManager
