import { mvDate } from "../../mvlabs-components-fe/functions/helpers"
import { IRecipeGroup, RecipeGroup } from "./recipeGroup"
import Slot from "./slot"

export interface User {
  id: string
  displayName: string
}

export interface IScheduleOverride {
  id: string
  createdByUser: User
  from: string
  isHalt: boolean
  lastUpdatedByUser: User | null
  note: string | null
  overriddenRecipeGroup: IRecipeGroup
  recipeGroup: IRecipeGroup
  to: string
  relatedManualOverride: string | null
}

class ScheduleOverride {
  private readonly _id: string
  private readonly _createdByUser: User
  private readonly _from: Date
  private readonly _isHalt: boolean
  private readonly _lastUpdatedByUser: User | null
  private readonly _note: string | null
  private readonly _overriddenRecipeGroup: RecipeGroup | null
  private readonly _recipeGroup: RecipeGroup | null
  private readonly _to: Date
  private readonly _relatedManualOverride: string | null

  public isInsideSlot(slot: Slot): boolean {
    return mvDate.isSameOrAfterSecond(this.from, slot.from) && mvDate.isSameOrBeforeSecond(this.from, slot.to)
  }

  get recipeGroup(): RecipeGroup | null {
    return this._recipeGroup ? this._recipeGroup.copyWith() : null
  }

  get relatedManualOverride(): string | null {
    return this._relatedManualOverride
  }

  get createdByUser(): User {
    return this._createdByUser
  }

  get from(): Date {
    return this._from
  }

  get isHalt(): boolean {
    return this._isHalt
  }

  get lastUpdatedByUser(): User | null {
    return this._lastUpdatedByUser
  }

  get note(): string | null {
    return this._note
  }

  get overriddenRecipeGroup(): RecipeGroup | null {
    return this._overriddenRecipeGroup ? this._overriddenRecipeGroup.copyWith() : null
  }

  get to(): Date {
    return this._to
  }

  get id(): string {
    return this._id
  }
  public copyWith = (
    props?: Partial<{
      id: string
      createdByUser: User
      from: Date
      isHalt: boolean
      lastUpdatedByUser: User | null
      note: string | null
      overriddenRecipeGroup: RecipeGroup | null
      recipeGroup: RecipeGroup | null
      to: Date,
      relatedManualOverride: string | null 
    }>): ScheduleOverride => {
      return new ScheduleOverride({
        id: props && props.id !== undefined ? props.id : this.id,
        createdByUser: props && props.createdByUser !== undefined ? props.createdByUser : this.createdByUser,
        from: props && props.from !== undefined ? props.from.toString() : this.from.toString(),
        isHalt: props && props.isHalt !== undefined ? props.isHalt : this.isHalt,
        lastUpdatedByUser: props && props.lastUpdatedByUser !== undefined ? props.lastUpdatedByUser : this.lastUpdatedByUser ,
        note: props && props.note !== undefined ? props.note : this.note,
        overriddenRecipeGroup: props && props.overriddenRecipeGroup !== undefined && props.overriddenRecipeGroup !== null  ? props.overriddenRecipeGroup.interface : this.overriddenRecipeGroup !== null ? this.overriddenRecipeGroup.interface : undefined,
        recipeGroup: props && props.recipeGroup !== undefined  && props.recipeGroup !== null ? props.recipeGroup.interface : this.recipeGroup !== null ? this.recipeGroup.interface : undefined,
        to: props && props.to !== undefined ? props.to.toString() : this.to.toString(),
        relatedManualOverride: props && props.relatedManualOverride || this.relatedManualOverride
      })
    }

  constructor(iScheduleOverride: Partial<IScheduleOverride>) {
    if(!iScheduleOverride.from || !iScheduleOverride.to || !iScheduleOverride.createdByUser /* || !iScheduleOverride.id */) {
      throw new Error()
    }
    this._id = iScheduleOverride.id || ''
    this._to = mvDate.getDateFromString(iScheduleOverride.to)
    this._from = mvDate.getDateFromString(iScheduleOverride.from)
    this._createdByUser = iScheduleOverride.createdByUser
    this._isHalt = iScheduleOverride.isHalt || false
    this._lastUpdatedByUser = iScheduleOverride.lastUpdatedByUser || null 
    this._note = iScheduleOverride.note || null
    this._overriddenRecipeGroup = iScheduleOverride.recipeGroup ? new RecipeGroup(iScheduleOverride.recipeGroup) : null
    this._recipeGroup = iScheduleOverride.recipeGroup ? new RecipeGroup(iScheduleOverride.recipeGroup) : null
    this._relatedManualOverride = iScheduleOverride.relatedManualOverride || null
  }
}

export default ScheduleOverride