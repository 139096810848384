// ------------------------- NPM --------------------------------------
import * as React from 'react'
import * as moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'

// ------------------------- COMPONENTS --------------------------------------
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { Button, Loader } from '@mv-submodules/inplant-components-fe'
import {
  cargoFetchData,
  postponeCargos,
  cargoOpsSetUnprocessedCargo,
} from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { ConfigManagerStore } from '@mv-submodules/inplant-mcs-fe-iblu/types/Store'

// ------------------------- MODULE --------------------------------------
import Filters from '../../../../types/Filters'
import CloseDayLegend from './CloseDayLegend'
import { changeDate } from '../../../../redux/actions/filters'
import { Cargo, CargoDirections, possibleDirections, closedCargoStatus } from '../../../../types/Cargo'
import CargoTable from '../../widgets/CargoTable/CargoTable'
import ReturnToDayButton from './ReturnToDayButton'
import { canViewCloseDayPageView } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'

library.add(faCircleNotch, faInfoCircle, faEyeDropper)

interface StateProps {
  filters: Filters
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchCargo: (cargoDirections: CargoDirections[], date: moment.Moment) => Promise<any>
  changeDate: (date: moment.Moment) => void
  postponeCargos: (cargos: Array<{ id: string; toDate: string }>, direction: CargoDirections) => Promise<void>
  setUnprocessedCargos: (cargos: Array<{ id: string }>, direction: CargoDirections) => Promise<void>
}

interface OwnProps extends RouteComponentProps<any> {}

export interface Schedule {
  date: moment.Moment | null
  direction: CargoDirections
}

interface Schedules {
  [key: string]: Schedule
}

interface OwnState {
  cargos: Cargo[]
  isFetching: boolean
  schedules: Schedules
  rescheduling: boolean
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchCargo: (cargoDirections, date) => dispatch(cargoFetchData(cargoDirections, date)),
    changeDate: date => dispatch(changeDate(date)),
    postponeCargos: (cargos, direction) => dispatch(postponeCargos(direction, cargos)),
    setUnprocessedCargos: (cargos, direction) => dispatch(cargoOpsSetUnprocessedCargo(cargos, direction)),
  }
}

class CloseDayPageView extends React.Component<Props, OwnState> {
  private form: React.RefObject<HTMLFormElement>

  constructor(props: Props) {
    super(props)
    this.state = {
      cargos: [],
      isFetching: false,
      schedules: {},
      rescheduling: false,
    }
    this.form = React.createRef()
    this.fetchData = this.fetchData.bind(this)
    this.getDateFromPathname = this.getDateFromPathname.bind(this)
    this.handleCargoRescheduleDateChange = this.handleCargoRescheduleDateChange.bind(this)
    this.handleReschedulesSubmit = this.handleReschedulesSubmit.bind(this)
    this.prepareSchedulesMap = this.prepareSchedulesMap.bind(this)
  }

  public async componentDidMount() {
    this.fetchData().then(cargos => {
      const date = this.getDateFromPathname()
      const today = moment()
      if ((!canViewCloseDayPageView(cargos) && date.isSame(today, 'day')) || date.isAfter(today, 'day')) {
        history.back()
      }
      this.prepareSchedulesMap(cargos)
    })
  }

  public async componentDidUpdate(prevProps: Props) {
    if (prevProps.location.key !== this.props.location.key) {
      this.setState({ cargos: [] })
      this.prepareSchedulesMap([])
    }
  }

  private prepareSchedulesMap(cargos: Cargo[]) {
    let schedules: Schedules = {}
    cargos
      .filter(cargo => !closedCargoStatus.includes(cargo.status))
      .forEach(cargo => (schedules = { ...schedules, [cargo.id!]: { date: null, direction: cargo.direction } }))
    this.setState({ schedules })
  }

  private async fetchData(): Promise<Cargo[]> {
    this.setState({ isFetching: true })
    let allCargos: Cargo[] = []
    await this.props
      .fetchCargo(possibleDirections(this.props.configManager.data), this.getDateFromPathname())
      .then(cargos => {
        allCargos = cargos
        this.setState({ cargos, isFetching: false })
      })
      .catch(e => {
        console.warn(e) //tslint:disable-line
      })
    return allCargos
  }

  private getDateFromPathname(): moment.Moment {
    const dateString = this.props.location.pathname.split('/')[3]
    return moment(dateString, 'YYYYMMDD')
  }

  private handleCargoRescheduleDateChange(cargoId: string, date: moment.Moment | null, direction: CargoDirections) {
    const schedules = { ...this.state.schedules, [cargoId]: { date, direction } }
    this.setState({ schedules })
  }

  private async handleReschedulesSubmit() {
    const form = this.form.current as HTMLFormElement
    if (form.checkValidity()) {
      const promises: Function[] = []

      let cargosObjUnprocessed: { [direction: string]: Array<{ id: string }> } = {}
      let cargosObjPostponed: { [direction: string]: Array<{ id: string; toDate: string }> } = {}

      Object.entries(this.state.schedules).forEach(([key, value]) => {
        if (value.date) {
          cargosObjPostponed = {
            ...cargosObjPostponed,
            [value.direction]: [
              ...(cargosObjPostponed[value.direction] || []),
              { id: key, toDate: value.date.format('YYYY-MM-DD') },
            ],
          }
        } else {
          cargosObjUnprocessed = {
            ...cargosObjUnprocessed,
            [value.direction]: [...(cargosObjUnprocessed[value.direction] || []), { id: key }],
          }
        }
      })

      possibleDirections(this.props.configManager.data).forEach(dir => {
        if (cargosObjUnprocessed[dir]?.length > 0) {
          promises.push(this.props.setUnprocessedCargos.bind(this, cargosObjUnprocessed[dir], dir))
        }
        if (cargosObjPostponed[dir]?.length > 0) {
          promises.push(this.props.postponeCargos.bind(this, cargosObjPostponed[dir], dir))
        }
      })

      this.setState({ rescheduling: true })
      Promise.all(promises.map(p => p()))
        .then(() => {
          this.setState({ rescheduling: false })
          this.props.history.push('/mcs/today')
        })
        .catch(e => {
          console.warn(e) //tslint:disable-line
        })
    }
    form.classList.add('was-validated')
  }

  private renderContent = () => {
    if (this.state.isFetching) {
      return <Loader />
    }

    const cargosForDirection: Array<{ cargos: Cargo[]; direction: CargoDirections }> = []

    possibleDirections(this.props.configManager.data).map(direction => {
      cargosForDirection.push({
        cargos: this.state.cargos.filter(c => c.direction === direction && !closedCargoStatus.includes(c.status)),
        direction,
      })
    })

    if (cargosForDirection.reduce((acc, cargos) => cargos.cargos.length === 0 && acc, true)) {
      return (
        <div className="alert alert-light text-center" role="alert">
          {this.props.t('mcs.closeDay.noCargoToClose')}
        </div>
      )
    }

    const getTrProps = (state: any, row: any) => {
      if (!row) {
        return {}
      }
      const schedule = this.state.schedules[row.original.id]
      if (schedule && !schedule.date && row.original.materialType && row.original.materialType.name === 'cit') {
        return { className: `status-reschedule-mandatory` }
      }
      if (schedule && !schedule.date && row.original.materialType && row.original.materialType.name !== 'cit') {
        return { className: `status-reschedule-optional` }
      }
      if (schedule && schedule.date) {
        return { className: `status-rescheduled` }
      }
      return {}
    }

    const datePickerCell = {
      Header: this.props.t('mcs.cargo.actions.label'),
      style: { overflow: 'visible', display: 'flex', justifyContent: 'flex-end' },

      Cell: (data: any) => {
        const schedule = this.state.schedules[data.original.id]
        if (!schedule) {
          return null
        }
        return (
          <DatePicker
            selected={schedule.date ? schedule.date.toDate() : null}
            /*isClearable={true}*/
            onChange={(date: Date) =>
              this.handleCargoRescheduleDateChange(data.original.id, moment(date), schedule.direction)
            }
            dateFormat="dd/MM/yyyy"
            className="form-control"
            required={data.original.materialType && data.original.materialType.name === 'cit'}
            minDate={
              this.getDateFromPathname().isSame(moment(), 'day')
                ? moment()
                    .add(1, 'day')
                    .toDate()
                : moment().toDate()
            }
            popperPlacement="left"
          />
        )
      },
    }

    return (
      <React.Fragment>
        <form noValidate={true} ref={this.form}>
          {cargosForDirection.map((cargos, index) => {
            return (
              <React.Fragment key={index}>
                <CargoTable
                  title={this.props.t(`mcs.today.${cargos.direction}`)}
                  key={`${cargos.direction}-${moment().format('x')}`}
                  className={'close-day-table'}
                  data={cargos.cargos}
                  direction={cargos.direction}
                  isLoading={this.state.isFetching}
                  type={{ type: 'closeDay' }}
                  otherColumns={[datePickerCell]}
                  getTrProps={getTrProps}
                />
              </React.Fragment>
            )
          })}
        </form>
        <CloseDayLegend />
        <Row flex={true} horizontalAlignment={'between'}>
          <ReturnToDayButton date={this.props.filters.date} />
          <Button
            variant={'success'}
            onClick={this.handleReschedulesSubmit}
            isLoading={this.state.rescheduling}
            label={this.props.t('mcs.closeDay.proceed')}
          />
        </Row>
      </React.Fragment>
    )
  }

  public render() {
    return (
      <div className="mv4iot-fe-mcs">
        <header>
          <Row flex={true} horizontalAlignment={'between'} spacing={{ vertical: true, horizontal: false }}>
            <div>
              <h1 className="title">{this.props.t('mcs.today.title')}</h1>
              <h2 className="subtitle">
                {this.props.t('mcs.closeDay.subtitle', {
                  date: this.getDateFromPathname().format('DD/MM/YYYY'),
                })}
              </h2>
            </div>
            <div>
              <ReturnToDayButton date={this.props.filters.date} />
            </div>
          </Row>
        </header>
        <div className="content">{this.renderContent()}</div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CloseDayPageView)))
