// * ------------------- NPM -----------------------------
import { ActionCreator, Dispatch } from 'redux'

// * ------------------- CORE -----------------------------
import { logoutUser } from '../../../inplant-core-fe/redux/actions'

// * ------------------- MODULE -----------------------------
import Configuration, { IConfiguration } from '../../types/configuration'
import { API, RECIPE_URL } from '../action/index'
import { getInstallationParam } from '../../functions'

export const RECIPE_CONTROL_PLANNER_CONFIG_SUCCESS = 'RECIPE_CONTROL_PLANNER_CONFIG_SUCCESS'

interface PlannerConfigSuccess {
  type: typeof RECIPE_CONTROL_PLANNER_CONFIG_SUCCESS
  data: Configuration
}

const plannerConfigSuccess: ActionCreator<PlannerConfigSuccess> = (data: Configuration): PlannerConfigSuccess => ({
  type: RECIPE_CONTROL_PLANNER_CONFIG_SUCCESS,
  data,
})

/**
 * @return recipe control configuration
 */
export const getPlannerConfig = (installationParams?: string) => async (dispatch: Dispatch): Promise<Configuration> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/configuration${getInstallationParam(installationParams)}`, {
        method: 'GET',
      })
      .then((configuration: IConfiguration) => {
        dispatch(plannerConfigSuccess(configuration))
        return new Configuration(configuration)
      })
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

export type plannerConfigActions = PlannerConfigSuccess
