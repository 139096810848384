import moment from 'moment'

// the key is the direction
export interface StatusBE {
  [key: string]: string | null
}

export const statusBEToStatus = (s: StatusBE): Status => {
  return Object.entries(s).reduce((acc, [direction, date]) => {
    return { ...acc, [direction]: date !== null ? moment(date) : undefined }
  }, {})
}

// the key is the direction
export interface Status {
  [key: string]: moment.Moment | undefined
}
