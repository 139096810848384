// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { withRouter, RouteComponentProps } from 'react-router'

// ------------------------- COMPONENTS --------------------------------------
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { Button } from '@mv-submodules/inplant-components-fe'

// ------------------------- MODULE --------------------------------------
import { CommonStore, ConfigManagerStore } from '../../../../types/Store'
import Filters from '../../../../types/Filters'
import AddCargoModal from '../Modals/AddCargoModal'
import { changeDirection } from '../../../../redux/actions/filters'
import CloseDayButton from '../../views/CloseDay/CloseDayButton'
import {
  Cargo,
  CargoDirections,
  CargoSelected,
  possibleDirections,
} from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { getLastClosedDay } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'
import ButtonWithToggle from '../Utils/Buttons'
import ActionModal from '../Modals/ActionModal'

interface StateProps {
  filters: Filters
  common: CommonStore
  configManager: ConfigManagerStore
}

interface DispatchProps {
  changeDirection: (direction: CargoDirections) => Promise<any>
}

interface OwnProps extends RouteComponentProps<{}> {
  toggleColumnsSelection?(): void

  refreshData: () => void

  cargosSelected?: CargoSelected
  allCargos: Cargo[]

  cargoOpsBatchDeleteCargo?: () => void
  cargoOpsBatchReplicaCargo?: () => void
  cargoOpsBatchPostponedCargo?: () => void
}

interface OwnState {
  modalState: ModalState
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

type FilterReplicaCargoFunction = (cargo: Cargo) => boolean

type ModalState =
  | { visible: false }
  | { visible: true; type: 'add'; cargoId: string | null }
  | { visible: true; type: 'replica'; filterFunction: FilterReplicaCargoFunction }

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    changeDirection: direction => dispatch(changeDirection(direction)),
  }
}

class UpperPageActions extends React.Component<Props, OwnState> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      modalState: { visible: false },
    }
    this.handleOpenAddCargoModal = this.handleOpenAddCargoModal.bind(this)
    this.handleOpenReplicaModal = this.handleOpenReplicaModal.bind(this)
    this.handleChangeDirection = this.handleChangeDirection.bind(this)
  }

  private handleOpenAddCargoModal(cargoId: string | null) {
    this.setState(prevState => ({ ...prevState, modalState: { visible: true, type: 'add', cargoId } }))
  }

  private handleCloseModal = (refreshData?: boolean) => {
    this.setState(prevState => ({ ...prevState, modalState: { visible: false } }))
    if (refreshData) {
      this.props.refreshData()
    }
  }

  private handleOpenReplicaModal(filterFunction: FilterReplicaCargoFunction) {
    this.setState(prevState => ({
      ...prevState,
      modalState: { visible: true, type: 'replica', filterFunction },
    }))
  }

  private handleChangeDirection(direction: CargoDirections) {
    this.props.changeDirection(direction)
  }

  public render() {
    const { modalState } = this.state

    return (
      <React.Fragment>
        {modalState.visible && modalState.type === 'add' ? (
          <AddCargoModal canRescheduleCargo={true} cargoId={modalState.cargoId} onClose={this.handleCloseModal} />
        ) : null}
        {modalState.visible && modalState.type === 'replica' ? (
          <ActionModal
            type={'replica'}
            cargoIds={this.props.allCargos
              .filter(c => modalState.filterFunction(c) && c.direction === this.props.filters.direction)
              .map(c => c.id || '')}
            onClose={this.handleCloseModal}
          />
        ) : null}
        <div className={'upper-page-actions'}>
          <Row horizontalAlignment={'between'}>
            <div>
              <div className="cargo-in-out-filter d-inline-block">
                <label className="mr-2">{this.props.t('mcs.today.show')}</label>
                <div className="btn-group" role="group">
                  {possibleDirections(this.props.configManager.data).map(direction => {
                    return (
                      <button
                        key={direction}
                        type="button"
                        className={`btn btn-outline-secondary ${
                          this.props.filters.direction === direction ? 'active' : ''
                        }`}
                        onClick={() => this.handleChangeDirection(direction)}
                      >
                        {this.props.t(`mcs.today.${direction}`)}
                      </button>
                    )
                  })}
                </div>
              </div>
              {moment().isSame(this.props.filters.date, 'day') && !this.props.match.url.includes('close-day') ? (
                <CloseDayButton cargos={this.props.allCargos} />
              ) : null}
            </div>
            <div>
              <>
                <ButtonWithToggle
                  className={'mx-2'}
                  blockLevel={true}
                  toggleVariant={'success'}
                  variant={'success'}
                  disabled={this.props.allCargos.length <= 0}
                  onClick={() => this.handleOpenReplicaModal(_c => true)}
                  label={this.props.t('mcs.today.replicaDay')}
                  dropdownItems={[
                    {
                      disabled: this.props.allCargos.length <= 0,
                      onClick: () =>
                        this.handleOpenReplicaModal(c => (c.materialType && c.materialType.name === 'multi') || false),
                      label: this.props.t('mcs.today.replicaMulti'),
                      variant: 'secondary',
                    },
                  ]}
                />
                {!getLastClosedDay(
                  this.props.common.lastClosedDays.data.incoming,
                  this.props.common.lastClosedDays.data.outgoing
                ) && !this.props.filters.date.isBefore(moment(), 'day') ? (
                  <React.Fragment>
                    <Button
                      variant={'success'}
                      label={this.props.t('mcs.today.addCargo')}
                      onClick={() => this.handleOpenAddCargoModal(null)}
                    />
                    {(this.props.toggleColumnsSelection && !this.props.cargosSelected) ||
                      (this.props.toggleColumnsSelection &&
                        this.props.cargosSelected &&
                        !Object.values(this.props.cargosSelected).find((value: boolean) => value) && (
                          <Button
                            variant={'info'}
                            onClick={this.props.toggleColumnsSelection}
                            label={this.props.t('mcs.today.multiActions')}
                          />
                        ))}
                    {this.props.toggleColumnsSelection &&
                      this.props.cargosSelected &&
                      !!Object.values(this.props.cargosSelected).find((value: boolean) => value) && (
                        <>
                          <Button
                            variant={'danger'}
                            label={this.props.t('mcs.cargo.actions.delete')}
                            icon={'trash'}
                            onClick={() => {
                              if (this.props.cargoOpsBatchDeleteCargo) {
                                this.props.cargoOpsBatchDeleteCargo()
                              }
                            }}
                          />
                          <Button
                            variant={'success'}
                            label={this.props.t('mcs.cargo.actions.replicaSelected')}
                            icon={'clone'}
                            onClick={() => {
                              if (this.props.cargoOpsBatchReplicaCargo) {
                                this.props.cargoOpsBatchReplicaCargo()
                              }
                            }}
                          />
                          <Button
                            variant={'success'}
                            label={this.props.t('mcs.cargo.actions.postponed')}
                            onClick={() => {
                              if (this.props.cargoOpsBatchPostponedCargo) {
                                this.props.cargoOpsBatchPostponedCargo()
                              }
                            }}
                          />
                        </>
                      )}
                  </React.Fragment>
                ) : null}
              </>
            </div>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpperPageActions)))
