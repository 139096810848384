import Row from "@mv-submodules/inplant-components-fe/ui/components/Grid/Row";
import Column from "@mv-submodules/inplant-components-fe/ui/components/Grid/Column";
import * as React from "react";
import {renderTextVariants, TextVariants} from "@mv-submodules/inplant-components-fe/ui/components/Utils/utils";

interface Props {
  label: string
}

const simpleSkeleton = <P extends object>(Component: React.ComponentType<P>) =>
  class SimpleSkeleton extends React.Component<P & Props> {
    public render() {
      const {...props} = this.props;
      return <div className="form-group mb-0">
        <Row spacing={{vertical:false}}>
          <Column sm={4}>
            <label className="col-form-label">
              {props.label}
            </label>
          </Column>
          <Column sm={8}>
            <Component {...props as P}/>
          </Column>
        </Row>
      </div>
    }
  };

interface FieldReadOnlyProps {
  value: string | number | string[] | undefined
  valueVariant?: TextVariants
}

const FieldRO = (props: FieldReadOnlyProps) => {
  return (
    <input
      type="text"
      readOnly={true}
      className={`form-control-plaintext${renderTextVariants(props.valueVariant)}`}
      value={props.value}
    />
  )
}

interface TextAreaProps {
  rows: number
}

const TextAreaRO = (props: FieldReadOnlyProps & TextAreaProps) => {
  return (
    <textarea
      className={`form-control-plaintext${renderTextVariants(props.valueVariant)}`}
      rows={props.rows}
      readOnly={true}
      value={props.value}
    />
  )


}

export const TextAreaReadOnly = simpleSkeleton(TextAreaRO)
export const FieldReadOnly = simpleSkeleton(FieldRO)