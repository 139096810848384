// * ------------------- NPM -----------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import FlexItem, { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import IconComponent from '../../../../../mvlabs-components-fe/ui/components/Icon/Icon'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { IconProps } from '../../../../../mvlabs-components-fe/types/base'

export enum Type {
  toConfirm = '',

  // not readonly and confirmed
  Confirmed = 'planner__badge--confirmed',

  // readonly and today
  today = 'planner__badge--today',

  // readonly confirmed !today
  nextConfirmed = 'planner__badge--next-confirmed'
}

interface Props extends IconProps {
  description: string
  readonly: {
    today: boolean
    confirmed: boolean
  } | false
}

const PlannerBadge = (props: Props) => {
  const name = 'planner__badge'

  const renderType = (): Type => {
    if (props.readonly) {
      if (props.readonly.confirmed) {
        return props.readonly.today ? Type.today : Type.nextConfirmed
      }
      return Type.toConfirm
    }
    return Type.Confirmed
  }

  return <FlexItem className={`${name} ${renderType()} `} alignSelf={AlignSelf.stretch}>
    {props.icon !== undefined ? <IconComponent icon={props.icon} /> : null}
    <Text text={props.description} />
  </FlexItem>
}

export default PlannerBadge