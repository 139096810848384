// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { Select } from '../../../../../mvlabs-components-fe/ui/components/Input/Input'
import { useComponentsTranslation } from '@mv-submodules/mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import Process from '../../../../types/process'

interface Props {
  onChange: (value: string) => void
  configuration: Configuration
  process?: Process
}

const RecipeSelect = (props: Props) => {
  const { t } = useComponentsTranslation()
  const { onChange, configuration, process } = props
  return (
    <Select
      onChange={onChange}
      alignSelf={AlignSelf.start}
      required={true}
      label={t(`recipeControl.form.recipe.processType`)}
      options={{
        defaultOption: (process && { disable: false, value: process.recipeGroup.id }) || {
          disable: true,
          label: '--',
          value: '',
        },
        items: configuration.recipeGroups.map(r => {
          return { value: r.id, label: r.displayName }
        }),
      }}
    />
  )
}

export default RecipeSelect
