// * -------------------------------- NPM --------------------------------------
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { connect } from 'react-redux'
import { Resize, SortingRule } from 'react-table'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'

// * -------------------------------- MODULE --------------------------------------
import { saveManageableResizedColumns, saveConcludedResizedColumns } from '../../../../redux/actions/incoming'
import WaitingTimes from '../../widgets/WaitingTimes/WaitingTimes'
import Legend from '../../widgets/ComponentsPastTodayFuture/Legend'
import UpperPageActions from '../../widgets/UpperPageActions/UpperPageActions'
import { CommonStore, ConfigManagerStore } from '../../../../types/Store'
import Filters from '../../../../types/Filters'
import TodayDetailedCargoModal from './TodayCargoModal'
import { Cargo, CargoDirections, CargoSelected } from '../../../../types/Cargo'
import DeleteCargoModal from '../../widgets/Modals/DeleteCargoModal'
import CheckoutCargoModal from '../../widgets/Modals/CheckoutCargoModal'
import SummaryCargoModal from '../../widgets/Modals/SummaryCargoModal'
import { changeDate } from '../../../../redux/actions/filters'
import { cargoOpsPutBack, cargoOpsBatchDeleteCargo } from '../../../../redux/actions/cargoOps'
import { fetchConfigManager } from '../../../../redux/actions/configManager'
import HeaderPage from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/ComponentsPastTodayFuture/HeaderPage'
import CargoTable from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/CargoTable'
import { Actions } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/ColumnsRender'
import { ModalState } from '@mv-submodules/inplant-mcs-fe-iblu/types/Modals'
import { PeriodProps } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/views/MCSRoutes/MCSRouterPageView'
import ActionModal from '../../widgets/Modals/ActionModal'
import { downloadCargoCSV } from '../../../../redux/actions/cargoDirections'

library.add(faCircleNotch, faInfoCircle, faEyeDropper, faHourglassHalf)

interface StateProps {
  filters: Filters
  common: CommonStore
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchConfigManager: (abortController: AbortController) => Promise<any>
  changeDate: (date: moment.Moment) => void
  putBack: (cargo: Cargo, direction: CargoDirections) => Promise<void>
  saveManageableResizedColumns: (resized: Resize[]) => Promise<void>
  saveConcludedResizedColumns: (resized: Resize[]) => Promise<void>
  cargoOpsBatchDeleteCargo: (cargoIds: string[], direction: CargoDirections) => void
  concludedCargoCSV: (direction: CargoDirections, date: moment.Moment) => Promise<string>
}

interface OwnProps extends RouteComponentProps<any> {}

interface OwnState {
  showModal: ModalState
  cargoOpsHappening: { [key: string]: boolean }
  cargosSelected: CargoSelected
  manageableCargosSorted: SortingRule[]
  concludedCargosSorted: SortingRule[]
  showCheckbox: boolean
  isLoadingCSV: boolean
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation & PeriodProps

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchConfigManager: abortController => dispatch(fetchConfigManager(abortController)),
    changeDate: date => dispatch(changeDate(date)),
    putBack: (cargo, direction) => dispatch(cargoOpsPutBack(cargo, direction)),
    saveManageableResizedColumns: resized => dispatch(saveManageableResizedColumns(resized)),
    saveConcludedResizedColumns: resized => dispatch(saveConcludedResizedColumns(resized)),
    cargoOpsBatchDeleteCargo: (cargoIds: string[], direction: CargoDirections) =>
      dispatch(cargoOpsBatchDeleteCargo(cargoIds, direction)),
    concludedCargoCSV: (direction: CargoDirections, date: moment.Moment) => dispatch(downloadCargoCSV(direction, date)),
  }
}

class TodayPageView extends React.Component<Props, OwnState> {
  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- INIT --------------------------------------------
  // * ----------------------------------------------------------------------------------------
  private intervalId: any
  private abortController: AbortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: { visible: false },
      cargoOpsHappening: {},
      cargosSelected: {},
      manageableCargosSorted: [],
      concludedCargosSorted: [],
      showCheckbox: false,
      isLoadingCSV: false,
    }
    this.putCargoBackToPendingArrival = this.putCargoBackToPendingArrival.bind(this)
  }

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- State Management ----------------------------------
  // * ----------------------------------------------------------------------------------------
  public componentDidMount() {
    this.props.fetchConfigManager(this.abortController).catch(e => {
      console.warn(e) //tslint:disable-line
    })
    this.intervalId = setInterval(() => this.props.fetchData(true), 15000)
    this.props.changeDate(moment())
  }

  public async componentDidUpdate(prevProps: Props) {
    if (prevProps.filters.direction !== this.props.filters.direction) {
      this.setState({ cargosSelected: {} })
    }
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    this.abortController.abort()
  }

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- BLoS --------------------------------------------
  // * ----------------------------------------------------------------------------------------

  private handleCloseCargosModal = () => {
    this.setState(
      {
        showModal: { visible: false },
        cargosSelected: {},
      },
      () => {
        this.toggleColumnsSelection()
      }
    )
  }

  private handleCloseModal = (refreshData?: boolean) => {
    this.setState({ showModal: { visible: false } })
    if (refreshData) {
      this.props.fetchData(true)
    }
  }

  private saveManageableColumnsSizeToStore = (newResized: Resize[]) => {
    this.props.saveManageableResizedColumns(newResized)
  }

  private saveConcludedColumnsSizeToStore = (newResized: Resize[]) => {
    this.props.saveConcludedResizedColumns(newResized)
  }

  private async putCargoBackToPendingArrival(cargo: Cargo, direction: CargoDirections) {
    const cargoOpsHappening = this.state.cargoOpsHappening
    cargoOpsHappening[cargo.id || ''] = true
    this.setState({ cargoOpsHappening })
    this.props
      .putBack(cargo, direction)
      .then(() => {
        cargoOpsHappening[cargo.id || ''] = false
        this.setState({ cargoOpsHappening })
        this.props.fetchData()
      })
      .catch(e => {
        console.warn(e) //tslint:disable-line
      })
  }

  private toggleColumnsSelection = () => {
    this.setState({
      showCheckbox: !this.state.showCheckbox,
    })
  }

  private cargoOpsBatchDeleteCargo = async () => {
    const cargosToDelete = Object.keys(this.state.cargosSelected).filter(cargo => this.state.cargosSelected[cargo])
    this.setState({
      showModal: { visible: true, type: 'multipleDelete', cargoIds: cargosToDelete },
    })
  }

  private cargoOpsBatchReplicaCargo = async () => {
    const cargosToReplicate = Object.keys(this.state.cargosSelected).filter(cargo => this.state.cargosSelected[cargo])
    this.setState({
      showModal: { visible: true, type: 'replica', cargoIds: cargosToReplicate },
    })
  }

  private cargoOpsBatchPostponedCargo = async () => {
    const cargosToPostponed = Object.keys(this.state.cargosSelected).filter(cargo => this.state.cargosSelected[cargo])
    this.setState({
      showModal: { visible: true, type: 'postponed', cargoIds: cargosToPostponed },
    })
  }

  private onAction = (action: Actions): void => {
    const cargoId = action.cargo.id || ''
    switch (action.type) {
      case 'delete':
        return this.setState({ showModal: { visible: true, type: 'delete', cargoId } })
      case 'detail':
        return this.setState({
          showModal: { visible: true, type: 'detail', cargoId, action: 'welcome' },
        })
      case 'edit':
        return this.setState({
          showModal: { visible: true, type: 'detail', cargoId, action: 'edit' },
        })
      case 'checkout':
        return this.setState({
          showModal: { visible: true, type: 'checkout', cargoId },
        })
      case 'default':
        return this.setState({ showModal: { visible: true, type: 'detail', cargoId, action: 'edit' } })
      case 'toggle_row':
        return this.setState({
          cargosSelected: {
            ...this.state.cargosSelected,
            [cargoId]: !this.state.cargosSelected[cargoId],
          },
        })
      case 'sendingToPendingArrival':
        this.putCargoBackToPendingArrival(action.cargo, this.props.filters.direction)
        break
      case 'summary':
        this.setState({ showModal: { visible: true, type: 'summary', cargoId } })
        break
    }
  }

  private exportCSVFile = async (): Promise<void> => {
    this.setState({
      isLoadingCSV: true,
    })
    const result = await this.props.concludedCargoCSV(this.props.filters.direction, this.props.date)
    const blob = new Blob([result])
    const objectURL = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = objectURL
    link.download = `concluded-trucks-${this.props.date.format('YYYY-MM-DD')}-${this.props.filters.direction}.csv`
    link.click()
    this.setState({
      isLoadingCSV: false,
    })
  }

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- RENDERs --------------------------------------------
  // * ----------------------------------------------------------------------------------------

  private renderModals = () => {
    if (this.state.showModal.visible) {
      switch (this.state.showModal.type) {
        case 'delete':
          return (
            <DeleteCargoModal type={'delete'} cargoId={this.state.showModal.cargoId} onClose={this.handleCloseModal} />
          )
        case 'checkout':
          return <CheckoutCargoModal cargoId={this.state.showModal.cargoId} onClose={this.handleCloseModal} />
        case 'detail':
          return (
            <TodayDetailedCargoModal
              canRescheduleCargo={true}
              cargoId={this.state.showModal.cargoId}
              action={this.state.showModal.action}
              onClose={this.handleCloseModal}
            />
          )
        case 'summary':
          return <SummaryCargoModal cargoId={this.state.showModal.cargoId} onClose={this.handleCloseModal} />
        case 'multipleDelete':
          return this.state.showModal.cargoIds.length !== 0 ? (
            <DeleteCargoModal
              type={'multipleDelete'}
              cargoIds={this.state.showModal.cargoIds}
              onClose={this.handleCloseCargosModal}
            />
          ) : (
            <></>
          )
        case 'replica':
        case 'postponed':
          return this.state.showModal.cargoIds.length !== 0 ? (
            <ActionModal
              type={this.state.showModal.type}
              onClose={this.handleCloseCargosModal}
              cargoIds={this.state.showModal.cargoIds}
            />
          ) : (
            <></>
          )
      }
    }
    return <></>
  }

  public render() {
    const cargos = this.props.cargos.filter(c => c.direction === this.props.filters.direction)

    const manageableCargos = cargos.filter((cargo: Cargo) => !['concluded', 'unprocessed'].includes(cargo.status))
    const concludedCargos = cargos.filter((cargo: Cargo) => ['concluded', 'unprocessed'].includes(cargo.status))

    return (
      <div className="mv4iot-fe-mcs">
        <HeaderPage date={this.props.filters.date} title={'today'} />
        <div className="content">
          {this.renderModals()}
          <WaitingTimes filters={this.props.filters} />
          <UpperPageActions
            allCargos={this.props.cargos}
            toggleColumnsSelection={this.toggleColumnsSelection}
            cargosSelected={this.state.cargosSelected}
            cargoOpsBatchDeleteCargo={this.cargoOpsBatchDeleteCargo}
            cargoOpsBatchReplicaCargo={this.cargoOpsBatchReplicaCargo}
            cargoOpsBatchPostponedCargo={this.cargoOpsBatchPostponedCargo}
            refreshData={() => this.props.fetchData(true)}
          />
          <React.Fragment>
            <CargoTable
              data={manageableCargos}
              isLoading={this.props.isFetchingCargos || this.props.configManager.fetching}
              type={{
                type: 'today',
                subType: 'manageable',
                progressiveNumber: this.props.configManager.data.progressiveTruckNumberEnabled,
              }}
              direction={this.props.filters.direction}
              onAction={this.onAction}
              showCheckbox={this.state.showCheckbox}
              cargosSelected={this.state.cargosSelected}
              onResizedChange={this.saveManageableColumnsSizeToStore}
              getTrProps={(state: any, row: any) => {
                if (!row) {
                  return {}
                }
                return { className: `status-${row.original.status}` }
              }}
            />
            <Legend />
            {concludedCargos.length > 0 ? (
              <div className="concluded-cargos">
                <CargoTable
                  exportCSVButton={{
                    action: this.exportCSVFile,
                    isLoading: this.state.isLoadingCSV,
                  }}
                  title={this.props.t('mcs.today.concludedCargos')}
                  data={concludedCargos}
                  isLoading={this.props.isFetchingCargos}
                  type={{ type: 'today', subType: 'concluded' }}
                  direction={this.props.filters.direction}
                  onAction={this.onAction}
                  onResizedChange={this.saveConcludedColumnsSizeToStore}
                />
              </div>
            ) : null}
          </React.Fragment>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TodayPageView)))
