// ------------------------- NPM --------------------------------------
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { useState } from 'react'

// ------------------------- COMPONENTS --------------------------------------
import { FormModal } from '@mv-submodules/inplant-components-fe/ui/components/Modal/Modal'
import { Input } from '@mv-submodules/inplant-components-fe'

// ------------------------- MODULE --------------------------------------
import {
  replicateSchedule,
  TruckToReplicate,
  postponeCargos,
} from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { changeDate, changeDirection } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/filters'

library.add(faCircleNotch)

interface Props {
  type: 'replica' | 'postponed'
  onClose: (refreshData?: boolean) => void
  cargoIds: string[]
}

const ActionModal: (props: Props) => JSX.Element = props => {
  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- HOOKs ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const filters = useSelector((state: any) => state.mcs.filters)

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- INIT ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  const [times, setTimes] = useState(1)
  const [dateToReplicate, setDateToReplicate] = useState(moment().add(1, 'day'))
  const [isLoading, setLoading] = useState(false)

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- BLoS ------------------------------------------
  // * ---------------------------------------------------------------------------------------
  const handleDateChange = (date: Date) => {
    setDateToReplicate(moment(date))
  }

  const replicateCargos = async (): Promise<void> => {
    const { cargoIds } = props
    const cargoToReplicate: TruckToReplicate[] = []
    if (cargoIds.length === 1) {
      cargoToReplicate.push({ count: times, id: cargoIds[0] })
    } else {
      cargoIds.forEach(c => {
        cargoToReplicate.push({ count: 1, id: c })
      })
    }

    await replicateSchedule(
      dateToReplicate,
      filters.direction,
      cargoToReplicate
    )(dispatch).catch((error: any) => {
      console.error(error) // tslint:disable-line
    })
    return
  }

  const postponedCargos = async (): Promise<void> => {
    const { cargoIds } = props

    const cargosObj: Array<{ id: string, toDate:string }> = []
    cargoIds.forEach(c => {
      cargosObj.push({ id: c, toDate: dateToReplicate.format('YYYY-MM-DD') })
    })
    await postponeCargos(
      filters.direction,
      cargosObj
    )(dispatch).catch((error: any) => {
      console.error(error) // tslint:disable-line
    })
  }

  // * ----------------------------------------------------------------------------------------
  // * ------------------------------------- RENDERS ------------------------------------------
  // * ----------------------------------------------------------------------------------------
  return ReactDOM.createPortal(
    <FormModal
      visible={true}
      onClose={props.onClose}
      closeLabel={t('mcs.modals.close')}
      title={t(`mcs.modals.actions.${props.type}.title`)}
      submitButton={{
        label: t(`mcs.modals.actions.${props.type}.replicate`),
        disabled: isLoading || props.cargoIds.length <= 0,
        isLoading,
      }}
      onSubmit={async e => {
        e.preventDefault()
        setLoading(true)
        switch (props.type) {
          case 'postponed':
            await postponedCargos()
            break
          case 'replica':
            await replicateCargos()
            break
        }
        setLoading(false)
        props.onClose()
        dispatch(changeDirection(filters.direction))
        dispatch(changeDate(dateToReplicate))
      }}
    >
      <p>{t(`mcs.modals.actions.${props.type}.text`)}</p>
      <div className="text-center">
        <DatePicker
          inline={true}
          selected={dateToReplicate.toDate()}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          className="form-control text-center"
        />
      </div>
      <div>
        <p>
          {t(`mcs.modals.actions.${props.type}.cargosFound`, {
            value: props.cargoIds.length,
            date: dateToReplicate.format('DD/MMM/YYYY'),
          })}
        </p>
        {props.cargoIds && props.cargoIds.length === 1 && props.type === 'replica' ? (
          <Input
            value={times}
            onChange={value => {
              let number = 0
              if (!value) {
                number = 0
              }
              if (value && Number(value) && Number.isInteger(value)) {
                number = Number(value)
              }
              setTimes(number)
            }}
            type={'number'}
            min={0}
            label={t(`mcs.modals.actions.${props.type}.times`)}
          />
        ) : null}
      </div>
    </FormModal>,
    document.getElementById('modal-container') as Element
  )
}

export default ActionModal
