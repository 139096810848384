// * ------------------- MODULE -----------------------------
import { IConfiguration } from '../../types/configuration'
import { getInstallation } from "../../functions";
import { plannerConfigActions, RECIPE_CONTROL_PLANNER_CONFIG_SUCCESS } from '../action/getPlannerConfig'

export interface PlannerConfigState {
  data: { [installation: string]: IConfiguration }
}

const initialState: PlannerConfigState = {
  data: {},
}

export const plannerConfig = (state: PlannerConfigState = initialState, action: plannerConfigActions) => {
  switch (action.type) {
    case RECIPE_CONTROL_PLANNER_CONFIG_SUCCESS:
      return {
        ...state,
        data: {...state.data, [getInstallation()]: action.data}
      }
    default:
      return state
  }
}
