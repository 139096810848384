// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import IconComponent from '../../../../../mvlabs-components-fe/ui/components/Icon/Icon'

// * -------------------------------- MODULE --------------------------------------
import PlannerSlotComponent from './PlannerSlot'
import { EmptySlot } from '../../../../types/emptySlot'
import { buildContentLevelSlot } from '../../../../functions/buildContentLevelSlot'
import { isSlotEditable } from '../../../../functions/slot'

// * --------------------- EmptySlot
class PlannerSlotEmptySlot extends PlannerSlotComponent<EmptySlot> {
  private t = this.props.translations.t
  

  protected showTooltip(): boolean {
    const slot = this.props.slot
    return (
      (slot.confirmed || this.isSlotPast()) &&
      this.props.percentageDimension < buildContentLevelSlot(this.props.showFullData || this.props.isOneShiftPerDay).levelShortContent
    )
  }

  protected renderTooltip(): string {
    return this.dateString
  }

  protected isSlotNotAssigned = (): boolean => {
    const slot = this.props.slot
    return (
      this.props.viewType === 'read-only' ||
      this.props.viewType === 'compare' ||
      slot.confirmed ||
      !isSlotEditable(this.props.configuration, this.props.shift, slot)
    )
  }

  protected isSlotNotAssignedAndConfirmed = (): boolean => {
    const slot = this.props.slot
    return this.isSlotPast() && !this.props.configuration.confirmationConfig.confirmationEnabled && !slot.confirmed
  }

  protected isCurrentSlotNotAssignedAndConfirmed = (): boolean => {
    const slot = this.props.slot
    return slot.isCurrentSlot() && !this.props.configuration.confirmationConfig.confirmationEnabled && !slot.confirmed
  }

  protected renderShortContent(): React.ReactNode {
    if (this.isSlotNotAssignedAndConfirmed()) {
      return this.renderText(`|**${this.t('recipeControl.planner.readonly.emptySlotNotConfirmed')}**|`, this.t('recipeControl.planner.readonly.emptySlotNotConfirmed'))
    }

    if (this.isSlotNotAssigned()) {
      return this.renderText(`|**${this.t('recipeControl.planner.readonly.emptySlot')}**|`, this.t('recipeControl.planner.readonly.emptySlot'))
    }

    return (
      <span>
        <IconComponent className={'icon-empty-slot'} icon={'plus'} />
      </span>
    )
  }

  protected renderHalfContent(): React.ReactNode {
    return this.renderAllContent()
  }

  protected renderAllContent(): React.ReactNode {
    // no activity assigned and confirmed
    if (this.isSlotNotAssignedAndConfirmed()) {
      return this.renderTextWithDate(`|**${this.t('recipeControl.planner.readonly.emptySlotNotConfirmed')}**|`,this.t('recipeControl.planner.readonly.emptySlotNotConfirmed') )
    }

    // no activity assigned
    if (this.isSlotNotAssigned() || this.isCurrentSlotNotAssignedAndConfirmed()) {
      return this.renderTextWithDate(`|**${this.t('recipeControl.planner.readonly.emptySlot')}**|`, this.t('recipeControl.planner.readonly.emptySlot'))
    }

    return (
      <span>
        <IconComponent className={'icon-empty-slot'} icon={'plus'} />
      </span>
    )
  }
}

export default PlannerSlotEmptySlot
