// * -------------------------------- NPM --------------------------------------
import React, { useRef } from 'react'
import { useEffect, useState } from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'

interface Props {
  date: Date
}

const CountDownTimer = (props: Props) => {
  const { date } = props
  const now = mvDate.now()
  const [countdown, setCountdown] = useState(
    mvDate.subtractHours(
      mvDate.subtractMinutes(mvDate.subtractSeconds(date, mvDate.getSeconds(now)), mvDate.getMinutes(now)),
      mvDate.getHours(now)
    )
  )
  const [isCountdownEnd, setIsCountdownEnd] = useState<boolean>(false)
  const timerInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  const handleCleanInterval = () => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current)
    }
  }

  useEffect(() => {
    if (mvDate.getHours(countdown) === 0 && mvDate.getMinutes(countdown) === 0 && mvDate.getSeconds(countdown) === 0) {
      handleCleanInterval()
      setIsCountdownEnd(true)
    }
  }, [countdown])

  useEffect(() => {
    timerInterval.current = setInterval(() => {
      setCountdown(prev => {
        return mvDate.subtractSeconds(prev, 1)
      })
    }, 1000)
    return () => {
      handleCleanInterval()
    }
  }, [])

  return (
    <div className={`recipecontrol__countdown ${isCountdownEnd ? 'recipecontrol__countdown--end' : ''}`}>
      <Text text={mvDate.format(countdown, 'mm:ss')} />
    </div>
  )
}

export default CountDownTimer
