import Button, { ButtonDefinition } from '@mv-submodules/inplant-components-fe/ui/components/Button/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonVariants } from '@mv-submodules/inplant-components-fe/ui/components/Button/types'

interface Props extends ButtonDefinition {
  className?: string
  toggleVariant: ButtonVariants
  dropdownItems: ButtonDefinition[]
}
const ButtonWithToggle = (props: Props) => {
  const { t } = useTranslation()
  const { dropdownItems, ...propsButton } = props
  return (
    <div className={dropdownItems.length > 0 ? `btn-group ${props.className}` : `${props.className}`}>
      <Button {...propsButton} blockLevel={true} spacing={{ vertical: false, horizontal: false }} />
      {(dropdownItems.length > 0 && (
        <>
          <button
            type="button"
            className={`btn btn-sm btn-${props.toggleVariant} dropdown-toggle dropdown-toggle-split`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="sr-only">{t('mcs.actions.toggleDropdown')}</span>
          </button>
          <div className={`dropdown-menu`}>
            {dropdownItems.map((value, index) => {
              return (
                <button
                  key={index}
                  disabled={value.disabled}
                  className={`dropdown-item ${value.variant}`}
                  onClick={value.onClick}
                >
                  {value.label}
                </button>
              )
            })}
          </div>
        </>
      )) ||
        null}
    </div>
  )
}

export default ButtonWithToggle
