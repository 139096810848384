// * ------------------- COMPONENTS -----------------------------
import { ITranslationService } from '../../mvlabs-components-fe/services/translation'
import { mvDate, REQUEST_FORMAT } from '../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import { AssetConnection, RecipeMismatch, Warning, WarningsType } from './warning'

export interface ISlot {
  day: string
  from: string
  to: string
  completed: boolean
  confirmed: boolean
  mismatchPercentage: number 
  events: Warning | null
}

class Slot {
  get warning(): Warning | null {
    if(this.allMismatchesWarning.length > 0 || this.allConnectionWarning.length > 0) {
      return this._warning
    }
    return null
  }

  get allWarnings(): WarningsType[] {
    const allWarning: WarningsType[] = []
    return allWarning.concat(this.allMismatchesWarning).concat(this.allConnectionWarning)
  }

  get allEvents(): WarningsType[] {
    const allWarning: WarningsType[] = []
    return allWarning.concat(this.allExecution).concat(this.allConnectionWarning)
  }

  get allMismatchesWarning(): RecipeMismatch[] {
    const mismatches: RecipeMismatch[] = []
    if(this._warning && this._warning.execution) {
      return mismatches.concat(this._warning.execution.filter(e => e.isMismatch))
    }
    return mismatches
  }

  get allExecution(): RecipeMismatch[] {
    const mismatches: RecipeMismatch[] = []
    if(this._warning && this._warning.execution) {
      return mismatches.concat(this._warning.execution)
    }
    return mismatches
  }

  get allConnectionWarning(): AssetConnection[] {
    const connections: AssetConnection[] = []
    if(this._warning && this._warning.connections) {
      return connections.concat(this._warning.connections)
    }
    return connections
  }

  get from(): Date {
    return this._from
  }

  get to(): Date {
    return this._to
  }

  get day(): Date {
    return this._day
  }

  get completed(): boolean {
    return this._completed
  }

  get confirmed(): boolean {
    return this._confirmed
  }

  get mismatchPercentage() : number {
    return this._mismatchPercentage
  }

  get interface(): ISlot {
    return {
      day: this.day.toString(),
      to: this.to.toString(),
      from: this.from.toString(),
      events: this.warning,
      completed: this.completed,
      confirmed: this.confirmed,
      mismatchPercentage: this.mismatchPercentage
    }
  }

  public getDisplayName(ts: ITranslationService): string {
    return ts.t('recipeControl.emptySlot')
  }

  private readonly _day: Date
  private readonly _from: Date
  private readonly _to: Date
  private readonly _completed: boolean
  private readonly _confirmed: boolean
  private readonly _warning: Warning | null
  private readonly _mismatchPercentage: number

  constructor(iSlot: Partial<ISlot>) {
    if (!iSlot.day || !iSlot.from || !iSlot.to) {
      throw new Error()
    }
    this._day = mvDate.getDateFromString(iSlot.day)
    this._from = mvDate.getDateFromString(iSlot.from)
    this._to = mvDate.getDateFromString(iSlot.to)
    this._completed = iSlot.completed || false
    this._confirmed = iSlot.confirmed || false
    this._warning = this.parseWarning(iSlot.events || null)
    this._mismatchPercentage = iSlot.mismatchPercentage || 0
  }

  private parseWarning(iWarning: Warning | null): Warning | null {
    if(!iWarning) {
      return null
    }
    return {
      ...iWarning,
      connections: iWarning.connections ? iWarning.connections.map(c => ({...c,type: 'connection'})) : [],
      execution: iWarning.execution ? iWarning.execution.map(c => ({...c,type: 'recipe-mismatch'})) : []
    }
  }  

  public isBeforeThen(date: Date) {
    return mvDate.isSameOrBeforeMinute(this.from, date)
  }

  public isAfterThen(date: Date) {
    return mvDate.isSameOrAfterMinute(this.to, date)
  }

  public isCurrentSlot() {
    const now = mvDate.now()
    return this.isBeforeThen(now) && this.isAfterThen(now)
  }
  
  public copyWith = (
    props?: Partial<{
      day: Date
      to: Date
      from: Date
      completed: boolean
      confirmed: boolean
      events: Warning
    }>
  ): Slot => {
    return new Slot({
      day: props && props.day ? props.day.toString() : this._day.toString(),
      to: props && props.to ? props.to.toString() : this._to.toString(),
      from: props && props.from ? props.from.toString() : this._from.toString(),
      completed: props && props.completed !== undefined ? props.completed : this.completed,
      confirmed: props && props.confirmed !== undefined ? props.confirmed : this.confirmed,
      events: (props && props.events) || this._warning,
    })
  }

  public get body(): string {
    return JSON.stringify({
      from: mvDate.format(this.from, REQUEST_FORMAT),
      to: mvDate.format(this.to, REQUEST_FORMAT),
    })
  }
}

export default Slot
