import { AnyAction } from 'redux'
import Filters from '../../types/Filters'
import * as moment from 'moment'

const filtersInitialState: Filters = {
  date: moment(),
  direction: 'incoming',
}

export const filters = (state = filtersInitialState, action: AnyAction) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      try {
        return {
          ...state,
          date: action.payload.mcs.filters.date ? moment(action.payload.mcs.filters.date) : state.date,
          direction: action.payload.mcs.filters.direction ? action.payload.mcs.filters.direction : state.direction
        }
      } catch {
        return state
      }
    case 'MCS_FILTERS_CHANGE_DATE':
      return {
        ...state,
        date: action.payload,
      }
    case 'MCS_FILTERS_CHANGE_DIRECTION':
      return {
        ...state,
        direction: action.payload,
      }
    default:
      return state
  }
}

export default filters
