import * as moment from 'moment'
import Material from './Material'
import { ConfigManager } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export const closedCargoStatus: CargoStatus[] = ['canceled', 'concluded', 'unprocessed']
export const cargoBlockingStatus: CargoStatus[] = [
  'in_queue',
  'under_processing',
  'ready_to_leave',
  'in_load_queue',
  'in_unload_queue',
  'under_load_processing',
  'under_unload_processing',
  'ready_to_transfer',
]

type CargoStatus =
  | 'pending_arrival'
  | 'blocked_for_irregularities'
  | 'canceled'
  | 'concluded'
  | 'in_queue'
  | 'in_load_queue' // only transfer
  | 'in_unload_queue' // only transfer
  | 'ready_to_leave'
  | 'requeued_with_priority'
  | 'requeued_with_priority_load' // only transfer
  | 'requeued_with_priority_unload' // only transfer
  | 'under_processing'
  | 'under_load_processing' // only transfer
  | 'under_unload_processing' // only transfer
  | 'unprocessed'
  | 'ready_to_transfer'

type CargoActions =
  | 'welcome'
  | 'block'
  | 'unblock'
  | 'edit'
  | 'edit_today_concluded'
  | 'manage'
  | 'conclude_manage'
  | 'checkout'
  | 'requeue'
  | 'requeue_priority'
  | 'under_processing'
  | 'reschedule'
  | 'summary'

export const possibleDirections = (configManager: ConfigManager): CargoDirections[] => {
  const defaultDirections: CargoDirections[] = ['incoming', 'outgoing']
  if (configManager.transfer.enable) {
    defaultDirections.push('transfer')
  }
  return defaultDirections
}
export const allPossibleDirections: CargoDirections[] = ['incoming', 'outgoing', 'transfer']
export type CargoDirections = 'incoming' | 'outgoing' | 'transfer'

export type CargoList = {
  [key in CargoDirections]: CargoBE[]
}

interface CargoBE {
  id?: string // TODO togliere il punto di domanda
  materialType?: Material
  progressiveNumber?: string | null
  producer?: string
  recipient?: string
  ticketNumber?: string | null
  carrier: string
  status: CargoStatus
  basin?: string | null
  samplingRequired: boolean | null
  samplingConfirmed: boolean | null
  note?: string
  packages?: number
  parking?: string
  plate?: string
  arrivalDate: moment.Moment | string
  rescheduledDate?: string | null
  forkliftDriverDisplayName?: string | null
  forkliftDriverId?: string | null
  forkliftDriverNote?: string | null
  queueEnteringAt: string
  queueExitingAt: string
  materialAnomalies?: string
  handledWeightKg?: number
  truckAnomalies?: string
  images?: string[]
  unit: string
}

interface Cargo {
  id?: string
  materialType?: Material
  progressiveNumber?: string | null
  producer?: string
  recipient?: string
  ticketNumber: string | null
  carrier: string
  status: CargoStatus
  basin: string | null
  samplingRequired: boolean
  samplingConfirmed: boolean | null
  note?: string
  packages?: number
  parking?: string
  plate?: string
  arrivalDate: moment.Moment
  rescheduledDate: moment.Moment | null
  forkliftDriverDisplayName: string | null
  forkliftDriverId: string | null
  forkliftDriverNote: string | null
  queueEnteringAt: moment.Moment | null
  queueExitingAt: moment.Moment | null
  invalidDocuments?: boolean
  materialAnomalies?: string
  truckAnomalies?: string
  handledWeightKg: number
  readonly images: string[]
  direction: CargoDirections
  unit: string
}

const cargoBeToCargo = (data: Partial<CargoBE>, direction: CargoDirections): Cargo => {
  return {
    id: data ? data.id : undefined,
    materialType: data ? data.materialType : undefined,
    progressiveNumber: data ? data.progressiveNumber : null,
    producer: data ? data.producer : '',
    recipient: data ? data.recipient : '',
    ticketNumber: (data && data.ticketNumber) || null,
    carrier: (data && data.carrier) || '',
    status: (data && data.status) || 'pending_arrival',
    basin: (data && data.basin) || null,
    samplingRequired:
      data && data.samplingRequired !== undefined && data.samplingRequired !== null ? data.samplingRequired : false,
    samplingConfirmed: data && data.samplingConfirmed !== undefined ? data.samplingConfirmed : null,
    note: data ? data.note : undefined,
    packages: data ? data.packages : undefined,
    parking: data ? data.parking : undefined,
    plate: data ? data.plate : undefined,
    arrivalDate: (data && data.arrivalDate && moment(data.arrivalDate)) || moment(),
    rescheduledDate: (data && data.rescheduledDate && moment(data.rescheduledDate)) || null,
    forkliftDriverDisplayName: (data && data.forkliftDriverDisplayName) || '',
    forkliftDriverId: (data && data.forkliftDriverId) || null,
    forkliftDriverNote: (data && data.forkliftDriverNote) || '',
    queueEnteringAt: (data && data.queueEnteringAt && moment(data.queueEnteringAt)) || null,
    queueExitingAt: (data && data.queueExitingAt && moment(data.queueExitingAt)) || null,
    materialAnomalies: data ? data.materialAnomalies : '',
    truckAnomalies: data ? data.truckAnomalies : '',
    images: data && data.images ? data.images : [],
    direction,
    unit: data && data.unit ? data.unit : '',
    handledWeightKg: (data && data.handledWeightKg) || 0,
    invalidDocuments: (data && data.truckAnomalies && data.truckAnomalies.includes('Documenti mancanti')) || false,
  }
}

export interface CargoSelected {
  [cargoId: string]: boolean
}

export { Cargo, CargoBE, CargoStatus, CargoActions, cargoBeToCargo }

export type CargoSlugs =
  | 'ticketNumber'
  | 'producer'
  | 'recipient'
  | 'carrier'
  | 'plate'
  | 'parking'
  | 'basin'
  | 'note'
  | 'unit'
  | 'invalidDocuments'
  | 'handledWeightKg'
