// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf'

// ------------------------- COMPONENTS --------------------------------------
import HeaderPage from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/ComponentsPastTodayFuture/HeaderPage'
import CargoTable from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/CargoTable'

// ------------------------- MODULE --------------------------------------
import UpperPageActions from '../../widgets/UpperPageActions/UpperPageActions'
import { CommonStore } from '../../../../types/Store'
import Filters from '../../../../types/Filters'
import SummaryCargoModal from '../../widgets/Modals/SummaryCargoModal'
import { ModalState } from '@mv-submodules/inplant-mcs-fe-iblu/types/Modals'
import { Cargo, CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { PeriodProps } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/views/MCSRoutes/MCSRouterPageView'
import { downloadCargoCSV } from '../../../../redux/actions/cargoDirections'

library.add(faCircleNotch, faInfoCircle, faEyeDropper, faHourglassHalf)

interface StateProps {
  filters: Filters
  common: CommonStore
}

interface OwnProps extends RouteComponentProps<any> {}

interface OwnState {
  showModal: ModalState
  isLoadingCSV: boolean
}

interface DispatchProps {
  concludedCargoCSV: (direction: CargoDirections, date: moment.Moment) => Promise<string>
}

type Props = StateProps & OwnProps & WithTranslation & PeriodProps & DispatchProps

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    concludedCargoCSV: (direction: CargoDirections, date: moment.Moment) => dispatch(downloadCargoCSV(direction, date)),
  }
}

class PastPageView extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: { visible: false },
      isLoadingCSV: false,
    }
  }

  private handleCloseModal = () => {
    this.setState({ showModal: { visible: false } })
  }

  private mainAction = (type: string, cargoId: string) => {
    switch (type) {
      case 'summary':
        this.setState({ showModal: { visible: true, type: 'summary', cargoId } })
        break
    }
  }

  private exportCSVFile = async (): Promise<void> => {
    this.setState({
      isLoadingCSV: true,
    })
    const result = await this.props.concludedCargoCSV(this.props.filters.direction, this.props.date)
    const blob = new Blob([result])
    const objectURL = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = objectURL
    link.download = `concluded-trucks-${this.props.date.format('YYYY-MM-DD')}-${this.props.filters.direction}.csv`
    link.click()
    this.setState({
      isLoadingCSV: false,
    })
  }

  private renderModals = () => {
    if (this.state.showModal.visible) {
      switch (this.state.showModal.type) {
        case 'summary':
          return <SummaryCargoModal cargoId={this.state.showModal.cargoId} onClose={this.handleCloseModal} />
      }
    }
    return <></>
  }

  private renderContent = () => {
    const cargos = this.props.cargos.filter(c => c.direction === this.props.filters.direction)
    const canceledCargos = cargos.filter((cargo: Cargo) => cargo.status === 'canceled')
    const rescheduledCargos = cargos.filter((cargo: Cargo) => cargo.rescheduledDate)
    const concludedCargos = cargos.filter((cargo: Cargo) => cargo.status === 'concluded' && !cargo.rescheduledDate)
    const unprocessedCargos = cargos.filter(
      (cargo: Cargo) => cargo.status !== 'concluded' && cargo.status !== 'canceled' && !cargo.rescheduledDate
    )

    return (
      <>
        <UpperPageActions allCargos={this.props.cargos} refreshData={() => this.props.fetchData(true)} />
        {!this.props.isFetchingCargos &&
        canceledCargos.length === 0 &&
        rescheduledCargos.length === 0 &&
        concludedCargos.length === 0 &&
        unprocessedCargos.length === 0 ? (
          <p>{this.props.t('mcs.archive.noCargosMessage')}</p>
        ) : (
          <React.Fragment>
            {canceledCargos.length > 0 || this.props.isFetchingCargos ? (
              <>
                <div className="canceled-cargos">
                  <CargoTable
                    title={this.props.t('mcs.archive.canceledCargos')}
                    data={canceledCargos}
                    isLoading={this.props.isFetchingCargos}
                    type={{ type: 'past', subType: 'canceled' }}
                    direction={this.props.filters.direction}
                    onAction={() => {
                      console.log('No Action') //tslint:disable-line
                    }}
                  />
                </div>
              </>
            ) : null}
            {rescheduledCargos.length > 0 || this.props.isFetchingCargos ? (
              <div className="rescheduled-cargos">
                <CargoTable
                  title={this.props.t('mcs.archive.rescheduledCargos')}
                  data={rescheduledCargos}
                  isLoading={this.props.isFetchingCargos}
                  type={{ type: 'past', subType: 'rescheduled' }}
                  direction={this.props.filters.direction}
                  onAction={() => {
                    console.log('No Action') //tslint:disable-line
                  }}
                />
              </div>
            ) : null}
            {concludedCargos.length > 0 || this.props.isFetchingCargos ? (
              <div className="concluded-cargos">
                <CargoTable
                  title={this.props.t('mcs.archive.concludedCargos')}
                  data={concludedCargos}
                  exportCSVButton={{
                    action: this.exportCSVFile,
                    isLoading: this.state.isLoadingCSV,
                  }}
                  isLoading={this.props.isFetchingCargos}
                  type={{ type: 'past', subType: 'concluded' }}
                  direction={this.props.filters.direction}
                  onAction={({ type, cargo }) => {
                    this.mainAction(type, cargo.id || '')
                  }}
                />
              </div>
            ) : null}
            {unprocessedCargos.length > 0 || this.props.isFetchingCargos ? (
              <div className="unprocessed-cargos">
                <CargoTable
                  title={this.props.t('mcs.archive.unprocessedCargos')}
                  data={unprocessedCargos}
                  isLoading={this.props.isFetchingCargos}
                  type={{ type: 'past', subType: 'unprocessed' }}
                  direction={this.props.filters.direction}
                  onAction={({ type, cargo }) => {
                    this.mainAction(type, cargo.id || '')
                  }}
                />
              </div>
            ) : null}
          </React.Fragment>
        )}
      </>
    )
  }

  public render() {
    return (
      <div className="mv4iot-fe-mcs">
        <HeaderPage date={this.props.filters.date} title={'archive'} />
        <div className="content">
          {this.renderModals()}
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PastPageView)))
