// * ------------------- NPM -----------------------------
import React, { useReducer, useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'

// * ------------------- COMPONENTS -----------------------------
import Input from '../../../../../mvlabs-components-fe/ui/components/Input/Input'
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import SelectComponent from '../../../../../mvlabs-components-fe/ui/components/Input/PrivateComponents/SelectComponent'
import SimpleTable from '../../../../../mvlabs-components-fe/ui/components/Table/SimpleTable'
import { CheckboxItem } from '@mv-submodules/mvlabs-components-fe/ui/components/Input/Checkbox'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'

// * ------------------- MODULE -----------------------------
import Recipe from '../../../../types/recipe'
import { Asset } from '../../../../types/asset'
import { Mapping } from '../../../../types/mapping'
import { RecipeGroup } from '../../../../types/recipeGroup'
import { createConfiguration, editConfiguration} from '../../../../redux/action/configurationActions'

interface Props extends WithTranslation {
  mode: { type: 'edit'; data: RecipeGroup } | { type: 'create' }
  onClose: () => void
  onActionComplete: () => void
  assets: Asset[]
  recipes: Recipe[]
}

interface Data {
  asset: Asset
  selected: boolean
  recipeId?: string
  initialRecipeId?: string
  possibleRecipes: Recipe[]
}

const ModalNewConfiguration = (props: Props) => {
  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT --------------------------
  // * -----------------------------------------------------------------------
  const { mode, translation, onClose, assets, recipes, onActionComplete } = props
  const { t } = translation
  const base = translation.base + '.newConfiguration'

  // * -----------------------------------------------------------------------
  // * ---------------------------- HOOKs --------------------------
  // * -----------------------------------------------------------------------
  const reduxDispatch = useDispatch()
  const [state, dispatch] = useReducer(
    reducer,
    {
      assets,
      recipes,
      data: mode.type === 'edit' ? mode.data : undefined,
    },
    init
  )
  const [name, setName] = useState<string>(props.mode.type === 'edit' ? props.mode.data.displayName : '')
  const [isLoading, setIsLoading] = useState(false)

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------
  const handleSubmit = () => {
    setIsLoading(true)
    const mappings = Object.values(state).reduce((acc: Mapping[], s) => {
      if (s.selected && s.recipeId) {
        return [...acc, new Mapping({ assetId: s.asset.id, recipeId: s.recipeId })]
      }
      return acc
    }, [])
    // create
    let promise: (dispatch: Dispatch) => Promise<void> = createConfiguration({
      displayName: name,
      mappings,
    })

    // edit
    if (mode.type === 'edit') {
      promise = editConfiguration(
        mode.data.copyWith({
          displayName: name,
          mappings,
        })
      )
    }
    promise(reduxDispatch)
      .then(() => {
        setIsLoading(false)
        onActionComplete()
        return
      })
      .catch(() => setIsLoading(false))
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs --------------------------
  // * -----------------------------------------------------------------------
  const data = Object.values(state)
  return (
    <Modal
      rightFooterContents={[
        {
          isDisable:
            !name ||
            data.filter(d => d.recipeId === undefined && d.selected).length > 0 ||
            data.filter(d => d.selected).length === 0,
          isLoading,
          label: t(`${base}.save`),
          semantic: 'primary',
          kind: 'button',
          onClick: handleSubmit,
        },
      ]}
      title={t(`${base}.title.${mode.type}`, { value: props.mode.type === 'edit' ? props.mode.data.displayName : '' })}
      onClose={onClose}
      visible={true}
    >
      <SectionTitle title={t(`${base}.info`)} />
      <Input kind={'input'} label={t(`${base}.name.label`)} required={true} onChange={setName} initialValue={name} />
      <SectionTitle title={t(`${base}.list`)} />
      <SimpleTable<Data>
        data={data}
        columns={[
          {
            cell: d => (
              <CheckboxItem
                initialSelected={d.selected}
                value={'val'}
                onChange={value => {
                  dispatch({ type: 'SET_CHECKBOX', data: { assetId: d.asset.id, selected: value } })
                }}
              />
            ),
            title: '',
          },
          { cell: d => d.asset.displayName, title: t(`${base}.machineName`) },
          {
            cell: d => (
              <SelectComponent
                key={d.asset.id}
                disabled={!d.selected}
                validity={''}
                onChange={value => {
                  dispatch({ type: 'SET_RECIPE', data: { recipeId: value, assetId: d.asset.id } })
                }}
                kind={'select'}
                type={'singleSelect'}
                options={{
                  defaultOption: (d.initialRecipeId && { disable: false, value: d.initialRecipeId }) || {
                    disable: true,
                    label: t(`${base}.selectNoValue`),
                    value: '',
                  },
                  items: d.possibleRecipes.map(r => ({ value: r.id, label: r.displayName })),
                }}
              />
            ),
            title: t(`${base}.recipeName`),
          },
        ]}
      />
    </Modal>
  )
}

// * -----------------------------------------------------------------------
// * ---------------------------- REDUCER --------------------------
// * -----------------------------------------------------------------------
// init
function init(initialState: { assets: Asset[]; recipes: Recipe[]; data?: RecipeGroup }): { [key: string]: Data } {
  const { assets, recipes, data } = initialState
  const mappingAsKeyValue = data?.getMappingAsKeyValue() || {}

  return assets.reduce((acc: { [key: string]: Data }, curr) => {
    const initialRecipeId = mappingAsKeyValue?.[curr.id]?.getRecipe(recipes)?.id || undefined
    return {
      ...acc,
      [curr.id]: {
        asset: curr,
        selected: mappingAsKeyValue?.[curr.id] !== undefined ?? false,
        recipeId: initialRecipeId,
        initialRecipeId,
        possibleRecipes: curr.recipes,
      },
    }
  }, {})
}

// actions
interface BaseAction {
  type: 'SET_CHECKBOX' | 'SET_RECIPE'
}

interface CheckboxAction extends BaseAction {
  type: 'SET_CHECKBOX'
  data: { assetId: string; selected: boolean }
}

interface RecipeAction extends BaseAction {
  type: 'SET_RECIPE'
  data: { assetId: string; recipeId: string }
}

type ActionType = CheckboxAction | RecipeAction

// reducer
function reducer(state: { [key: string]: Data }, action: ActionType): { [key: string]: Data } {
  switch (action.type) {
    case 'SET_CHECKBOX':
      return {
        ...state,
        [action.data.assetId]: {
          ...state[action.data.assetId],
          selected: action.data.selected,
        },
      }
    case 'SET_RECIPE':
      return {
        ...state,
        [action.data.assetId]: {
          ...state[action.data.assetId],
          recipeId: action.data.recipeId,
        },
      }
    default:
      return state
  }
}

export default ModalNewConfiguration
