import React, { useEffect, useState } from 'react'
import { DAY_MONTH_YEAR, mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useLazyGenericFetchHook, useStateValidity } from '../../../../../mvlabs-components-fe/functions/hooks'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services'
import { Alert, Modal, Radio, StretchColumn } from '../../../../../mvlabs-components-fe/ui/components'
import { ActionType, overrideCurrentProcess } from '../../../../redux/action/plannerActions'
import Configuration from '../../../../types/configuration'
import { EmptySlot } from '../../../../types/emptySlot'
import Halt from '../../../../types/halt'
import Process from '../../../../types/process'
import Shift from '../../../../types/shift'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import RecipeSelect from '../FormComponents/RecipeSelect'
import { customPropertiesObject, reduceCustomProperties } from '../../../../functions/customProperties'
import GenericInputComponent from '../FormComponents/GenericInputComponent'
import { FormData as FormDataRecipes } from '../../../../types/formData'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { getInstallationBody } from '../../../../functions'

interface Props {
  configuration: Configuration
  visible: boolean
  onClose: () => void
  updateShifts: (shifts: Shift[]) => void
  process: Halt | Process | EmptySlot
  shift: Shift
}

const ModalOverrideCurrentProcess = (props: Props) => {
  const { visible, onClose, process, shift, configuration, updateShifts } = props
  const { overrideAdditionalFormData } = configuration
  const { t } = useComponentsTranslation()
  const isProcess = process instanceof Process
  // const isHalt = process instanceof Halt
  // const isEmptySlot = process instanceof EmptySlot

  const [activity, setActivity] = useState<ActionType>(process instanceof Halt ? ActionType.halt : ActionType.process)
  const [, addDataValidity, isValid] = useStateValidity({})

  const [customProprieties, setCustomProprieties] = useState<{ [key: string]: any }>(
    (isProcess && customPropertiesObject(process.interface)) || {}
  )

  const [recipeGroupId, setRecipeGroupId] = useState<string | null>(
    process instanceof Process ? process.recipeGroup.id : null
  )

  useEffect(() => {
    if(activity === ActionType.halt) {
      setRecipeGroupId(null)
    }
  }, [recipeGroupId, activity])

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------

  const handleChangeInput = (slug: string, value: any) => {
    setCustomProprieties(prev => ({ ...prev, [slug]: value }))
  }

  const [execOverrideCurrentProcess, { loading }] = useLazyGenericFetchHook(
    overrideCurrentProcess,
    data => {
      updateShifts(data)
      onClose()
    },
    e => {
      console.warn(e) //tslint:disable-line
    }
  )

  const base = `recipeControl.modalOverrideCurrentSlot`

  function handleSubmit() {
    execOverrideCurrentProcess({
      recipeGroupId,
      ...getInstallationBody(),
      ...reduceCustomProperties(customProprieties)
    })
  }

  const getInput = (slug: string): any => {
    return customProprieties?.[slug] || ''
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs ---------------------------------
  // * -----------------------------------------------------------------------

  const renderGenericInput = (formData: FormDataRecipes) => {
    return (
      <GenericInputComponent onChangeState={addDataValidity} formData={formData} initialValue={getInput(formData.slug)} onChange={handleChangeInput} />
    )
  }

  const renderProcessForm = () => {
    const proc: Process | undefined = isProcess ? process : undefined
    return (
      <>
        <RecipeSelect onChange={setRecipeGroupId} process={proc} configuration={configuration} />
        {overrideAdditionalFormData.process.map(fd => (
          <React.Fragment key={fd.slug}>{renderGenericInput(fd)}</React.Fragment>
        ))}
      </>
    )
  }

  const renderHaltForm = () => {
    return overrideAdditionalFormData.halt.map(fd => <React.Fragment key={fd.slug}>{renderGenericInput(fd)}</React.Fragment>)
  }

  return (
    <Modal
      visible={visible}
      title={`${t(`${base}.title`)} ${mvDate.format(shift.day, DAY_MONTH_YEAR)}, ${shift.name}`}
      onClose={onClose}
      rightFooterContents={[
        {
          kind: 'button',
          semantic: 'primary',
          onClick: () => handleSubmit(),
          isLoading: loading,
          isDisable: !isValid || loading,
          label: `${t(`${base}.footer.overrideActivity`)}`,
        },
      ]}
    >
      <StretchColumn spaceSize={'md'}>
        <Text text={`|**${t(`${base}.form.infoProcess`)}**|`} />

        <Text
          text={`${t(`${base}.newHours`, {
            from: mvDate.format(mvDate.now(), 'HH:mm'),
            to: mvDate.format(
              process.to,
              'HH:mm'
            )
          })}`}
        />
        {configuration.haltsEnabled && (
          <Radio
            onChange={value => {
              setActivity(value as ActionType)
            }}
            items={[
              { label: t(`${base}.form.radio.${ActionType.process}`), value: ActionType.process },
              { label: t(`${base}.form.radio.${ActionType.halt}`), value: ActionType.halt },
            ]}
            initialValueSelected={activity}
          />
        )}
        {activity === ActionType.process ? renderProcessForm() : renderHaltForm()}
        {/* <CountDownSlot processType={'current'} process={process} /> */}
        {/*         <Text
          text={`${t(`${tStart}.newHours`)} |\n| |**${mvDate.format(mvDate.now(), 'HH-mm')} - ${mvDate.format(
            process.to,
            'HH-mm'
          )}**|`}
        /> */}
        <Alert variant={'info'} text={t(`${base}.banner`)} alignSelf={AlignSelf.stretch} />
      </StretchColumn>
    </Modal>
  )
}

export default ModalOverrideCurrentProcess
