import { CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'

export interface MaterialBE {
  name:
    | 'cit'
    | 'multi'
    | 'ctl'
    | 'cta'
    | 'ctc'
    | 'film'
    | 'mpoc'
    | 'fils'
    | 'iron'
    | 'aluminium'
    | 'iron-wire'
    | 'cte'
    | 'plasmix-end-line'
    | 'plasmix-bulky'
    | 'plasmix-end'
    | 'mpof'
  requiresTicket: boolean
  parking: string
  enabled: boolean
  description: string
}

export const materialBEToMaterial = (m:MaterialBE,cargoDirection:CargoDirections):Material =>  {
  return {
    name: m.name,
    requiresTicket: m.requiresTicket || false,
    parking: m.parking,
    enabled: m.enabled,
    description: m.description,
    direction: cargoDirection
  }
}

interface Material {
  name:
    | 'cit'
    | 'multi'
    | 'ctl'
    | 'cta'
    | 'ctc'
    | 'film'
    | 'mpoc'
    | 'fils'
    | 'iron'
    | 'aluminium'
    | 'iron-wire'
    | 'cte'
    | 'plasmix-end-line'
    | 'plasmix-bulky'
    | 'plasmix-end'
    | 'mpof'
  requiresTicket: boolean
  parking: string
  enabled: boolean
  description: string
  direction: CargoDirections
}



export default Material
