import { IActivity } from '../types/activity'

// some params are given from BE the others are remove from the custom set (from,to,recipe)
export function customPropertiesObject(act: IActivity): { [key: string]: any } {
  const toRemove = ['from', 'to', 'recipe']
  return Object.entries(act).reduce((acc, [key, value]) => {
    if (!toRemove.includes(key)) {
      return { ...acc, [key]: value }
    }
    return acc
  }, {})
}

export function reduceCustomProperties(customProprieties: Record<string, any>): Record<string, any> {
  return Object.entries(customProprieties).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}