// -------------------------- NPM -------------------
import * as React from 'react'
import { connect } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import AlertErrorData from '../../../../../mvlabs-components-fe/ui/components/Alert/AlertErrorData'
import CenterLoader from '../../../../../mvlabs-components-fe/ui/components/Loaders/CenterLoader'
import Flex, {
  AlignContent,
  AlignItems,
  Direction,
  Fit,
  JustifyContent,
  StretchColumn,
} from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import FlexItem, { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { generateSize, SizeType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'
import { groupBy } from '../../../../../mvlabs-components-fe/functions/helpers/objectHelper'
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { showNotification } from '../../../../../mvlabs-components-fe/ui/components/Notification/Notification'

// * -------------------------------- MODULE --------------------------------------
import Activity from '../../../../types/activity'
import Configuration from '../../../../types/configuration'
import Halt from '../../../../types/halt'
import ManualShiftController from '../ManualShiftController/ManualShiftController'
import ModalCompare from '../ModalCompare/ModalCompare'
import ModalManageFactory from '../ModalEdit/ModalManageFactory'
import ModalSlotDetail from '../ModalSlotDetail/ModalSlotDetail'
import ModalWarning from '../ModalWarning/ModalWarning'
import PlannerBanners from './PlannerBanners'
import PlannerDates from './PlannerDates'
import PlannerHead from './PlannerHead'
import PlannerSlotFactory from '../PlannerSlotFactory/PlannerSlotFactory'
import PlannerTurnBanner from './PlannerShiftBanner'
import Process from '../../../../types/process'
import Shift from '../../../../types/shift'
import Slot from '../../../../types/slot'
import WithConfigManager from '../ConfigManagerController/ConfigManagerConnector'
import {
  ActionType,
  createProcess,
  deleteProcess,
  editProcess,
  plannerTurns,
} from '../../../../redux/action/plannerActions'
import { DataToCreateProcess } from '../../../../types/others'
import { EmptySlot } from '../../../../types/emptySlot'
import { PlannerViewTypes } from '../../../../types/viewType'
import { Warning } from '../../../../types/warning'
import { canReadSlotDetail, isSlotEditable, isSlotOverride } from '../../../../functions/slot'
import ModalOverrideCurrentProcess from '../ModalOverride/ModalOverrideCurrentProcess'

// * Props
export interface OwnPlannerWidgetProps {
  days: number
  plannerViewType: PlannerViewTypes
  plannerConfig: Configuration
  installationSlug?: string
}

interface DispatchProps {
  plannerTurns: (from: Date, to: Date, installationSlug?: string) => Promise<Shift[]>
  createProcess: (data: DataToCreateProcess, type: ActionType) => Promise<Shift[]>
  editProcess: (process: Activity, type: ActionType) => Promise<Shift[]>
  deleteProcess: (process: Activity, type: ActionType) => Promise<Shift[]>
}

type Props = OwnPlannerWidgetProps & WithTranslation & DispatchProps

type ModalState =
  | { visible: false }
  | { visible: true; type: 'edit-slot'; shift: Shift; slot: Slot }
  | { visible: true; type: 'override-slot'; shift: Shift; slot: Slot }
  | { visible: true; type: 'warning'; warning: Warning }
  | { visible: true; type: 'detail-slot'; slot: Slot; shift: Shift }
  | { visible: true; type: 'compare'; slot: Process | Halt | EmptySlot; shift: Shift }

// * State
interface State {
  firstPlannerDate: Date
  isLoading: boolean
  isError: boolean
  shifts: Shift[]
  showModal: ModalState
  timeoutFetchShifts: ReturnType<typeof setInterval> | null
}

/*
 * How is composed the PlannerWidget:
    - Planner Head: Today button and arrow to move
    - Planner Dates: Row with dates of visible days
    - For each Shift in a days there are: 
      - PlannerShiftBanner
      - ShiftComponent
        - every ShiftComponent contains more PlannerSlot
*/
class PlannerWidget extends React.Component<Props, State> {
  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT --------------------------
  // * -----------------------------------------------------------------------
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: false,
      isError: false,
      shifts: [],
      showModal: { visible: false },
      firstPlannerDate: mvDate.now(),
      timeoutFetchShifts: null,
    }
    this.changeInterval = this.changeInterval.bind(this)
    this.updateShifts = this.updateShifts.bind(this)
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- STATE MANAGEMENT --------------------------
  // * -----------------------------------------------------------------------
  public componentDidMount() {
    this.setState({ isLoading: true })
    this.fetchData()
      .then(shifts => {
        this.setState({ shifts, isError: false })
        // if (this.props.plannerViewType === 'compare') {
        //   const result = JSON.parse(this.props.days > 1 ? compareData : compareSingleData).results.map(
        //     (v: IShift) => new Shift(v)
        //   )
        //   this.setState({ shifts: result, isError: false })
        // } else {
        //   this.setState({ shifts, isError: false })
        // }
      })
      .catch(_e => {
        this.setState({ isError: true })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
    const timeout = setInterval(() => {
      this.fetchData()
        .then((results: Shift[]) => {
          // compare stored data with the new one
          if (
            !(results.map(s => JSON.stringify(s)).join('') === this.state.shifts.map(s => JSON.stringify(s)).join(''))
          ) {
            showNotification({
              type: 'info',
              title: this.props.translation.t(`recipeControl.planner.notification.refreshData`),
              uniqueNotification: true,
              slug: 'recipe-planner-update',
            })
          }
          // if (this.props.plannerViewType === 'compare') {
          //   const resultMock = JSON.parse(this.props.days > 1 ? compareData : compareSingleData).results.map(
          //     (v: IShift) => new Shift(v)
          //   )
          //   this.setState({ shifts: resultMock, isError: false })
          // } else {
          //   this.setState({ shifts: results, isError: false })
          // }
          this.setState({ shifts: results, isError: false })
        })
        .catch(_e => {
          this.setState({ isError: true })
        })
      // interval in minute
    }, 5 * 1000 * 60)
    this.setState({ timeoutFetchShifts: timeout })
  }

  public componentWillUnmount() {
    if (this.state.timeoutFetchShifts !== null) {
      clearTimeout(this.state.timeoutFetchShifts)
    }
  }

  private fetchData(): Promise<Shift[]> {
    return this.props.plannerTurns(
      this.state.firstPlannerDate,
      mvDate.addDays(this.state.firstPlannerDate, this.props.days - 1),
      this.props.installationSlug
    )
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------
  private renderVerticalShifts = () => {
    return this.currentDayView()
  }

  private currentDayView = (): boolean => {
    return this.props.days === 1
  }

  /// change interval of dates starting from `from`
  private readonly changeInterval = (from: Date) => {
    this.setState({ isLoading: true })
    this.props
      .plannerTurns(from, mvDate.addDays(from, this.props.days - 1), this.props.installationSlug)
      .then(shifts => {
        this.setState({ firstPlannerDate: from, shifts, isLoading: false })
      })
      .catch(e => {
        console.error('e', e) //tslint:disable-line
      })
  }

  /// update the state with the `updatedShifts`
  private readonly updateShifts = (updatedShifts: Shift[]): void => {
    const newShifts: Shift[] = [...this.state.shifts]
    for (const shift of updatedShifts) {
      const index = newShifts.findIndex(s => s.id === shift.id)
      if (index >= 0) {
        newShifts[index] = shift
      }
    }
    this.setState({ shifts: newShifts })
  }

  // Handle state for showing modal
  private handleClickSlot: (shift: Shift) => ((slot: Slot) => void) | undefined = shift => {
    switch (this.props.plannerViewType) {
      case 'read-only':
        return slot => {
          if (canReadSlotDetail(this.props.plannerViewType, slot)) {
            this.setState({
              showModal: {
                visible: true,
                type: 'detail-slot',
                slot,
                shift,
              },
            })
            return
          }
        }
      case 'attuative':
        return slot => {
          if (isSlotOverride(this.props.plannerConfig, shift, slot)) {
            this.setState({ showModal: { visible: true, type: 'override-slot', shift, slot } })
            return
          }
          if (isSlotEditable(this.props.plannerConfig, shift, slot) && slot instanceof EmptySlot) {
            this.setState({ showModal: { visible: true, type: 'edit-slot', shift, slot } })
            return
          }
          if (canReadSlotDetail(this.props.plannerViewType, slot)) {
            this.setState({
              showModal: {
                visible: true,
                type: 'detail-slot',
                slot,
                shift,
              },
            })
            return
          }
        }
      case 'compare':
        return slot => {
          if (slot instanceof Process || slot instanceof Halt || slot instanceof EmptySlot) {
            const scheduleOverridePresent =
              shift.scheduledOverrides.length > 0 &&
              shift.scheduledOverrides.filter(o => o.isInsideSlot(slot)).length > 0
            if (slot.warning !== null /* && slot.warning.mismatches.length !== 0 */ || scheduleOverridePresent) {
              this.setState({
                showModal: {
                  visible: true,
                  type: 'compare',
                  slot,
                  shift,
                },
              })
              return
            }
          }
          if (slot instanceof Process || slot instanceof Halt || slot instanceof EmptySlot) {
            this.setState({
              showModal: {
                visible: true,
                type: 'detail-slot',
                slot,
                shift,
              },
            })
          }
        }
      case 'manager':
        return slot => {
          if (isSlotOverride(this.props.plannerConfig, shift, slot)) {
            this.setState({ showModal: { visible: true, type: 'override-slot', shift, slot } })
            return
          }
          if (isSlotEditable(this.props.plannerConfig, shift, slot)) {
            this.setState({ showModal: { visible: true, type: 'edit-slot', shift, slot } })
            return
          }
          if (canReadSlotDetail(this.props.plannerViewType, slot)) {
            this.setState({
              showModal: {
                visible: true,
                type: 'detail-slot',
                slot,
                shift,
              },
            })
            return
          }
        }
    }
  }

  private handleCloseModal = () => {
    this.setState({ showModal: { visible: false } })
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs --------------------------
  // * -----------------------------------------------------------------------
  /// create all the visible shifts
  private renderWeekShifts = (shiftsGroupedForDays: { [key: string]: Shift[] }): React.ReactNode[] => {
    const dayShiftComponents: React.ReactNode[] = []
    const renderVerticalShifts = this.renderVerticalShifts()

    let maxShiftDay = 0
    Object.values(shiftsGroupedForDays).forEach(shiftsGrouped => {
      if (shiftsGrouped.length > maxShiftDay) {
        maxShiftDay = shiftsGrouped.length
      }
    })

    for (let i = 0; i < maxShiftDay; i++) {
      const dayShift: Shift[] = Object.values(shiftsGroupedForDays).map(shiftsGrouped => {
        return shiftsGrouped[i]
      })

      dayShiftComponents.push(
        <Flex
          className={`planner__shift ${renderVerticalShifts ? generateSize(SizeType.vHeight, 75) : ''}`}
          direction={Direction.column}
          alignItems={AlignItems.stretch}
          alignContent={AlignContent.stretch}
          grow={renderVerticalShifts ? 1 : 0}
          key={i}
        >
          {dayShift.length !== 0 ? <PlannerTurnBanner shift={dayShift[0]} /> : null}
          {this.renderDayShifts(dayShift, maxShiftDay === 1)}
        </Flex>
      )
    }
    return dayShiftComponents
  }

  /// create the day shifts
  private renderDayShifts = (shifts: Shift[], isOneShiftPerDay?: boolean) => {
    const plannerGridLines: React.ReactNode[] = []
    const plannerShiftSlots: React.ReactNode[] = []

    for (const shift of shifts) {
      let allProcess: Slot[] = shift.process
      allProcess = allProcess.concat(shift.remainingSlots).concat(shift.processHalts)
      allProcess = allProcess.sort((a, b) => {
        return mvDate.isBeforeMinute(a.from, b.from) ? -1 : 1
      })

      const totalMinutes: number = mvDate.differenceInMinutes(shift.to, shift.from)

      plannerShiftSlots.push(
        <FlexItem
          className={`planner__day ${isOneShiftPerDay && 'planner__day--xl'}`}
          key={shift.id}
          grow={1}
          shrink={1}
        >
          <Flex
            direction={Direction.column}
            fit={Fit.oneLine}
            alignContent={AlignContent.stretch}
            alignItems={AlignItems.stretch}
            justifyContent={JustifyContent.evenly}
            spaceSize={'tiny'}
          >
            {allProcess.map((process, index) => {
              const processMinutes: number = mvDate.differenceInMinutes(process.to, process.from)

              return (
                <PlannerSlotFactory
                  days={this.props.days}
                  configuration={this.props.plannerConfig}
                  percentageDimension={(processMinutes / totalMinutes) * 100}
                  recipes={this.props.plannerConfig.recipes}
                  viewType={this.props.plannerViewType}
                  slot={process}
                  minTimeEditProcess={this.props.plannerConfig.minTimeEditProcess}
                  showFullData={this.renderVerticalShifts()}
                  onClick={this.handleClickSlot(shift)}
                  key={index}
                  shift={shift}
                  isOneShiftPerDay={isOneShiftPerDay || false}
                />
              )
            })}
          </Flex>
        </FlexItem>
      )
    }

    if (shifts.length >= 0) {
      const shift = shifts[0]
      let i = 0
      let from = shift.from
      while (mvDate.isBefore(from, shift.to, 'minute')) {
        plannerGridLines.push(<FlexItem grow={1} shrink={1} key={i} className={'grid__line'} />)
        i++
        from = mvDate.addHours(from, 1)
      }
    }

    const baseStyleFromDays = this.props.days > 1 ? 'week' : 'single-day'
    return (
      <Flex
        justifyContent={JustifyContent.evenly}
        fit={Fit.oneLine}
        grow={1}
        alignContent={AlignContent.center}
        className={`planner__${baseStyleFromDays}`}
      >
        <StretchColumn
          className={`${baseStyleFromDays}__grid`}
          justifyContent={JustifyContent.evenly}
          spaceSize={'tiny'}
        >
          {plannerGridLines}
        </StretchColumn>
        {(this.renderVerticalShifts() && (
          <StretchColumn
            alignSelf={AlignSelf.stretch}
            className={`${baseStyleFromDays}__grid`}
            justifyContent={JustifyContent.evenly}
            spaceSize={'tiny'}
          >
            {plannerShiftSlots}
          </StretchColumn>
        )) || <> {plannerShiftSlots}</>}
      </Flex>
    )
  }

  private renderModals() {
    if (!this.state.showModal.visible) {
      return
    }
    if (this.state.showModal.type === 'warning') {
      return <ModalWarning warning={this.state.showModal.warning} onClose={this.handleCloseModal} />
    }
    if (this.state.showModal.type === 'compare') {
      return (
        <ModalCompare
          configuration={this.props.plannerConfig}
          translation={{ t: this.props.translation.t, base: 'recipeControl' }}
          shift={this.state.showModal.shift}
          slot={this.state.showModal.slot}
          onClose={this.handleCloseModal}
        />
      )
    }
    const shift = this.state.showModal.shift
    if (this.state.showModal.type === 'edit-slot') {
      return (
        <ModalManageFactory
          configuration={this.props.plannerConfig}
          visible={true}
          onClose={this.handleCloseModal}
          updateShifts={this.updateShifts}
          process={
            this.state.showModal.slot instanceof Process
              ? { data: this.state.showModal.slot.copyWith({}), kind: 'edit', type: ActionType.process }
              : this.state.showModal.slot instanceof Halt
              ? { data: this.state.showModal.slot.copyWith({}), kind: 'edit', type: ActionType.halt }
              : { data: this.state.showModal.slot.copyWith({}), kind: 'create' }
          }
          shift={shift}
        />
      )
    }
    if (this.state.showModal.type === 'override-slot') {
      return (
        <ModalOverrideCurrentProcess
          configuration={this.props.plannerConfig}
          visible={true}
          onClose={this.handleCloseModal}
          updateShifts={this.updateShifts}
          process={this.state.showModal.slot}
          shift={shift}
        />
      )
    }
    if (this.state.showModal.type === 'detail-slot') {
      return (
        <ModalSlotDetail
          onClose={this.handleCloseModal}
          slot={this.state.showModal.slot}
          onClickEditProcess={process => {
            this.setState({ showModal: { visible: true, type: 'edit-slot', shift, slot: process } })
          }}
          configuration={this.props.plannerConfig}
          viewType={this.props.plannerViewType}
          shift={shift}
        />
      )
    }
  }

  public render() {
    const { isLoading, isError, shifts } = this.state
    if (isLoading) {
      return <CenterLoader />
    }
    if (isError) {
      return <AlertErrorData />
    }

    const shiftsGroupedForDays = groupBy(shifts || [], 'day')
    return (
      <>
        {this.renderModals()}
        <Flex
          direction={Direction.column}
          className={'planner'}
          alignItems={AlignItems.stretch}
          alignContent={AlignContent.stretch}
        >
          {!(this.props.plannerViewType === 'read-only') ? (
            <PlannerHead
              installationSlug={this.props.installationSlug}
              onChangeDate={this.changeInterval}
              dates={Object.keys(shiftsGroupedForDays).map(stringDate => mvDate.getDateFromString(stringDate))}
            />
          ) : null}
          <PlannerBanners
            shifts={shifts}
            shiftsGroupedForDays={shiftsGroupedForDays}
            configuration={this.props.plannerConfig}
            readonly={this.props.plannerViewType === 'read-only' || this.props.plannerViewType === 'compare'}
            onClickWarning={warning => {
              this.setState({ showModal: { visible: true, type: 'warning', warning } })
            }}
          />
          {this.props.plannerConfig.requiresManualShiftUpload && this.props.plannerViewType === 'attuative' && (
            <ManualShiftController
              translation={{ t: this.props.translation.t, base: 'recipeControl.planner' }}
              shiftsGroupedForDays={shiftsGroupedForDays}
              viewTypes={this.props.plannerViewType}
              onConfirmShift={this.updateShifts}
              onOpenModalManualShift={this.handleCloseModal}
              configuration={this.props.plannerConfig}
            />
          )}
          <PlannerDates
            configuration={this.props.plannerConfig}
            shiftsGroupedForDays={shiftsGroupedForDays}
            viewType={this.props.plannerViewType}
            onConfirmDay={this.updateShifts}
          />
          <Flex
            direction={this.renderVerticalShifts() ? Direction.row : Direction.column}
            alignItems={AlignItems.stretch}
            alignContent={AlignContent.stretch}
            grow={this.renderVerticalShifts() ? 1 : 0}
            spaceSize={'md'}
          >
            {this.renderWeekShifts(shiftsGroupedForDays)}
          </Flex>
        </Flex>
      </>
    )
  }
}

/*
 * -----------------------------------------------------------------------
 * ---------------------------- REDUX ------------------------------------
 * -----------------------------------------------------------------------
 * */
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  plannerTurns: (from, to, installationSlug) => plannerTurns(from, to, installationSlug)(dispatch),
  createProcess: (data, type) => createProcess(data, type)(dispatch),
  editProcess: (process, type) => editProcess(process, type)(dispatch),
  deleteProcess: (process, type) => deleteProcess(process, type)(dispatch),
})

export default WithConfigManager(connect(null, mapDispatchToProps)(PlannerWidget))
