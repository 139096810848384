// * ------------------- COMPONENTS -----------------------------
import { ITranslationService } from '../../mvlabs-components-fe/services/translation'
import { mvDate, REQUEST_FORMAT } from '../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import Activity, { IActivity } from './activity'
import { IRecipeGroup, RecipeGroup } from './recipeGroup'
import { Warning } from './warning'

export interface IProcess extends IActivity {
  id: string
  recipeGroup: IRecipeGroup
  materialBlend?: string
}

class Process extends Activity {
  get recipeGroup(): RecipeGroup {
    return this._recipeGroup.copyWith()
  }

  get materialBlend() {
    return this._materialBlend
  }

  public getDisplayName(ts: ITranslationService): string {
    return this.recipeGroup.displayName
  }

  get interface(): IProcess {
    return {
      id: this.id,
      to: this.to.toString(),
      from: this.from.toString(),
      day: this.day.toString(),
      confirmed: this.confirmed,
      completed: this.completed,
      materialBlend: this.materialBlend,
      recipeGroup: this.recipeGroup.interface,
      events: this.warning,
      mismatchPercentage: this.mismatchPercentage,
    }
  }

  private readonly _recipeGroup: RecipeGroup
  private readonly _materialBlend?: string

  constructor(iProcess: IProcess) {
    super(iProcess)
    this._recipeGroup = new RecipeGroup(iProcess.recipeGroup)
    this._materialBlend = iProcess.materialBlend
  }

  public copyWith = (
    props?: Partial<{
      day: Date
      to: Date
      from: Date
      id: string
      recipe: RecipeGroup
      completed: boolean
      confirmed: boolean
      materialBlend: string
      warning: Warning | null
      isSyncConfirmed: boolean
      mismatchPercentage: number
    }>
  ): Process => {
    return new Process({
      day: props && props.day !== undefined ? props.day.toString() : this.day.toString(),
      to: props && props.to !== undefined ? props.to.toString() : this.to.toString(),
      from: props && props.from !== undefined ? props.from.toString() : this.from.toString(),
      id: props && props.id !== undefined ? props.id : this.id,
      recipeGroup: props && props.recipe !== undefined ? props.recipe.interface : this._recipeGroup.interface,
      completed: props && props.completed !== undefined ? props.completed : this.completed,
      confirmed: props && props.confirmed !== undefined ? props.confirmed : this.confirmed,
      materialBlend: props && (props.materialBlend ?? this._materialBlend),
      events: (props && props.warning) || this.warning,
      mismatchPercentage:
        props && props.mismatchPercentage !== undefined ? props.mismatchPercentage : this.mismatchPercentage,
    })
  }

  public get body(): string {
    return JSON.stringify({
      recipeGroupId: this.recipeGroup.id,
      from: mvDate.format(this.from, REQUEST_FORMAT),
      to: mvDate.format(this.to, REQUEST_FORMAT),
      materialBlend: this.materialBlend,
    })
  }
}

export default Process
