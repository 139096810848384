// ------------------------- NPM --------------------------------------
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as moment from 'moment'

// ------------------------- COMPONENTS --------------------------------------
import Button from '@mv-submodules/inplant-components-fe/ui/components/Button/Button'

interface Props {
  date: moment.Moment
}

const ReturnToDayButton = (props: Props) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { date } = props

  const isDateBeforeToday = () => {
    return date.isBefore(moment(), 'date')
  }

  return (
    <Button
      spacing={{ horizontal: false }}
      variant={'secondary-alternate'}
      onClick={() => history.push(`/mcs/view/${date.format('YYYYMMDD')}`)}
      label={t('mcs.closeDay.returnToDay')}
      disabled={isDateBeforeToday()}
    />
  )
}

export default ReturnToDayButton
