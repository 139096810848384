// * ------------------- MODULE -----------------------------
import Recipe from './recipe'
import { Asset } from './asset'

export interface IMapping {
  assetId: string
  recipeId: string
}

export class Mapping {
  get assetId(): string {
    return this._assetId
  }

  get recipeId(): string {
    return this._recipeId
  }

  get interface(): IMapping {
    return {
      assetId: this.assetId,
      recipeId: this.recipeId,
    }
  }

  constructor(iMapping: Partial<IMapping>) {
    if (!iMapping.recipeId || !iMapping.assetId) {
      throw new Error()
    }
    this._assetId = iMapping.assetId
    this._recipeId = iMapping.recipeId
  }

  private readonly _assetId: string
  private readonly _recipeId: string

  public getRecipe(recipes: Recipe[]): Recipe | undefined {
    return recipes.find(r => this._recipeId === r.id)
  }

  public getAsset(assets: Asset[]): Asset | undefined {
    return assets.find(a => this._assetId === a.id)
  }

  public copyWith(props?: Partial<{ assetId: string; recipeId: string }>): Mapping {
    return new Mapping({
      assetId: props?.assetId || this.assetId,
      recipeId: props?.recipeId || this.recipeId,
    })
  }
}
