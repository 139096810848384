// * ------------------- NPM -----------------------------
import * as React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'

// * -------------------------------- MODULE --------------------------------------
import Shift from '../../../../types/shift'

interface Props {
  shift: Shift
}

const PlannerShiftBanner = (props: Props) => {
  /*
   * -----------------------------------------------------------------------
   * ---------------------------- INIT ------------------------------------
   * -----------------------------------------------------------------------
   * */
  const { shift } = props

  /*
   * -----------------------------------------------------------------------
   * ---------------------------- RENDERS ------------------------------------
   * -----------------------------------------------------------------------
   * */
  return (
    <div className={'planner__shift-head'}>
      <Text text={`|**${shift.name.toUpperCase()}**|`} />
      <Text text={`${mvDate.format(shift.from, TIME)} - ${mvDate.format(shift.to, TIME)}`} />
    </div>
  )
}

export default PlannerShiftBanner
