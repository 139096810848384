// * -------------------------------- COMPONENTS --------------------------------------
import { Icon } from '../../mvlabs-components-fe/services/icon'
import { RouteProps } from '../../inplant-core-fe/types'

// * -------------------------------- MODULE --------------------------------------
import ConfigurationPageView from './components/views/Configuration/ConfigurationPageView'
import PlannerPageView from './components/views/PlannerPageView/PlannerPageView'

function generateRoutes(installationSlug: string, module: Modules, hideWeekCompare?: boolean): RouteProps {
  switch (module) {
    case Modules.plant:
      const pagesPlant = !hideWeekCompare ? [AllPages.planner, AllPages.weekSummary, AllPages.daySummary, AllPages.weekCompare] :  [AllPages.planner, AllPages.weekSummary, AllPages.daySummary]
      return getRoutes(installationSlug, module, pagesPlant)
    case Modules.titech:
      const pagesTitech = !hideWeekCompare ? [AllPages.configuration, AllPages.planner, AllPages.dailyManagement, AllPages.weekCompare] :  [AllPages.configuration, AllPages.planner, AllPages.dailyManagement]
      return getRoutes(installationSlug, module, pagesTitech)
  }
}

function getRoutes(installationSlug: string, module: Modules, pageSlugs: AllPages[]): RouteProps {
  return {
    path: `/${installationSlug}-control/${module === Modules.plant ? AllPages.daySummary : AllPages.dailyManagement}`,
    i18nkey: `recipeControl.module.${installationSlug}`,
    icon: selectIcon(module),
    visible: true,
    children: pageSlugs.reduce((acc: RouteProps[], pageSlug) => {
      return acc.concat(
        {
          path: `/${installationSlug}-control/${pageSlug}`,
          exact: true,
          i18nkey: `recipeControl.navigation.${pageSlug}`,
          component: selectPageToGenerate(pageSlug),
          visible: true,
        },
        ...createDerivatePages(pageSlug),
      )
    }, []),
  }
}

const createDerivatePages = (pageSlug: AllPages): RouteProps[] => {
  switch (pageSlug) {
    default:
      return []
  }
}

const selectIcon = (module: Modules): Icon => {
  switch (module) {
    case Modules.plant:
      return 'table'
    case Modules.titech:
      return 'eye'
  }
}

const selectPageToGenerate = (pageSlug: AllPages) => {
  switch (pageSlug) {
    case AllPages.configuration:
      return ConfigurationPageView
    default:
      return PlannerPageView
  }
}

export enum Modules {
  plant = 'plant',
  titech = 'titech',
}

export enum AllPages {
  planner = 'planner',
  weekSummary = 'week-summary',
  daySummary = 'day-summary',
  configuration = 'configuration',
  dailyManagement = 'daily-management',
  weekCompare = 'week-compare'
}

export default generateRoutes
