// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Input from '../../../../../mvlabs-components-fe/ui/components/Input/Input'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { StateType } from '../../../../../mvlabs-components-fe/functions/hooks/useGenericInputHook'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import { FormData as FormDataRecipes } from '../../../../types/formData'

interface Props {
  formData: FormDataRecipes
  initialValue: string
  onChange: (slug: string, value: string) => void
  onChangeState?: (slug: string, state: StateType) => void
}

const GenericInputComponent = (props: Props) => {
  const { t } = useComponentsTranslation()
  const tStart = 'recipeControl.form'

  const { formData, onChange, initialValue, onChangeState } = props

  const handleChange = (value: string) => {
    onChange(formData.slug, value)
  }

  switch (formData.formType) {
    case 'input':
      return (
        <Input
          required={formData.isRequired}
          alignSelf={AlignSelf.stretch}
          initialValue={initialValue}
          kind={'input'}
          label={t(`${tStart}.${formData.slug}`)}
          onChange={handleChange}
          onChangeState={(stateType) => onChangeState?.(formData.slug,stateType)}
        />
      )
    case 'text-area':
      return (
        <Input
          rows={3}
          required={formData.isRequired}
          alignSelf={AlignSelf.stretch}
          initialValue={initialValue}
          kind={'input'}
          label={t(`${tStart}.${formData.slug}`)}
          onChange={handleChange}
          onChangeState={(stateType) => onChangeState?.(formData.slug,stateType)}
        />
      )
  }
  return null
}

export default GenericInputComponent
