// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import Alert from '../../../../../mvlabs-components-fe/ui/components/Alert/Alert'
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { differenceOf } from "../../../../../mvlabs-components-fe/functions/helpers/arrayHelper";
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'

// * -------------------------------- MODULE --------------------------------------
import Activity from "../../../../types/activity";
import Configuration from '../../../../types/configuration'
import ModalManualShift from './ModalManualShift'
import Shift from '../../../../types/shift'
import Slot from '../../../../types/slot'
import { POLLING_TIME } from "../../../../types/const";
import { PlannerViewTypes } from "../../../../types/viewType";
import { confirmSlot } from "../../../../redux/action/plannerActions";

interface Props {
  viewTypes: PlannerViewTypes
  shiftsGroupedForDays: { [key: string]: Shift[] }
  onConfirmShift: (shifts: Shift[]) => void
  onOpenModalManualShift: () => void
  configuration:Configuration
}

interface StateVisible {
  visible: true
  current: Slot
  next: Slot
}

type State = { visible: false } | StateVisible

/**
 * Every `POLLING_TIME` ms found the current and next slot then show messages to confirm the next slot
 * Check if the current slot is confirmed if not show a banner that request to confirm it
 * @param props
 * @constructor
 */
const ManualShiftController = (props: Props & WithTranslation) => {
  // * -----------------------------------------------------------------------
  // * ---------------------------- HOOks --------------------------
  // * -----------------------------------------------------------------------
  const dispatch = useDispatch()

  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT --------------------------
  // * -----------------------------------------------------------------------
  const {translation,configuration} = props
  const [showModal, setShowModal] = useState<State>({visible: false,})
  const [showBanner, setShowBanner] = useState<State>({visible: false})
  const [isLoadingActionBanner, setIsLoadingActionBanner] = useState<boolean>(false)
  const [nextInterval, setNextInterval] = useState<[Slot | null, Slot | null]>([null, null])
  const timerInterval = useRef<ReturnType<typeof setInterval> | null>(null)
  // * -----------------------------------------------------------------------
  // * ---------------------------- STATE MANAGEMENT --------------------------
  // * -----------------------------------------------------------------------

  useEffect(() => {
    if (props.viewTypes !== "attuative") {
      return
    }

    isRequiredAManualUpload(props.shiftsGroupedForDays)
    timerInterval.current = setInterval(() => {
      isRequiredAManualUpload(props.shiftsGroupedForDays)
    }, POLLING_TIME)
    return () => {
      if (timerInterval.current) {
        clearTimeout(timerInterval.current)
      }
    }
  }, [props.shiftsGroupedForDays])

  useEffect(() => {
    if (showModal.visible) {
      props.onOpenModalManualShift()
    }
  }, [showModal])

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------
  function findNextInterval(processesToCheck: Slot[]): [Slot | null, Slot | null] {
    const now = mvDate.now()
    let current: Slot | null = null
    let next: Slot | null = null
    processesToCheck.forEach(process => {
      if (mvDate.isSameOrAfterMinute(process.to, now) && mvDate.isSameOrBeforeMinute(process.from, now)) {
        current = process.copyWith({})
      }
      if (mvDate.isAfterMinute(process.from, now)) {
        if (!next || mvDate.isBeforeMinute(process.from, next.from)) {
          next = process.copyWith({})
        }
      }
    })
    setNextInterval([current, next])
    return [current, next]
  }

  function isRequiredAManualUpload(shiftsGroupedForDays: { [key: string]: Shift[] }) {

    // shifts are grouped by day, sort the object by date
    const daySorted = Object.entries(shiftsGroupedForDays).sort(([aKey, _aValue], [bKey, _bValue]) =>
      mvDate.isSameOrBefore(mvDate.getDateFromString(aKey), mvDate.getDateFromString(bKey)) ? -1 : 1
    )

    if (daySorted.length >= 1) {
      // today
      const [, today] = daySorted[0]
      let processToCheck = today.reduce(
        (acc: Slot[], curr) =>
          acc
            .concat(curr.processHalts)
            .concat(curr.process)
            .concat(curr.remainingSlots),
        []
      )

      // next day
      if (daySorted.length >= 2) {
        const [, tomorrow] = daySorted[1]
        processToCheck = processToCheck.concat(
          tomorrow.reduce(
            (acc: Slot[], curr) =>
              acc
                .concat(curr.processHalts)
                .concat(curr.process)
                .concat(curr.remainingSlots),
            []
          )
        )
      }
      const [current, next] = findNextInterval(processToCheck)

      if (
        current &&
        next &&
        next instanceof Activity &&
        !next.confirmed && mvDate.isSameOrAfter(mvDate.addMinutes(mvDate.now(), configuration.confirmationConfig.minutesTimingAdvanceBeforeManualUpload),next.from)
      ) {
        setShowModal({visible: true, next, current})
      }
    }
  }

  const handleCloseModal = (state: StateVisible, isToShowBanner: boolean = true) => {
    if (timerInterval.current) {
      clearTimeout(timerInterval.current)
    }
    if (isToShowBanner) {
      setShowBanner({
        visible: true,
        current: state.current,
        next: state.next,
      })
    }

    setShowModal({visible: false})
  }

  const handleConfirmSlot = async (slot: Slot): Promise<boolean> => {
    setIsLoadingActionBanner(true)
    return confirmSlot(slot)(dispatch).then(shifts => {
      setIsLoadingActionBanner(false)
      props.onConfirmShift(shifts)
      return true
    }).catch(_e => {
      setIsLoadingActionBanner(false)
      return false
    })
  }


  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs --------------------------
  // * -----------------------------------------------------------------------
  const showModalManualShift = (state: StateVisible) => {
    return (
      <ModalManualShift
        translation={translation}
        currentProcess={state.current}
        nextProcess={state.next}
        onClose={(isToShowBanner) => handleCloseModal(state, isToShowBanner)}
        onConfirmSlot={() => handleConfirmSlot(state.next)}
      />
    )
  }

  const renderBanner = (state: StateVisible) => {
    return (
      <Alert
        variant={'warning'}
        text={translation.t(`${translation.base}.alertManualShift.nextSlotWarning`)}
        actions={
          <Button
            semantic={'secondary'}
            label={translation.t(`${translation.base}.alertManualShift.confirm`, {
              value: state.next.getDisplayName(translation),
            })}
            isDisable={isLoadingActionBanner}
            isLoading={isLoadingActionBanner}
            onClick={() => handleConfirmSlot(state.next)}
          />
        }
      />
    )
  }

  const renderBannerCurrentSlotNotManualConfirmed = () => {
    if (nextInterval[0] && !nextInterval[0]?.confirmed && nextInterval[0] instanceof Activity) {
      const activity: Activity = nextInterval[0]
      return (
        <Alert
          variant={'warning'}
          text={translation.t(`${translation.base}.alertManualShift.currentSlotWarning`)}
          actions={
            <Button
              semantic={'secondary'}
              label={translation.t(`${translation.base}.alertManualShift.start`, {
                value: activity.getDisplayName(translation),
              })}
              isDisable={isLoadingActionBanner}
              isLoading={isLoadingActionBanner}
              onClick={() => handleConfirmSlot(activity)}
            />
          }
        />
      )
    }
    return null
  }

  return (
    <>
      {renderBannerCurrentSlotNotManualConfirmed()}
      {showBanner.visible && !nextInterval[1]?.confirmed && renderBanner(showBanner)}
      {showModal.visible && showModalManualShift(showModal)}
    </>
  )
}

export default React.memo(ManualShiftController, ((prevProps, nextProps) => {
  const prevShifts = Object.values(prevProps.shiftsGroupedForDays)
  const nextShifts = Object.values(nextProps.shiftsGroupedForDays)
  if (differenceOf(prevShifts, nextShifts).length === 0 && differenceOf(nextShifts, prevShifts).length === 0) {
    return true
  }
  return false
}))
