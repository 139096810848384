import { API } from './index'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { ActionCreator, Dispatch } from 'redux'
import {
  ConfigManager,
  ConfigManagerBE,
  configManagerBeToConfigManager,
} from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'
import { ReduxRehydrateSuccess } from '@mv-submodules/inplant-mcs-fe-iblu/redux/reducers'

export const CONFIG_MANAGER_SUCCESS = 'CONFIG_MANAGER_SUCCESS'
export const CONFIG_MANAGER_IS_FETCHING = 'CONFIG_MANAGER_IS_FETCHING'
export const CONFIG_MANAGER_ERROR = 'CONFIG_MANAGER_ERROR'

interface FetchConfigManagerSuccess {
  type: typeof CONFIG_MANAGER_SUCCESS
  payload: ConfigManager
}

interface IsFetchingConfigManager {
  type: typeof CONFIG_MANAGER_IS_FETCHING
}

interface FetchConfigManagerError {
  type: typeof CONFIG_MANAGER_ERROR
  payload: Error
}

export const fetchConfigManager = (abortController:AbortController): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    dispatch(isFetchingConfigManager())
    try {
      const data: ConfigManagerBE = await API().request('/config/manager', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'it-IT',
        },
        signal: abortController.signal
      })
      dispatch(fetchConfigManagerSuccess(configManagerBeToConfigManager(data)))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      if (error.name !== 'AbortError') {
        dispatch(fetchConfigManagerError(error))
        throw error
      }
    }
  }
}

export const fetchConfigManagerSuccess: ActionCreator<FetchConfigManagerSuccess> = (
  configData: ConfigManager,
): FetchConfigManagerSuccess => {
  return {
    type: CONFIG_MANAGER_SUCCESS,
    payload: configData,
  }
}

export const isFetchingConfigManager: ActionCreator<IsFetchingConfigManager> = (): IsFetchingConfigManager => {
  return {
    type: CONFIG_MANAGER_IS_FETCHING,
  }
}

export const fetchConfigManagerError: ActionCreator<FetchConfigManagerError> = (
  error: Error,
): FetchConfigManagerError => {
  return {
    type: CONFIG_MANAGER_ERROR,
    payload: error,
  }
}

export type ConfigManagerTypes = FetchConfigManagerSuccess | IsFetchingConfigManager | FetchConfigManagerError | ReduxRehydrateSuccess
