import * as React from 'react'
import * as moment from 'moment'
import Filters from '../../../../types/Filters'
import { CommonStore } from '../../../../types/Store'
import { RouteComponentProps, withRouter } from 'react-router'
import { changeDate } from '../../../../redux/actions/filters'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import DateSwitcher from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/DateSwitcher/DateSwitcher'
import { CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'

library.add(faChevronLeft, faChevronRight)

interface StateProps {
  filters: Filters
  common: CommonStore
}

interface DispatchProps {
  changeDate: (date: moment.Moment) => void
}

interface OwnProps extends RouteComponentProps<any> {
  direction: CargoDirections
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    changeDate: date => dispatch(changeDate(date)),
  }
}

class WeeklyDateSwitcher extends React.Component<Props> {
  public constructor(props: Props) {
    super(props)
    this.redirectToDate = this.redirectToDate.bind(this)
  }

  private redirectToDate(date: moment.Moment) {
    this.props.changeDate(date)
    this.props.history.push(`/mcs/weekly-report-${this.props.direction}`)
  }

  public render() {
    return (
      <DateSwitcher date={this.props.filters.date} onDateChange={this.redirectToDate} />
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(WeeklyDateSwitcher)),
)
