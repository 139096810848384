// * ------------------- NPM -----------------------------
import * as React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import Flex, { AlignContent } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { ButtonVariants } from '../../../../../mvlabs-components-fe/ui/components/Buttons/types'
import { DAY_MONTH_SHORT, mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

interface OwnProps {
  dates: Date[]
  installationSlug?: string
  onChangeDate: (newDate: Date) => void
}

type Props = OwnProps

const PlannerHead = (props: Props) => {
  const { t } = useComponentsTranslation()
/*   const baseRecipe = `recipeControl.module`
 */
  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT ------------------------------------
  // * -----------------------------------------------------------------------
  const { dates, onChangeDate } = props

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS ------------------------------------
  // * -----------------------------------------------------------------------
  const getDateWithCurrentTime = (date: Date): Date => {
    const now = mvDate.now()
    return mvDate.add(date, {
      hours: mvDate.getHours(now),
      minutes: mvDate.getMinutes(now),
      seconds: mvDate.getSeconds(now),
    })
  }

  const handleClickToday = () => {
    onChangeDate(mvDate.now())
  }
/*   const handleClickYesterday = () => {
    onChangeDate(mvDate.subtractDays(mvDate.now(), 1))
  } */

  const handleClickBackButton = () => {
    onChangeDate(mvDate.subtractDays(getDateWithCurrentTime(dates[0]), dates.length))
  }

  const handleClickNextButton = () => {
    onChangeDate(mvDate.addDays(getDateWithCurrentTime(dates[0]), dates.length))
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs ------------------------------------
  // * -----------------------------------------------------------------------
  // never be false
  if (dates.length > 2) {
    const firstDate = dates[0]
    const lastDate = dates[dates.length - 1]

    return (
      <div className={'planner__head'}>
        <Flex alignContent={AlignContent.center}>
          <Button
            semantic={'primary'}
            onClick={handleClickToday}
            variant={ButtonVariants.outline}
            label={t('recipeControl.planner.head.today')}
          />
          <Flex className={'head__pagination'} alignContent={AlignContent.center}>
            <Button
              onClick={handleClickBackButton}
              semantic={'secondary'}
              variant={ButtonVariants.flat}
              icon={'chevron-left'}
            />
            <Text
              text={`|**${mvDate.format(firstDate, DAY_MONTH_SHORT)} - ${mvDate.format(lastDate, 'd MMM, yyyy')}**|`}
            />
            <Button
              onClick={handleClickNextButton}
              semantic={'secondary'}
              variant={ButtonVariants.flat}
              icon={'chevron-right'}
            />
          </Flex>
        </Flex>
      </div>
    )
  }

/*   if (dates.length === 1) {
    const date = dates[0]
    return (
      <Flex className={'head__pagination'} justifyContent={JustifyContent.between}>
        <SectionTitle title={t(`${baseRecipe}.${props.installationSlug}`)} />
        <ButtonGroup
          semantic={'primary'}
          buttons={[
            {
              action: 'action',
              variant: mvDate.isSameDay(date, mvDate.subtractDays(mvDate.now(), 1))
                ? undefined
                : ButtonVariants.outline,
              onClick: handleClickYesterday,
              label: t('recipeControl.planner.head.yesterday'),
              isDisable: mvDate.isSameDay(date, mvDate.subtractDays(mvDate.now(), 1)),
            },
            {
              action: 'action',
              variant: mvDate.isSameDay(date, mvDate.now()) ? undefined : ButtonVariants.outline,
              label: t('recipeControl.planner.head.today'),
              onClick: handleClickToday,
              isDisable: mvDate.isSameDay(date, mvDate.now()),
            },
          ]}
        />
      </Flex>
    )
  } */

  return <div />
}

export default PlannerHead
