import { Cargo, CargoDirections, CargoSelected } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import * as React from 'react'
import ColumnsRender, {
  Actions,
} from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/ColumnsRender'
import ReactTable, { Resize, SortingRule } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { useTranslation } from 'react-i18next'
import { TodayType } from './Today'
import { PastType } from './Past'
import { Column } from 'react-table'
import CargoTableHeader, { ExportCSVProps, Pages } from './CargoTableHeader'
import { filterCargoDataByText } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'

interface PeriodConfig {
  type: Pages
}

interface TodayConfig extends PeriodConfig {
  type: 'today'
  subType: TodayType
}

export interface TodayManageableConfig extends TodayConfig {
  subType: 'manageable'
  progressiveNumber: boolean
}

export interface TodayConcludedConfig extends TodayConfig {
  subType: 'concluded'
}

interface PastConfig extends PeriodConfig {
  type: 'past'
  subType: PastType
}

export interface PastRescheduledConfig extends PastConfig {
  subType: 'rescheduled'
}

export interface PastCanceledConfig extends PastConfig {
  subType: 'canceled'
}

export interface PastConcludedConfig extends PastConfig {
  subType: 'concluded'
}

export interface PastUnprocessedConfig extends PastConfig {
  subType: 'unprocessed'
}

interface CloseDayConfig extends PeriodConfig {
  type: 'closeDay'
}

export interface FutureConfig extends PeriodConfig {
  type: 'future'
}

export interface WeeklyReportConfig extends PeriodConfig {
  type: 'weeklyReport'
}

export type TableColumns =
  | FutureConfig
  | TodayManageableConfig
  | TodayConcludedConfig
  | PastRescheduledConfig
  | PastCanceledConfig
  | PastConcludedConfig
  | PastUnprocessedConfig
  | CloseDayConfig
  | WeeklyReportConfig

interface Props {
  title?: string
  data: Cargo[]
  isLoading: boolean
  type: TableColumns
  direction: CargoDirections
  onAction?: (action: Actions) => void
  className?: string
  otherColumns?: Column[]
  /*
   * due to previous logic we have the logic to show or not the batchActions outside the component.
   */
  showCheckbox?: boolean
  cargosSelected?: CargoSelected
  onResizedChange?: (newResized: Resize[]) => void // TODO da implementare per future?
  getTrProps?: (state: any, row: any) => any // TODO da migliorare
  exportCSVButton?: ExportCSVProps
}

const CargoTable: React.FC<Props> = ({
  showCheckbox = false,
  cargosSelected = {},
  otherColumns = [],
  className = '',
  title = '',
  ...props
}) => {
  const { data, isLoading, type, direction, onAction, onResizedChange, getTrProps, exportCSVButton } = props
  const { t } = useTranslation()

  const columns = ColumnsRender({ type, direction, onAction, cargosSelected, showCheckbox })

  const [cargoSorted, setCargoSorted] = React.useState<SortingRule[]>([])
  const [fullTextFilter, setFullTextFilter] = React.useState('')

  function getCargoFiltered(): Cargo[] {
    return filterCargoDataByText(data, fullTextFilter)
  }

  const renderSpinner = () => {
    if (data.length !== 0) {
      // silent spinner
      return (
        <div className={`table-loader polling text-right ${isLoading ? 'active' : ''}`}>
          <div className="polling-text small">{t('mcs.today.loading')}</div>
          <FontAwesomeIcon icon={faCircleNotch} size="1x" spin={true} />
        </div>
      )
    }
    return (
      <div className={`table-loader ${isLoading ? 'active' : ''}`}>
        <FontAwesomeIcon icon={faCircleNotch} size="3x" spin={true} />
      </div>
    )
  }

  const filteredData = getCargoFiltered()

  return (
    <>
      <h3>{title}</h3>
      {data.length > 0 && props.type.type !== 'weeklyReport' ? (
        <CargoTableHeader
          fullTextFilter={fullTextFilter}
          handleFullTextFilterChange={value => setFullTextFilter(value)}
          onResetOrder={() => setCargoSorted([])}
          cargoSorted={cargoSorted}
          exportCSVButton={exportCSVButton}
        />
      ) : (
        <></>
      )}
      <ReactTable
        className={`-striped -highlight ${className}`}
        showPagination={false}
        sortable={true}
        sorted={cargoSorted}
        onSortedChange={newSorted => {
          setCargoSorted(newSorted)
        }}
        data={getCargoFiltered()}
        pageSize={filteredData.length > 0 ? Math.max(filteredData.length, 2) : 2}
        loading={isLoading}
        LoadingComponent={renderSpinner}
        columns={[...columns, ...otherColumns]}
        noDataText={t('mcs.common.noData')}
        onResizedChange={onResizedChange}
        getTrProps={getTrProps}
      />
    </>
  )
}

export default CargoTable
