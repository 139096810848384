type check = (availableSpace: number) => boolean

export interface ContentLevel {
  levelShortContent: number,
  levelHalfContent: number,
  levelAllContent: number
  isToShowShortContent: check,
  isToShowHalfContent: check,
  isToShowAllContent: check
}

export const buildContentLevelSlot = (showFullData: boolean): ContentLevel => {
  if (showFullData) {
    return {
      isToShowShortContent: availableSpace => availableSpace <= 8,
      isToShowHalfContent: availableSpace => availableSpace <= 25 && availableSpace > 8,
      isToShowAllContent: availableSpace => availableSpace > 25,
      levelShortContent: 0,
      levelHalfContent: 8,
      levelAllContent: Infinity
    }
  }
  return {
    isToShowShortContent: availableSpace => availableSpace <= 30,
    isToShowHalfContent: availableSpace => availableSpace <= 40 && availableSpace > 30,
    isToShowAllContent: availableSpace => availableSpace > 60,
    levelShortContent: 0,
    levelHalfContent: 15,
    levelAllContent: Infinity
  }

}