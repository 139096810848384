// * ------------------- COMPONENTS -----------------------------
import { AlertVariants } from '../../mvlabs-components-fe/ui/components/Alert/Alert'
import { Icon } from '../../mvlabs-components-fe/services/icon'

// * ------------------- MODULE -----------------------------
import Recipe from "../types/recipe";
import { Asset } from "../types/asset";
import { AssetConnection, RecipeMismatch, WarningsType } from '../types/warning'

const getSlugFromWarning = (warning: WarningsType): 'error' | 'warning' | '' => {
  switch (true) {
    case warning.type === 'connection':
      return 'error'
    case warning.type === 'recipe-mismatch':
      return 'warning'
    default:
      return ''
  }
}

export const getSemanticFromWarning = (warning: WarningsType, baseSlug: string = '') => {
  const semantic = getSlugFromWarning(warning)
  if (semantic) {
    return `${baseSlug}--${semantic}`
  }
  return ''
}

export const getAlertVariantFromWarning = (warning: WarningsType): AlertVariants => {
  const semantic = getSlugFromWarning(warning)
  switch (semantic) {
    case 'error':
      return 'danger'
    default:
      return 'warning'
  }
}

export const getIconFromWarning = (warning: WarningsType): Icon => {
  switch (true) {
    case warning.type === 'connection':
      return 'wifi'
    case warning.type === 'recipe-mismatch':
      return 'not-equal'
    default:
      return 'not-equal'
  }
}

export const warningContent = (warning: WarningsType, boldText?:boolean): string => {
  return boldText ? `|**${warning.assetDisplayName}**|` : `${warning.assetDisplayName}`
/*   if (warning.type === 'connection') {
    return boldText ? `|**${warning.assetDisplayName}**|` : `${warning.assetDisplayName}`
  }
  return boldText ? `|**${warning.assetDisplayName}**|` : `${warning.assetDisplayName}` */
}

export const warningLength = (warning: WarningsType[], type: 'recipe-mismatch' | 'connection'): number => {
  if(type === 'recipe-mismatch') {
    return warning.filter(w => w.type === type && !w.relatedManualOverrideId).length
  }
  return warning.filter(w => w.type === type).length
}

export const isMismatch = (expectedRecipe:Recipe,mismatchWarning?:RecipeMismatch[]) => {
  if (mismatchWarning) {
    const recipeFoundInWarning = mismatchWarning.find(m => m.expectedRecipe && m.expectedRecipe.id === expectedRecipe.id && m.isMismatch)
    if (recipeFoundInWarning) {
      return true
    }
  }
  return false
}

export const mismatchString = (expectedRecipe:Recipe,unknownRecipe:string, mismatchWarning?:RecipeMismatch[]) => {
  if (mismatchWarning) {
    const recipeFoundInWarning = mismatchWarning.find(m => m.expectedRecipe && m.expectedRecipe.id === expectedRecipe.id && m.isMismatch)
    if (recipeFoundInWarning) {
      return recipeFoundInWarning.actualRecipe?.displayName || unknownRecipe
    }
  }
  return expectedRecipe.displayName
}

export const connectionProblemString = (expectedAsset: Asset,okMessage:string,koMessage:string,processProblemConnection?:AssetConnection[]):string => {
  if (processProblemConnection) {
    const assetFoundInWarning = processProblemConnection.find(c => c.assetId === expectedAsset.id && !c.isConnected)
    if (assetFoundInWarning) {
      return koMessage
    }
  }
  return okMessage
}

export const isConnectionProblem = (expectedAsset: Asset,processProblemConnection?:AssetConnection[]) => {
  if (processProblemConnection) {
    const assetFoundInWarning = processProblemConnection.find(c => c.assetId === expectedAsset.id && !c.isConnected)
    if (assetFoundInWarning) {
      return true
    }
  }
  return false
}
