import React from 'react'
import SimpleTable from '../../../../../mvlabs-components-fe/ui/components/Table/SimpleTable'
import { Asset } from '../../../../types/asset'
import Recipe from '../../../../types/recipe'
import { Text } from '../../../../../mvlabs-components-fe/ui/components'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services'

export interface TableType {
  asset: Asset
  recipe: Recipe
}

interface Props {
  list: TableType[]
}

const TableMachines = (props: Props) => {
  const { t } = useComponentsTranslation()
  const base = `recipeControl.machine`

  const { list } = props

  return (
    <SimpleTable<TableType>
      data={list}
      columns={[
        {
          title: t(`${base}.machineName`),
          cell: data => <Text text={data.asset.displayName} semantic={'light'} />,
          width: '400px',
        },
        { title: t(`${base}.recipeName`), cell: data => <Text text={data.recipe.displayName} semantic={'light'} /> },
      ]}
    />
  )
}

export default TableMachines
