// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex, {
  AlignContent,
  AlignItems,
  Direction,
  Fit,
  JustifyContent,
} from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import FlexItem, { AlignSelf, TooltipPosition } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { ITranslationService } from '../../../../../mvlabs-components-fe/services/translation'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'

// * -------------------------------- MODULE --------------------------------------
import Activity from '../../../../types/activity'
import Configuration from '../../../../types/configuration'
import Recipe from '../../../../types/recipe'
import Shift from '../../../../types/shift'
import Slot from '../../../../types/slot'
import { ContentLevel } from '../../../../functions/buildContentLevelSlot'
import { PlannerViewTypes } from '../../../../types/viewType'
import { UseErrorController } from '../ErrorController/useErrorController'
import { canReadSlotDetail, isSlotEditable, isSlotOverride } from '../../../../functions/slot'
import { getSemanticFromWarning } from '../../../../functions/warning'
import { WarningsType } from '../../../../types/warning'

export interface PlannerSlotProps<T extends Slot = Slot> {
  slot: T
  days: number
  percentageDimension: number
  showFullData: boolean
  viewType: PlannerViewTypes
  minTimeEditProcess: number
  onClick?: (work: Slot) => void
  recipes: Recipe[]
  configuration: Configuration
  shift: Shift
  contentLevel: ContentLevel
  translations: ITranslationService
  isOneShiftPerDay: boolean
}

abstract class PlannerSlotComponent<T extends Slot> extends React.Component<PlannerSlotProps<T> & UseErrorController> {
  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT --------------------------
  // * -----------------------------------------------------------------------
  protected CHAR_TRIM = 20
  protected SLOT_SLUG = 'planner__slot'

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------
  protected showLongText = (text?: string) => {
    if (this.showTooltip() && text && text.length >= this.CHAR_TRIM) {
      return `${text.substring(0, this.CHAR_TRIM)}...`
    }
    return text || ''
  }

  protected get dateString() {
    return `${mvDate.format(this.props.slot.from, TIME)} - ${mvDate.format(this.props.slot.to, TIME)}`
  }

  protected isSlotPast = (): boolean => {
    return (
      !this.isCurrentSlot() &&
      this.props.slot.isBeforeThen(mvDate.addMinutes(mvDate.now(), this.props.minTimeEditProcess))
    )
  }

  protected isCurrentSlot = () => {
    const { slot } = this.props

    return (
      slot.isCurrentSlot() &&
      (this.props.configuration.editConfig.currentShiftEditEnabled || this.props.configuration.scheduleOverrideEnable)
    )
  }

  // className slot
  protected processToSlotTypes(): String {
    const { slot } = this.props

    // default not readonly
    const readonly = this.props.viewType === 'read-only' ? `${this.SLOT_SLUG}--readonly` : ''

    // not clickable
    // const notClickable = this

    // default process (filled)
    const empty = slot instanceof Activity ? '' : `${this.SLOT_SLUG}--empty`

    // const _halt = slot instanceof Halt ? `${this.SLOT_SLUG}--halt` : ''

    const compare = this.props.viewType === 'compare' ? `${this.SLOT_SLUG}--compare` : ''

    // default next days
    let dayString = ''
    if (this.isCurrentSlot()) {
      dayString = `${this.SLOT_SLUG}--now`
    } else if (this.isSlotPast()) {
      dayString = `${this.SLOT_SLUG}--past`
    } else if (mvDate.getDate(slot.day) === mvDate.getDate(mvDate.now())) {
      dayString = `${this.SLOT_SLUG}--today`
    }

    // default not-confirmed
    const confirmed = slot.confirmed ? `${this.SLOT_SLUG}--confirmed` : ''

    // warnings to colors
    const allWarning: WarningsType[] = slot.allWarnings
    const overrides = this.props.shift.scheduledOverrides
    const overrideWarning: string =
      overrides.length > 0 && overrides.filter(o => o.isInsideSlot(slot)).length > 0 ? `${this.SLOT_SLUG}--warning` : ''
    const warningsString: string[] = allWarning.map(w => getSemanticFromWarning(w, this.SLOT_SLUG))

    // based on function notClickable (used to manage the slot onClick function for edit)
    const notClickable =
      isSlotEditable(this.props.configuration, this.props.shift, this.props.slot) ||
      isSlotOverride(this.props.configuration, this.props.shift, this.props.slot) ||
      canReadSlotDetail(this.props.viewType, slot)
        ? ''
        : `${this.SLOT_SLUG}--not-clickable`

    return (
      readonly +
      '' +
      compare +
      ' ' +
      empty +
      ' ' +
      // halt +
      ' ' +
      dayString +
      ' ' +
      confirmed +
      ' ' +
      warningsString.join(' ') +
      ' ' +
      overrideWarning +
      ' ' +
      notClickable
    )
  }

  private showTooltipDefault(): boolean {
    return (this.props.errorTooltip && this.props.errorTooltip !== '') || this.showTooltip()
  }

  private renderTooltipDefault(): string {
    return this.renderTooltip() + '\n' + (this.props.errorTooltip ? this.props.errorTooltip : '')
  }
  private handleClickSlot = () => {
    const { onClick, slot } = this.props
    if (onClick) {
      return onClick(slot)
    }
    return undefined
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs --------------------------
  // * -----------------------------------------------------------------------
  public render() {
    return (
      <FlexItem
        style={{ flexBasis: `${this.props.percentageDimension}%` }}
        className={`${this.SLOT_SLUG} ${this.processToSlotTypes()}`}
        // alignSelf={AlignSelf.stretch}
        onClick={this.handleClickSlot}
        tooltip={{
          disableIcon: false,
          content: this.showTooltipDefault() ? this.renderTooltipDefault() : '',
          position: 'top',
          infoIconPosition: TooltipPosition.leftCenter,
        }}
      >
        {(((Array.isArray(this.props.children) && this.props.children.length > 0) ||
          (!Array.isArray(this.props.children) && this.props.children !== null)) && (
          <Flex
            direction={this.props.layout === 'row' ? Direction.row : Direction.column}
            alignSelf={AlignSelf.stretch}
            fit={Fit.multiLine}
          >
            <Flex grow={1} shrink={1} direction={Direction.column} justifyContent={JustifyContent.center}>
              {this.renderContent()}
            </Flex>
            <Flex
              grow={0}
              direction={Direction.column}
              justifyContent={JustifyContent.center}
              alignItems={AlignItems.center}
              alignSelf={AlignSelf.center}
            >
              {this.props.children}
            </Flex>
          </Flex>
        )) || (
          <Flex
            alignContent={AlignContent.center}
            fit={Fit.multiLine}
            grow={1}
            shrink={1}
            direction={Direction.column}
            justifyContent={JustifyContent.center}
          >
            {this.renderContent()}
          </Flex>
        )}
      </FlexItem>
    )
  }

  private renderContent() {
    const { percentageDimension, contentLevel } = this.props
    if (contentLevel.isToShowAllContent(percentageDimension)) {
      return this.renderAllContent()
    }
    if (contentLevel.isToShowHalfContent(percentageDimension)) {
      return this.renderHalfContent()
    }
    if (contentLevel.isToShowShortContent(percentageDimension)) {
      return this.renderShortContent()
    }

    return this.renderAllContent()
  }

  protected renderText(content: string, contentForTooltip?: string): React.ReactNode {
    const { percentageDimension, contentLevel } = this.props
    const styleClass = `planner__slot--title-content${
      contentLevel.isToShowAllContent(percentageDimension) ? '-lg' : ''
    }`

    const text = () => {
      return <Text text={content} />
      /* return this.props.days > 1 ? (
        <FlexItem
          tooltip={{
            content: contentForTooltip,
            disableIcon: true,
            position: 'top',
          }}
        >
          <Text text={content} />
        </FlexItem>
      ) : (
        <Text text={content} />
      ) */
    }

    return (
      <Flex
        justifyContent={JustifyContent.center}
        alignContent={AlignContent.center}
        className={'planner__slot--title-container planner__slot--title'}
      >
        <div
          className={this.props.days > 1 ? styleClass : undefined}
        >
          {text()}
        </div>
      </Flex>
    )
  }

  protected renderTextWithDate(content: string, contentForTooltip?: string): React.ReactNode {
    const { percentageDimension, contentLevel } = this.props
    const styleClass = `planner__slot--title-content${
      contentLevel.isToShowAllContent(percentageDimension) ? '-lg' : ''
    }`

    const text = () => {
      return <Text text={content} />
      /* return this.props.days > 1 ? (
        <FlexItem
          tooltip={{
            content: contentForTooltip,
            disableIcon: true,
            position: 'top',
          }}
        >
          <Text text={content} />
        </FlexItem>
      ) : (
        <Text text={content} />
      ) */
    }
    return (
      <Flex
        direction={Direction.column}
        justifyContent={JustifyContent.center}
        alignContent={AlignContent.center}
        className={'planner__slot--title-container planner__slot--title'}
      >
        <div className={this.props.days > 1 ? styleClass : undefined}>
          {text()}
        </div>
        <Text text={this.dateString} />
      </Flex>
    )
  }

  protected abstract showTooltip(): boolean

  protected abstract renderTooltip(): string

  protected abstract renderShortContent(): React.ReactNode

  protected abstract renderHalfContent(): React.ReactNode

  protected abstract renderAllContent(): React.ReactNode
}

export default PlannerSlotComponent
