import { API } from '../actions'
import { Dispatch } from 'redux'
import { Cargo, cargoBeToCargo, CargoDirections } from '../../types/Cargo'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import * as moment from 'moment'
import { createCargoToSend } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'

enum TypeAddCargo {
  withTicket = 'with-ticket',
  withoutTicket = 'without-ticket',
}

export const cargoOpsAddCargo = (cargo: Cargo): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    let path = `/${cargo.direction}`
    switch (cargo.direction) {
      case 'incoming':
      case 'outgoing':
        if (cargo.ticketNumber) {
          path += `/${TypeAddCargo.withTicket}`
        } else {
          path += `/${TypeAddCargo.withoutTicket}`
        }
        break
      default:
        break
    }
    return API()
      .request(path, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(createCargoToSend(cargo, 'add')),
      })
      .then((_result: any) => {
        return
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}

export const cargoOpsFetchCargo = (
  cargoId: string,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<Cargo>) => {
  return async dispatch => {
    try {
      const data = await API().request(`/${direction}/${cargoId}`)
      return cargoBeToCargo(data, direction)
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsEditCargo = (cargo: Cargo): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    return API()
      .request(`/${cargo.direction}/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(createCargoToSend(cargo, 'edit')),
      })
      .then((_result: any) => {
        return
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}

export const cargoOpsWelcomeCargo = (
  cargo: Cargo,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      await API().request(`/${direction}/welcome/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(createCargoToSend(cargo, 'welcome')),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsBlockCargo = (
  cargo: Cargo,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      await API().request(`/${direction}/stop-for-irregularities/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(createCargoToSend(cargo, 'block')),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsSaveTodayCargo = (
  cargo: Cargo,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      await API().request(`/${direction}/today/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(createCargoToSend(cargo, 'saveToday')),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsSaveConcludedTodayCargo = (
  cargo: Cargo,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      await API().request(`/${direction}/today/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(createCargoToSend(cargo, 'editConcludedToday')),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsDeleteCargo = (
  cargoId: string,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      await API().request(`/${direction}/${cargoId}`, {
        headers: { 'content-type': 'application/json' },
        method: 'DELETE',
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsBatchDeleteCargo = (
  cargoIds: string[],
  direction: CargoDirections,
): ((dispatch: Dispatch<any>) => Promise<void>) => {
  return async dispatch => {
    await Promise.all(cargoIds.map(cargoId => dispatch(cargoOpsDeleteCargo(cargoId, direction))))
  }
}

export const cargoOpsCheckoutCargo = (
  cargo: Cargo,
  direction: CargoDirections,
  handledWeightKg: number,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      const bodyParams: any = {
        note: cargo.note,
        handledWeightKg,
      }
      await API().request(
        `/${direction}/${
          direction === 'transfer' && cargo.status === 'ready_to_transfer' ? 'approve-for-transfer/' : 'checkout/'
        }${cargo.id}`,
        {
          headers: { 'content-type': 'application/json' },
          method: 'PUT',
          body: JSON.stringify(bodyParams),
        },
      )
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

type ResendPath = 'resend-to-queue' | 'resend-to-loading-queue' | 'resend-to-unloading-queue'

export const cargoOpsRequeueCargo = (
  cargo: Cargo,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      const bodyParams: any = {
        note: cargo.note,
      }
      let resendPath: ResendPath = 'resend-to-queue'
      if (direction === 'transfer' && cargo.status === 'ready_to_transfer') {
        resendPath = 'resend-to-loading-queue'
      } else if (direction === 'transfer' && cargo.status === 'ready_to_leave') {
        resendPath = 'resend-to-unloading-queue'
      }
      await API().request(`/${direction}/${resendPath}/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsRescheduleCargo = (
  cargoId: string,
  newDate: moment.Moment,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      const bodyParams: any = {
        newDate: newDate.format('YYYY-MM-DD'),
      }
      await API().request(`/${direction}/reschedule/${cargoId}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const cargoOpsPrintCheckout = (
  cargoId: string,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<Blob | null>) => {
  return async dispatch => {
    try {
      return await API().request(`/reports/${direction}/${cargoId}`)
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

type PutBackType = 'back-to-pending-arrival' | 'back-to-ready-to-transfer'

export const cargoOpsPutBack = (
  cargo: Cargo,
  direction: CargoDirections,
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    let putBackType: PutBackType = 'back-to-pending-arrival'
    if (cargo.status === 'in_unload_queue') {
      putBackType = 'back-to-ready-to-transfer'
    }
    try {
      await API().request(`/${direction}/${putBackType}/${cargo.id}`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

