// * ------------------- NPM -----------------------------
import { Dispatch } from 'redux'

// * ------------------- CORE -----------------------------
import { logoutUser } from '../../../inplant-core-fe/redux/actions'

// * ------------------- COMPONENTS -----------------------------
import { mvDate, REQUEST_FORMAT } from '../../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import Activity from '../../types/activity'
import Process, { IProcess } from '../../types/process'
import Shift, { IShift, IShiftList } from '../../types/shift'
import Slot from '../../types/slot'
import { API, RECIPE_URL } from '.'
import { DataToCreateProcess } from '../../types/others'
import { createBodyProcesses, getInstallationBody } from '../../functions'

// * Actions for Planner
export enum ActionType {
  process = 'processes',
  halt = 'halts',
}

/**
 * @param from first date, (is inclusive)
 * @param to second date, (is inclusive)
 * @return all shifts from `from` to `to`
 * @throws Error if something goes wrong
 */

export const plannerTurns = (from: Date, to: Date, installationSlug?:string) => async (dispatch: Dispatch): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/shifts/list`, {
        method: 'POST',
        body: JSON.stringify({
          from: mvDate.format(from, REQUEST_FORMAT),
          to: mvDate.format(to, REQUEST_FORMAT),
          ...getInstallationBody(installationSlug),
        }),
      })
      .then((shiftList: IShiftList) => shiftList.results.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

/**
 * @return list of all shift changed due to this action
 * @throws Error if something goes wrong
 */
export const createProcess = (data: DataToCreateProcess, type: ActionType = ActionType.process) => async (
  dispatch: Dispatch
): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${type}/create`, {
        method: 'POST',
        body: createBodyProcesses(data, type),
      })
      .then((shifts: IShift[]) => shifts.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

/**
 * @return list of all shift changed due to this action
 * @throws Error if something goes wrong
 */
export const editProcess = (activity: Activity, type: ActionType = ActionType.process) => async (
  dispatch: Dispatch
): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${type}/edit/${activity.id}`, {
        method: 'POST',
        body: activity.body,
      })
      .then((shifts: IShift[]) => shifts.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

/**
 * @return list of all shift changed due to this action
 * @throws Error if something goes wrong
 */
export const deleteProcess = (activity: Activity, type: ActionType = ActionType.process) => async (
  dispatch: Dispatch
): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${type}/${activity.id}`, {
        method: 'DELETE',
      })
      .then((shifts: IShift[]) => shifts.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

/**
 * @return list of all shift changed due to this action
 * @throws Error if something goes wrong
 */
 export const overrideCurrentProcess = (data: Record<string, any>) => async (
  dispatch: Dispatch
): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/schedule-overrides`, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((shifts: IShift[]) => shifts.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

/**
 *
 * @param day day to confirm
 * @returns list of all shift changed due to this action
 * @throws Error if something goes wrong
 */
export const confirmDay = (day: Date) => async (dispatch: Dispatch): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/shifts/confirm`, {
        body: JSON.stringify({
          day: mvDate.format(day, 'yyyy-MM-dd'),
          ...getInstallationBody(),
        }),
        method: 'POST',
      })
      .then((shifts: IShift[]) => shifts.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

export const confirmSlot = (slot: Slot) => async (dispatch: Dispatch): Promise<Shift[]> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${ActionType.process}/confirm`, {
        method: 'POST',
        body: JSON.stringify({
          from: mvDate.format(slot.from, REQUEST_FORMAT),
          to: mvDate.format(slot.to, REQUEST_FORMAT),
          ...getInstallationBody(),
        }),
      })
      .then((shifts: IShift[]) => shifts.map(s => new Shift(s)))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}

export const getSlot = (id: string) => async (dispatch: Dispatch): Promise<Process> => {
  try {
    return API()
      .request(`/${RECIPE_URL}/${ActionType.process}/${id}`, {
        method: 'GET',
      })
      .then((iProcess: IProcess) => new Process(iProcess))
  } catch (error:any) {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    throw error
  }
}
