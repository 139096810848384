import { combineReducers, ReducersMapObject } from 'redux'
import filters from './filters'
import common from './common'
import configManager from './configManager'


const mcsReducersMap: ReducersMapObject = {
  filters,
  common,
  configManager
}

export interface ReduxRehydrateSuccess {
  type: 'persist/REHYDRATE',
  payload: any
}


export default combineReducers(mcsReducersMap)
