import * as React from 'react'
import { withTranslation, WithTranslation, getI18n } from 'react-i18next'
import { ConfigManagerStore } from '../../../../types/Store'
import Filters from '../../../../types/Filters'
import { connect } from 'react-redux'
import { HandleWeightKg, StatMaterial, Stats } from '../../../../types/Stats'
import { fetchStats } from '../../../../redux/actions/stats'
import * as moment from 'moment'
import 'moment/locale/it'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tooltip } from 'react-tippy'

interface StateProps {
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchStats: (abortController:AbortController) => Promise<Stats>
}

interface State {
  stats: Stats,
}

interface OwnProps {
  filters: Filters
}

const mapStateToProps = (state: any): StateProps => ({
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchStats: (abortController) => dispatch(fetchStats(abortController)),
  }
}

type Props = StateProps & DispatchProps & WithTranslation & OwnProps

class WaitingTimes extends React.Component<Props, State> {
  private intervalId: any
  private abortController: AbortController = new AbortController()

  public constructor(props: Props) {
    super(props)
    this.state = {
      stats: {
        inQueue: 0,
        avgInQueue: 0,
        avgToday: 0,
        handledWeightKg: null,
        readyToLeave: 0,
        underProcessing: 0,
      },
    }
    this.formatTime = this.formatTime.bind(this)
    this.createNotesDescription = this.createNotesDescription.bind(this)
    this.renderHandleWeightInfo = this.renderHandleWeightInfo.bind(this)
  }

  private requestStats = () => {
    this.props.fetchStats(this.abortController).then(stats => {
      this.setState({ stats })
    }).catch(error => {
      console.warn('error', error) //tslint:disable-line
    })
  }

  public componentDidMount() {
    this.requestStats()
    this.intervalId = setInterval(this.requestStats, 15000)
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    this.abortController.abort()
  }

  private formatTime(value: number | null) {
    if (!value) {
      return '--'
    }
    return moment
      .duration(value * 1000)
      .locale(getI18n().language)
      .humanize()
  }

  public render() {
    return (
      <Row horizontalAlignment={'start'}>
        <Row flex={true} spacing={{ horizontal: false }}>
          <label>{this.props.t('mcs.today.usersInQueue')}:</label>
          <div className='font-weight-bold ml-2 mr-2'>{this.state.stats.inQueue}</div>
        </Row>
        <Row flex={true}>
          <label>{this.props.t('mcs.today.usersUnderProcessing')}:</label>
          <div className='font-weight-bold ml-2'>{this.state.stats.underProcessing}</div>
        </Row>
        <Row flex={true}>
          <label>{this.props.t('mcs.today.usersTotalInPlant')}:</label>
          <div className='font-weight-bold ml-2'>
            {this.state.stats.inQueue +
            this.state.stats.underProcessing +
            this.state.stats.readyToLeave}
          </div>
        </Row>
        <Row flex={true}>
          <label>{this.props.t('mcs.today.dailyMeanWaitTime')}:</label>
          <div className='font-weight-bold ml-2'>{this.formatTime(this.state.stats.avgToday)}</div>
        </Row>
        <Row flex={true}>
          <label>{this.props.t('mcs.today.currentMeanWaitTime')}:</label>
          <div className='font-weight-bold ml-2'>{this.formatTime(this.state.stats.avgInQueue)}</div>
        </Row>
        {this.props.configManager.data.handleWeightKg
          ? this.renderHandleWeightInfo(this.state.stats.handledWeightKg, this.props.filters.direction)
          : null}
      </Row>
    )
  }

  private renderHandleWeightInfo = (handleWeightKg: HandleWeightKg | null, direction: CargoDirections) => {
    if (handleWeightKg !== null) {
      switch (direction) {
        case 'incoming':
          return this.renderDataForStatMaterials(handleWeightKg.in, direction)
        case 'outgoing':
          return this.renderDataForStatMaterials(handleWeightKg.out, direction)
      }
    }
    return (
      <Row flex={true} spacing={{ horizontal: false }} verticalAlignment={'start'}>
        <label>{direction === 'incoming' ? this.props.t('mcs.today.handledWeightKg.titleIncoming') : this.props.t('mcs.today.handledWeightKg.titleOutgoing')}:</label>
        <div className='font-weight-bold ml-2 mr-2'>{'--'}</div>
      </Row>
    )
  }

  private renderDataForStatMaterials = (materials: StatMaterial[], direction: CargoDirections) => {
    const total = materials.reduce((acc, material) => acc + (material.kgSum !== null ? material.kgSum : 0), 0)
    return (
      <Row flex={true} spacing={{ horizontal: false }} verticalAlignment={'start'}>
        <label>
          {direction === 'incoming'
            ? this.props.t('mcs.today.handledWeightKg.titleIncoming')
            : this.props.t('mcs.today.handledWeightKg.titleOutgoing')}
          :
        </label>
        <div className='font-weight-bold ml-2 mr-2'>{total}</div>
        {total !== 0 ? (
          <Tooltip arrow={true} html={this.createNotesDescription(materials)}>
            <FontAwesomeIcon icon={faInfoCircle} className='text-secondary' />
          </Tooltip>
        ) : null}
      </Row>
    )
  }

  private createNotesDescription = (materials: StatMaterial[]) => {
    return materials.map((material, index) => {
      return (
        <div className={'text-left'} key={index}>
          {this.props.t('mcs.today.handledWeightKg.info.measure') +
          (material.kgSum !== null ? material.kgSum : 0) +
          '  ' +
          this.props.t('mcs.today.handledWeightKg.info.material') +
          material.materialDescription}
        </div>
      )
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WaitingTimes))
