// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as moment from 'moment'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'

// ------------------------- COMPONENTS --------------------------------------
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import { Modal } from '@mv-submodules/inplant-components-fe'
import Button, { ButtonDefinition } from '../../../../../inplant-components-fe/ui/components/Button/Button'

// ------------------------- MODULE --------------------------------------
import { Cargo, CargoDirections } from '../../../../types/Cargo'
import Filters from '../../../../types/Filters'
import { CommonStore, ConfigManagerStore } from '../../../../types/Store'
import { cargoOpsFetchCargo, cargoOpsPrintCheckout } from '../../../../redux/actions/cargoOps'
import { FieldReadOnly, TextAreaReadOnly } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/Field'
import { imageUrl, listImages } from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'
import { getCargoQueueWaitingTime, handleDownloadImages } from '@mv-submodules/inplant-mcs-fe-iblu/functions/utils'
import RemoteImage from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/RemoteImage'
import { Input } from '../Utils/Inputs'
import TodayCargoModal from '../../views/Today/TodayCargoModal'

library.add(faCircleNotch)

interface StateProps {
  filters: Filters
  common: CommonStore
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchCargo: (cargoId: string, direction: CargoDirections) => Promise<Cargo>
  printCargo: (cargoId: string, direction: CargoDirections) => Promise<Blob>

  listImages: (direction: CargoDirections, truck: Cargo) => Promise<string[]>
  imageUrl: (direction: CargoDirections, imageId: string, truck: Cargo) => Promise<string>
}

interface OwnState {
  cargo: Cargo | null
  direction: CargoDirections
  isFetching: boolean
  isPrinting: boolean
  isLoadingImages: boolean
  images: Array<{ imageId: string; imageURL: string | undefined }>

  showModal: { type: 'Checkout' } | { type: 'FullScreenImage'; imageURL: string } | { type: 'EditTodayCargo' }

  imagesToDownload: Array<{ imageId: string; imageURL: string }>
}

interface OwnProps extends RouteComponentProps<any> {
  cargoId: string
  onClose: (refreshData?: boolean) => void
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchCargo: (cargoId, direction) => dispatch(cargoOpsFetchCargo(cargoId, direction)),
    printCargo: (cargoId, direction) => dispatch(cargoOpsPrintCheckout(cargoId, direction)),

    listImages: (direction, truck) => dispatch(listImages(direction, truck)),
    imageUrl: (direction: CargoDirections, imageId: string, truck: Cargo) =>
      dispatch(imageUrl(direction, imageId, truck)),
  }
}

class SummaryCargoModal extends React.Component<Props, OwnState> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      isPrinting: false,
      isFetching: false,
      cargo: null,
      direction: props.filters.direction,
      isLoadingImages: false,
      images: [],
      showModal: { type: 'Checkout' },
      imagesToDownload: [],
    }
    this.handlePrintCargoSummary = this.handlePrintCargoSummary.bind(this)
  }

  public async componentDidMount() {
    this.setState({ isFetching: true })
    const cargo = await this.props.fetchCargo(this.props.cargoId, this.props.filters.direction)
    this.setState({ cargo, isFetching: false })
  }

  private async handlePrintCargoSummary() {
    this.setState({ isPrinting: true })
    try {
      const cargo = this.state.cargo as Cargo
      const blob = await this.props.printCargo(cargo.id!, this.state.direction)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const title = `${this.props.filters.date.format('YYYYMMDD')} - ${this.props.t(
        `mcs.cargo.direction.${this.props.filters.direction}`
      )} - ${cargo.plate}`
      link.href = objectURL
      link.download = `${title}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error(error) // tslint:disable-line
    }
    this.setState({ isPrinting: false })
  }

  private showEditModal = () => {
    this.setState({ showModal: { type: 'EditTodayCargo' } })
  }

  private closeEditModal = () => {
    this.setState({ showModal: { type: 'Checkout' } })
    this.props.onClose(true)
  }

  public render() {
    const { cargo } = this.state

    let additionalFooterButtons: ButtonDefinition[] = [
      {
        onClick: this.handlePrintCargoSummary,
        variant: 'primary-alternate',
        disabled: this.state.isPrinting || this.state.isFetching,
        isLoading: this.state.isPrinting,
        label: this.props.t('mcs.cargo.actions.print'),
      },
    ]

    if (this.state.imagesToDownload.length > 0) {
      additionalFooterButtons = [
        {
          onClick: () => handleDownloadImages(this.state.imagesToDownload),
          variant: 'success',
          label: this.props.t('mcs.cargo.actions.downloadImages'),
        },
        ...additionalFooterButtons,
      ]
    }

    if (this.props.configManager.data.canEditTodayConcludedTrucks && cargo?.arrivalDate.isSame(moment(), 'day')) {
      additionalFooterButtons = [
        {
          onClick: this.showEditModal,
          variant: 'secondary',
          label: this.props.t('mcs.cargo.actions.modify'),
        },
        ...additionalFooterButtons,
      ]
    }

    return (
      <>
        <Modal
          width={75}
          visible={this.state.showModal.type === 'Checkout'}
          onClose={this.props.onClose}
          title={this.props.t('mcs.modals.summaryCargo.title')}
          closeLabel={this.props.t('mcs.modals.close')}
          additionalFooterButtons={additionalFooterButtons}
        >
          {this.state.isFetching || this.state.cargo === null ? (
            <div className="text-center">
              <FontAwesomeIcon icon={faCircleNotch} spin={true} size="3x" className="text-secondary" />
            </div>
          ) : (
            <Row>
              <Column xs={6}>
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.arrivalDate')}
                  value={moment(this.state.cargo!.arrivalDate).format('DD/MM/YYYY')}
                />

                <FieldReadOnly
                  label={this.props.t('mcs.cargo.direction.label')}
                  value={
                    this.props.t(`mcs.cargo.direction.${this.props.filters.direction}`)
                      ? (this.props.t(`mcs.cargo.direction.${this.props.filters.direction}`) as string)
                      : ''
                  }
                />
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.materialType.label')}
                  value={this.state.cargo!.materialType!.description}
                />
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.ticketNumber.label')}
                  value={this.state.cargo!.ticketNumber || ''}
                />
                {this.state.direction === 'incoming' ? (
                  <FieldReadOnly
                    label={this.props.t('mcs.cargo.producer.label')}
                    value={this.state.cargo!.producer || ''}
                  />
                ) : (
                  <FieldReadOnly
                    label={this.props.t('mcs.cargo.recipient.label')}
                    value={this.state.cargo!.recipient || ''}
                  />
                )}
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.carrier.label')}
                  value={this.state.cargo!.carrier || ''}
                />

                <FieldReadOnly
                  label={this.props.t('mcs.cargo.queueTiming')}
                  value={`${moment(this.state.cargo!.queueEnteringAt!).format('HH:mm')} - ${moment(
                    this.state.cargo!.queueExitingAt!
                  ).format('HH:mm')} (${getCargoQueueWaitingTime(this.state.cargo)})`}
                />
                <div className="form-group">
                  <Row>
                    <Column sm={4}>
                      <label className="col-form-label">
                        {this.props.t('mcs.cargo.forkliftDriverNote.label')}
                        <br />
                        {this.state.cargo.forkliftDriverDisplayName ? (
                          <small>({this.state.cargo.forkliftDriverDisplayName})</small>
                        ) : null}
                      </label>
                    </Column>
                    <Column sm={8}>
                      <textarea
                        className="form-control-plaintext"
                        rows={3}
                        readOnly={true}
                        value={
                          (this.state.cargo.forkliftDriverNote || '') +
                          (this.state.cargo.truckAnomalies ? '\n' + this.state.cargo.truckAnomalies : '')
                        }
                      />
                    </Column>
                  </Row>
                </div>
                <TextAreaReadOnly
                  label={this.props.t('mcs.cargo.materialAnomalies.label')}
                  rows={3}
                  value={this.state.cargo.materialAnomalies}
                />
                {this.props.configManager.data.handleWeightKg ? (
                  <Input
                    slug={'handledWeightKg'}
                    readOnly={true}
                    type={'number'}
                    value={this.state.cargo.handledWeightKg.toString()}
                  />
                ) : null}
              </Column>
              <Column xs={6}>
                <FieldReadOnly label={this.props.t('mcs.cargo.plate.label')} value={this.state.cargo!.plate || ''} />
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.parking.label')}
                  value={this.state.cargo!.parking || ''}
                />

                {this.state.direction === 'incoming' ? (
                  <>
                    <FieldReadOnly
                      label={this.props.t('mcs.cargo.samplingConfirmed.label')}
                      value={
                        this.props.t(
                          this.state.cargo!.samplingConfirmed
                            ? 'mcs.cargo.samplingConfirmed.yes'
                            : 'mcs.cargo.samplingConfirmed.no'
                        ) as string
                      }
                      valueVariant={
                        (this.state.cargo!.samplingConfirmed !== this.state.cargo!.samplingRequired && 'danger') ||
                        undefined
                      }
                    />

                    <FieldReadOnly
                      label={this.props.t('mcs.cargo.samplingRequired.label')}
                      value={
                        this.props.t(
                          this.state.cargo!.samplingRequired
                            ? 'mcs.cargo.samplingRequired.yes'
                            : 'mcs.cargo.samplingRequired.no'
                        ) as string
                      }
                      valueVariant={
                        (this.state.cargo!.samplingConfirmed !== this.state.cargo!.samplingRequired && 'danger') ||
                        undefined
                      }
                    />

                    <FieldReadOnly
                      label={this.props.t('mcs.cargo.basin.label')}
                      value={this.state.cargo!.basin || ''}
                    />
                  </>
                ) : null}
                <FieldReadOnly
                  label={this.props.t('mcs.cargo.packages.label')}
                  value={this.state.cargo!.packages || ''}
                />
                <FieldReadOnly label={this.props.t('mcs.cargo.note.label')} value={this.state.cargo.note || ''} />
              </Column>
              {(cargo && cargo.images.length > 0 && (
                <Column xs={12}>
                  {this.state.images.length === 0 && (
                    <Row horizontalAlignment={'start'} verticalAlignment={'center'}>
                      <Column xs={12}>
                        <Button
                          spacing={{ horizontal: false }}
                          isLoading={this.state.isLoadingImages}
                          label={this.props.t('mcs.modals.checkoutCargo.showImages')}
                          variant={'primary'}
                          onClick={() => {
                            this.setState({ isLoadingImages: true })
                            this.props
                              .listImages(this.props.filters.direction, cargo)
                              .then(imagesId =>
                                this.setState({
                                  images: imagesId.map(id => ({ imageId: id, imageURL: undefined })),
                                  isLoadingImages: false,
                                })
                              )
                              .catch(_e => {
                                this.setState({ isLoadingImages: false })
                              })
                          }}
                        />
                      </Column>
                    </Row>
                  )}
                  <Row>
                    {this.state.images.map((objImage, index) => {
                      return (
                        <Column xs={3} key={objImage.imageId}>
                          <RemoteImage
                            imageURL={objImage.imageURL}
                            onImageLoaded={(imageURL: string) => {
                              const newImages = [...this.state.images]
                              newImages[index] = { imageId: newImages[index].imageId, imageURL }
                              this.setState({ images: newImages })
                            }}
                            onSelectImage={imageURL => {
                              this.setState({ showModal: { type: 'FullScreenImage', imageURL }, imagesToDownload: [] })
                            }}
                            onCheckedImage={(imageURL, isSelected) => {
                              const indexFound = this.state.imagesToDownload.findIndex(
                                img => img.imageId === objImage.imageId
                              )
                              let newImageURLS = [...this.state.imagesToDownload]
                              if (isSelected && indexFound >= 0) {
                                newImageURLS[indexFound] = { imageId: objImage.imageId, imageURL }
                              } else if (!isSelected && indexFound >= 0) {
                                newImageURLS = newImageURLS.filter(el => el.imageId !== objImage.imageId)
                              } else if (isSelected && indexFound < 0) {
                                newImageURLS.push({ imageId: objImage.imageId, imageURL })
                              }
                              this.setState({ imagesToDownload: newImageURLS })
                            }}
                            direction={this.props.filters.direction}
                            truck={cargo}
                            imageId={objImage.imageId}
                          />
                        </Column>
                      )
                    })}
                  </Row>
                </Column>
              )) ||
                null}
            </Row>
          )}
        </Modal>
        {this.state.showModal.type === 'EditTodayCargo' && (
          <TodayCargoModal
            action="edit_today_concluded"
            canRescheduleCargo={false}
            onClose={_ => this.closeEditModal()}
            cargoId={this.state.cargo?.id!}
          />
        )}
        {(this.state.showModal.type === 'FullScreenImage' && (
          <Modal
            width={75}
            visible={true}
            title={this.props.t('mcs.modals.checkoutCargo.modal.title')}
            onClose={() => {
              this.setState({ showModal: { type: 'Checkout' } })
            }}
            closeLabel={this.props.t('mcs.modals.checkoutCargo.modal.closeLabel')}
          >
            <img style={{ minWidth: '100%' }} src={this.state.showModal.imageURL} />
          </Modal>
        )) ||
          null}
      </>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SummaryCargoModal)))
