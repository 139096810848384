import { faTruckLoading } from '@fortawesome/free-solid-svg-icons/faTruckLoading'
import CloseDay from './components/views/CloseDay/CloseDayPageView'
import MCSRouter from './components/views/MCSRoutes/MCSRouterPageView'
import WeeklyReportPageView from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/views/WeeklyReport/WeeklyReportPageView'
import { CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { RouteProps } from '../../inplant-coreadapter-fe/types/routes'

const generateWeeklyReport = (): RouteProps[] => {
  const directionsEnv = (window as any).MCS_DIRECTIONS || process.env.REACT_APP_MCS_DIRECTIONS
  const directions: CargoDirections[] =
    directionsEnv !== undefined ? JSON.parse(directionsEnv) : ['incoming', 'outgoing']
  if (directions && Array.isArray(directions)) {
    return directions.map(direction => ({
      path: `/mcs/weekly-report-${direction}`,
      exact: true,
      i18nkey: `mcs.weeklyReport.${direction}.title`,
      component: WeeklyReportPageView,
      visible: true,
    }))
  }
  return []
}

const routes: RouteProps = {
  path: '/mcs/today',
  i18nkey: 'mcs.navigation.mcs',
  icon: faTruckLoading,
  visible: true,
  children: [
    {
      path: '/mcs/today',
      exact: true,
      i18nkey: 'mcs.navigation.today',
      component: MCSRouter,
      visible: true,
    },
    ...generateWeeklyReport(),
    {
      path: '/mcs/view/:date',
      exact: false,
      i18nkey: 'mcs.navigation.view',
      component: MCSRouter,
      visible: false,
    },
    {
      path: '/mcs/close-day/:date',
      exact: false,
      i18nkey: 'mcs.navigation.closeDay',
      component: CloseDay,
      visible: false,
    },
  ],
}

export default routes
