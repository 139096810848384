// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import FlexItem from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import EmptySlotDetail from './EmptySlotDetail'
import Halt from '../../../../types/halt'
import HaltDetail from './HaltDetail'
import Process from '../../../../types/process'
import ProcessDetail from './ProcessDetail'
import Shift from '../../../../types/shift'
import Slot from '../../../../types/slot'
import { PlannerViewTypes } from '../../../../types/viewType'

interface Props {
  slot: Slot
  onClose: () => void
  onClickEditProcess: (process: Process) => void
  viewType: PlannerViewTypes
  configuration: Configuration
  shift: Shift
}

const ModalSlotDetail: React.FC<Props> = props => {
  const { onClose, slot, onClickEditProcess, viewType, shift, configuration } = props
  const { assets, recipes } = configuration

  const translation = useComponentsTranslation()
  const { t } = translation
  const base = 'recipeControl.planner.modalProcessDetail'

  const renderContent = () => {
    if (slot instanceof Process) {
      return (
        <ProcessDetail
          process={slot}
          assets={assets}
          recipes={recipes}
          translation={{ t, base }}
          onClickEditProcess={onClickEditProcess}
          viewType={viewType}
          shift={shift}
          configuration={configuration}
        />
      )
    }
    if (slot instanceof Halt) {
      return <HaltDetail shift={shift} halt={slot} translation={{ t, base }} />
    }
    return <EmptySlotDetail shift={shift} slot={slot} translation={{ t, base }} />
  }

  return (
    <Modal
      title={t(`${base}.title`, {
        date: mvDate.format(slot.day, 'dd/MM/yy'),
        name: slot.getDisplayName(translation),
      })}
      visible={true}
      onClose={onClose}
      closeOnFocusOut={true}
    >
      <div>
        <SectionTitle title={t(`${base}.info`)} />
        {renderContent()}
      </div>
    </Modal>
  )
}

export const Info = (props: { title: string; text: string; date: string } & WithTranslation) => {
  const { t, base } = props.translation

  return (
    <Flex basis={50} grow={0} shrink={0}>
      <FlexItem basis={50}>
        <Text text={props.title} semantic={'light'} />
        <Text text={props.text} />
      </FlexItem>
      <FlexItem basis={50}>
        <Text text={t(`${base}.hour`)} semantic={'light'} />
        <Text text={props.date} />
      </FlexItem>
    </Flex>
  )
}

export default ModalSlotDetail
