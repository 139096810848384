import Period, { Action } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/Period'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export class WeeklyReport extends Period {
  public actionButton = (cargo: Cargo): Action | undefined => undefined

  protected getConfigColumns = (configTables: Tables) => configTables.weeklyReport /*[TableColumnType.materialType,TableColumnType.directionInfo,TableColumnType.rescheduled,TableColumnType.samplingRequired]*/

  public getDropdownAction = (cargo: Cargo): Action[] => []

  public noActionShowText = (cargo: Cargo): boolean => false
}