const en = {
  mcs: {
    navigation: {
      mcs: 'Cargo in/out',
      today: 'Daily schedule:',
    },
    dateSwitcher: {
      today: 'Today'
    },
    today: {
      title: 'Cargo in/out',
      subtitle: 'Daily schedule: {{ date }}',
      show: 'Show',
      incoming: 'Incoming',
      outgoing: 'Outgoing',
      closeDay: 'Close Day',
      addCargo: 'Add Cargo',
      usersInQueue: 'In queue',
      usersUnderProcessing: 'Under processing',
      usersTotalInPlant: 'Total in plant',
      dailyMeanWaitTime: 'Daily mean wait time',
      currentMeanWaitTime: 'Current mean wait time',
      awaited: 'Awaited',
      blocked: 'Blocked',
      handledWeightKg: {
        titleIncoming: 'Incoming busy Kg ',
        titleOutgoing: 'Outgoing busy Kg',
        info: {
          measure: 'Kg: ',
          material: 'Material: ',
        },
      },
      enqueued: 'Enqueued',
      underProcessing: 'Under processing',
      exiting: 'Exiting',
      rescheduled: 'Rescheduled',
      concludedCargos: 'Concluded jobs',
      loading: 'Updating...',
      filter: 'Search cargo:',
      replicaDay: 'Replica Day',
      multiActions: 'Multiple actions',
    },
    archive: {
      subtitle: 'Schedule for {{ date }}',
      canceledCargos: 'Canceled cargos',
      rescheduledCargos: 'Rescheduled cargos',
      concludedCargos: 'Concluded cargos',
      unprocessedCargos: 'Unprocessed',
      noCargosMessage: 'No cargos to display.',
    },
    program: {
      subtitle: 'Schedule for {{ date }}',
    },
    closeDay: {
      subtitle: 'Day closing {{ date }}',
      toRescheduleMandatory: 'To reschedule (CIT)',
      toRescheduleOptional: 'Optionally to reschedule',
      rescheduled: 'Rescheduled',
      proceed: 'Proceed',
      returnToDay: 'Back to day',
      noCargoToClose: 'No cargo to reschedule.',
    },
    cargo: {
      progressiveNumber: {
        label: 'N',
        placeholder: 'Num. Progressivo',
        undefined: '-- undefined --',
      },
      ticketNumber: {
        label: 'Ticket n.',
        placeholder: '-- Insert ticket n. --',
        invalidFeedback: 'Please insert the ticket number.',
      },
      images: {
        label: 'Img.'
      },
      material: {
        label: 'Material',
        undefined: '-- Select material --',
        invalidFeedback: 'Plase select a material.',
        cit: 'CIT',
        multi: 'MULTI',
        ctl: 'CTL',
        cta: 'CTA',
        ctc: 'CTC',
        cte: 'CTE',
        film: 'FILM',
        mpoc: 'MPOC',
        mpof: 'MPOF',
        'plasmix-end-line': 'PLASMIX END LINE',
        'plasmix-bulky': 'PLASMIX BULKY',
        'plasmix-end': 'PLASMIX FINE',
        'plasmix-agg2': 'PLASMIX AGG2',
        'temporary-deposit': 'TEMPORARY DEPOSIT',
        other: 'OTHER',
        iron: 'IRON',
        aluminium: 'ALUMINIUM',
        'iron-wire': 'IRON WIRE',
        fils: 'FILS',
        flexs: 'FLEX/S',
        'cit-flux-b': 'CIT Flusso B',
        'cit-flux-c': 'CIT Flusso C',
        mpr: 'MPR',
        ipp: 'IPP',
        flexc: 'FLEX C',
        flex: 'FLEX',
        'plasmix-agg': 'PLASMIX AGG',
        'plasmix-ing': 'PLASMIX ING',
        mpo: 'MPO',
        'flex-sifted': 'FLEX vagliato',
        'flex-dry-shot': 'FLEX secco sparato',
        'flex-dry-ballistic': 'FLEX secco balistico',
      },
      producer: {
        label: 'Producer',
        placeholder: '-- Insert producer name --',
        invalidFeedback: 'Please insert the producer name.',
      },
      recipient: {
        label: 'Recipient',
        placeholder: '-- Insert recipient name --',
        invalidFeedback: 'Please insert the recipient name.',
      },
      carrier: {
        label: 'Carrier',
        placeholder: '-- Insert carrier name --',
        invalidFeedback: 'Please insert the carrier name.',
      },
      plate: {
        label: 'Plate',
        placeholder: '-- Please insert plate number --',
        invalidFeedback: 'Insert a valid plate number.',
      },
      samplingRequired: {
        label: 'Sampling required',
        no: 'No',
        yes: 'Yes',
        invalidFeedback: 'Select an option',
      },
      samplingConfirmed: {
        label: 'Sampling confirmed',
        no: 'No',
        yes: 'Yes',
      },
      basin: {
        label: 'Basin',
        placeholder: '',
        invalidFeedback: 'Enter basin.',
      },
      packages: {
        label: 'Packages',
        placeholder: '',
        invalidFeedback: 'Insert package number.',
      },
      handleWeightKg: {
        label: 'Kg movimentati',
        placeholder: '',
        invalidFeedback: 'Insert busy Kg.',
      },
      note: {
        label: 'Office notes',
        placeholder: '',
        invalidFeedback: 'Insert some notes here.',
      },
      info: 'Cargo info',
      arrivalDate: 'Arrival date',
      queueTiming: 'Queue timing',
      parking: {
        label: 'Parking',
        undefined: '-- Please select a parking lot --',
        invalidFeedback: 'Insert the parking lot.',
      },
      type: {
        label: 'Cargo type',
        incoming: 'Incoming',
        outgoing: 'Outgoing',
      },
      status: {
        pending_arrival: 'Waiting',
        in_queue: 'In queue',
        requeued_with_priority: 'In queue',
        under_processing: 'Processing',
        concluded: 'Concluded',
      },
      forkliftDriverDisplayName: {
        label: 'Forklift driver',
      },
      materialAnomalies: {
        label: 'Material Anomalies',
      },
      forkliftDriverId: {
        label: 'Forklift driver ID',
      },
      forkliftDriverNote: {
        label: 'Forklift driver notes',
      },
      timeAbbrev: 'time',
      actions: {
        label: '',
        modify: 'Modify',
        welcome: 'Checkin',
        edit: 'Save',
        block: 'Block',
        unblock: 'Unblock',
        reschedule: 'Reschedule',
        delete: 'Delete',
        checkout: 'Checkout',
        requeue: 'Requeue',
        print: 'Print',
        show: 'Detail',
        putBackToPendingArrival: 'Put back to arrivals',
      },
    },
    modals: {
      close: 'Close',
      add: 'Add',
      save: 'Save',
      deleteCargo: {
        title: 'Delete cargo',
        delete: 'Delete',
        cancel: 'Cancel',
        text: 'Are you sure you want to delete the cargo?',
      },
      deleteCargos: {
        title: 'Delete selected cargos',
        delete: 'Delete',
        cancel: 'Cancel',
        text: 'Are you sure you want to delete the selected cargos?',
      },
      checkoutCargo: {
        title: 'Checkout cargo',
        showImages: 'Mostra le immagini',
      },
      summaryCargo: {
        title: 'Summary',
      },
      multiReplica: {
        title: 'MULTI replica',
        text: 'Select the day from which you want to replicate the incoming MULTI cargos.',
        replicate: 'Replicate',
        cargosFound: '{{value}} cargos will be replicated on day {{date}}.',
        times: 'How many times do you want to replicate the cargo?',
      },
    },
    actions: {
      toggleDropdown: 'Toggle dropdown',
    },
    common: {
      closeDayMessage1: 'The day of {{ day }} is not closed.',
      closeDayMessage2: 'to proceed to close it.',
      clickHere: 'Click here',
    },
  },
}

export default en
