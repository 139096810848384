// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import * as moment from 'moment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

// ------------------------- COMPONENTS --------------------------------------
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Column from '@mv-submodules/inplant-components-fe/ui/components/Grid/Column'
import {
  Input,
  MnemonicInputWrapper,
  Select,
  TextArea,
} from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/Inputs'
import { FormModal } from '@mv-submodules/inplant-components-fe/ui/components/Modal/Modal'
import MnemonicInput from '@mv-submodules/inplant-components-fe/ui/components/Input/MnemonicInput'

// ------------------------- MODULE --------------------------------------
import {
  Cargo,
  CargoActions,
  cargoBeToCargo,
  CargoDirections,
  CargoSlugs,
  possibleDirections,
} from '../../../../types/Cargo'
import Filters from '../../../../types/Filters'
import { CommonStore, ConfigManagerStore } from '../../../../types/Store'
import { incomingProducersFetchData } from '../../../../redux/actions/incoming'
import { outgoingRecipientsFetchData } from '../../../../redux/actions/outgoing'
import Material from '../../../../types/Material'
import {
  cargoOpsBlockCargo,
  cargoOpsFetchCargo,
  cargoOpsSaveTodayCargo,
  cargoOpsWelcomeCargo,
  cargoOpsRescheduleCargo,
  cargoOpsSaveConcludedTodayCargo,
} from '../../../../redux/actions/cargoOps'
import { commonFetchParkings } from '../../../../redux/actions/common'
import { FieldReadOnly } from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/Utils/Field'
import { ConfigurableField } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'
import {
  fetchData,
  FetchDataProperty,
  materialsFetchData,
} from '@mv-submodules/inplant-mcs-fe-iblu/redux/actions/cargoDirections'

library.add(faCircleNotch)

interface StateProps {
  filters: Filters
  common: CommonStore
  configManager: ConfigManagerStore
}

interface DispatchProps {
  fetchCargo: (cargoId: string, direction: CargoDirections) => Promise<Cargo>
  fetchMaterials: (direction: CargoDirections) => Promise<Material[]>
  fetchParkings: () => Promise<void>
  welcomeCargo: (cargo: Cargo, direction: CargoDirections) => Promise<void>
  blockCargo: (cargo: Cargo, direction: CargoDirections) => Promise<void>
  saveCargo: (cargo: Cargo, direction: CargoDirections) => Promise<void>
  editConcludedCargo: (cargo: Cargo, direction: CargoDirections) => Promise<void>
  rescheduleCargo: (cargoId: string, newDate: moment.Moment, direction: CargoDirections) => Promise<void>
  fetchOutgoingRecipients: (q?: string) => Promise<string[]>
  fetchIncomingProducers: (q?: string) => Promise<string[]>
  fetchData: (cargoDirections: CargoDirections, property: FetchDataProperty, q?: string) => Promise<string[]>
}

interface OwnState {
  isFetchingCargo: boolean
  isFetchingMaterials: boolean
  isSending: boolean
  isBlocking: boolean
  materials: Material[]
  cargo: Cargo
  direction: CargoDirections
  reschedule: boolean
}

interface OwnProps extends RouteComponentProps<any> {
  cargoId: string
  action: CargoActions
  onClose: (refreshData?: boolean) => void
  canRescheduleCargo: boolean
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
  common: state.mcs.common,
  configManager: state.mcs.configManager,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchCargo: (cargoId, direction) => dispatch(cargoOpsFetchCargo(cargoId, direction)),
    fetchMaterials: direction => dispatch(materialsFetchData(direction)),
    fetchParkings: () => dispatch(commonFetchParkings()),
    welcomeCargo: (cargo, direction) => dispatch(cargoOpsWelcomeCargo(cargo, direction)),
    blockCargo: (cargo, direction) => dispatch(cargoOpsBlockCargo(cargo, direction)),
    saveCargo: (cargo, direction) => dispatch(cargoOpsSaveTodayCargo(cargo, direction)),
    editConcludedCargo: (cargo, direction) => dispatch(cargoOpsSaveConcludedTodayCargo(cargo, direction)),
    rescheduleCargo: (cargoId, newDate, direction) => dispatch(cargoOpsRescheduleCargo(cargoId, newDate, direction)),
    fetchOutgoingRecipients: q => dispatch(outgoingRecipientsFetchData(q)),
    fetchIncomingProducers: q => dispatch(incomingProducersFetchData(q)),
    fetchData: (direction, property, q) => dispatch(fetchData(direction, property, q)),
  }
}

class TodayCargoModal extends React.Component<Props, OwnState> {
  public constructor(props: Props) {
    super(props)
    this.state = {
      isSending: false,
      isFetchingCargo: false,
      isBlocking: false,
      materials: [],
      isFetchingMaterials: false,
      cargo: cargoBeToCargo({ id: props.cargoId }, this.props.filters.direction),
      direction: props.filters.direction,
      reschedule: false,
    }
    this.handleArrivalDateChange = this.handleArrivalDateChange.bind(this)
    this.handleArrivalDateKeyDown = this.handleArrivalDateKeyDown.bind(this)
    this.handleDirectionChange = this.handleDirectionChange.bind(this)
    this.handleMaterialChange = this.handleMaterialChange.bind(this)
    this.handleSamplingChange = this.handleSamplingChange.bind(this)
    this.handlePackagesChange = this.handlePackagesChange.bind(this)
    this.handleWelcomeCargo = this.handleWelcomeCargo.bind(this)
    this.handleSaveCargo = this.handleSaveCargo.bind(this)
    this.handleBlockCargo = this.handleBlockCargo.bind(this)
    this.getDefaultParkingForMaterialType = this.getDefaultParkingForMaterialType.bind(this)
  }

  public async componentDidMount() {
    let allMaterials: Material[] = []
    const materialsPromises: Array<Promise<Material[]>> = possibleDirections(this.props.configManager.data).map(
      direction => {
        return new Promise((resolve, _reject) => resolve(this.props.fetchMaterials(direction)))
      }
    )
    this.setState({ isFetchingMaterials: true })
    await Promise.all(materialsPromises)
      .then(materials => (allMaterials = materials.reduce((a, b) => a.concat(b), [])))
      .catch(e => {
        console.warn(e) //tslint:disable-line
      })
    this.setState({ materials: allMaterials, isFetchingMaterials: false })
    this.props.fetchParkings()
    this.setState({ isFetchingCargo: true })
    this.props
      .fetchCargo(this.props.cargoId, this.props.filters.direction)
      .then(cargo => {
        cargo.parking = this.getDefaultParkingForMaterialType(cargo.materialType)
        this.setState({ isFetchingCargo: false, cargo })
      })
      .catch(e => {
        console.warn(e) //tslint:disable-line
      })
  }

  private handleArrivalDateChange(date: Date) {
    if (moment(date).isValid()) {
      const cargo: Cargo = { ...this.state.cargo, arrivalDate: moment(date) }
      this.setState(prevState => ({ ...prevState, cargo, reschedule: true }))
    }
  }

  private handleArrivalDateKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  private handleDirectionChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.persist()
    this.setState(prevState => {
      const direction = e.target.value as CargoDirections
      const cargo: Cargo = { ...prevState.cargo, materialType: undefined, recipient: '', producer: '', direction }
      return { ...prevState, cargo, direction }
    })
  }

  private handleMaterialChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.persist()
    this.setState(prevState => {
      const material = this.state.materials
        .filter(m => m.direction === this.state.direction)
        .find((m: Material) => m.name === e.target.value)
      const parking = this.getDefaultParkingForMaterialType(material)
      const cargo = { ...prevState.cargo, materialType: material, ticketNumber: null, parking }

      return { ...prevState, cargo }
    })
  }

  private handleChange = (
    slug: CargoSlugs,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    console.log('premuto') //tslint:disable-line
    e.persist()
    this.handleChangeValue(slug, e.target.value)
  }

  private handleChangeValue = (slug: CargoSlugs, value: string | boolean) => {
    this.setState(prevState => {
      const cargo: Cargo = { ...prevState.cargo, [slug]: value }
      return { ...prevState, cargo }
    })
  }

  private handleSamplingChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist()
    this.setState(prevState => {
      const cargo: Cargo = { ...prevState.cargo, basin: null, samplingRequired: e.target.value === 'true' }
      return { ...prevState, cargo }
    })
  }

  private handleChangeBoolean = (slug: CargoSlugs, e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    this.handleChangeValue(slug, e.target.value === 'true')
  }

  private handlePackagesChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist()
    this.setState(prevState => {
      const cargo: Cargo = { ...prevState.cargo, packages: parseInt(e.target.value, 10) || 0 }
      return { ...prevState, cargo }
    })
  }

  private async handleWelcomeCargo(e: React.FormEvent<HTMLFormElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget.checkValidity()) {
      try {
        this.setState({ isSending: true })
        await this.props.welcomeCargo(this.state.cargo, this.state.direction)
        this.setState({ isSending: false })
        this.props.onClose(true)
      } catch (error) {
        console.error(error) // tslint:disable-line
        this.setState({ isSending: false })
      }
    }
  }

  private async handleSaveCargo(e: React.FormEvent<HTMLFormElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    if (e.currentTarget.checkValidity()) {
      try {
        this.setState({ isSending: true })
        if (this.props.action === 'edit_today_concluded') {
          await this.props.editConcludedCargo(this.state.cargo, this.state.direction)
        } else {
          await this.props.saveCargo(this.state.cargo, this.state.direction)
        }
        // we need to take the rescheduling into account here, so we check if the date of arrival has been changed
        // and call the rescheduling function if it needs be
        if (this.state.reschedule) {
          await this.props.rescheduleCargo(this.state.cargo.id!, this.state.cargo.arrivalDate, this.state.direction)
        }
        this.setState({ isSending: false })
        this.props.onClose(true)
      } catch (error) {
        console.error(error) // tslint:disable-line
        this.setState({ isSending: false })
      }
    }
  }

  private async handleBlockCargo(e: React.FormEvent<HTMLButtonElement>) {
    e.persist()
    e.preventDefault()
    e.stopPropagation()
    try {
      this.setState({ isBlocking: true })
      await this.props.blockCargo(this.state.cargo, this.state.direction)
      this.setState({ isBlocking: false })
      this.props.onClose(true)
    } catch (error) {
      this.setState({ isBlocking: false })
      console.error(error) // tslint:disable-line
    }
  }

  private getDefaultParkingForMaterialType(materialType: Material | undefined) {
    if (this.state.cargo.parking) {
      return this.state.cargo.parking
    }
    if (!materialType) {
      return ''
    }
    return materialType.parking
  }

  private getConfigurationFields = (): { [field: string]: ConfigurableField } => {
    const { direction } = this.props.filters
    let configurableFields: ConfigurableField[] = []
    if (direction === 'incoming') {
      configurableFields = this.props.configManager.data.inFields
    }
    if (direction === 'outgoing') {
      configurableFields = this.props.configManager.data.outFields
    }
    if (direction === 'transfer') {
      configurableFields = this.props.configManager.data.tranFields
    }
    return configurableFields.reduce((acc, curr) => ({ ...acc, [curr.slug]: curr }), {})
  }

  private isEdit() {
    return this.props.action === 'edit' || this.props.action === 'edit_today_concluded'
  }

  public render() {
    const configurableFields = this.getConfigurationFields()

    const isFetching = this.state.isFetchingMaterials || this.state.isFetchingCargo

    return (
      <FormModal
        width={75}
        title={this.props.t('mcs.cargo.info')}
        closeLabel={this.props.t('mcs.modals.close')}
        visible={true}
        onClose={this.props.onClose}
        onSubmit={this.isEdit() ? this.handleSaveCargo : this.handleWelcomeCargo}
        submitButton={{
          disabled: isFetching,
          isLoading: this.state.isSending,
          label: this.props.t(this.isEdit() ? 'mcs.cargo.actions.edit' : 'mcs.cargo.actions.welcome'),
        }}
        additionalFooterButtons={
          this.props.action === 'welcome' && this.state.cargo.status === 'pending_arrival'
            ? [
                {
                  variant: 'danger',
                  label: this.props.t('mcs.cargo.actions.block'),
                  onClick: this.handleBlockCargo,
                  disabled: isFetching,
                  isLoading: this.state.isBlocking,
                },
              ]
            : []
        }
      >
        <Row>
          <Column xs={6}>
            {this.props.configManager.data.progressiveTruckNumberEnabled && !(this.props.action === 'welcome') && (
              <FieldReadOnly
                label={this.props.t('mcs.cargo.progressiveNumber.placeholder')}
                value={
                  this.state.cargo.progressiveNumber
                    ? this.state.cargo.progressiveNumber
                    : (this.props.t('mcs.cargo.progressiveNumber.undefined') as string)
                }
              />
            )}
            {this.props.canRescheduleCargo ? (
              <div className={'form-group'}>
                <Row verticalAlignment={'center'}>
                  <Column sm={4}>
                    <label className="col-form-label">{this.props.t('mcs.cargo.arrivalDate')}</label>
                  </Column>
                  <Column sm={8}>
                    <DatePicker
                      selected={moment(this.state.cargo.arrivalDate).toDate()}
                      onChange={this.handleArrivalDateChange}
                      onKeyDown={this.handleArrivalDateKeyDown}
                      dateFormat="dd/MM/yyyy"
                      className="form-control datepicker"
                      required={true}
                      readOnly={this.props.action === 'welcome'}
                      minDate={moment().toDate()}
                    />
                  </Column>
                </Row>
              </div>
            ) : (
              <FieldReadOnly
                label={this.props.t('mcs.cargo.arrivalDate')}
                value={this.state.cargo.arrivalDate.format('DD/MM/YYYY')}
              />
            )}
            <Select
              slug={'direction'}
              onChange={this.handleDirectionChange}
              required={true}
              defaultValue={this.props.filters.direction}
              values={possibleDirections(this.props.configManager.data).map(d => ({ value: d, label: d }))}
              disabled={!!this.state.cargo.id}
            />
            {(this.props.configManager.data.units.enable && (
              <Select
                slug={'units'}
                onChange={this.handleDirectionChange}
                required={true}
                dontTranslateValues={true}
                defaultValue={undefined}
                values={this.props.configManager.data.units.values.map(d => ({ value: d.id, label: d.name }))}
                disabled={!!this.state.cargo.id}
              />
            )) ||
              null}
            <div className="form-group">
              <Row verticalAlignment={'center'}>
                <Column sm={4}>
                  <label className="col-form-label">{this.props.t('mcs.cargo.materialType.label')}</label>
                </Column>
                <Column sm={8}>
                  {this.state.isFetchingMaterials ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-secondary" />
                  ) : (
                    <React.Fragment>
                      <select
                        className="custom-select"
                        onChange={this.handleMaterialChange}
                        required={!this.isEdit() || this.state.direction === 'incoming'}
                        value={this.state.cargo.materialType ? this.state.cargo.materialType.name : ''}
                      >
                        <option key="null" value="" disabled={true}>
                          {this.props.t('mcs.cargo.materialType.undefined')}
                        </option>
                        {this.state.materials
                          .filter(m => m.direction === this.state.direction)
                          .sort((a: Material, b: Material) =>
                            a.description.toLowerCase().localeCompare(b.description.toLowerCase())
                          )
                          .map((material: Material) => {
                            return (
                              <option key={material.name} value={material.name}>
                                {material.description}
                              </option>
                            )
                          })}
                      </select>
                      <div className="invalid-feedback">{this.props.t('mcs.cargo.materialType.invalidFeedback')}</div>
                    </React.Fragment>
                  )}
                </Column>
              </Row>
            </div>
            {configurableFields.ticket_number ? (
              <MnemonicInputWrapper
                required={
                  this.props.filters.direction === 'incoming' &&
                  this.state.cargo.materialType &&
                  this.state.cargo.materialType.requiresTicket
                }
                readOnly={configurableFields.ticket_number.readOnly || !this.state.cargo.materialType?.requiresTicket}
                suggestionFunction={value => this.props.fetchData(this.state.direction, 'ticket-numbers', value)}
                slug={'ticketNumber'}
                onChange={e => this.handleChangeValue('ticketNumber', e)}
                value={this.state.cargo.ticketNumber || ''}
              />
            ) : null}
            {this.state.direction === 'incoming' ? (
              <MnemonicInputWrapper
                suggestionFunction={this.props.fetchIncomingProducers}
                slug={'producer'}
                onChange={e => this.handleChangeValue('producer', e)}
                value={this.state.cargo.producer || ''}
                required={true}
              />
            ) : this.state.direction === 'outgoing' ? (
              <MnemonicInputWrapper
                suggestionFunction={this.props.fetchOutgoingRecipients}
                slug={'recipient'}
                onChange={e => this.handleChangeValue('recipient', e)}
                value={this.state.cargo.recipient || ''}
                required={true}
              />
            ) : null}
            <MnemonicInputWrapper
              suggestionFunction={value => this.props.fetchData(this.state.direction, 'carriers', value)}
              slug={'carrier'}
              onChange={e => this.handleChangeValue('carrier', e)}
              value={this.state.cargo.carrier || ''}
              required={true}
            />
            {this.props.configManager.data.handleWeightKg ? (
              <Input
                slug={'handledWeightKg'}
                readOnly={this.props.action !== 'edit_today_concluded'}
                type={'number'}
                onChange={e => this.handleChange('handledWeightKg', e)}
                value={this.state.cargo.handledWeightKg.toString()}
              />
            ) : null}
          </Column>
          <Column sm={6}>
            <div className="form-group">
              <Row verticalAlignment={'center'}>
                <Column sm={4}>
                  <label className="col-form-label">{this.props.t('mcs.cargo.plate.label')}</label>
                </Column>

                <Column sm={8}>
                  <MnemonicInput
                    className="form-control"
                    onChange={e => this.handleChangeValue('plate', e)}
                    suggestionFunction={value => this.props.fetchData(this.state.direction, 'plates', value)}
                    placeholder={this.props.t('mcs.cargo.plate.placeholder')}
                    required={this.props.action === 'welcome'}
                    pattern="^[^ ][A-Za-z0-9 ]+[^ ]$"
                    value={this.state.cargo.plate}
                  />
                  <div className="invalid-feedback">{this.props.t('mcs.cargo.plate.invalidFeedback')}</div>
                </Column>
              </Row>
            </div>
            <div className="form-group">
              <Row verticalAlignment={'center'}>
                <Column sm={4}>
                  <label className="col-form-label">{this.props.t('mcs.cargo.parking.label')}</label>
                </Column>
                <Column sm={8}>
                  {this.props.common.parkings.fetching ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-secondary" />
                  ) : (
                    <React.Fragment>
                      <select
                        className="custom-select"
                        onChange={e => this.handleChange('parking', e)}
                        required={this.props.action === 'welcome'}
                        value={
                          this.state.cargo.parking
                            ? this.state.cargo.parking
                            : this.getDefaultParkingForMaterialType(this.state.cargo.materialType)
                        }
                      >
                        <option key="null" value="" disabled={true}>
                          {this.props.t('mcs.cargo.parking.undefined')}
                        </option>
                        {this.props.common.parkings.data.map((parking: string) => {
                          return (
                            <option key={parking} value={parking}>
                              {parking}
                            </option>
                          )
                        })}
                      </select>
                      <div className="invalid-feedback">{this.props.t('mcs.cargo.parking.invalidFeedback')}</div>
                    </React.Fragment>
                  )}
                </Column>
              </Row>
            </div>
            {this.props.filters.direction === 'incoming' ? (
              <div className="form-group">
                <Row verticalAlignment={'center'}>
                  <Column sm={4}>
                    <label className="col-form-label">{this.props.t('mcs.cargo.samplingRequired.label')}</label>{' '}
                  </Column>
                  <Column sm={8}>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="samplingRadio"
                        id="samplingRadioNo"
                        className="custom-control-input"
                        checked={this.state.cargo.samplingRequired === false}
                        onChange={this.handleSamplingChange}
                        value="false"
                        required={this.props.action === 'welcome'}
                      />
                      <label className="custom-control-label" htmlFor="samplingRadioNo">
                        {this.props.t('mcs.cargo.samplingRequired.no')}
                      </label>
                      <div
                        className="invalid-feedback"
                        style={{
                          position: 'absolute',
                          top: '1.5rem',
                          left: 0,
                          width: '200px',
                        }}
                      >
                        {this.props.t('mcs.cargo.samplingRequired.invalidFeedback')}
                      </div>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="samplingRadio"
                        id="samplingRadioYes"
                        className="custom-control-input"
                        checked={this.state.cargo.samplingRequired === true}
                        onChange={this.handleSamplingChange}
                        value="true"
                        required={this.props.action === 'welcome'}
                      />
                      <label className="custom-control-label" htmlFor="samplingRadioYes">
                        {this.props.t('mcs.cargo.samplingRequired.yes')}
                      </label>
                    </div>
                  </Column>
                </Row>
              </div>
            ) : null}
            {configurableFields.basin ? (
              <Input
                slug={'basin'}
                onChange={e => this.handleChange('basin', e)}
                value={this.state.cargo.basin || ''}
                required={
                  this.props.configManager.data.isBasinMandatoryIfSamplingIsRequired
                    ? this.state.cargo.samplingRequired === true
                    : false
                }
                readOnly={configurableFields.basin.readOnly}
              />
            ) : null}
            {this.props.filters.direction === 'incoming' ? (
              <Input
                slug={'packages'}
                onChange={this.handlePackagesChange}
                value={this.state.cargo.packages ? this.state.cargo.packages.toString() : ''}
                min={0}
                step={1}
                type="number"
              />
            ) : null}
            {configurableFields.invalid_documents ? (
              <div className="form-group">
                <Row verticalAlignment={'center'}>
                  <Column sm={4}>
                    <label className="col-form-label">{this.props.t('mcs.cargo.invalidDocuments.label')}</label>{' '}
                  </Column>
                  <Column sm={8}>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="invalidDocuments"
                        id="invalidDocumentsNo"
                        readOnly={configurableFields.invalid_documents.readOnly}
                        className="custom-control-input"
                        checked={this.state.cargo.invalidDocuments === false}
                        onChange={e => this.handleChangeBoolean('invalidDocuments', e)}
                        value="false"
                        required={this.props.action === 'welcome'}
                      />
                      <label className="custom-control-label" htmlFor="invalidDocumentsNo">
                        {this.props.t('mcs.cargo.invalidDocuments.no')}
                      </label>
                      <div
                        className="invalid-feedback"
                        style={{
                          position: 'absolute',
                          top: '1.5rem',
                          left: 0,
                          width: '200px',
                        }}
                      >
                        {this.props.t('mcs.cargo.invalidDocuments.invalidFeedback')}
                      </div>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="invalidDocuments"
                        id="invalidDocumentsYes"
                        className="custom-control-input"
                        checked={this.state.cargo.invalidDocuments === true}
                        onChange={e => this.handleChangeBoolean('invalidDocuments', e)}
                        value="true"
                        required={this.props.action === 'welcome'}
                      />
                      <label className="custom-control-label" htmlFor="invalidDocumentsYes">
                        {this.props.t('mcs.cargo.invalidDocuments.yes')}
                      </label>
                    </div>
                  </Column>
                </Row>
              </div>
            ) : null}
            <TextArea slug={'note'} onChange={e => this.handleChange('note', e)} value={this.state.cargo.note || ''} />
          </Column>
        </Row>
      </FormModal>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TodayCargoModal)))
