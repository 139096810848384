// * ------------------- NPM -----------------------------
import React, { useState } from 'react'

// * ------------------- CORE -----------------------------

// * ------------------- COMPONENTS -----------------------------
import AlertErrorData from "../../../../../mvlabs-components-fe/ui/components/Alert/AlertErrorData";
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import CenterLoader from "../../../../../mvlabs-components-fe/ui/components/Loaders/CenterLoader";
import Flex, { JustifyContent, StretchColumn } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import Title, { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import useGenericFetchHook from "../../../../../mvlabs-components-fe/functions/hooks/useGenericFetchHook";
import { useComponentsTranslation } from "../../../../../mvlabs-components-fe/services/translation";

// * ------------------- MODULE -----------------------------
import Configuration from "../../../../types/configuration";
import MachineList from './MachineList'
import ModalDeleteRecipeGroup from "./ModalDeleteRecipeGroup";
import ModalNewConfiguration from './ModalNewConfiguration'
import Recipe from "../../../../types/recipe";
import VidimWrapper from '../../widgets/vidim-wrapper/VidimWrapperHOC';
import { AllPages } from "../../../routes";
import { Asset } from "../../../../types/asset";
import { RecipeGroup } from "../../../../types/recipeGroup";
import { getPlannerConfig } from "../../../../redux/action/getPlannerConfig";

type ModalState =
  | { isVisible: false }
  | { isVisible: true; type: 'create' }
  | { isVisible: true; type: 'edit'; data: RecipeGroup }
  | { isVisible: true; type: 'delete'; data: RecipeGroup }

const ConfigurationPageView = () => {
  const configurationHook = useGenericFetchHook<Configuration>(() => getPlannerConfig())
  const {t} = useComponentsTranslation()
  const base = 'recipeControl.configuration'

  const [modalState, setModalState] = useState<ModalState>({isVisible: false})
  const [machineListOpened, setMachineListOpened] = useState<{ [recipeGroupId: string]: boolean }>()

  const handleCloseModal = () => {
    setModalState({isVisible: false})
  }

  const handleActionComplete = () => {
    configurationHook.retryCall()
    handleCloseModal()
  }

  const handleClickMachineCollapse = (state: 'open' | 'closed', recipeGroupId: string) => {
    setMachineListOpened(prev => ({...prev, [recipeGroupId]: state === 'open'}))
  }

  const renderModals = (data: Configuration) => {
    const {assets, recipes} = data

    if (!modalState.isVisible) {
      return
    }
    if (modalState.type === 'edit' || modalState.type === 'create') {
      return <ModalNewConfiguration
        onActionComplete={handleActionComplete}
        mode={
          modalState.type === 'create' ? {type: modalState.type} : {type: modalState.type, data: modalState.data}
        }
        assets={assets}
        recipes={recipes}
        onClose={handleCloseModal}
        translation={{t, base}}
      />
    }
    return <ModalDeleteRecipeGroup
      onActionComplete={handleActionComplete}
      translation={{t, base}}
      recipeGroup={modalState.data}
      onClose={handleCloseModal}/>
  }


  const renderSuccess = (data: Configuration) => {
    const {recipeGroups, assets, recipes} = data

    return (
      <div className={''}>
        {renderModals(data)}
        <Flex justifyContent={JustifyContent.between}>
          <Title level={1} title={t(`recipeControl.navigation.${AllPages.configuration}`)}/>
          <Button
            semantic={'primary'}
            label={t(`${base}.addConfig`)}
            onClick={() => {
              setModalState({isVisible: true, type: 'create'})
            }}
          />
        </Flex>
        <StretchColumn spaceSize={"md"}>
          <SectionTitle title={t(`${base}.recipeList`)}/>
          {recipeGroups.map(rg => {

            return (
              <MachineList
                title={rg.displayName}
                key={rg.id}
                initialOpened={machineListOpened?.[rg.id] || false}
                onClick={(state) => handleClickMachineCollapse(state, rg.id)}
                list={rg.mappings.reduce((acc: Array<{ asset: Asset, recipe: Recipe }>, curr) => {
                  const asset = curr.getAsset(assets)
                  const recipe = curr.getRecipe(recipes)
                  if (asset && recipe) {
                    return acc.concat({asset, recipe})
                  }
                  return acc
                }, [])}
                translation={{base, t}}
                onClickEdit={() => {
                  setModalState({isVisible: true, type: 'edit', data: rg})
                }}
                onClickDelete={() => {
                  setModalState({isVisible: true, type: 'delete', data: rg})
                }}
              />
            )
          })}
        </StretchColumn>
      </div>
    )

  }

  const renderError = () => {
    return (
      <div className={'mv-right-content'}>
        <Flex justifyContent={JustifyContent.between}>
          <Title level={1} title={t(`recipeControl.navigation.configuration`)}/>
        </Flex>
        <StretchColumn>
          <AlertErrorData/>
        </StretchColumn>
      </div>
    )
  }

  const renderLoading = () => {
    return (
      <div >
        <CenterLoader/>
      </div>
    )
  }

  const renderPossibleCase = (): JSX.Element => {
    switch (configurationHook.state.kind) {
      case 'success':
        return renderSuccess(configurationHook.state.data)
      case 'fetching':
        return renderLoading()
      case 'error':
        return renderError()
      default:
        return renderLoading()
    }
  }

  return (
    <div>
      {renderPossibleCase()}
    </div>
  )
}

export default VidimWrapper(ConfigurationPageView)
