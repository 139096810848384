import { Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Stats } from '../../types/Stats'
import { API } from '.'


export const fetchStats = (abortController: AbortController) => async (dispatch: Dispatch): Promise<Stats> => {
  return API().request('/today-stats', { signal: abortController.signal }).then((stats: Stats) => {
    return stats
  }).catch((error: any) => {
    if (error.name === 'FetchError' && error.statusCode === 401) {
      dispatch(logoutUser())
    }
    if (error.name !== 'AbortError') {
      throw error
    }
  })
}

