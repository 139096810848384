import { API } from './index'
import { Dispatch } from 'redux'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Resize } from 'react-table'


export const incomingProducersFetchData = (q?: string): ((dispatch: Dispatch) => Promise<string[]>) => {
  return async dispatch => {
    try {
      return await API().request(`/incoming/producers${q ? `?q=${q}` : ''}`)
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const saveManageableResizedColumns = (resized: Resize[]) => async (dispatch: Dispatch) => {
  dispatch({
    type: 'MCS_MANAGEABLE_SAVE_RESIZED_COLUMNS',
    resized,
  })
}

export const saveConcludedResizedColumns = (resized: Resize[]) => async (dispatch: Dispatch) => {
  dispatch({
    type: 'MCS_CONCLUDED_SAVE_RESIZED_COLUMNS',
    resized,
  })
}

