// * -------------------------------- NPM --------------------------------------
import * as React from 'react'

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange: (value: string) => void
  suggestionFunction: (value: string) => Promise<string[]>
  suggestionFuncDelay?: number
}

interface State {
  value: string
  suggestions: string[]
  suggestionSelected: number
  suggestionFuncDelay: number
  suggestionFuncTimeout: NodeJS.Timer | null
  loading: boolean
  focused: boolean
  lastOnChangeEvent: React.ChangeEvent<HTMLInputElement> | null
}

class MnemonicInput extends React.Component<Props, State> {
  private inputRef: React.RefObject<HTMLInputElement>
  private ulRef: React.RefObject<HTMLUListElement>

  public constructor(props: Props) {
    super(props)
    this.state = {
      value: '',
      suggestions: [],
      suggestionSelected: 0,
      suggestionFuncDelay: props.suggestionFuncDelay || 250,
      suggestionFuncTimeout: null,
      loading: false,
      focused: false,
      lastOnChangeEvent: null,
    }
    this.inputRef = React.createRef()
    this.ulRef = React.createRef()
    this.onChange = this.onChange.bind(this)
    this.onInputFocus = this.onInputFocus.bind(this)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onSuggestionClick = this.onSuggestionClick.bind(this)
    this.manageFocus = this.manageFocus.bind(this)
    this.manageKeyDown = this.manageKeyDown.bind(this)
    this.fetchSuggestions = this.fetchSuggestions.bind(this)
    this.manageTimeout = this.manageTimeout.bind(this)
  }

  private async fetchSuggestions() {
    if (this.state.value) {
      this.setState({ loading: true })
      try {
        const suggestions = await this.props.suggestionFunction(this.state.value)
        this.setState(currentState => ({
          ...currentState,
          suggestions: currentState.focused ? suggestions : [],
          loading: false,
        }))
      } catch (error) {
        this.setState({ suggestions: [], loading: false })
        console.error(error) //tslint:disable-line
      }
    }
  }

  private manageTimeout() {
    if (this.state.suggestionFuncTimeout) {
      clearTimeout(this.state.suggestionFuncTimeout)
    }
    this.setState({ suggestionFuncTimeout: setTimeout(this.fetchSuggestions, this.state.suggestionFuncDelay) })
  }

  private manageFocus() {
    const activeElement = document.activeElement
    const inputElement = this.inputRef.current
    const ulElement = this.ulRef.current
    if (activeElement !== inputElement || activeElement !== ulElement) {
      this.setState({ suggestions: [] })
    }
  }

  private manageKeyDown(event: KeyboardEvent) {
    const { suggestionSelected, suggestions } = this.state

    if (event.key === 'Tab') {
      this.setState({ suggestions: [], suggestionSelected: 0 })
    }

    if (event.key === 'ArrowDown' && suggestionSelected < suggestions.length - 1) {
      this.setState({ suggestionSelected: suggestionSelected + 1 })
    }

    if (event.key === 'ArrowUp' && suggestionSelected > 0) {
      this.setState({ suggestionSelected: suggestionSelected - 1 })
    }

    if (event.key === 'Enter') {
      if (this.state.suggestions[suggestionSelected]) {
        event.preventDefault()
        this.newSuggestionClicked(this.state.suggestions[suggestionSelected])
      }
    }

    if (event.key === ' ') {
      this.setState({ suggestionSelected: 0 })
    }

    if (event.key === 'Escape') {
      this.setState({ suggestions: [], suggestionSelected: 0 })
    }

  }

  private async onChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist()
    const value = event.target.value
    this.setState({ value, lastOnChangeEvent: event })
    this.props.onChange(value)
    this.manageTimeout()
    if (!this.state.value) {
      this.setState({ suggestions: [] })
    }
  }

  private onInputFocus() {
    this.setState({ focused: true }, () => {
      document.addEventListener('click', this.manageFocus)
      document.addEventListener('keydown', this.manageKeyDown)
    })
  }

  private onInputBlur() {
    this.setState({ focused: false }, () => {
      document.removeEventListener('click', this.manageFocus)
      document.removeEventListener('keydown', this.manageKeyDown)
    })
  }

  private onSuggestionClick(event: React.MouseEvent<HTMLLIElement>) {
    const value = event.currentTarget.dataset.value
    if (value !== undefined) {
      this.newSuggestionClicked(value)
    }
  }

  private newSuggestionClicked(value: string) {
    this.setState({ value, suggestions: [] })
    const onChangeEvent = this.state.lastOnChangeEvent
    if (onChangeEvent !== null) {
      this.props.onChange(value)
      onChangeEvent.target.value = value
    }
  }

  public render() {
    const { suggestionFunction, suggestionFuncDelay, onChange, ...props } = this.props

    return (
      <React.Fragment>
        <input
          {...props}
          ref={this.inputRef}
          type={this.props.type || 'text'}
          className={`mnemonicinput input ${this.props.className || ''}`}
          value={this.state.value || this.props.value || ''}
          onChange={this.onChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
        />

        {this.state.loading ? <div className="mnemonicinput la-ball-scale la-sm" /> : null}

        {this.state.suggestions.length > 0 ? (
          <ul ref={this.ulRef} className="mnemonicinput suggestion-list">
            {this.state.suggestions.map((suggestion, index) => (
              <li
                key={index}
                className={index === this.state.suggestionSelected ? 'highlight' : ''}
                onClick={e => {
                  this.onSuggestionClick(e)
                }}
                data-value={suggestion}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        ) : null}
      </React.Fragment>
    )
  }
}

export default MnemonicInput
