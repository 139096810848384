// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------

// * -------------------------------- MODULE --------------------------------------
import Halt from '../../../../types/halt'
import PlannerSlotComponent from './PlannerSlot'
import { buildContentLevelSlot } from '../../../../functions/buildContentLevelSlot'

// --------------------- Halt
class PlannerSlotHalt extends PlannerSlotComponent<Halt> {
  private t = this.props.translations.t

  protected showTooltip(): boolean {
    return (
      (this.props.slot.notes && this.props.slot.notes.length >= this.CHAR_TRIM) ||
      this.props.percentageDimension < buildContentLevelSlot(this.props.showFullData || this.props.isOneShiftPerDay).levelHalfContent
    )
  }

  protected renderTooltip(): string {
    const halt = this.props.slot
    return this.dateString + ((halt.notes && `\n ${halt.notes}`) || '')
  }

  protected renderShortContent(): React.ReactNode {
    const content = `|**${this.t('recipeControl.planner.slot.halt')}**|`
    const contentTooltip = this.t('recipeControl.planner.slot.halt')
    return this.renderText(content, contentTooltip)
  }

  protected renderHalfContent(): React.ReactNode {
    const content = `|**${this.t('recipeControl.planner.slot.halt')}**|`
    const contentTooltip = this.t('recipeControl.planner.slot.halt')
    return this.renderTextWithDate(content, contentTooltip)
  }

  protected renderAllContent(): React.ReactNode {
    const halt = this.props.slot
    const content = `|**${this.t('recipeControl.planner.slot.halt')}**| ${halt.notes ? `|\n|  |**${this.showLongText(halt.notes)}**|` : ''}`
    const contentTooltip = `${this.t('recipeControl.planner.slot.halt')} ${halt.notes ? `|\n| ${this.showLongText(halt.notes)}` : ''}`
    return this.renderTextWithDate(content, contentTooltip)
  }
}

// const HaltText = (props: { slug: string; notes: string; dateString: string }) => {
//   const { t } = useComponentsTranslation()
//   return <Text text={`|**${t(props.slug)}**| |\n|  |**${props.notes}**|`} />
// }

export default PlannerSlotHalt
