import Period, { Action } from './Period'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export class CloseDay extends Period {
  constructor() {
    super()
  }

  protected getConfigColumns = (configTables: Tables) => {
    return configTables.closeDay
    /* switch (this.type) {
      case 'incoming':
        return  [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.samplingRequired,
          TableColumnType.note,
        ]
      case 'outgoing':
        return [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.note,
        ]
      case 'transfer':
        return [
          TableColumnType.ticketNumber,
          TableColumnType.units,
          TableColumnType.materialType,
          TableColumnType.directionInfo,
          TableColumnType.carrier,
          TableColumnType.plate,
          TableColumnType.note,
        ]
    } */
  }

  public noActionShowText: (cargo: Cargo) => boolean = _cargo => false

  public actionButton: (cargo: Cargo) => Action | undefined = _cargo => undefined

  public getDropdownAction: (cargo: Cargo) => Action[] = _cargo => []
}
