import {
  ErrorWithAddition,
  FetchPromise,
  useLazyGenericFetchHook,
} from '../../../mvlabs-components-fe/functions/hooks/useGenericFetchHook'

export const useLazyCoreFetch: <ReturnType, ParametersType extends any[] = [], AdditionalThenParams extends any[] = []>(
  promise: FetchPromise<ReturnType, ParametersType>,
  then?: (data: ReturnType, ...thenParams: AdditionalThenParams) => void,
  onError?: (error: ErrorWithAddition<{ errors: Record<string, string[]> }>) => void,
  ...thenParams: AdditionalThenParams
) => [
  (...params: ParametersType) => void,
  {
    data: ReturnType | undefined
    loading: boolean
    error: ErrorWithAddition<{ errors: { [key: string]: string[] } }> | undefined
  }
] = useLazyGenericFetchHook
