// * -------------------------------- NPM --------------------------------------
import React, { useEffect, useState } from 'react'
import _uniqueId from 'lodash/uniqueId'

// * -------------------------------- COMPONENT --------------------------------
import Flex, { JustifyContent } from '../Flex/Flex'
import IconComponent from '../Icon/Icon'
import { BackgroundSemantics } from '../../../types/base'
import { IconName } from '../../../services/icon'
import { SectionTitle } from '../Text/Title'

type State = 'open' | 'closed'

/**
 * @param initialDestroyed: if initialOpen = false with initialDestroyed children will not render at init
 */
export interface CollapseProps {
  title: string
  initialOpen?: boolean
  id?: string
  semantics?: BackgroundSemantics

  iconOpen?: IconName
  iconClose?: IconName
  iconPosition?: 'left' | 'right'

  initialChildrenDestroyed?: boolean

  state?: State

  actions?: React.ReactNode

  onClick?: (state: State) => void
}

const Collapse: React.FC<CollapseProps> = ({
  iconOpen = "minus-square",
  iconClose = "plus-square",
  iconPosition = 'left',
  ...props
}) => {
  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- INIT --------------------------------------
  // * ----------------------------------------------------------------------------------------
  const name = 'mv-collapse'
  const { title, initialOpen, actions } = props
  const [uniqueId] = useState(_uniqueId(props.id))

  const [isOpen, setIsOpen] = useState(initialOpen || false)
  const [destroyChildren, setDestroyChildren] = useState<boolean>(props.initialChildrenDestroyed || false)

  useEffect(() => {
    if (props.state !== undefined) {
      setIsOpen(props.state === 'open')
    }
  }, [props.state])

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- BLoS --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function handleClick() {
    if (destroyChildren) {
      setDestroyChildren(false)
    }
    setIsOpen(prev => {
      if (props.onClick) {
        props.onClick(!prev ? 'open' : 'closed')
      }
      return !prev
    })
  }

  // * ----------------------------------------------------------------------------------------
  // * -------------------------------- RENDERs --------------------------------------
  // * ----------------------------------------------------------------------------------------
  function contentClassName(): string {
    if (isOpen) {
      return `${name}__content__collapsible--show`
    }
    return `${name}__content__collapsible--hide`
  }

  function renderContent() {
    if (destroyChildren) {
      return
    }
    return props.children
  }

  function renderHeader() {
    const header = `${name}__header`
    const semantics = props.semantics ? `${header}--${props.semantics}` : ''

    const className = `${header} ${semantics}`

    if (iconPosition === 'left') {
      return (
        <Flex className={className} onClick={handleClick} justifyContent={JustifyContent.between}>
          <Flex>
            <IconComponent icon={isOpen ? iconOpen : iconClose} />
            <SectionTitle title={title} />
          </Flex>
          {actions && <Flex spaceSize={'md'}>{actions}</Flex>}
        </Flex>
      )
    }
    return (
      <Flex justifyContent={JustifyContent.between} onClick={handleClick} className={className}>
        <SectionTitle title={title} />
        <Flex spaceSize={'md'}>
          {actions}
          <IconComponent icon={isOpen ? iconOpen : iconClose} />
        </Flex>
      </Flex>
    )
  }

  return (
    <div className={`${name} ${isOpen ? `${name}--open` : `${name}--close`}`}>
      {renderHeader()}
      <div className={`${name}__content`}>
        <div id={uniqueId} className={`${name}__content__collapsible ${contentClassName()}`}>
          {renderContent()}
        </div>
      </div>
    </div>
  )
}

export default Collapse
