import Period, { Action } from './Period'
import { Cargo } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Tables } from '@mv-submodules/inplant-mcs-fe-iblu/types/ConfigManager'

export class Future extends Period {
  protected getConfigColumns = (configTables: Tables) => {
    return configTables.future /* [
      TableColumnType.ticketNumber,
      TableColumnType.units,
      TableColumnType.materialType,
      TableColumnType.basin,
      TableColumnType.directionInfo,
      TableColumnType.carrier,
      TableColumnType.plate,
      TableColumnType.forkliftDriverDisplayName,
      TableColumnType.packages,
      TableColumnType.note,
      TableColumnType.actions,
    ] */
  }

  public noActionShowText: (cargo: Cargo) => boolean = cargo => {
    return cargo.status === 'in_queue' || cargo.status === 'requeued_with_priority' // TODO come today
  }

  public actionButton: (cargo: Cargo) => Action | undefined = cargo => {
    switch (cargo.status) {
      case 'pending_arrival':
        return { label: 'modify', onClickType: 'add' }
      default:
        return { label: 'unknown', onClickType: 'default' }
    }
  }

  public getDropdownAction: (cargo: Cargo) => Action[] = cargo => {
    switch (cargo.status) {
      case 'pending_arrival':
        return [{ label: 'delete', onClickType: 'delete' }]
      default:
        return []
    }
  }
}
