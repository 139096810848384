// * ------------------- NPM -----------------------------
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// * ------------------- COMPONENTS -----------------------------
import Title from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * ------------------- MODULE -----------------------------
import PlannerWidget, { OwnPlannerWidgetProps } from '../../widgets/Planner/PlannerWidget'
import VidimWrapper from '../../widgets/vidim-wrapper/VidimWrapperHOC'
import { AllPages } from '../../../routes'

const PlannerPageView = () => {
  const { t } = useComponentsTranslation()
  const location = useLocation()

  const [page, setPage] = useState<AllPages>(AllPages.planner)

  useEffect(() => {
    const allPages = [
      AllPages.planner,
      AllPages.weekSummary,
      AllPages.daySummary,
      AllPages.dailyManagement,
      AllPages.weekCompare,
    ]
    const locationPage = allPages.find(p => location.pathname.includes(p))
    if (locationPage) {
      setPage(locationPage)
    }
  }, [])

  const setPlannerProps = (): Omit<OwnPlannerWidgetProps, 'plannerConfig'> => {
    switch (page) {
      case AllPages.weekSummary:
        return { plannerViewType: 'read-only', days: 7 }
      case AllPages.daySummary:
        return { plannerViewType: 'read-only', days: 1 }
      case AllPages.dailyManagement:
        return { plannerViewType: 'attuative', days: 1 }
      case AllPages.weekCompare:
        return { plannerViewType: 'compare', days: 7 }
      case AllPages.planner:
      default:
        return { plannerViewType: 'manager', days: 7 }
    }
  }

  const props = setPlannerProps()

  return (
    <div>
      <Title title={t(`recipeControl.navigation.${page}`)} level={1} />
      <PlannerWidget translation={{t, base: 'recipeControl'}} {...props} />
    </div>
  )
}

export default VidimWrapper(PlannerPageView)
