// ------------------------- NPM --------------------------------------
import * as React from 'react'
import { useTranslation } from 'react-i18next'

// ------------------------- COMPONENTS --------------------------------------
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'

const CloseDayLegend = () => {
  const { t } = useTranslation()

  return (
      <div className={'close-day-legend d-flex flex-row justify-content-start'}>
        <Row flex={true} spacing={{horizontal:false}}>
          <div className={'item'}>
            <div className="square reschedule-mandatory" />
            <label>{t('mcs.closeDay.toRescheduleMandatory')}</label>
          </div>
        </Row>
        <Row flex={true} spacing={{horizontal:false}} >
          <div className={'item'}>
            <div className="square reschedule-optional" />
            <label>{t('mcs.closeDay.toRescheduleOptional')}</label>
          </div>
        </Row>
        <Row flex={true}spacing={{horizontal:false}} >
          <div className={'item'}>
            <div className="square rescheduled" />
            <label>{t('mcs.closeDay.rescheduled')}</label>
          </div>
        </Row>
      </div>
  )
}

export default CloseDayLegend
