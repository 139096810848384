// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { Column } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import { generateSpace, SpaceType } from '../../../../../mvlabs-components-fe/functions/bootstrapUtility'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Slot from '../../../../types/slot'

interface Props {
  processType: 'current' | 'next'
  process: Slot
}

const SlotProcessCountdown = (props: Props) => {
  const { process, processType } = props
  const translation = useComponentsTranslation()
  const base = 'recipeControl.planner.manualShiftModal.countdown'

  return (
    <Column
      grow={1}
      borderRadius={'sm'}
      backgroundColor={processType === 'current' ? 'light' : ''}
      className={`${generateSpace(SpaceType.padding, { val: 3 })} recipecontrol__manual-process-shift--${processType}`}
    >
      <Text
        text={`${translation.t(`${base}.${processType}Process`)} |\n| |**${process.getDisplayName(
          translation
        )}**| |\n| |**${mvDate.format(process.from, TIME)} - ${mvDate.format(process.to, TIME)}**|`}
      />
    </Column>
  )
}

export default SlotProcessCountdown
