// * ------------------- COMPONENTS -----------------------------
import { getModuleSlug } from '../../mvlabs-components-fe/functions/getSlug'
import { mvDate, REQUEST_FORMAT } from '../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import Shift from '../types/shift'
import { ActionType } from '../redux/action/plannerActions'
import { DataToCreateProcess, DataToOverrideProcess } from '../types/others'
import { getInstallationsSlug } from '..'

export const shiftEditable = (shift: Shift, minTimeEditProcess: number): boolean => {
  return !mvDate.isSameOrBeforeMinute(shift.from, mvDate.subtractMinutes(mvDate.now(), minTimeEditProcess))
}

export const createBodyProcesses = (process: DataToCreateProcess, type: ActionType) => {
  let body = {}
  if (type === ActionType.process) {
    body = {
      recipeGroupId: process.recipeGroupId,
      from: mvDate.format(process.from, REQUEST_FORMAT),
      to: mvDate.format(process.to, REQUEST_FORMAT),
      materialBlend: process.materialBlend,
      ...getInstallationBody(),
    }
  }

  if (type === ActionType.halt) {
    body = {
      from: mvDate.format(process.from, REQUEST_FORMAT),
      to: mvDate.format(process.to, REQUEST_FORMAT),
      notes: process.notes,
      ...getInstallationBody(),
    }
  }
  return JSON.stringify(body)
}

export const createOverrideBodyProcesses = (process: DataToOverrideProcess) => {
  return JSON.stringify({
    recipeGroupId: process.recipeGroupId,
    materialBlend: process.materialBlend,
    notes: process.notes,
    ...getInstallationBody(),
  })
}

export const getInstallation = () => {
  return getModuleSlug().replace('-control', '')
}

export const getModule = () => {
  const installation = getInstallation()
  const installationSlugs = getInstallationsSlug()
  return installationSlugs.find(i => i.installationSlug === installation)?.module
}

export const getInstallationParam = (installationSlug?: string) => {
  // return `/${getInstallation()}`
  return `?installationSlug=${installationSlug ? installationSlug : getInstallation()}`
}

export const getInstallationBody = (installationSlug?: string) => {
  // return `/${getInstallation()}`
  return { installationSlug: installationSlug ? installationSlug : getInstallation() }
}
