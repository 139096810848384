import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import { Cargo, cargoBeToCargo, CargoDirections, CargoList } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import { Dispatch } from 'redux'
import { API } from './index'
import * as moment from 'moment'
import Material, { MaterialBE, materialBEToMaterial } from '@mv-submodules/inplant-mcs-fe-iblu/types/Material'

export interface TruckToReplicate {
  id: string
  count: number
}

export enum CargoType {
  default = '',
  archive = '/archive',
}

export const cargoFetchData = (
  cargoDirection: CargoDirections[],
  date: moment.Moment = moment(),
  type: CargoType = CargoType.default
): ((dispatch: Dispatch) => Promise<Cargo[]>) => {
  return async dispatch => {
    try {
      const searchParams = new URLSearchParams(cargoDirection.map(d => ['direction[]', d]))
      const data: CargoList = await API().request(
        `/trucks${type}/${date.format('YYYY-MM-DD')}?${searchParams.toString()}`
      )
      return Object.entries(data)
        .map(([dir, cargos]) => cargos.map(c => cargoBeToCargo(c, dir as CargoDirections)))
        .reduce((acc, curr) => acc.concat(curr), [])
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const materialsFetchData = (cargoDirection: CargoDirections): ((dispatch: Dispatch) => Promise<Material[]>) => {
  return async dispatch => {
    try {
      const data: MaterialBE[] = await API().request(`/${cargoDirection}/material-types`)
      return data.map(d => materialBEToMaterial(d, cargoDirection))
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export type FetchDataProperty = 'plates' | 'carriers' | 'ticket-numbers'

export const fetchData = (
  cargoDirection: CargoDirections,
  property: FetchDataProperty,
  q?: string
): ((dispatch: Dispatch) => Promise<string[]>) => {
  return async dispatch => {
    return API()
      .request(`/${cargoDirection}/${property}${q ? `?q=${q}` : ''}`)
      .then((result: string[]) => result)
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}

export const replicateSchedule = (
  toDate: moment.Moment,
  direction: CargoDirections,
  trucks: TruckToReplicate[]
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    try {
      const bodyParams: any = {
        toDate: toDate.format('YYYY-MM-DD'),
        trucks,
      }
      await API().request(`/${direction}/replicate-schedule-single-trucks`, {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(bodyParams),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const postponeCargos = (
  direction: CargoDirections,
  cargos: Array<{ id: string; toDate: string }>
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    const bodyParams: any = {
      trucks: cargos,
    }
    await API()
      .request(`/${direction}/postpone-schedule-single-trucks`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      })
      .catch((error: any) => {
        if (error.name === 'FetchError' && error.statusCode === 401) {
          dispatch(logoutUser())
        }
        throw error
      })
  }
}

export const cargoOpsSetUnprocessedCargo = (
  cargos: Array<{ id: string }>,
  direction: CargoDirections
): ((dispatch: Dispatch) => Promise<void>) => {
  return async dispatch => {
    const bodyParams: any = {
      trucks: cargos,
    }
    try {
      await API().request(`/${direction}/set-unprocessed-single-trucks`, {
        headers: { 'content-type': 'application/json' },
        method: 'PUT',
        body: JSON.stringify(bodyParams),
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    }
  }
}

export const listImages = (direction: CargoDirections, truck: Cargo) => (dispatch: Dispatch): Promise<string[]> => {
  return API()
    .request(`/${direction}/images/${truck.id}`, {
      headers: { 'content-type': 'application/json' },
      method: 'GET',
    })
    .then((result: string[]) => {
      return result
    })
    .catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const imageUrl = (direction: CargoDirections, imageId: string, truck: Cargo) => (
  dispatch: Dispatch
): Promise<string> => {
  return API()
    .request(`/${direction}/images/${truck.id}/${imageId}`, {
      headers: { 'content-type': 'application/json' },
      method: 'GET',
    })
    .then((result: string) => result)
    .catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}

export const downloadCargoCSV = (direction: CargoDirections, date: moment.Moment) => (
  dispatch: Dispatch
): Promise<string> => {
  return API()
    .request(`/trucks/concluded-csv/${date.format('YYYY-MM-DD')}?direction[]=${direction}`)
    .then((result: string) => result)
    .catch((error: any) => {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      throw error
    })
}