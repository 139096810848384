// * ------------------- NPM -----------------------------
import React from 'react'

// * ------------------- COMPONENTS -----------------------------
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import Collapse from '../../../../../mvlabs-components-fe/ui/components/Collapse/Collapse'
import { ButtonVariants } from '../../../../../mvlabs-components-fe/ui/components/Buttons/types'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'

// * ------------------- MODULE -----------------------------
import Recipe from '../../../../types/recipe'
import { Asset } from '../../../../types/asset'
import TableMachines from '../../widgets/Table/TableMachines'

interface TableType {
  asset: Asset
  recipe: Recipe
}

interface Props extends WithTranslation {
  title: string
  initialOpened: boolean
  list: TableType[]
  onClickEdit: () => void
  onClickDelete: () => void
  onClick: (state: 'open' | 'closed') => void
}

const MachineList = (props: Props) => {
  const { list, translation, title, onClickEdit, onClickDelete, initialOpened, onClick } = props
  const { t } = translation
  const base = props.translation.base + '.machine'

  return (
    <Collapse
      initialOpen={initialOpened}
      title={title}
      onClick={onClick}
      actions={
        <>
          <Button
            semantic={'secondary'}
            variant={ButtonVariants.outline}
            icon={'trash'}
            onClick={e => {
              e.stopPropagation()
              onClickDelete()
            }}
          />
          <Button
            semantic={'secondary'}
            variant={ButtonVariants.outline}
            icon={'pencil-alt'}
            onClick={e => {
              e.stopPropagation()
              onClickEdit()
            }}
          />
        </>
      }
    >
      <SectionTitle title={t(`${base}.list`)} />
      <TableMachines list={list} />
    </Collapse>
  )
}

export default MachineList
