// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Alert from '../../../../../mvlabs-components-fe/ui/components/Alert/Alert'
import Button from '../../../../../mvlabs-components-fe/ui/components/Buttons/Button'
import { groupBy } from '../../../../../mvlabs-components-fe/functions/helpers/objectHelper'
import { mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Configuration from '../../../../types/configuration'
import Shift from '../../../../types/shift'
import Slot from '../../../../types/slot'
import { /* AssetConnection, RecipeMismatch, */ Warning, WarningsType } from '../../../../types/warning'
import { getAlertVariantFromWarning } from '../../../../functions/warning'
import { shiftEditable } from '../../../../functions'

interface Props {
  shifts: Shift[]
  shiftsGroupedForDays: { [key: string]: Shift[] }
  configuration: Configuration
  readonly: boolean
  onClickWarning: (warning: Warning) => void
}

const PlannerBanners = (props: Props) => {
  const { t } = useComponentsTranslation()

  const { configuration, shiftsGroupedForDays, readonly, shifts } = props
  const { alertConfig } = configuration
  const daysToCheck = configuration.confirmationConfig.minDateConfirmShifts

  const tName = 'recipeControl.planner'

  // ------------------------ EMPTY SLOT BANNER ------------------------
  function showBannerEmptySlot(): boolean {
    // there are no empty slot  in the first `daysToCheck` dates
    const check = Object.values(shiftsGroupedForDays)
      .slice(0, daysToCheck)
      .every(shiftsGrouped => shiftsGrouped.every(shift => shift.remainingSlots.length === 0))
    return !check && alertConfig.emptySlot
  }

  // ------------------------ NO DAYS CONFIRMED BANNER ------------------------
  function showBannerDaysNotConfirmed(): boolean {
    const minTimeEditProcess = configuration.minTimeEditProcess
    // all shift of the first `daysToCheck` dates are confirmed
    const check = Object.values(shiftsGroupedForDays)
      .slice(0, daysToCheck)
      .every(shiftsGrouped =>
        shiftsGrouped.filter(shift => shiftEditable(shift, minTimeEditProcess)).every(shift => shift.confirmed)
      )
    return !check && alertConfig.unconfirmedDay
  }

  function handleClickWarnings(warnings: WarningsType[]): void {
    // take only the current warning
    const now = mvDate.now()
    let current: WarningsType | null = null
    warnings.forEach(warning => {
      if (!current) {
        current = { ...warning }
      }
      if (
        mvDate.isSameOrAfterMinute(mvDate.getDateFromString(warning.endedAt), mvDate.getDateFromString(current.endedAt)) &&
        mvDate.isSameOrBeforeMinute(mvDate.getDateFromString(warning.endedAt), now)
      ) {
        current = { ...warning }
      }
    })
    if (current) {
      props.onClickWarning(current)
    }
  }

  // ------------------------ WARNINGS BANNER ------------------------
  function showBannersForWarningMessages() {
    const featuresRemoved = true
    if (featuresRemoved) {
      return null
    }
    // const allWarnings = shifts
    //   .reduce(
    //     (acc: Slot[], curr) =>
    //       acc
    //         .concat(curr.remainingSlots)
    //         .concat(curr.process)
    //         .concat(curr.processHalts),
    //     []
    //   )
    //   .reduce((acc: Warning[], curr) => acc.concat(curr.warnings), [])

      const allWarnings: WarningsType[] = [] 
      shifts.reduce(
        (acc: Slot[], curr) =>
          acc
            .concat(curr.remainingSlots)
            .concat(curr.process)
            .concat(curr.processHalts),
        []
      ).forEach(value => {
        if(value.allWarnings.length > 0) {
          value.allWarnings.forEach(w => allWarnings.push(w))
        }
      })

    const allWarningsGrouped = groupBy(allWarnings, warning => warning.type)
    return Object.entries(allWarningsGrouped).reduce((acc: React.ReactNode[], [warningType, warnings]) => {
      if (warningType && warnings.length >= 1) {
        return acc.concat(
          <Alert
            key={warningType}
            variant={getAlertVariantFromWarning(warnings[0])}
            text={t(`${tName}.banners.${warningType}`)}
            actions={
              <Button
                size={'sm'}
                semantic={'secondary'}
                label={t(`${tName}.banners.details`)}
                onClick={() => handleClickWarnings(warnings)}
              />
            }
          />
        )
      }
      return acc
    }, [])
  }

  if (!readonly) {
    return (
      <>
        {showBannersForWarningMessages()}
        {showBannerDaysNotConfirmed() ? (
          <Alert variant={'danger'} text={`${t(`${tName}.banners.danger`, { value: daysToCheck })}`} />
        ) : null}
        {showBannerEmptySlot() ? <Alert variant={'warning'} text={`${t(`${tName}.banners.warning`)}`} /> : null}
      </>
    )
  }

  return null
}

export default PlannerBanners
