// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------
import Flex from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import FlexItem from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import Modal from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { SectionTitle } from '../../../../../mvlabs-components-fe/ui/components/Text/Title'
import { WithTranslation } from '../../../../../mvlabs-components-fe/types/base'
import { mvDate, TIME } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
// import TableConnection from '../Table/TableConnection'
// import TableRecipeMissmatch from '../Table/TableRecipeMissmatch'
import { Warning } from '../../../../types/warning'

interface Props {
  warning: Warning
  onClose: () => void
}

const ModalWarning: React.FC<Props & WithTranslation> = props => {
  const { onClose, warning } = props
  const { t, base } = props.translation
  return (
    <Modal
      title={t(`${base}.title`, {
        date: mvDate.format(mvDate.getDateFromString(warning.day), 'dd/MM/yy'),
        name: warning.shiftName,
      })}
      visible={true}
      onClose={onClose}
      closeOnFocusOut={true}
    >
      <div>
        <SectionTitle title={t(`${base}.info`)} />

        <Flex>
          <FlexItem basis={25} shrink={0} grow={0}>
            <Text text={t(`${base}.recipeName`)} semantic={'light'} />
            {/* <Text text={warning.recipeGroupDisplayName} /> */}
          </FlexItem>
          <div>
            <Text text={t(`${base}.hour`)} semantic={'light'} />
            <Text
              text={`${mvDate.format(mvDate.getDateFromString(warning.from), TIME)} - ${mvDate.format(
                mvDate.getDateFromString(warning.to),
                TIME
              )}`}
            />
          </div>
        </Flex>
        <SectionTitle title={t(`${base}.listMachine`)} />
        {props.children}
      </div>
    </Modal>
  )
}

const ModalWarningFactory = (Component: React.ComponentType<Props & WithTranslation>) => (props: Props) => {
  const { warning } = props
  const { t } = useComponentsTranslation()
  const base = 'recipeControl.planner.modalProcessDetail'
  const children: React.ReactNode = null
  // if (warning.type === 'recipe-mismatch') {
  //   children = (
  //     <TableRecipeMissmatch
  //       warning={warning}
  //       translation={{
  //         t,
  //         base,
  //       }}
  //     />
  //   )
  // }
  // if (warning.type === 'connection') {
  //   children = (
  //     <TableConnection
  //       warning={warning}
  //       translation={{
  //         t,
  //         base,
  //       }}
  //     />
  //   )
  // }

  return (
    <Component {...props} warning={warning} onClose={props.onClose} translation={{ t, base }}>
      {children}
    </Component>
  )
}

export default ModalWarningFactory(ModalWarning)
