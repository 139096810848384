// * ------------------- COMPONENTS -----------------------------
import { mvDate, REQUEST_FORMAT } from '../../mvlabs-components-fe/functions/helpers/dateHelper'
import { ITranslationService } from '../../mvlabs-components-fe/services/translation'

// * ------------------- MODULE -----------------------------
import Activity, { IActivity } from './activity'
import { Warning } from './warning'

export interface IHalt extends IActivity {
  notes: string
}

class Halt extends Activity {
  get notes() {
    return this._notes
  }

  private readonly _notes: string

  constructor(iHalt: IHalt) {
    super({ ...(iHalt as IActivity) })
    this._notes = iHalt.notes
  }

  public getDisplayName(ts: ITranslationService): string {
    return ts.t('recipeControl.halt')
  }

  get interface(): IHalt {
    return {
      id: this.id,
      to: this.to.toString(),
      from: this.from.toString(),
      day: this.day.toString(),
      confirmed: this.confirmed,
      completed: this.completed,
      notes: this.notes,
      events: this.warning,
      mismatchPercentage: this.mismatchPercentage,
    }
  }

  public copyWith = (
    props?: Partial<{
      day: Date
      to: Date
      from: Date
      id: string
      completed: boolean
      confirmed: boolean
      notes: string
      events: Warning | null
      isSyncConfirmed: boolean
      mismatchPercentage: number
    }>
  ): Halt => {
    return new Halt({
      day: props && props.day !== undefined ? props.day.toString() : this.day.toString(),
      to: props && props.to !== undefined ? props.to.toString() : this.to.toString(),
      from: props && props.from !== undefined ? props.from.toString() : this.from.toString(),
      id: props && props.id !== undefined ? props.id : this.id,
      completed: props && props.completed !== undefined ? props.completed : this.completed,
      confirmed: props && props.confirmed !== undefined ? props.confirmed : this.confirmed,
      notes: props && props.notes !== undefined ? props.notes : this.notes,
      events: (props && props.events) || this.warning,
      mismatchPercentage:
        props && props.mismatchPercentage !== undefined ? props.mismatchPercentage : this.mismatchPercentage,
    })
  }

  public get body(): string {
    return JSON.stringify({
      from: mvDate.format(this.from, REQUEST_FORMAT),
      to: mvDate.format(this.to, REQUEST_FORMAT),
      notes: this.notes,
    })
  }
}

export default Halt
