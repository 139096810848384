import * as React from 'react'
import { withRouter } from 'react-router'
import * as moment from 'moment'
import { fetchWeeklyReportData } from '../../../../redux/actions/weeklyReport'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { WeeklyReport } from '../../../../types/WeeklyReport'
import Filters from '../../../../types/Filters'
import WeeklyReportDateSwitcher from './WeeklyReportDateSwitcher'
import Row from '@mv-submodules/inplant-components-fe/ui/components/Grid/Row'
import Button from '@mv-submodules/inplant-components-fe/ui/components/Button/Button'
import { CargoDirections } from '@mv-submodules/inplant-mcs-fe-iblu/types/Cargo'
import CargoTable from '@mv-submodules/inplant-mcs-fe-iblu/ui/components/widgets/CargoTable/CargoTable'


interface StateProps {
  filters: Filters
}

interface DispatchProps {
  fetchWeeklyReportData: (date: string) => Promise<any>
}

interface State {
  direction: CargoDirections
  isFetching: boolean
  weeklyReport: WeeklyReport
}

type Props = StateProps & DispatchProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  filters: state.mcs.filters,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchWeeklyReportData: date => dispatch(fetchWeeklyReportData(date)),
  }
}

class WeeklyReportPageView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.fetchData = this.fetchData.bind(this)
    this.handlePrintCargos = this.handlePrintCargos.bind(this)
    this.state = {
      direction: window.location.pathname.split('/')[2].split('-')[2] as CargoDirections,
      isFetching: false,
      weeklyReport: {
        days: [],
        endDate: '',
        weekNumber: 0,
        startDate: '',
      },
    }
  }

  public async componentDidMount() {
    await this.fetchData()
  }

  public async componentDidUpdate(prevProps: Props) {
    if (prevProps.filters.date !== this.props.filters.date) {
      await this.fetchData()
    }
  }

  private handlePrintCargos() {
    window.print()
  }

  private async fetchData() {
    this.setState({ isFetching: true })
    this.props.fetchWeeklyReportData(
      this.props.filters && this.props.filters.date
        ? this.props.filters.date.format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    ).then(result => {
      this.setState({ isFetching: false, weeklyReport: result })
    }).catch(e => {
      this.setState({ isFetching: false })
      console.warn(e) //tslint:disable-line
    })
  }


  public render() {
    return (
      <div className='mv4iot-fe-mcs'>
        <header>
          <Row flex={true} horizontalAlignment={'between'}>
            <div>
              <h1 className='title'>{this.props.t(`mcs.weeklyReport.${this.state.direction}.title`)}</h1>
              <h2 className='subtitle'>
                {this.props.t(`mcs.weeklyReport.${this.state.direction}.subtitle`)} {this.state.weeklyReport.weekNumber}{' '}
                {this.props.t('mcs.weeklyReport.subtitleFrom')}{' '}
                {moment(this.state.weeklyReport.startDate).isValid() ? moment(this.state.weeklyReport.startDate).format('DD/MM/YYYY') : ''}
                {this.props.t('mcs.weeklyReport.subtitleTo')}{' '}
                {moment(this.state.weeklyReport.endDate).isValid() ? moment(this.state.weeklyReport.endDate).format('DD/MM/YYYY') : ''}
              </h2>
            </div>
            <div className='d-flex align-items-center'>
              <Button
                spacing={{ vertical: false }}
                type='button'
                variant={'secondary-alternate'}
                onClick={this.handlePrintCargos}
                icon={"print"}
              />
              <WeeklyReportDateSwitcher direction={this.state.direction} />
            </div>
          </Row>
        </header>
        <div className='content'>
          <Row>
            {this.state.weeklyReport.days
              .filter(
                (day, i: number) =>
                  i !== 6 || (i === 6 && day[this.state.direction] && day[this.state.direction].trucks && day[this.state.direction].trucks.length > 0),
              )
              .map((d, i: number) => (
                <div
                  className='col col-4 my-2 px-0'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    border: '1px solid #bfbfbf',
                  }}
                  key={i}
                >
                  <div className='flex flex-column'>
                    <div>
                      <h5 className='text-center mt-2'>
                        {moment(d.date)
                          .format('dddd')[0]
                          .toUpperCase() +
                        moment(d.date)
                          .format('dddd')
                          .substr(1) +
                        ' ' +
                        moment(d.date).format('DD/MM/YYYY')}
                      </h5>
                      <div style={{ borderTop: '1px solid #bfbfbf' }}>
                        <CargoTable
                          className={'ingoingReport'}
                          data={d && d[this.state.direction] ? d[this.state.direction].trucks : []}
                          isLoading={this.state.isFetching}
                          type={{ type: 'weeklyReport' }}
                          direction={this.state.direction}
                        />

                      </div>
                    </div>
                  </div>
                  <h6 className='pt-2 mb-1 pb-1 pl-2 weeklyTableFooter'>
                    {d[this.state.direction] && d[this.state.direction].footer.map((f: string, index: number) => (
                      <div key={index}>{f}</div>
                    ))}
                  </h6>
                </div>
              ))}
          </Row>
        </div>
      </div>
    )
  }
}

export default withRouter<any, any>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(WeeklyReportPageView)),
)
