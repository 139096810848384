// * ------------------- COMPONENTS -----------------------------
import { mvDate, REQUEST_FORMAT } from '../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import Slot, { ISlot } from '../types/slot'

export interface IActivity extends ISlot {
  id: string
}

class Activity extends Slot {
  get id(): string {
    return this._id
  }

  get interface(): IActivity {
    return {
      id: this._id,
      to: this.to.toString(),
      from: this.from.toString(),
      day: this.day.toString(),
      confirmed: this.confirmed,
      completed: this.completed,
      events: this.warning,
      mismatchPercentage: this.mismatchPercentage,
    }
  }

  private readonly _id: string

  constructor(iHalt: IActivity) {
    super(iHalt)
    this._id = iHalt.id
  }

  public copyWith = (props?: Partial<Activity>): Activity => {
    return new Activity({
      day: props && props.day !== undefined ? props.day.toString() : this.day.toString(),
      to: props && props.to !== undefined ? props.to.toString() : this.to.toString(),
      from: props && props.from !== undefined ? props.from.toString() : this.from.toString(),
      id: props && props.id !== undefined ? props.id : this._id,
      completed: props && props.completed !== undefined ? props.completed : this.completed,
      confirmed: props && props.confirmed !== undefined ? props.confirmed : this.confirmed,
      events: (props && props.warning) || this.warning,
      mismatchPercentage:
        props && props.mismatchPercentage !== undefined ? props.mismatchPercentage : this.mismatchPercentage,
    })
  }

  public get body(): string {
    return JSON.stringify({
      from: mvDate.format(this.from, REQUEST_FORMAT),
      to: mvDate.format(this.to, REQUEST_FORMAT),
    })
  }
}

export default Activity
