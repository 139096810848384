// * -------------------------------- NPM --------------------------------------
import React from 'react'

// * -------------------------------- COMPONENTS --------------------------------------

import { buildContentLevelSlot } from '../../../../functions/buildContentLevelSlot'

// * -------------------------------- MODULE --------------------------------------
import PlannerSlotComponent from './PlannerSlot'
import Process from '../../../../types/process'

// --------------------- Process
export class PlannerSlotProcess extends PlannerSlotComponent<Process> {
  protected showTooltip(): boolean {
    const process = this.props.slot

    return (
      (process.materialBlend && process.materialBlend.length >= this.CHAR_TRIM) ||
      this.props.percentageDimension < buildContentLevelSlot(this.props.showFullData || this.props.isOneShiftPerDay).levelHalfContent
    )
  }

  protected renderTooltip(): string {
    const process = this.props.slot

    return this.dateString + ((process.materialBlend && ` '\n' ${process.materialBlend}`) || '')
  }

  protected renderShortContent(): React.ReactNode {
    const process = this.props.slot
    let textToShowOnTooltip = process.recipeGroup.displayName
    let textToShow = `|**${textToShowOnTooltip}**|`
    if (this.showLongText(process.materialBlend).length > 0) {
      textToShow += `|\n||**${this.showLongText(process.materialBlend)}**|`
      textToShowOnTooltip += `|\n|${this.showLongText(process.materialBlend)}`
    }
    return this.renderText(textToShow, textToShowOnTooltip)
  }

  protected renderHalfContent(): React.ReactNode {
    return this.renderAllContent()
  }

  protected renderAllContent(): React.ReactNode {
    const process = this.props.slot

    const longText = this.showLongText(process.materialBlend)
    const content = `|**${process.recipeGroup.displayName}**| ${longText && `|\n||**${this.showLongText(process.materialBlend)}**`}`
    const contentForTooltip = `${process.recipeGroup.displayName} ${longText && `|\n|${this.showLongText(process.materialBlend)}`}`
    return this.renderTextWithDate(content, contentForTooltip)
  }
}

export default PlannerSlotProcess
