// * ------------------- COMPONENT -----------------------------
import { mvDate } from '../../mvlabs-components-fe/functions/helpers/dateHelper'

// * ------------------- MODULE -----------------------------
import Configuration from '../types/configuration'
import Shift from '../types/shift'
import Slot from '../types/slot'
import { PlannerViewTypes } from '../types/viewType'

export const isSlotEditable = (configuration: Configuration, shift: Shift, slot: Slot): boolean => {
  // * let all if explicit!
  const now = mvDate.now()
  const isCurrentShift = mvDate.isAfterSecond(now, shift.from) && mvDate.isBeforeSecond(now, shift.to)
  const shiftIsPast = mvDate.isAfterSecond(now, shift.to)
  
  if (configuration.editConfig.currentShiftEditEnabled && isCurrentShift) {
    return true
  }
  
  if (
    (configuration.editConfig.currentShiftEditEnabled && !shiftIsPast) ||
    mvDate.isAfterMinute(mvDate.subtractMinutes(shift.from, configuration.editConfig.minTimeEditShift), now)
  ) {
    return true
  }

  return false
}

export const isSlotOverride = (configuration: Configuration, shift: Shift, slot: Slot): boolean => {
  // * let all if explicit!
  const now = mvDate.now()
  if (
    configuration.scheduleOverrideEnable &&
    mvDate.isAfterSecond(now, slot.from) &&
    mvDate.isBeforeSecond(now, slot.to) &&
    shift.remainingWorks > 0 &&
    mvDate.isBeforeSecond(now, shift.maximumAllowedOverrideTime)
  ) {
    return true
  }

  return false
}

export const canReadSlotDetail = (plannerViewType: PlannerViewTypes, slot: Slot): boolean => {
  const now = mvDate.now()
  if (
    (plannerViewType === 'read-only' || plannerViewType === 'attuative' || plannerViewType === 'compare' )
  ) {
    return true
  }

  if(plannerViewType === 'manager' && !mvDate.isSameOrAfter(slot.to, now)) {
    return true
  }

  return false
}
