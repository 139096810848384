import { InstallationSlug } from './types/installationSlug'
import generateRoutes, { Modules } from './ui/routes'
import { isJSON } from '@mv-submodules/inplant-core-fe/functions'
import SingleDayCompareTitech from './ui/components/widgets/Dashboard/SingleDayCompareTitech'

export { default as generateRecipeRoutes, AllPages as AllRecipePages, Modules as RecipeModules } from './ui/routes'
export { default as reducers } from './redux/reducers'

export function getInstallationsSlug(): InstallationSlug[] {
  const installationsSlugsEnv =
    process.env.REACT_APP_RECIPES_INSTALLATIONS_SLUG || (window as any).RECIPES_INSTALLATIONS_SLUG
  if (installationsSlugsEnv && isJSON(installationsSlugsEnv)) {
    const installationsSlug: InstallationSlug[] = JSON.parse(installationsSlugsEnv)
    return installationsSlug
  }
  return []
}

export const recipeRoutes = (() => {
  return getInstallationsSlug().map(iS => {
    return generateRoutes(iS.installationSlug, iS.module as Modules)
  })
})()

export const recipeRoutesNoWeekCompare = (() => {
  return getInstallationsSlug().map(iS => {
    return generateRoutes(iS.installationSlug, iS.module as Modules, true)
  })
})()

export const recipeSingleDayCompareTitech = SingleDayCompareTitech
