// * ------------------- NPM -----------------------------
import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'

// * -------------------------------- COMPONENTS --------------------------------------
import Alert from '../../../../../mvlabs-components-fe/ui/components/Alert/Alert'
import Flex, { AlignContent, AlignItems, Direction, Fit } from '../../../../../mvlabs-components-fe/ui/components/Flex/Flex'
import Modal, { ConfirmButton } from '../../../../../mvlabs-components-fe/ui/components/Modal/Modal'
import Radio from '../../../../../mvlabs-components-fe/ui/components/Input/Radio'
import Text from '../../../../../mvlabs-components-fe/ui/components/Text/Text'
import { AlignSelf } from '../../../../../mvlabs-components-fe/ui/components/Flex/FlexItem'
import { ButtonVariants } from '../../../../../mvlabs-components-fe/ui/components/Buttons/types'
import { DAY_MONTH_YEAR, mvDate } from '../../../../../mvlabs-components-fe/functions/helpers/dateHelper'
import { showNotification } from '../../../../../mvlabs-components-fe/ui/components/Notification/Notification'
import { useComponentsTranslation } from '../../../../../mvlabs-components-fe/services/translation'

// * -------------------------------- MODULE --------------------------------------
import Activity from '../../../../types/activity'
import GenericInputComponent from '../FormComponents/GenericInputComponent'
import Halt from '../../../../types/halt'
import HoursInterval from '../FormComponents/HoursInterval'
import Process from '../../../../types/process'
import RecipeSelect from '../FormComponents/RecipeSelect'
import Shift from '../../../../types/shift'
import { ActionType, createProcess, deleteProcess, editProcess } from '../../../../redux/action/plannerActions'
import { FormData as FormDataRecipes } from '../../../../types/formData'
import { ModalEditFactoryProps } from './ModalManageFactory'
import { customPropertiesObject, reduceCustomProperties } from '../../../../functions/customProperties'

interface Result {
  promise: (dispatch: Dispatch) => Promise<Shift[]>
  successMessage: string
}

/*
 * Warning: this page create new data and object(Process, EmptySlot, Shift) are classes not interfaces
 */

const ModalManageSlot = (props: ModalEditFactoryProps) => {
  const { t } = useComponentsTranslation()
  const dispatch = useDispatch()
  // * -----------------------------------------------------------------------
  // * ---------------------------- INIT --------------------------
  // * -----------------------------------------------------------------------
  const { visible, onClose, process, shift, configuration } = props
  const { recipeGroups, haltAdditionalFormData, processAdditionalFormData } = configuration
  const tStart = 'recipeControl.planner.modalManageSlot'

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [canSubmit, setCanSubmit] = useState<boolean>(process.kind === 'edit')
  const [showBannerTimeProblem, setShowBannerTimeProblem] = useState<boolean>(false)

  const [from, setFrom] = useState<Date>(
    mvDate.isSameOrAfter(process.data.from, mvDate.now())
      ? process.data.from
      : mvDate.setSeconds(mvDate.setMinutes(mvDate.now(), 0), 0)
  )
  const [to, setTo] = useState<Date>(process.data.to)
  const [recipeGroupId, setRecipeGroupId] = useState<string>(
    process.kind === 'edit' && process.type === ActionType.process ? process.data.recipeGroup.id : ''
  )
  const [customProprieties, setCustomProprieties] = useState<{ [key: string]: any }>(
    (process.kind === 'edit' && customPropertiesObject(process.data.interface)) || {}
  )

  const [activity, setActivity] = useState<ActionType>(process.kind === 'edit' ? process.type : ActionType.process)
  const isEditing = process.kind === 'edit'

  // * -----------------------------------------------------------------------
  // * ---------------------------- STATE MANAGEMENT --------------------------
  // * -----------------------------------------------------------------------
  useEffect(() => {
    if (
      mvDate.isBeforeMinute(from,to) && // correct date
      mvDate.isSameOrBeforeMinute(to,shift.to) && // inside shift slot
      mvDate.isSameOrAfterMinute(from,shift.from) && // inside shift slot
      !areSomeTimeIntersection() && // no overlap with other process
      mvDate.isSameOrAfter(from, mvDate.now()) // not previous now
    ) {
      if (
        ((recipeGroupId && activity === ActionType.process) || activity === ActionType.halt) &&
        (process.kind === 'create' ? shift.remainingWorks > 0 : true)
      ) {
        setCanSubmit(true)
      } else {
        setCanSubmit(false)
      }
      setShowBannerTimeProblem(false)
    } else {
      setCanSubmit(false)
      setShowBannerTimeProblem(true)
    }
  }, [from, to, recipeGroupId, activity])

  // * -----------------------------------------------------------------------
  // * ---------------------------- BLoS --------------------------
  // * -----------------------------------------------------------------------

  // const reduceCustomProperties: () => { [key: string]: any } = () => {
  //   return Object.entries(customProprieties).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  // }

  const handleChangeInput = (slug: string, value: any) => {
    setCustomProprieties(prev => ({ ...prev, [slug]: value }))
  }

  const getInput = (slug: string): any => {
    return customProprieties?.[slug] || ''
  }

  const areSomeTimeIntersection = (): boolean => {
    for (const proc of shift.process) {
      const sameProc = process.kind === 'edit' && process.data.id === proc.id
      if (!sameProc && mvDate.isBeforeMinute(from, proc.to) && mvDate.isAfterMinute(to, proc.from)) {
        return true
      }
    }
    return false
  }

  const onTimeChange = (type: 'from' | 'to', value: Date) => {
    if (type === 'from') {
      setFrom(value)
    }
    if (type === 'to') {
      setTo(value)
    }
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- SUBMIT --------------------------
  // * -----------------------------------------------------------------------
  const handleSubmit = () => {
    setIsSubmitting(true)
    let result: Result

    switch (activity) {
      case ActionType.halt:
        result = handleSubmitHalt(process.kind, process.data as Halt)
        break
      case ActionType.process:
        result = handleSubmitProcess(process.kind, process.data as Process)
        break
    }

    // promise
    result
      .promise(dispatch)
      .then(shifts => {
        props.updateShifts(shifts)
        setIsSubmitting(false)
        showNotification({
          type: 'success',
          title: t(result.successMessage),
        })
        props.onClose()
      })
      .catch(() => setIsSubmitting(false))
  }

  const handleSubmitHalt = (kind: 'edit' | 'create', halt: Halt): Result => {
    if (kind === 'edit') {
      return {
        promise: editProcess(
          halt.copyWith({
            ...reduceCustomProperties(customProprieties),
            from,
            to,
          }),
          ActionType.halt
        ),
        successMessage: 'recipeControl.planner.notification.successEditProcess',
      }
    }

    return {
      promise: createProcess(
        {
          ...reduceCustomProperties(customProprieties),
          from,
          to,
          recipeGroupId,
        },
        ActionType.halt
      ),
      successMessage: 'recipeControl.planner.notification.successNewProcess',
    }
  }

  const handleSubmitProcess = (kind: 'edit' | 'create', proc: Process): Result => {
    if (kind === 'edit') {
      return {
        promise: editProcess(
          proc.copyWith({
            ...reduceCustomProperties(customProprieties),
            from,
            to,
            recipe: recipeGroups.filter(r => r.id === recipeGroupId)[0],
          }),
          ActionType.process
        ),
        successMessage: 'recipeControl.planner.notification.successEditProcess',
      }
    }
    return {
      promise: createProcess(
        {
          ...reduceCustomProperties(customProprieties),
          from,
          to,
          recipeGroupId,
        },
        ActionType.process
      ),
      successMessage: 'recipeControl.planner.notification.successNewProcess',
    }
  }

  const handleDelete = (actionType: ActionType, data: Activity) => {
    setIsDeleting(true)
    deleteProcess(
      data,
      actionType
    )(dispatch)
      .then(shifts => {
        props.updateShifts(shifts)
        setIsDeleting(false)
        props.onClose()
      })
      .catch(() => setIsDeleting(false))
  }

  // * -----------------------------------------------------------------------
  // * ---------------------------- RENDERs --------------------------
  // * -----------------------------------------------------------------------
  const renderDeleteButton = (): ConfirmButton | undefined => {
    if (process.kind === 'edit') {
      return {
        kind: 'confirmButton',
        semantic: 'danger',
        variant: ButtonVariants.outline,
        label: `${t(`${tStart}.footer.delete`)}`,
        icon: 'trash',
        isDisable: isSubmitting || isDeleting,
        isLoading: isDeleting,
        labelConfirm: `${t(`${tStart}.footer.sureDeleteQuestion`)}`,
        buttonConfirm: {
          semantic: 'danger',
          variant: ButtonVariants.outline,
          label: `${t(`${tStart}.footer.sureDelete`)}`,
          onClick: () => {
            handleDelete(process.type, process.data)
          },
        },
      }
    }
  }

  const renderGenericInput = (formData: FormDataRecipes) => {
    return (
      <GenericInputComponent formData={formData} initialValue={getInput(formData.slug)} onChange={handleChangeInput} />
    )
  }

  const renderProcessForm = () => {
    const proc: Process | undefined =
      process.kind === 'edit' && process.type === ActionType.process ? process.data : undefined
    return (
      <>
        <RecipeSelect onChange={setRecipeGroupId} process={proc} configuration={configuration} />
        {processAdditionalFormData.map(fd => (
          <React.Fragment key={fd.slug}>{renderGenericInput(fd)}</React.Fragment>
        ))}
      </>
    )
  }

  const renderHaltForm = () => {
    return haltAdditionalFormData.map(fd => <React.Fragment key={fd.slug}>{renderGenericInput(fd)}</React.Fragment>)
  }

  const deleteButton = renderDeleteButton()

  return (
    <Modal
      width={50}
      leftFooterContents={deleteButton !== undefined ? [deleteButton] : undefined}
      rightFooterContents={[
        {
          kind: 'button',
          semantic: 'primary',
          onClick: handleSubmit,
          isLoading: isSubmitting,
          isDisable: !canSubmit || isSubmitting || isDeleting,
          label: `${t(`${tStart}.footer.save${isEditing ? 'Edit' : 'Create'}`)}`,
        },
      ]}
      visible={visible}
      onClose={onClose}
      title={`${t(`${tStart}.title.${isEditing ? 'edit' : 'create'}`, {
        value: (isEditing && t(`${tStart}.title.${process.type}`)) || '',
      })} ${mvDate.format(shift.day, DAY_MONTH_YEAR)}, ${shift.name}`}
    >
      <Flex
        direction={Direction.column}
        alignContent={AlignContent.start}
        alignItems={AlignItems.start}
        fit={Fit.oneLine}
        className={'recipecontrol__modal-process'}
      >
        <Text text={`|**${t(`${tStart}.form.infoProcess`)}**|`} />
        {process.kind === 'create' && configuration.haltsEnabled ? (
          <Radio
            onChange={value => {
              setActivity(value as ActionType)
            }}
            items={[
              { label: t(`${tStart}.form.radio.${ActionType.process}`), value: ActionType.process },
              { label: t(`${tStart}.form.radio.${ActionType.halt}`), value: ActionType.halt },
            ]}
            initialValueSelected={activity}
          />
        ) : null}
        <HoursInterval
          initialTime={{ from, to }}
          startTime={shift.from}
          endTime={shift.to}
          onTimeChange={onTimeChange}
          minWorkingDuration={configuration.minWorkingDuration}
        />
        {activity === ActionType.process ? renderProcessForm() : renderHaltForm()}
        <Text
          text={`${t(`${tStart}.info.processAvailable`, {
            value: `|**${shift.remainingWorks.toString()}**|`,
          })}|\n|${t(`${tStart}.info.timeAvailable`, { value: `|**${shift.remainingTimes}**|` })}`}
        />

        <br />
        {showBannerTimeProblem ? (
          <Alert variant={'warning'} text={t(`${tStart}.info.alert.warning`)} alignSelf={AlignSelf.stretch} />
        ) : configuration.confirmationConfig.confirmationEnabled ? (
          <Alert
            variant={'info'}
            text={t(`${tStart}.info.alert.${isEditing ? 'edit' : 'create'}`)}
            alignSelf={AlignSelf.stretch}
          />
        ) : null}
      </Flex>
    </Modal>
  )
}

export default ModalManageSlot
