export interface IRecipe {
  id: string
  displayName: string
}

class Recipe {

  private readonly _id: string
  private readonly _displayName: string

  get id(): string {
    return this._id
  }

  get displayName(): string {
    return this._displayName
  }

  get interface(): IRecipe {
    return {
      id: this._id,
      displayName: this._displayName,
    }
  }

  constructor(props: IRecipe) {
    this._displayName = props.displayName
    this._id = props.id
  }

  public copyWith = (props?: Partial<{ id: string, displayName: string }>): Recipe => {
    return new Recipe({
      id: props && props.id || this._id,
      displayName: props && props.displayName || this._displayName
    })
  }
}

export default Recipe