import * as React from 'react'
import { useTranslation } from 'react-i18next'

const Legend = () => {
  const { t } = useTranslation()

  const itemsLegend: Array<{ color: string; label: string }> = [
    { label: 'awaited', color: 'success' },
    { label: 'blocked', color: 'alert' },
    { label: 'enqueued', color: 'warning' },
    { label: 'underProcessing', color: 'secondary' },
    { label: 'exiting', color: 'light-warning' },
    { label: 'rescheduled', color: 'dark-warning' },
  ]

  return (
    <div className="d-flex flex-row justify-content-start mt-3 legend">
      {itemsLegend.map(item => {
        return (
          <div key={item.label} className="d-flex flex-row item">
            <div className={`square ${item.color}`} />
            <label>{t(`mcs.today.${item.label}`)}</label>
          </div>
        )
      })}
    </div>
  )
}

export default Legend
